import { ChangeEvent, forwardRef, useCallback } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { EditorBlockInputProps } from '../../types'
import styles from './index.module.scss'

const TextEditorBlockInput = forwardRef(
  ({ menuOpened, block: blockValue, onBlockChange }: EditorBlockInputProps, ref) => {
    const handleChange = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement>) => {
        onBlockChange?.({
          ...blockValue,
          value: e.target.value as any,
        })
      },
      [onBlockChange, blockValue]
    )

    return (
      <div className={[styles.text, menuOpened && styles.menuOpened].filter((item) => item).join(' ')}>
        <TextareaAutosize
          className="fs-14 w-100"
          value={blockValue.value as string}
          onChange={handleChange}
          autoComplete="off"
          autoFocus
          placeholder="텍스트를 입력해 주세요."
        />
      </div>
    )
  }
)

export default TextEditorBlockInput
