import { useEffect, useRef, useState } from 'react'
import api from 'src/api'

type Props = {
  defaultUrlKey: string
  placeholder?: string
  showSuccessIcon?: boolean
  needDuplicateCheck?: boolean
  className?: string
  onChange: (companyUrlKey: string) => void
}

export default function CompanyUrlKeyInput({
  defaultUrlKey,
  placeholder,
  showSuccessIcon = false,
  needDuplicateCheck = false,
  className = null,
  onChange,
}: Props) {
  const timeout = useRef(null)

  const [companyUrlKey, setCompanyUrlKey] = useState(defaultUrlKey)
  const [companyUrlKeyErrMsg, setCompanyUrlKeyErrMsg] = useState(null)

  useEffect(() => {
    if (companyUrlKey?.length === 0) {
      onChange(null)
      setCompanyUrlKeyErrMsg('스튜디오 주소를 입력해 주세요')
      return
    }

    if (needDuplicateCheck && companyUrlKey?.length > 0) {
      
      clearTimeout(timeout.current)
      timeout.current = setTimeout(()=> {
        checkDuplicate() 
      }, 200)
    } else {
      setCompanyUrlKeyErrMsg(null)
      onChange(companyUrlKey)
    }
  }, [companyUrlKey])

  const checkDuplicate = ()=> {
    api.company
          .getByUrlKey(companyUrlKey)
          .then((_) => {
            setCompanyUrlKeyErrMsg(null)
            onChange(companyUrlKey)
          })
          .catch((err) => {
            const { status } = err.response
            if (status === 404) {
              setCompanyUrlKeyErrMsg('해당 스튜디오 주소를 찾을 수 없습니다')
              onChange(null)
            }
          })
  }

  const changeCompanyUrlKey = (e: any) => {
    const companyUrlKey = String(e.target.value)
      setCompanyUrlKey(companyUrlKey)

    // // clearTimeout(timeout.current)
    // timeout.current = setTimeout(()=> {
    //   const companyUrlKey = String(e.target.value)
    //   setCompanyUrlKey(companyUrlKey)
    // }, 200)
  }

  const renderInput = () => {
    let inputClassNames = ['input', 'w-100']
    if (companyUrlKeyErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (companyUrlKey?.length > 0 && showSuccessIcon) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <div style={{ position: 'relative' }}>
        <input
          type="companyUrlKey"
          className={inputClassNames.join(' ')}
          placeholder={placeholder}
          required
          value={companyUrlKey}
          onChange={changeCompanyUrlKey}
          style={{ paddingLeft: 126 }}
        />
        <span className={className || 'fs-14 color-grey-600'} style={{ position: 'absolute', top: 10, left: 12 }}>
          www.bindus.kr/c/
        </span>
        {companyUrlKeyErrMsg && <p className="mt-10 color-danger">{companyUrlKeyErrMsg}</p>}
      </div>
    )
  }

  return renderInput()
}
