import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import api from 'src/api'
import { ReferenceRes } from 'src/api/types/reference'
import CompanyProfileImg from 'src/components/Common/CompanyProfileImg'
import { getUserLink, UserProfileImg } from 'src/components/Common/Profile'
import Tab from 'src/components/Common/Tab'
import ReferenceListItem from 'src/components/Home/ReferenceListItem'
import VisitListTabContent from 'src/components/My/VisitListTabContent'
import authState from 'src/recoil/atom/auth'
import Reference from '../Reference'

export default function Index() {
  const navigate = useNavigate()

  const [{ account }] = useRecoilState(authState)

  const [tabIndex, setTabIndex] = useState(null)
  const [referenceList, setReferenceList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    setTabIndex(0)
  }, [])

  useEffect(() => {
    if (tabIndex === 0) {
      fetchInterestReferenceList()
    } else if (tabIndex === 1) {
      fetchInterestCompanyList()
    }
  }, [tabIndex])

  const fetchInterestReferenceList = () => {
    setLoading(true)
    setReferenceList([])
    api.userInterest
      .getPortfolioList()
      .then((res) => {
        setReferenceList(res.data.content || [])
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  const fetchInterestCompanyList = () => {
    setLoading(true)
    setCompanyList([])
    api.userInterest
      .getCompanyList()
      .then((res) => {
        setLoading(false)
        setCompanyList(res.data.content || [])
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  const renderContent = () => {
    if (tabIndex === 0) {
      return renderReferenceList()
    } else if (tabIndex === 1) {
      return renderCompanyList()
    } else {
      return <VisitListTabContent />
    }
  }

  const renderCompanyList = () => {
    if (companyList.length === 0) {
      return (
        <div className="fs-18 w-100 flex-center color-grey-400" style={{ height: 500 }}>
          관심 회사가 없어요
        </div>
      )
    }

    return (
      <ul className="company-list">
        {companyList?.map((item) => {
          return (
            <li
              onClick={() => {
                navigate('/c/' + item.urlKey)
              }}
            >
              <div className="top-area">
                <CompanyProfileImg company={item} size={40} />
                <div>
                  <p className="company-name fs-14 bold">{item.name}</p>
                  <p className="fs-12" style={{ marginTop: 4 }}>
                    {process.env.REACT_APP_BASE_URL}/c/{item.urlKey}
                  </p>
                </div>
              </div>
              <ul className="tags">
                {item.tags?.slice(0, 5).map((tag) => {
                  return <li>{tag.name}</li>
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    )
  }

  const renderReferenceList = () => {
    if (loading) {
      return <div className="spinner with-margin"></div>
    }

    if (!referenceList || referenceList.length === 0) {
      return (
        <div className="fs-18 w-100 flex-center color-grey-400" style={{ height: 500 }}>
          인상적인 레퍼런스가 없어요
        </div>
      )
    }
    return (
      <>
        <ul className="reference-items">
          {referenceList?.map((item: ReferenceRes, index) => {
            return (
              <ReferenceListItem
                item={item}
                key={index}
                onCustomClick={(item) => {
                  setSelectedItem(item)
                }}
              />
            )
          })}
        </ul>
        {selectedItem && (
          <Reference
            referenceId={selectedItem.id}
            goPrev={() => {
              let targetIndex = referenceList.indexOf(selectedItem) - 1
              if (targetIndex < 0) {
                targetIndex = 0
              }
              setSelectedItem(referenceList[targetIndex])
            }}
            goNext={() => {
              let targetIndex = referenceList.indexOf(selectedItem) + 1
              if (targetIndex >= referenceList.length) {
                targetIndex = referenceList.length - 1
              }
              setSelectedItem(referenceList[targetIndex])
            }}
            onHide={() => {
              setSelectedItem(null)
            }}
          />
        )}
      </>
    )
  }

  return (
    <div className="page user-library-page bg-color-grey-50">
      <div className="box-container container">
        <div className="mobile-only bg-white" style={{ padding: '30px 0' }}>
          <div className="user-profile-area mobile-row">
            <UserProfileImg account={account} />
            <div>
              <p className="fs-18 bold">
                <Link to={getUserLink(account)}>{account.name}</Link>
              </p>
              <p className="fs-12 color-text-secondary">
                <Link style={{ marginTop: 4 }} to={getUserLink(account)}>
                  {process.env.REACT_APP_BASE_URL}
                  {getUserLink(account)}
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div className="tab-area">
            <Tab
              titles={['인상적인 레퍼런스', '관심 회사', '방문 기록']}
              onChange={(index) => {
                setTabIndex(index)
              }}
            />
          </div>
          {renderContent()}
        </div>
      </div>
    </div>
  )
}
