import request from 'src/utils/Request'
import { BannerItemRes } from './types/banner'

const categoryApi = {
  // 배너 조회
  getList: (type: 'None' | 'Popup' | 'Main' | 'MainBottom') => {
    return request.get<BannerItemRes[]>('/v1/banner', { params: { type } })
  },
}

export default categoryApi
