import { useBlockerCallback } from 'src/hooks/useBlocker'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { Category, Tag } from 'src/api/types/reference'
import CompanyProfileEditor from 'src/components/BlockEditor'
import { EditorBlocks } from 'src/components/BlockEditor/types'
import ConfirmPopup from 'src/components/Popup/Confirm'
import StudioMaxPortfolioConfirm from 'src/components/Popup/StudioMaxPortfolioPublishConfirm'
import ReferenceEditCategory from 'src/components/Studio/ReferenceEditCategory'
import ReferenceEditDetailInfo from 'src/components/Studio/ReferenceEditDetailInfo'
import ReferenceEditTag from 'src/components/Studio/ReferenceEditTag'
import ReferenceEditThumb from 'src/components/Studio/ReferenceEditThumb'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'

export default function Index() {
  const navigate = useNavigate()

  const setNotificationState = useSetRecoilState(notificationState)
  const { account } = useRecoilValue(authState)

  const [title, setTitle] = useState('')
  const [editorValue, setEditorValue] = useState<EditorBlocks>([])
  const handleOnChange = useCallback((newValue: EditorBlocks) => {
    setEditorValue(newValue)
  }, [])
  const [categories, setCategories] = useState<Category[]>([])
  const [representativeCategoryId, setRepresentativeCategoryId] = useState(null)
  const [tags, setTags] = useState<Tag[]>([])
  const [thumbnail, setThumbnail] = useState(null)
  const [period, setPeriod] = useState(null)
  const [scope, setScope] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [partner, setPartner] = useState(null)

  const [saveDetailInfo, setSaveDetailInfo] = useState(true)

  const location = useLocation()
  const [portfolioId, setPortfolioId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showCancelPopup, setShowCancelPopup] = useState(false)
  const [showPublishPopup, setShowPublishPopup] = useState(false)
  const [showCancelSavePopup, setShowCancelSavePopup] = useState(false)
  const [showMaxPopup, setShowMaxPopup] = useState(false)
  const [showPublishConfirmPopup, setShowPublishConfirmPopup] = useState(false)

  const [mobileStep, setMobileStep] = useState(0)
  const [isDirty, setIsDirty] = useState(false)
  const txRef = useRef(null)
  const saveLoadingRef = useRef(false)

  useBlockerCallback((callback) => {
    setShowCancelSavePopup(true)
    txRef.current = callback
  }, isDirty)

  useEffect(() => {
    if (!location.state) {
      setTimeout(() => {
        setLoading(false)
      }, 100)

      return
    }

    const { portfolioId } = location.state as any
    if (portfolioId) {
      setPortfolioId(portfolioId)
      fetchPortfolio(portfolioId)
    }
  }, [location])

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' })
  }, [mobileStep])

  useEffect(() => {
    if (loading) {
      return
    }
    setIsDirty(true)
  }, [title, editorValue, thumbnail, categories, tags])

  const fetchPortfolio = (portfolioId: number) => {
    setLoading(true)
    api.portfolio
      .get(portfolioId)
      .then((res) => {
        const data = res.data

        setTitle(data.title)
        setTags(data.tags)
        setEditorValue(JSON.parse(data.contentJson))
        setRepresentativeCategoryId(data.representativeCategory?.[0]?.id)
        setThumbnail(data.thumbnailFile)
        setCategories(data.categories)
        setPeriod(data.period)
        setScope(data.scope)
        setCustomer(data.customer)
        setPartner(data.partner)
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 200)
      })
  }

  const save = (callback = null) => {
    if (saveLoadingRef.current) {
      return
    }

    let categoryIds = []
    for (const category of categories) {
      categoryIds.push(category.id)
    }

    let tagIds = []
    for (const tag of tags) {
      tagIds.push(tag.id)
    }

    saveLoadingRef.current = true
    if (portfolioId) {
      api.companyPortfolio
        .update(
          account.company.id,
          portfolioId,
          title,
          JSON.stringify(editorValue),
          categoryIds,
          representativeCategoryId,
          tagIds,
          thumbnail?.uuid,
          period?.trim(),
          scope,
          customer,
          partner
        )
        .then(() => {
          setIsDirty(false)
          if (callback) {
            callback(portfolioId)
          } else {
            setNotificationState({
              type: 'check',
              message: '임시로 저장되었어요',
            })
          }
        })
        .catch((e) => {
          setNotificationState({
            type: 'error',
            message: '네트워크 에러',
          })
        })
        .finally(() => {
          saveLoadingRef.current = false
        })
    } else {
      api.companyPortfolio
        .create(
          account.company.id,
          title,
          JSON.stringify(editorValue),
          categoryIds,
          representativeCategoryId,
          tagIds,
          thumbnail?.uuid,
          period,
          scope,
          customer,
          partner
        )
        .then((res) => {
          setIsDirty(false)
          const portfolioId = res.data.id
          setPortfolioId(portfolioId)
          if (callback) {
            callback(portfolioId)
          } else {
            setNotificationState({
              type: 'check',
              message: '임시로 저장되었어요',
            })
          }
        })
        .catch((e) => {
          console.log(e)
          setNotificationState({
            type: 'error',
            message: '네트워크 에러',
          })
        })
        .finally(() => {
          saveLoadingRef.current = false
        })
    }
  }

  const saveAndActivate = () => {
    if (!title || title?.length === 0) {
      setNotificationState({
        type: 'error',
        message: '레퍼런스 제목을 입력해주세요',
      })
      return
    }

    if (!thumbnail) {
      setNotificationState({
        type: 'error',
        message: '레퍼런스 썸네일 이미지를 등록해 주세요',
      })
      return
    }

    if (tags.length === 0) {
      setNotificationState({
        type: 'error',
        message: '레퍼런스 태그를 등록해 주세요',
      })
      return
    }

    if (categories.length === 0) {
      setNotificationState({
        type: 'error',
        message: '레퍼런스 카테고리를 등록해 주세요',
      })
      return
    }

    if (!representativeCategoryId) {
      setNotificationState({
        type: 'error',
        message: '대표 카테고리를 등록해 주세요',
      })
      return
    }

    if (!editorValue || editorValue.length === 0) {
      setNotificationState({
        type: 'error',
        message: '내용을 등록해 주세요',
      })
      return
    }

    if (saveDetailInfo && !period && !scope && !customer && !partner) {
      setNotificationState({
        type: 'error',
        message: '레퍼런스 상세정보를 입력해 주세요',
      })
      return
    }

    save((portfolioId: number) => {
      api.companyPortfolio
        .publish(account.company.id, portfolioId)
        .then(() => {
          setNotificationState({
            type: 'check',
            message: '게시되었습니다',
          })

          navigate('/studio/reference')
        })
        .catch((e) => {
          if (e.response?.status === 400) {
            setShowMaxPopup(true)
            return
          }

          setNotificationState({
            type: 'error',
            message: '네트워크 에러',
          })
        })
    })
  }

  if (loading) {
    return <div className="spinner with-margin"></div>
  }

  const renderHeader = () => {
    return (
      <div className="mobile-only mobile-header">
        <ul className="mb-20 circle-pagination mobile-only">
          <li className={mobileStep === 0 ? 'active' : ''}></li>
          <li className={mobileStep === 1 ? 'active' : ''}></li>
        </ul>
        <p className="fs-24 bold text-center">{mobileStep === 0 ? '레퍼런스 내용 작성' : '레퍼런스 정보 작성'}</p>
      </div>
    )
  }

  const renderFooter = () => {
    if (mobileStep === 0) {
      return (
        <div className="mobile-only mobile-footer btn-wrap page-foot-btn">
          <button
            className="btn w-100"
            onClick={() => {
              setShowCancelPopup(true)
            }}
          >
            취소
          </button>
          <button
            className="btn w-100"
            onClick={() => {
              save()
            }}
          >
            임시저장
          </button>
          <button
            className="btn btn-primary w-100"
            onClick={() => {
              setMobileStep(1)
            }}
          >
            다음
          </button>
        </div>
      )
    } else {
      return (
        <div className="mobile-only btn-wrap page-foot-btn">
          <button
            className="btn"
            onClick={() => {
              setMobileStep(0)
            }}
          >
            이전
          </button>
          <button
            className="btn"
            onClick={() => {
              save()
            }}
          >
            임시저장
          </button>
          <button
            className="btn btn-primary w-100"
            onClick={() => {
              setShowPublishConfirmPopup(true)
            }}
          >
            게시
          </button>
        </div>
      )
    }
  }

  return (
    <div className="page reference-edit-page studio-edit-page studio-page">
      {renderHeader()}
      <div className="container box-container">
        <div className={`main-area w-100 ${mobileStep === 1 ? 'pc-only' : ''}`}>
          <div>
            <input
              type="text"
              className="input input-lg mb-20 w-100 fs-22 title-input"
              placeholder="레퍼런스의 제목을 입력 해 주세요."
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
            <CompanyProfileEditor blocks={editorValue} onBlocksChange={handleOnChange} />
          </div>
        </div>
        <div className={`side-area ${mobileStep === 0 ? 'pc-only' : ''}`}>
          <div className="box">
            <p className="fs-16 bold">썸네일 이미지</p>
            <ReferenceEditThumb
              defaultThumb={thumbnail}
              onChange={(thumbnail) => {
                setThumbnail(thumbnail)
              }}
            />
            <p className="mt-30 fs-16 bold">레퍼런스 카테고리</p>
            <p className="fs-14 color-grey-700" style={{ margin: '4px 0 14px' }}>
              카테고리 추가 후 <span className="color-red-700">대표 카테고리 1개</span>만 선택해 주세요.
            </p>
            <ReferenceEditCategory
              defaultCategories={categories}
              defaultRepresentativeCategoryId={representativeCategoryId}
              maxCount={account.company.plan.plan.maxPortfolioCategory}
              onChange={(items: Category[]) => {
                setCategories(items)
              }}
              onChangeRepresentId={(categoryId: number) => {
                setRepresentativeCategoryId(categoryId)
              }}
            />
            <p className="mt-30 fs-16 bold">레퍼런스 태그</p>
            <ReferenceEditTag
              defaultTags={tags}
              maxCount={account.company.plan.plan.maxPortfolioTag}
              onChange={(tags: Tag[]) => {
                setTags(tags)
              }}
            />
            <p className="mt-30 fs-16 bold">레퍼런스 상세정보</p>
            <p className="fs-14 color-grey-700" style={{ margin: '4px 0 14px' }}>
              고객사 또는 협력사가 레퍼런스를 더 잘 이해할 수 있도록 상세정보를 입력해 주세요.
            </p>
            <ReferenceEditDetailInfo
              defaultPeriod={period}
              defaultScope={scope}
              defaultCustomer={customer}
              defaultPartner={partner}
              defaultIsChecked={portfolioId && !period && !scope && !customer && !partner}
              onChange={(period, scope, customer, partner, isChecked) => {
                setPeriod(period)
                setScope(scope)
                setCustomer(customer)
                setPartner(partner)
                setSaveDetailInfo(!isChecked)
              }}
            />
            <div className="btn-wrap pc-only" style={{ marginTop: 50 }}>
              <button
                className="btn"
                onClick={() => {
                  setShowCancelPopup(true)
                }}
              >
                취소
              </button>
              <button
                className="btn"
                onClick={() => {
                  save()
                }}
              >
                임시저장
              </button>
            </div>
            <button
              className="mt-10 btn btn-primary w-100 pc-only"
              onClick={() => {
                setShowPublishConfirmPopup(true)
              }}
            >
              게시
            </button>
          </div>
        </div>
        {renderFooter()}
      </div>
      <div className="pc-only w-100" style={{ height: 150 }}></div>
      {showCancelPopup && (
        <ConfirmPopup
          title="레퍼런스 작성을 취소하시겠습니까?"
          okText="취소"
          cancelText="아니요"
          onOk={() => {
            setShowCancelPopup(false)
            navigate('/studio/reference')
          }}
          onCancel={() => {
            setShowCancelPopup(false)
          }}
        />
      )}
      {showPublishPopup && (
        <ConfirmPopup
          title="레퍼런스를 게시했어요"
          onOk={() => {
            setShowPublishPopup(false)
          }}
          onCancel={() => {
            setShowPublishPopup(false)
          }}
        />
      )}
      {showMaxPopup && (
        <StudioMaxPortfolioConfirm
          onHide={() => {
            setShowMaxPopup(false)
          }}
        />
      )}
      {showPublishConfirmPopup && (
        <ConfirmPopup
          title="레퍼런스를 공개로 게시합니다"
          body="공개/비공개 여부는 레퍼런스 관리페이지에서<br/>설정하실 수 있습니다."
          okText="공개로 게시"
          cancelText="취소"
          onOk={() => {
            saveAndActivate()
            setShowPublishConfirmPopup(false)
          }}
          onCancel={() => {
            setShowPublishConfirmPopup(false)
          }}
        />
      )}
      {showCancelSavePopup && (
        <ConfirmPopup
          title="저장하지 않고 페이지를 벗어나면<br/>변경한 내용이 사라집니다"
          okText="저장"
          cancelText="저장안함"
          onOk={() => {
            save()
            setShowCancelSavePopup(false)
          }}
          onCancel={() => {
            setIsDirty(false)
            setShowCancelSavePopup(false)

            setTimeout(() => {
              txRef.current()
            }, 200)
          }}
        />
      )}
    </div>
  )
}
