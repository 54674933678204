import { useState } from 'react'
import StudioActivate from 'src/components/Popup/StudioActivate'

export default function ActivateStudio() {
  const [showActivatePopup, setShowActivatePopup] = useState(true)

  return (
    <>
      {showActivatePopup && (
        <StudioActivate
          onHide={() => {
            setShowActivatePopup(false)
          }}
        />
      )}
    </>
  )
}
