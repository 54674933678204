import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { Message } from 'src/api/types/message'
import MessageBox from 'src/components/Header/MessageBox'
import ProfileBox from 'src/components/Header/ProfileBox'
import SearchInput from 'src/components/Header/SearchInput'
import authState from 'src/recoil/atom/auth'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import settingState from 'src/recoil/atom/setting'
import reportClickEvent from 'src/utils/Gtm'
import CompanyProfileImg from '../Common/CompanyProfileImg'
import { UserProfileImg } from '../Common/Profile'
import Confirm from '../Popup/Confirm'
import MobileCategoryButton from './MobileCategoryButton'
import MobileSearchInput from './MobileSearchInput'

function Header() {
  const setPopupState = useSetRecoilState(popupState)
  const location = useLocation()
  const navigate = useNavigate()
  const { waiting, account } = useRecoilValue(authState)

  const [showAside, setShowAside] = useState(false)
  const [showMessageBox, setShowMessageBox] = useState(false)
  const [showProfileBox, setShowProfileBox] = useState(false)
  const [showAdminChangedPopup, setShowAdminChangedPopup] = useState(false)

  const [auth, setAuth] = useState(null)
  const [userMessages, setUserMessages] = useState<Message[]>([])
  const [companyMessages, setCompanyMessages] = useState<Message[]>([])

  const [{ isStudioMode }, setSettingState] = useRecoilState(settingState)

  useEffect(() => {
    const isProduction = window.location.host === 'www.bindus.kr'
    if (isProduction) {
      setInterval(() => {
        fetchUnreadList()
      }, 3000)
    }

    fetchUnreadList()
  }, [])

  useEffect(() => {
    if (!account) {
      return
    }
    fetchUnreadList()
  }, [account])

  useEffect(() => {
    if (showAside) {
      document.body.classList.add('show-aside')
    } else {
      document.body.classList.remove('show-aside')
    }

    return () => { }
  }, [showAside])

  const fetchUnreadList = () => {
    if (!account) {
      return
    }

    api.message
      .getUnreadList()
      .then((response) => {
        setUserMessages(response.data)
      })
      .catch((_) => {
        setUserMessages([])
      })

    if (account.company) {
      api.companyMessage
        .getUnreadList(account.company.id)
        .then((response) => {
          setCompanyMessages(response.data)
        })
        .catch((_) => {
          setCompanyMessages([])
        })
    }

    api.accountCompany
      .getInvite()
      .then((res) => {
        setAuth(res.data ? res.data[0] : null)
      })
      .catch(() => {
        // Do nothing
      })
  }

  const toggleShowAside = () => {
    setShowAside(!showAside)
  }

  const signIn = () => {
    setPopupState({ popupType: MainPopupType.SignIn })

    reportClickEvent('header', 'login', '로그인')
  }

  const signUp = () => {
    setPopupState({ popupType: MainPopupType.SignUp })

    reportClickEvent('header', 'signup', '가입하기')
  }

  const goHome = () => {
    setPopupState({ popupType: MainPopupType.None })

    // if (location.pathname === '/' && !location.search) {
    //   navigate(0)
    // } else if (location.pathname.startsWith('/main/')) {
    //   navigate(location.pathname)
    // } else {
    //   navigate('/')
    // }
    if (location.pathname === '/' && !location.search) {
      navigate(0)
    } else {
      navigate('/')
    }
  }

  const hideAllPopup = () => {
    setShowMessageBox(false)
    setShowProfileBox(false)
  }

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (e.target.closest('.message-box') || e.target.closest('.profile-box')) {
          return
        }

        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const ref = useOutsideClick(hideAllPopup)

  const renderLeftArea = () => {
    return (
      <div className="left-area">
        <div className="menu flex-center" onClick={toggleShowAside}>
          <i className="icon" />
        </div>
        <img src="/static/img/logo.svg" alt="Bindus" className="logo pc-only" onClick={goHome} />
        <MobileCategoryButton />
        <div className='pc-only link-area' style={{ marginLeft: 50 }}>
          <Link to="/main/creative" className={`fs-18 bold ${location.pathname.indexOf('/main/creative') !== -1 && 'active'}`}>크리에이티브</Link>
          <Link to="/main/tech" className={`fs-18 bold ${location.pathname.indexOf('/main/tech') !== -1 && 'active'}`} style={{ marginLeft: 24 }}>테크</Link>
        </div>
      </div>
    )
  }

  const renderStudioButton = () => {
    if (account?.company === null) {
      return <Link className="btn btn-studio-gradient btn-sm mr-20" to="/studio/create">
        스튜디오
      </Link>
    }
    else if (isStudioMode) {
      return <Link className="btn btn-third btn-sm" to="/my/profile" style={{ marginRight: 14, padding: '0 4px' }}>
        개인으로 전환
      </Link>
    } else {
      return <Link className="btn btn-third btn-sm" to="/studio/profile" style={{ marginRight: 14, padding: '0 4px' }}>
        스튜디오 전환
      </Link>
    }
  }

  const renderProfileImg = () => {
    if (location.pathname.indexOf('/studio') !== -1 &&
      location.pathname.indexOf('/studio/create') && account?.company) {
      return <CompanyProfileImg company={account.company} size={24} clickable={false} />
    }
    else {
      return <UserProfileImg account={account} size={24} clickable={false} />
    }

  }

  const renderRightArea = () => {
    if (waiting) {
      return <></>
    }

    if (account === null) {
      return (
        <div className="right-area">
          <button className="btn login-btn" onClick={signIn}>
            로그인
          </button>
          <button className="btn btn-primary signup-btn" style={{ marginLeft: 12 }} onClick={signUp}>
            가입하기
          </button>
        </div>
      )
    } else {
      return (
        <div className="right-area" ref={ref}>
          {renderStudioButton()}
          <button
            className={`message-btn ${userMessages.length > 0 || companyMessages.length > 0 || auth ? 'has-noti' : ''}`}
            onClick={(e) => {
              setShowMessageBox(!showMessageBox)
              setShowProfileBox(false)
            }}
          />
          <div className='info-btn'
            onClick={(e) => {
              setShowProfileBox(!showProfileBox)
              setShowMessageBox(false)
            }}>

            {renderProfileImg()}
          </div>
        </div >
      )
    }
  }

  return (
    <header>
      <div className="header-container">
        {renderLeftArea()}
        <SearchInput />
        {renderRightArea()}
      </div>
      <MobileSearchInput />
      {showMessageBox && (
        <MessageBox
          onHide={() => {
            fetchUnreadList()
            hideAllPopup()
          }}
          onChangeAdmin={() => {
            setShowAdminChangedPopup(true)
          }}
          auth={auth}
          userMessages={userMessages}
          companyMessages={companyMessages}
        />
      )}
      {showProfileBox && <ProfileBox onHide={hideAllPopup} />}
      {showAdminChangedPopup && (
        <Confirm
          title={`<span class="color-primary">${auth?.company?.name}</span>의 스튜디오 관리자가 되었어요!<br/>스튜디오를 살펴보고 활동을 시작하세요`}
          okText={'스튜디오로 이동'}
          onOk={() => {
            navigate('/studio/profile')
            setShowAdminChangedPopup(false)
          }}
        />
      )}
    </header>
  )
}

export default Header
