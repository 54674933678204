import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { AccountCopyLink } from 'src/components/Common/CopyLink'
import { UserProfileImg } from 'src/components/Common/Profile'
import StudioSettingActivate from 'src/components/Popup/StudioActivate'
import StudioSettingDeactivate from 'src/components/Popup/StudioSettingDeactivate'
import StudioSettingDelete from 'src/components/Popup/StudioSettingDelete'

import ChangeAuthButton from 'src/components/Studio/ChangeAuthButton'
import useAuth from 'src/hooks/useAuth'
import authState from 'src/recoil/atom/auth'

export default function StudioSetting() {
  const navigate = useNavigate()
  const [{ account }] = useRecoilState(authState)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [showDeactivatePopup, setShowDeactivatePopup] = useState(false)
  const [showActivatePopup, setShowActivatePopup] = useState(false)
  const { logout } = useAuth()

  if (!account || !account.company) {
    return <></>
  }

  return (
    <div className="page bg-color-grey-50">
      <div className="box-container container">
        <div className="box">
          <p className="fs-16 bold">회사 계정 관리</p>
          <p className="mt-20 fs-14 color-grey-700">회사 이름</p>
          <p className="mt-10 fs-16 bold">{account.company?.name}</p>
          <p className="mt-20 fs-14 color-grey-700">
            관리자 정보
            <div className="float-right">
              <ChangeAuthButton />
            </div>
          </p>
          <div className="mt-10 profile-box flex flex-vertical-center">
            <UserProfileImg account={account} size={60} />
            <div className="ml-20 w-100 flex-col">
              <p className="fs-18 bold">{account.name}</p>
              <AccountCopyLink account={account} />
            </div>
          </div>
        </div>
        <div className="box" style={{ marginTop: 12 }}>
          <p className="fs-16 bold">약관 및 정책</p>
          <p className="mt-20 fs-14">
            <a href="https://brand.bindus.kr/rights-and-responsibility" target={'_blank'}>
              권리 및 책임 정책 ＞
            </a>
          </p>
          {/* <p className="fs-14" style={{ marginTop: 16 }}>
            <a href="https://brand.bindus.kr/%EC%95%BD%EA%B4%80-%EB%B0%8F-%EC%A0%95%EC%B1%85" target={'_blank'}>
              쿠키 정책 전문 보기 ＞
            </a>
          </p> */}
        </div>
        <div className="box" style={{ marginTop: 12 }}>
          <p className="fs-16 bold">요금제와 서비스</p>
          <p className="mt-20 fs-14">비즈니스 플랜별 다양한 서비스를 알아보세요.</p>
          <p className="fs-14 display-inline-block" style={{ marginTop: 12 }}>
            <a className="link" href="https://brand.bindus.kr/pricing" target="_blank">
              바인더스 플랜 알아보기 ＞
            </a>
          </p>
          <div className="mt-40 box-inner-container box-inner-container-sm">
            <Link className="w-100 btn btn-primary" to="/studio/plan">
              플랜 관리
            </Link>
          </div>
        </div>
        <div className="box" style={{ marginTop: 12 }}>
          <p className="fs-16 bold">
            스튜디오 비활성화 및 삭제
            <button
              className="btn btn-outline btn-sm float-right"
              onClick={() => {
                navigate('/')
                logout()
              }}
            >
              로그아웃
            </button>
          </p>
          {account.company.activeStatus !== 'Disable' && (
            <>
              <button
                className="mt-20 btn btn-sm btn-outline-grey"
                onClick={() => {
                  setShowDeactivatePopup(true)
                }}
              >
                스튜디오 비활성화
              </button>
              <p className="fs-12 color-grey-700" style={{ marginTop: 8 }}>
                스튜디오를 비활성화하면, 등록한 레퍼런스와 회사소개가 비공개로 전환됩니다.
              </p>
            </>
          )}
          {account.company.activeStatus === 'Disable' && (
            <>
              <button
                className="mt-20 btn btn-sm btn-outline-grey"
                onClick={() => {
                  setShowActivatePopup(true)
                }}
              >
                스튜디오 활성화
              </button>
              <p className="fs-12 color-grey-700" style={{ marginTop: 8 }}>
                스튜디오를 비활성화 하면 모든 컨텐츠와 소개페이지가 보이지 않으며, 언제든지 계정설정에서
                활성화가능합니다.
              </p>
            </>
          )}
          <button
            className="mt-30 btn btn-sm btn-outline-grey"
            onClick={() => {
              setShowDeletePopup(true)
            }}
          >
            스튜디오 삭제
          </button>
          <p className="fs-12 color-danger" style={{ marginTop: 6 }}>
            스튜디오를 비활성화하면, 등록한 레퍼런스와 회사소개가 비공개로 전환됩니다.
          </p>
          {showActivatePopup && (
            <StudioSettingActivate
              onHide={() => {
                setShowActivatePopup(false)
              }}
            />
          )}
          {showDeactivatePopup && (
            <StudioSettingDeactivate
              companyId={account.company?.id}
              onHide={() => {
                setShowDeactivatePopup(false)
              }}
            />
          )}
          {showDeletePopup && (
            <StudioSettingDelete
              companyId={account.company?.id}
              onHide={() => {
                setShowDeletePopup(false)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
