import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import popupState, { MainPopupType } from 'src/recoil/atom/popup'

export default function MobileCategoryButton() {
    const location = useLocation()
    const navigate = useNavigate()

    const setPopupState = useSetRecoilState(popupState)

    const [showMenu, setShowMenu] = useState(false)
    const [categoryName, setCategoryName] = useState('')

    useEffect(() => {

        if (location.pathname.indexOf('/main/tech') !== -1) {
            setCategoryName('tech')
        }
        else if (location.pathname.indexOf('/main/creative') !== -1) {
            setCategoryName('creative')
        }
        else {
            setCategoryName('')
        }
    }, [location])

    const onHide = () => {
        setShowMenu(false)
    }

    const useOutsideClick = (callback: () => void) => {
        const ref = useRef()

        useEffect(() => {
            const handleClick = (e: any) => {
                if (ref.current && !(ref.current as any).contains(e.target)) {
                    callback()
                }
            }

            document.addEventListener('click', handleClick, true)

            return () => {
                document.removeEventListener('click', handleClick, true)
            }
        }, [ref])

        return ref
    }

    const ref = useOutsideClick(onHide)

    return <>
        <img src="/static/img/logo.svg" alt="Bindus" className="logo mobile-only" onClick={() => {
            setShowMenu(!showMenu);
        }} />
        <div className="category-area mobile-only" ref={ref}>
            <span className={`category ${categoryName}`}></span>
            <div className='category-btn' onClick={() => {
                setShowMenu(!showMenu);
            }}>
                {showMenu &&
                    <ul>
                        <li onClick={() => {
                            setPopupState({ popupType: MainPopupType.None })
                            navigate('/main/creative')
                        }}>
                            크리에이티브
                        </li>
                        <li onClick={() => {
                            setPopupState({ popupType: MainPopupType.None })
                            navigate('/main/tech')
                        }}>
                            테크
                        </li>
                        <li onClick={() => {
                            setPopupState({ popupType: MainPopupType.None })
                            navigate('/')
                        }}>
                            홈
                        </li>
                    </ul>}
            </div>
        </div>
    </>

}