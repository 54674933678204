import { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { isTablet } from 'react-device-detect'
import { useLocation, useNavigate } from 'react-router-dom'
import api from 'src/api'
import { Category, Tag } from 'src/api/types/reference'
import { Swiper, SwiperSlide } from 'swiper/react'

export default function MainFilterContainer() {
    const navigate = useNavigate()
    const [cookies] = useCookies(['defaultMainSort'])
    
    const location = useLocation()

    const [keyword, setKeyword] = useState('')
    const [tagId, setTagId] = useState(null)

    const [categoryId, setCategoryId] = useState(null)

    const [searchType, setSearchType] = useState(null)

    const [summaryTags, setSummaryTags] = useState<Tag[]>(null)
    const [summaryCategories, setSummaryCategories] = useState(null)

    const [categories, setCategories] = useState<Category[]>(null)

    const [mainCategoryId, setMainCategoryId] = useState(null);

    const [swiper, setSwiper] = useState(null)
    const [categorySwiper, setCategorySwiper] = useState(null)

    const scrollTopRef = useRef(null)

    useEffect(() => {
        fetchCategories()

        const containerEl = document.getElementById('home-list-container')
        containerEl.addEventListener("scroll", onScroll);
        return () => containerEl.removeEventListener("scroll", onScroll);
    }, [])


    useEffect(() => {
        const mainCategoryId = location.pathname.indexOf('/main/tech') !== -1 ? 11 : 10
        setMainCategoryId(mainCategoryId)

        const searchParams = new URLSearchParams(location.search)
        const keyword = searchParams.get('keyword')
        const categoryId = searchParams.get('categoryId') ? parseInt(searchParams.get('categoryId')) : null
        const tagId = searchParams.get('tagId')
        let searchType = searchParams.get('searchType')
        if (!searchType) {
            searchType = (categoryId || keyword) ? 'Recent' : cookies.defaultMainSort
        }

        if ((categoryId || keyword) && !tagId) {
            getSummary(categoryId, null, keyword)
        }

        setTagId(tagId)
        setKeyword(keyword)
        setCategoryId(categoryId || mainCategoryId)
        setSearchType(searchType)

        if (keyword) {
            getKeywordSummary(keyword)
        }
        else {
            setSummaryCategories(null)
        }

    }, [location])


    function isScrollAtBottom(containerEl: HTMLElement) {
        const scrollTop = containerEl.scrollTop;
        const scrollHeight = containerEl.scrollHeight;
        const clientHeight = containerEl.clientHeight;
        return scrollTop + clientHeight >= scrollHeight - 100;
    }


    const onScroll = () => {
        if (isTablet) {
            return
        }

        const containerEl = document.getElementById('home-list-container')
        const subCategoryEl = document.querySelector('.fold-area') as any
        if (!subCategoryEl) {
            return
        }

        if (isScrollAtBottom(containerEl)) {
            return
        }

        const scrollTop = containerEl.scrollTop
        const yOffset = scrollTop - scrollTopRef.current
        if (yOffset > 0) {
            subCategoryEl.style.marginTop = -subCategoryEl.clientHeight + 'px'
            subCategoryEl.style.opacity = 0
        }
        else {
            subCategoryEl.style.marginTop = 0
            subCategoryEl.style.opacity = 1
        }
        scrollTopRef.current = scrollTop
    };


    const fetchCategories = () => {
        setCategories(null)
        api.category.getList().then((res) => {
            setCategories(res.data || [])
        })
    }

    const getSummary = (categoryId?: number, tagId?: string, keyword?: string) => {
        const subCategoryEl = document.querySelector('.fold-area') as any
        if (subCategoryEl) {
            subCategoryEl.style.marginTop = 0
            subCategoryEl.style.opacity = 1
        }

        if (tagId) {
            return
        }

        setSummaryTags([])
        api.portfolio
            .getSummary(categoryId, tagId, keyword?.toLocaleLowerCase())
            .then((res) => {
                const { tags } = res.data
                setSummaryTags(tags || [])

                swiper?.slideTo(0)
            })
            .catch(() => {
                // do nothing
            })
    }

    const getKeywordSummary = (keyword?: string) => {
        api.portfolio
            .getSummary(mainCategoryId, null, keyword?.toLocaleLowerCase())
            .then((res) => {
                if (categories) {
                    setCategories(categories)
                }
                setSummaryCategories(res.data.categories || {})
                setTimeout(() => {
                    categorySwiper?.update()
                }, 200)
            })
            .catch(() => {
                // do nothing
            })
    }


    const search = async (query: any) => {
        if (keyword) {
            query.keyword = keyword
        }

        if (categoryId && !query.hasOwnProperty('categoryId')) {
            query.categoryId = categoryId
        }

        if (!query.searchType) {

            if (searchType) {
                query.searchType = searchType
            }

            const hasRecommend = !keyword && categoryId === mainCategoryId
            if (!hasRecommend && query.searchType === 'Recommend') {
                query.searchType = 'Recent'
            }
        }

        var queryString = Object.keys(query)
            .map((key) => (query[key] ? key + '=' + query[key] : ''))
            .filter(item => item !== '')
            .join('&')

        navigate({
            pathname: '',
            search: '?' + queryString,
        })
    }

    const isActiveCategory = (category: Category, index: number) => {
        const findCategory = categories.find(item => item.id === categoryId)

        let isActive = false
        if (categoryId) {
            if (category.id === categoryId) {
                isActive = true
            }
            else if (category.id === findCategory?.parent?.id && category.name !== '전체') {
                isActive = true
            }
        }
        else if (index === 0) {
            isActive = true
        }

        return isActive
    }


    const renderCategory = () => {
        if (!categories || categories?.length === 0) {
            return <></>
        }
        const result = categories?.filter(category => categoryId === category.id);
        const firstMatchedCategory = result.length > 0 ? result[0] : null;

        let filteredCategories = categories?.filter(category => category.parent?.id === mainCategoryId)
        filteredCategories = [{ id: mainCategoryId, name: '전체' }, ...filteredCategories];

        return <div className='category-btn-list-wrap'>
            {
                <Swiper className="category-btn-list mobile-only"
                    slidesPerView={'auto'}
                    freeMode={true}
                    spaceBetween={10}
                    onSwiper={setCategorySwiper}
                >
                    {filteredCategories?.map((category, index) => {
                        const isActive = isActiveCategory(category, index)
                        const countText = summaryCategories?.[category.id] && keyword ? ` (${summaryCategories?.[category.id]})` : ''

                        return <SwiperSlide
                            className={`swiper-slide ${isActive ? 'active' : ''}`}
                            key={index}
                            onClick={() => {
                                search({ categoryId: category.id === mainCategoryId ? null : category.id })
                            }}>
                            {category.name}{countText}
                        </SwiperSlide>
                    })}
                </Swiper>
            }
            {
                <ul className="category-btn-list pc-only"
                >
                    {filteredCategories?.map((category, index) => {
                        const isActive = isActiveCategory(category, index)
                        const countText = summaryCategories?.[category.id] && keyword ? ` (${summaryCategories?.[category.id]})` : ''

                        return <li
                            className={`swiper-slide ${isActive ? 'active' : ''}`}
                            key={index}
                            onClick={() => {
                                search({ categoryId: category.id === mainCategoryId ? null : category.id })
                            }}>
                            {category.name}{countText}
                        </li>
                    })}
                </ul>
            }
            {
                firstMatchedCategory && firstMatchedCategory.subCategories &&
                <ul className='sub-category-list-wrap'>
                    {firstMatchedCategory.subCategories.map((subCategory) => {
                        return <li key={subCategory.id}>{subCategory.name}</li>
                    })}
                </ul>
            }
        </div>
    }


    const renderSubCategory = () => {
        if (!categories || categoryId === mainCategoryId) {
            return <></>
        }

        const findCategory = categories.find(item => item.id === categoryId)

        let subCategories = categories.filter(category => category.parent?.id === categoryId)
        let totalCategoryId = categoryId
        if (subCategories?.length === 0 && findCategory.parent?.id !== mainCategoryId) {
            totalCategoryId = findCategory.parent?.id
            subCategories = categories.filter(category => category.parent?.id === findCategory.parent?.id)
        }

        subCategories = ([{ id: totalCategoryId, name: '전체' }, ...subCategories])

        return <div className='sub-category-links-wrap'>
            <ul>
                {subCategories.map(subCategory => {
                    let classNames = ['fs-14']
                    if (subCategory.id === categoryId) {
                        classNames.push('active')
                    }
                    return <li
                        className={classNames.join(' ')}
                        key={subCategory.id}
                        onClick={() => {
                            search({ categoryId: subCategory.id })
                        }}>
                        {subCategory.name}
                    </li>
                })}
            </ul>
        </div>
    }


    const renderTagList = () => {
        if (!summaryTags) {
            return
        }

        // if (!keyword) {
        //     return
        // }

        return <Swiper className="tag-list" freeMode={true} slidesPerView={'auto'} spaceBetween={10} onSwiper={setSwiper}>
            {summaryTags?.map((tag) => {
                return (
                    <SwiperSlide
                        className={parseInt(tagId) === tag.id ? 'active' : ''}
                        key={tag.id}
                        onClick={() => {
                            search({ tagId: tag.id })
                        }}
                    >
                        {tag.name}
                    </SwiperSlide>
                )
            })}
        </Swiper>
    }


    const renderFoldArea = () => {
        if (!summaryTags || (categoryId === mainCategoryId)) {
            return
        }

        return <div className="fold-area">
            <div className="fold-inner-area">
                {renderSubCategory()}
                {renderTagList()}
            </div>
        </div>
    }


    const renderOrderFilter = () => {
        if (keyword && summaryTags?.length === 0) {
            return <></>
        }

        let options = [
            { label: '최신순', value: 'Recent' },
            { label: '인기순', value: 'Popular' },
        ];

        const hasRecommend = !keyword && categoryId === mainCategoryId
        if (hasRecommend) {
            options = [{ label: '추천순', value: 'Recommend' }, ...options]
        }

        let targetSearchType = searchType
        if (!hasRecommend && targetSearchType === 'Recommend') {
            targetSearchType = 'Recent'
        }

        if (!targetSearchType) {
            targetSearchType = hasRecommend ? 'Recommend' : 'Recent'
        }

        return <ul className='order-filters'>
            {options.map((option, index) => {
                return <li key={index} className={targetSearchType === option.value ? 'active' : ''} onClick={() => {
                    let query: any = { searchType: option.value }
                    if (tagId) {
                        query.tagId = tagId
                    }
                    search(query)
                }}>{option.label}</li>
            })}
        </ul>
    }


    return (
        <div className='container'>
            {keyword && <p className="search-keyword pc-only">{keyword}</p>}
            <div className="filter-wrap">
                {renderCategory()}
                {renderFoldArea()}
                {renderOrderFilter()}
            </div>
        </div>
    )
}
