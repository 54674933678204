import { ChangeEvent, useCallback, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import api from 'src/api'
import Image from 'src/components/Common/Image'
import buttonStyles from '../../common/button.module.scss'
import ImageIcon from '../../icons/Image'
import { EditorBlockInputProps, ImageBlock } from '../../types'
import styles from './index.module.scss'

const ImageBlockInput = forwardRef(({ onBlockChange, block: blockProps }: EditorBlockInputProps, ref) => {
  const block = blockProps as unknown as {
    id: string
  } & ImageBlock


  const fileInput = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    startEdit() {
      handleFileInputClick()
    }
  }));

  const handleFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return
      }

      const uploadFile = e.target.files[0]

      const formData = new FormData()
      formData.append('image', uploadFile)


      api.image.upload(uploadFile).then((response) => {
        onBlockChange?.({
          id: block.id,
          type: 'image',
          value: response.data
        })
      })
    },
    [onBlockChange, block.id]
  )

  const handleFileInputClick = useCallback(() => {
    if (fileInput) {
      fileInput.current?.click()
    }
  }, [])

  if (block.value === null) {
    return (
      <div className={styles.imageBlock}>
        <div className={styles.uploadGuide}>
          <p>
            이미지를 화면 가로에 맞춰 채우려면 <br />
            너비가 최소 1400픽셀 이상인 이미지를 업로드해 주세요.
          </p>
          <input ref={fileInput} type="file" onChange={handleFileUpload} accept="image/png, image/jpeg" />
          <button className={buttonStyles.button} onClick={handleFileInputClick}>
            <ImageIcon />
            이미지 업로드
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className={[styles.imageBlock].join(' ')}>
      <div className={styles.imageWrapper}>
        <input ref={fileInput} type="file" onChange={handleFileUpload} accept="image/png, image/jpeg" style={{ display: 'none' }} />
        <Image img={block.value} />
      </div>
    </div>
  )
});
export default ImageBlockInput;