export function parseVimeoId(url: string) {
    var match = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/.exec(url)
    if (match) {
        return match[5]
    } else {
        return false
    }
}

export function parseNaverId(url: string) {
    var match = /tv.naver.*\/(\d+)/i.exec(url)
    if (match) {
        return match[1]
    } else {
        return false
    }
}

export function parseNaverBlogId(url: string) {
    const prefix = 'https://serviceapi.nmv.naver.com/flash/convertIframeTag.nhn'
    if (url.indexOf(prefix) === -1) {
        return false
    }

    const params = new URL(url).searchParams
    return `vid=${params.get('vid')}&outKey=${params.get('outKey')}`
}

export function getVideoUrl(item: string) {
    if (!item || item.length === 0) {
        return null
    }

    let videoId = null
    let videoType = 'youtube'
    const comps = item.split('|')
    if (comps.length > 1) {
        videoType = comps[0]
        videoId = comps[1]
    } else {
        videoId = item
    }

    let videoURL = null
    if (videoType === 'youtube') {
        videoURL = `https://www.youtube.com/embed/${videoId}`
    } else if (videoType === 'naver') {
        videoURL = `https://tv.naver.com/embed/${videoId}?autoPlay=true`
    } else if (videoType === 'naver_blog') {
        const prefix = 'https://serviceapi.nmv.naver.com/flash/convertIframeTag.nhn'
        videoURL = `${prefix}?${videoId}`
    } else {
        videoURL = `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`
    }

    return videoURL
}