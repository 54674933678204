import { useMediaQuery } from 'react-responsive'
import { CompanyMedal } from 'src/api/types/company'

type Props = {
  medals: CompanyMedal[]
  align?: 'left' | 'right'
  size?: number
  mobileSize?: number
}
export default function CompanyMedals({ medals, align = 'left', size = 46, mobileSize = 46 }: Props) {
  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  })

  if (!medals || medals?.length === 0) {
    return <></>
  }

  const getSize = () => {
    return isMobile ? mobileSize : size
  }

  return (
    <div className={`company-medals ${align}`}>
      {medals?.map((medal) => {
        return <img src={medal.imageUrl} title={medal.name} style={{ width: getSize(), height: getSize() }} />
      })}
    </div>
  )
}
