import { Navigate, useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import Confirm from './Confirm'

type Props = {
  onHide: () => void
}

export default function Index({ onHide }: Props) {
  const navigate = useNavigate()

  const setNotificationState = useSetRecoilState(notificationState)
  const [{ account }, setAuthState] = useRecoilState(authState)

  const activate = () => {
    api.company
      .updateActivateState(account.company.id, 'Active', [])
      .then((res) => {
        setNotificationState({
          type: 'unlock',
          message: '스튜디오가 활성화되었어요',
        })
        fetchUser()
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const fetchUser = () => {
    api.account
      .fetch()
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })
        onHide()
      })
      .catch((error) => {
        setAuthState({
          account: null,
          waiting: false,
        })
        onHide()
      })
  }

  return (
    <Confirm
      okText="활성화"
      cancelText="아니요"
      title="비활성화된 스튜디오입니다.<br/>스튜디오를 다시 활성화하시겠어요?"
      onOk={activate}
      onCancel={() => {
        navigate(-1)
      }}
    />
  )
}
