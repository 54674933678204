import { useState } from 'react'

type Props = {
  titles: string[]
  defaultIndex?: number
  onChange: (index: number) => void
}

export default function Tab({ titles, defaultIndex = 0, onChange }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex)
  const items = titles.map((title: string, index: number) => {
    return (
      <li
        key={index}
        className={selectedIndex === index ? 'active' : ''}
        onClick={() => {
          setSelectedIndex(index)
          onChange(index)
        }}
      >
        {title}
      </li>
    )
  })

  return <ul className="tabs">{items}</ul>
}
