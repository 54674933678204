import { Link, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Account, AccountCompany } from 'src/api/types/account'
import authState from 'src/recoil/atom/auth'

export function getUserLink(account: Account) {
  return `/u/${account.urlKey}`
}

export function getCompanyLink(company: AccountCompany) {
  return `/c/${company.urlKey}`
}

export function UserProfileLink(account: Account) {
  return (
    <Link to={getUserLink(account)} className="color-text-regular">
      {getUserLink(account)}
    </Link>
  )
}

export function UserProfileImg({
  account,
  size = 60,
  classNames = [],
  clickable = true,
}: {
  account: Account
  size?: number
  classNames?: string[]
  clickable?: boolean
}) {
  const authValue = useRecoilValue(authState)
  const myAccount = authValue.account
  const navigate = useNavigate()

  const renderContent = () => {
    if (account.profileImage) {
      return <img src={account.profileImage?.url} alt="profile img" />
    }
  }

  return (
    <div
      className={`profile-img-wrap user ${classNames.join(' ')}`}
      onClick={() => {
        if (!clickable) {
          return
        }

        if (account.activeStatus === 'Disable' || account.activeStatus === 'Delete') {
          return
        }

        const isMyAccount = myAccount.id === account.id
        navigate(isMyAccount ? '/my/profile' : getUserLink(account))
      }}
      style={{ width: size, height: size, minWidth: size, minHeight: size, borderRadius: size / 2 }}
    >
      {renderContent()}
    </div>
  )
}

export function CompanyProfileLink(company: AccountCompany) {
  return (
    <Link to={getCompanyLink(company)} className="color-text-regular">
      bindus.kr{getCompanyLink(company)}
    </Link>
  )
}
