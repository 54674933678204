import request from 'src/utils/Request'
import { Message, MessageListRes } from './types/message'

const messageApi = {
  // 메시지 보내기
  send: (type: string, id: number, message: string) => {
    return request.post(`/v1/message/${type}/${id}`, message, { headers: { 'content-type': 'text/plain' } })
  },
  // 메시지 리스트
  getList(onlyRead: boolean, page: number) {
    return request.get<MessageListRes>('/v1/message', { params: { onlyRead, page } })
  },
  // 메시지 읽음 처리
  readMessage: (messageId: string) => {
    return request.get(`/v1/message/${messageId}`)
  },
  // 읽지 않은 메시지
  getUnreadList: () => {
    return request.get<Message[]>('/v1/message/unread')
  },
  // 보낸 메시지 리스트
  getSentList: (page: number) => {
    return request.get<MessageListRes>(`/v1/message/sent`, { params: { page } })
  },
}

export default messageApi
