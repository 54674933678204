import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import BlockEditorViewer from 'src/components/BlockEditor/Viewer'
import Tab from 'src/components/Common/Tab'
import BannerCategoryBadge from 'src/components/Etc/BannerCategoryBadge'
import ManageLeftAside from 'src/components/Studio/LeftAside'
import StudioProfileArea from 'src/components/Studio/StudioProfileArea'
import Reference from 'src/pages/Reference'
import authState from 'src/recoil/atom/auth'
import popupState from 'src/recoil/atom/popup'
import { pushMessageState } from 'src/recoil/atom/pushMessage'
import settingState from 'src/recoil/atom/setting'

export default function Index() {
  const location = useLocation()
  const navigate = useNavigate()

  const [cookies, setCookie] = useCookies(['isStudioMode'])
  const [{ waiting, account }, setAuthState] = useRecoilState(authState)
  const [{ popupType }] = useRecoilState(popupState)
  const setPopupState = useSetRecoilState(popupState)

  const [editMode, setEditMode] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [introductionJson, setIntroduceJson] = useState(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)

  const [{ isStudioMode }, setSettingState] = useRecoilState(settingState)
  const setPushMessageState = useSetRecoilState(pushMessageState)

  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  })

  useEffect(() => {
    setPushMessageState({
      id: 3,
    })
    setSettingState({ isStudioMode: true })
  }, [])

  useEffect(() => {
    let urlKey = null
    const isEditMode = location.pathname.indexOf('/c/') === -1
    if (!isEditMode) {
      const comps = location.pathname.split('/c/')
      urlKey = comps.pop()
    } else if (account.company) {
      urlKey = account.company.urlKey
    }

    setEditMode(isEditMode)

    if (!urlKey) {
      navigate('/notFound')
      return
    }

    fetchCompany(urlKey)
  }, [location])

  const fetchCompany = (urlKey: string) => {
    setData(null)
    setLoading(true)
    api.company
      .getByUrlKey(urlKey)
      .then((res) => {
        setIntroduceJson(res.data.introductionJson)
        setData(res.data)
        fetchReferenceList(res.data.id)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const fetchReferenceList = (companyId: number) => {
    api.companyPortfolio
      .getList(companyId, true)
      .then((res) => {
        let items = res.data
        items = [...items].sort((a, b) => {
          return b['editedAt'] < a['editedAt'] ? -1 : 0
        })
        setItems(items)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const renderIntroduce = () => {
    if (!account || !introductionJson) {
      return <></>
    }

    if (introductionJson?.length < 10) {
      if (!editMode) {
        return <div className="fs-18 w-100 flex-center color-grey-400 empty-view">준비 중입니다</div>
      }

      return (
        <div className="box border-light">
          <p className="mt-20 fs-24 bold">회사를 소개하세요!</p>
          <p className="mt-20 fs-16">
            회사 소개는 바인더스 사용자들이 우리 회사를 찾아왔을 때 가장 처음 만나는 화면이에요.
            <br />
            우리 회사의 역량을 어필할 수 있는 정보들로 페이지를 구성해 보세요.
          </p>
          <button
            className="mt-40 btn btn-third btn-md"
            onClick={() => {
              navigate('/studio/introduce/edit')
            }}
          >
            회사 소개 작성
          </button>
        </div>
      )
    }

    return (
      <>
        {editMode && (
          <div className="w-100" style={{ overflow: 'hidden' }}>
            <button
              className="btn btn-third btn-md float-right edit-btn"
              onClick={() => {
                navigate('/studio/introduce/edit')
              }}
            >
              회사 소개 편집
            </button>
          </div>
        )}
        <div className="mt-20"></div>
        <BlockEditorViewer data={introductionJson} />
      </>
    )
  }

  const renderReference = () => {
    if (items.length === 0) {
      if (editMode) {
        return <div className="mt-20 box border-light">레퍼런스가 없습니다</div>
      } else {
        return <div className="fs-18 w-100 flex-center color-grey-400 empty-view">준비 중입니다</div>
      }
    }

    return (
      <div className="mt-20">
        <ul className="reference-items" style={{ marginTop: 10 }}>
          {items?.map((item, index) => {
            return (
              <li
                className={`${item.publishedAt ? '' : 'deactive'} ${item.type === 'Banner' ? 'banner' : ''}`}
                key={index}
                onClick={() => {
                  setSelectedItem(item)
                }}
              >
                <div className="thumb-wrap border-light">
                  <img alt="thumb" src={item.thumbnailFile?.url} />
                </div>
                <BannerCategoryBadge
                  categories={item.representativeCategory?.length > 0 ? item.representativeCategory : item.categories}
                  type={item.type}
                />
                <p className="title fs-16 bold">{item.title}</p>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const renderContent = () => {
    let targetIndex = isMobile ? tabIndex : tabIndex + 1

    if (targetIndex === 0) {
      return <StudioProfileArea data={data} editMode={false} />
    } else if (targetIndex === 1) {
      return renderIntroduce()
    } else if (targetIndex === 2) {
      return renderReference()
    }
  }

  if (!data || loading) {
    return <div className="spinner with-margin"></div>
  }

  return (
    <div className="page studio-page bg-color-grey-50">
      <div className="container">
        <ManageLeftAside data={data} editMode={true} />
        {isMobile && (
          <Tab
            titles={['회사 프로필', '회사소개']}
            onChange={(index) => {
              setTabIndex(index)
            }}
          />
        )}
        <div className="main-area">
          <div className="tab-content">{renderContent()}</div>
        </div>
      </div>
      {selectedItem && (
        <Reference
          referenceId={selectedItem.id}
          goPrev={() => {
            let targetIndex = items.indexOf(selectedItem) - 1
            if (targetIndex < 0) {
              targetIndex = 0
            }
            setSelectedItem(items[targetIndex])
          }}
          goNext={() => {
            let targetIndex = items.indexOf(selectedItem) + 1
            if (targetIndex >= items.length) {
              targetIndex = items.length - 1
            }
            setSelectedItem(items[targetIndex])
          }}
          onHide={() => {
            setSelectedItem(null)
          }}
        />
      )}
    </div>
  )
}
