import { useRecoilValue, useSetRecoilState } from 'recoil'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'
import ConfirmPopup from './Confirm'

type Props = {
  onHide: () => void
}
export default function StudioMaxPortfolioPublishConfirm({ onHide }: Props) {
  const { account } = useRecoilValue(authState)

  const currentPlanName = account.company.plan.plan.name
  return (
    <>
      {currentPlanName === 'starter' && (
        <ConfirmPopup
          showCloseBtn={true}
          title={`레퍼런스를 <span class="color-primary">${account.company.plan.plan.maxPortfolioPublish}</span>개까지 게시할 수 있어요`}
          body={`더 많은 레퍼런스를 등록하기 위해,<br/>프로페셔널 플랜으로 업그레이드해 보세요.<br/>
                  <span class="color-danger">23.05.31까지 프로페셔널 플랜이 무료</span>예요!`}
          okClassName="btn btn-primary max-width-none"
          okText="플랜관리로 이동"
          onOk={() => {
            window.open('/studio/plan')
            onHide()
          }}
          onCancel={() => {
            onHide()
          }}
        />
      )}
      {currentPlanName === 'professional' && (
        <ConfirmPopup
          showCloseBtn={true}
          title={`레퍼런스를 <span class="color-primary">${account.company.plan.plan.maxPortfolioPublish}</span>개까지 게시할 수 있어요`}
          body="조금만 기다려 주세요!<br/>더 풍부한 서비스로 보답할게요."
          okText="서비스 업데이트 알림 받기"
          okClassName="btn btn-primary max-width-none"
          onOk={() => {
            window.open(process.env.REACT_APP_SERVICE_UPDATE_URL)
            onHide()
          }}
          onCancel={() => {
            onHide()
          }}
        />
      )}
    </>
  )
}
