export function hexEncode(str: string) {
  let result = ''
  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16)
  }

  return parseInt(result)
}

export function replaceSpecialCharacters(str: string) {
  return str.replace(/[\b\r]/g, '')
}