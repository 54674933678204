import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import api from 'src/api'
import { ReferenceRes } from 'src/api/types/reference'
import Reference from 'src/pages/Reference'
import { notificationState } from 'src/recoil/atom/notification'
import { getMainCategoryId } from 'src/utils/Category'
import { dateFormat } from 'src/utils/Format'
import { ReferenceCopyLink } from '../Common/CopyLink'
import BannerCategoryBadge from '../Etc/BannerCategoryBadge'
import Confirm from '../Popup/Confirm'
import DeleteReferenceConfirm from '../Popup/DeleteReferenceConfirm'
import StudioMaxPortfolioConfirm from '../Popup/StudioMaxPortfolioPublishConfirm'
import Select from '../Select/Select'
import ReferenceListItemSaver from './ReferenceListItemSaver'

type Props = {
  item: ReferenceRes
  companyId: number
  type?: 'Banner' | 'Reference'
  onRefresh: () => void
}
export default function Index({ companyId, item, type = 'Reference', onRefresh }: Props) {
  const navigate = useNavigate()

  const setNotificationState = useSetRecoilState(notificationState)

  const { clickCount, listCount, saveCount, publishedAt } = item

  const [isPublished, setIspublished] = useState(publishedAt !== null)
  const [isMainBanner, setIsMainBanner] = useState(item.isMainBanner)

  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [showReference, setShowReference] = useState(false)
  const [showSaver, setShowSaver] = useState(false)
  const [showMaxPopup, setShowMaxPopup] = useState(false)
  const [showBadRequestPopup, setShowBadRequestPopup] = useState(false)
  const [mainCategoryName, setMainCategoryName] = useState(null)

  const [loading, setLoading] = useState(false)

  const categories = item.representativeCategory?.length > 0 ? item.representativeCategory : item.categories

  useEffect(() => {
    const mainCategoryId = getMainCategoryId(categories?.[0])
    if (mainCategoryId === 10) {
      setMainCategoryName('creative')
    } else if (mainCategoryId === 11) {
      setMainCategoryName('tech')
    }
  }, [])

  const activatePublish = () => {
    setLoading(true)
    api.companyPortfolio
      .publish(companyId, item.id)
      .then(() => {
        setNotificationState({
          type: 'check',
          message: '공개되었습니다',
        })
        setIspublished(true)
        onRefresh()
      })
      .catch((e) => {
        setIspublished(false)

        const { status, data } = e.response
        if (status === 400) {
          if (data?.message === '임시 저장된 포트폴리오입니다.') {
            setShowBadRequestPopup(true)
            return
          } else if (item.type !== 'Banner') {
            setShowMaxPopup(true)
            return
          }
        }

        setNotificationState({
          type: 'error',
          message: data?.message || '네트워크 에러',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const cancelPublish = () => {
    api.companyPortfolio.cancelPublish(companyId, item.id).then(() => {
      setNotificationState({
        type: 'check',
        message: '비공개되었습니다',
      })
      setIspublished(false)
      onRefresh()
    })
  }

  const deleteReference = () => {
    api.companyPortfolio
      .delete(companyId, item.id)
      .then(() => {
        setShowDeletePopup(true)
        onRefresh()

        setNotificationState({
          type: 'check',
          message: '삭제되었습니다',
        })
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '삭제실패 관리자에 문의해주세요',
        })
      })
  }

  const updateMainBanner = () => {
    const targetCategory = item.categories?.[0].parent?.id === 10 ? 10 : 11

    api.companyPortfolio
      .updateMainBanner(companyId, targetCategory, isMainBanner ? null : item.id)
      .then(() => {
        setNotificationState({
          type: 'check',
          message: isMainBanner ? '메인 배너에 게시가 취소되었습니다' : '메인 배너에 게시되었습니다',
        })
        setIsMainBanner(!isMainBanner)

        onRefresh()
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const editReference = () => {
    if (item.type === 'Banner') {
      navigate('/studio/banner/edit', {
        state: { portfolioId: item.id },
      })
    } else {
      navigate('/studio/reference/edit', {
        state: { portfolioId: item.id },
      })
    }
  }

  const duplicateReference = () => {
    api.companyPortfolio
      .duplicate(companyId, item.id)
      .then(() => {
        setNotificationState({
          type: 'check',
          message: `레퍼런스가 복사되었습니다`,
        })
        onRefresh()
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const renderThumbArea = () => {
    if (item.thumbnailFile) {
      return (
        <div
          className="thumb-area border-light"
          onClick={() => {
            setShowReference(true)
          }}
        >
          <img src={item.thumbnailFile.url} alt="" />
        </div>
      )
    } else {
      return (
        <div
          className="thumb-area border-light flex-center text-center bg-color-grey-200 color-grey-900"
          onClick={() => {
            setShowReference(true)
          }}
        >
          썸네일을
          <br />
          등록해 주세요
        </div>
      )
    }
  }

  return (
    <div
      className={`box studio-reference-list-item ${
        isPublished ? '' : 'deactive'
      } ${type.toLowerCase()} ${mainCategoryName}`}
      style={{ marginBottom: 12 }}
      key={item.id}
    >
      {type === 'Banner' && (
        <div className="main-banner-check-area">
          <input
            type="checkbox"
            className="checkbox green"
            id={'bannerCheckbox' + item.id}
            checked={isMainBanner}
            onChange={(e) => {
              updateMainBanner()
            }}
          />
          <label htmlFor={'bannerCheckbox' + item.id}></label>
        </div>
      )}
      {renderThumbArea()}
      <div className="content-area">
        <BannerCategoryBadge
          categories={
            item.type === 'Banner'
              ? item.categories
              : item.representativeCategory?.length > 0
              ? item.representativeCategory
              : item.categories
          }
          type={item.type}
          style={{ marginBottom: 6 }}
        />
        <p
          className="fs-18 bold max-line-1"
          onClick={() => {
            setShowReference(true)
          }}
          style={{ cursor: 'pointer' }}
        >
          <span>{item.title ? item.title : '제목을 등록해 주세요'}</span>
        </p>
        <ReferenceCopyLink data={String(item.id)} style={{ marginTop: 8 }} />
        <div className="space"></div>
        <p className="fs-12 color-grey-500">
          <span>등록일 {dateFormat(item.createdAt)}</span>
        </p>
        <p className="fs-12 color-grey-500">
          <span>최근 수정일 {dateFormat(item.updatedAt)}</span>
        </p>
        {!loading && (
          <Select
            options={[
              { label: '공개', value: '1' },
              { label: '비공개', value: '0' },
            ]}
            size="small"
            defaultLabel={isPublished ? '공개' : '비공개'}
            onChange={(option) => {
              const isPublish = option.value === '1'
              if (isPublish) {
                activatePublish()
              } else {
                cancelPublish()
              }
            }}
          />
        )}
      </div>
      <div className="button-area">
        <button
          className="btn btn-outline-grey btn-sm"
          onClick={() => {
            setShowDeletePopup(true)
          }}
        >
          <i className="ico ico-trash ico-sm" />
        </button>
        <button
          className="btn btn-outline-grey btn-sm flex"
          onClick={() => {
            editReference()
          }}
        >
          <i className="ico ico-pencil2 ico-sm" />
          <span style={{ marginLeft: 2 }}>편집</span>
        </button>
        {item.type !== 'Banner' && (
          <button
            className="btn btn-outline-grey btn-sm flex"
            onClick={() => {
              duplicateReference()
            }}
          >
            <i className="ico ico-copy ico-sm" style={{ width: 20, height: 20 }} />
            <span style={{ marginLeft: 2 }}>복사</span>
          </button>
        )}
      </div>
      <div className="count-area">
        <p>
          <span>노출</span>
          <span className="bold" style={{ marginLeft: 4 }}>
            {listCount > 0 ? listCount : '-'}
          </span>
        </p>
        <p>
          <span>클릭</span>
          <span className="bold" style={{ marginLeft: 4 }}>
            {clickCount > 0 ? clickCount : '-'}
          </span>
        </p>
        <button
          className="clickable"
          onClick={() => {
            setShowSaver(true)
          }}
        >
          <span>저장</span>
          <span className="color-primary bold" style={{ marginLeft: 4 }}>
            {saveCount > 0 ? saveCount : '-'}
          </span>
        </button>
      </div>
      {showDeletePopup && (
        <DeleteReferenceConfirm
          onClick={() => {
            deleteReference()
          }}
          onHide={() => {
            setShowDeletePopup(false)
          }}
        />
      )}
      {showReference && (
        <Reference
          referenceId={item.id}
          onHide={() => {
            setShowReference(false)
          }}
        />
      )}
      {showSaver && (
        <ReferenceListItemSaver
          portfolioId={item.id}
          onHide={() => {
            setShowSaver(false)
          }}
        />
      )}
      {showMaxPopup && (
        <StudioMaxPortfolioConfirm
          onHide={() => {
            setShowMaxPopup(false)
          }}
        />
      )}
      {showBadRequestPopup && (
        <Confirm
          title="임시저장된 레퍼런스입니다<br/>레퍼런스 내용을 확인해 주세요"
          body="레퍼런스 내용 확인 후 게시 버튼을 누르면<br/>공개 레퍼런스로 전환 가능합니다."
          okText="레퍼런스 확인"
          cancelText="취소"
          onOk={() => {
            editReference()
            setShowBadRequestPopup(false)
          }}
          onCancel={() => {
            setShowBadRequestPopup(false)
          }}
        />
      )}
    </div>
  )
}
