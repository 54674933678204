import { useRef, useState } from 'react'
import api from 'src/api'
import { getEmailValidationMsg } from 'src/utils/Vaildiator'

type Props = {
  defaultEmail: string
  placeholder?: string
  showSuccessIcon?: boolean
  needDuplicateCheck?: boolean
  onChange: (email: string) => void
}

export default function EmailInput({
  defaultEmail,
  placeholder,
  showSuccessIcon = false,
  needDuplicateCheck = false,
  onChange,
}: Props) {
  const timeout = useRef(null)

  const [email, setEmail] = useState(defaultEmail)
  const [emailErrMsg, setEmailErrMsg] = useState(null)

  const changeBirthday = (e: any) => {
    const email = String(e.target.value)
    const emailErrMsg = getEmailValidationMsg(email)

    setEmail(email)
    setEmailErrMsg(emailErrMsg)

    if (needDuplicateCheck && email?.length > 0) {
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        api.account
          .checkEmailDuplicate(email)
          .then((_) => {
            onChange(emailErrMsg ? null : email)
          })
          .catch((err) => {
            const { status } = err.response
            if (status === 409) {
              setEmailErrMsg('이미 가입된 이메일입니다')
              onChange(null)
            }
          })
      }, 200)
    } else {
      onChange(emailErrMsg ? null : email)
    }
  }

  const renderInput = () => {
    let inputClassNames = ['input', 'w-100']
    if (emailErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (email?.length > 0 && showSuccessIcon) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="email"
          className={inputClassNames.join(' ')}
          placeholder={placeholder}
          required
          value={email}
          onChange={changeBirthday}
        />
        {emailErrMsg && <p className="mt-10 color-danger">{emailErrMsg}</p>}
      </>
    )
  }

  return renderInput()
}
