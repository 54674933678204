import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import api from 'src/api'
import { ReferenceRes } from 'src/api/types/reference'
import { SelectOption } from 'src/components/Select/Select'
import ReferenceListItem from 'src/components/Studio/ReferenceListItem'
import authState from 'src/recoil/atom/auth'

export default function BannerIndex() {
  const navigate = useNavigate()
  const [{ account }, setAuthState] = useRecoilState(authState)
  const [bannerList, setBannerList] = useState<ReferenceRes[]>([])
  const [loading, setLoading] = useState(true)
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([
    { label: '수정일 순', value: 'editedAt' },
    { label: '등록 순', value: 'createdAt' },
    { label: '이름 순', value: 'title' },
  ])
  const [selectedOptionValue, setSelectedOptionValue] = useState('editedAt');

  useEffect(() => {
    getReferenceList()
  }, [])

  const getReferenceList = () => {
    if (!account.company) {
      return
    }
    setLoading(true)
    api.companyPortfolio.getList(account.company.id, false).then((response) => {
      let list = []
      for (let item of response.data) {
        if (item.categories?.length > 1) {
          item.categories[0].parent = item.categories[1]
          item.categories = [item.categories[0]]
        }

        list.push(item)
      }
      console.log(list)

      list = [...list].sort((a, b) => {
        return b['createdAt'] < a['createdAt'] ? -1 : 0
      })
      setBannerList(list)
      setLoading(false)
    })
  }

  const renderEmptyBox = () => {
    return (
      <div className="box">
        <p className="fs-24 bold">배너를 작성해 보세요!</p>
        <p className="mt-20 fs-14">회사의 역량을 보여줄 수 있는 배너를 지금 바로 만들어 보세요.</p>
        <button
          className="mt-40 btn btn-third btn-md pc-only"
          onClick={() => {
            navigate('/studio/banner/create')
          }}
        >
          <i className="ico ico-plus"></i>새 배너
        </button>
      </div>
    )
  }

  const renderList = () => {
    if (loading) {
      return <div className="spinner with-margin"></div>
    }

    if (bannerList.length === 0) {
      return renderEmptyBox()
    }

    return (
      <>
        <div className="filter-wrap">
          <button
            className="btn btn-third btn-md pc-only"
            onClick={() => {
              navigate('/studio/banner/create')
            }}
          >
            <i className="ico ico-plus"></i>새 배너
          </button>
          <div className="flex-grow-1"></div>
          <ul className='order-filters'>
            {selectOptions.map(option => {
              return <li className={option.value === selectedOptionValue ? 'active' : ''} onClick={() => {
                const value = option.value
                setSelectedOptionValue(value)
                setBannerList(
                  [...bannerList].sort((a, b) => {
                    return b[value] < a[value] ? -1 : 0
                  })
                )
              }}>{option.label}</li>
            })}
          </ul>
        </div>
        {bannerList.map((item) => {
          return (
            <ReferenceListItem
              type="Banner"
              item={item}
              companyId={account.company.id}
              key={item.id}
              onRefresh={() => {
                getReferenceList()
              }}
            />
          )
        })}
      </>
    )
  }

  return (
    <div className="page studio-reference-page">
      <div className="box-container container">{renderList()}</div>
      <div className="pc-only" style={{ height: 400 }}></div>
      <div className="btn-wrap page-foot-btn mobile-only">
        <button className="btn btn-third"
          onClick={() => {
            navigate('/studio/banner/create')
          }}>
          <i className="ico ico-plus"></i>새 배너
        </button>
      </div>
    </div >
  )
}
