import React from 'react'
import { Link } from 'react-router-dom'

type Props = {}

export default function NotFound({}: Props) {
  return (
    <main>
      <div className="page bg-color-grey-300 flex-center">
        <div className="text-center" style={{ padding: 24 }}>
          <i className="ico ico-error-line" style={{ width: 60, height: 60 }}></i>
          <h1 className="mt-30 fs-24 bold text-center">요청하신 페이지를 찾을 수 없습니다</h1>
          <p className="fs-16 text-center" style={{ marginTop: 50 }}>
            입력한 주소가 잘못되었거나, 사용이 일시 중단되어 요청하신 페이지를 찾을수 없습니다.
            <br />
            이용에 불편을 드려 죄송합니다.
          </p>
          <div>
            <Link
              className="mx-auto btn btn-outline-primary btn-md"
              to="/"
              style={{ marginTop: 100, width: 162, backgroundColor: 'transparent' }}
            >
              바인더스 홈
            </Link>
          </div>
        </div>
      </div>
    </main>
  )
}
