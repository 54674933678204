import request from 'src/utils/Request'

const tagApi = {
  // 결제 수단 등록
  getInfo: () => {
    return request.get(`/v1/toss/billing`)
  },
  // 결제 수단 등록 성공 호출
  success: (customerKey: string, authKey: string) => {
    return request.get(`/v1/toss/billing/success?customerKey=${customerKey}&authKey=${authKey}`)
  },
  // 결제 수단 등록 실패 호출
  fail: (code: string, message: string, orderId: string) => {
    return request.get(`/v1/toss/billing/fail?code=${code}&message=${message}&orderId=${orderId}`)
  },
}

export default tagApi
