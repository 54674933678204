import { Outlet } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Aside from 'src/components/Header/Aside'
import Header from 'src/components/Header/Common'
import Notification from 'src/components/Notification'
import CustomerCenterPopup from 'src/components/Popup/CustomerCenter'
import NeedToLoginPopup from 'src/components/Popup/NeedToLogin'
import RequestResetPasswordPopup from 'src/components/Popup/RequestResetPassword'
import SignInPopup from 'src/components/Popup/SignIn'
import SignUpPopup from 'src/components/Popup/SignUp'
import PushMessage from 'src/components/PushMessage'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import Footer from './Footer'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

export default function MainLayout() {
  const [{ popupType }] = useRecoilState(popupState)

  useEffect(() => {
    // if (window) {
    //   let prevVisualViewport = window.visualViewport?.height
    //   const handleVisualViewportResize = () => {
    //     const currentVisualViewport = Number(window.visualViewport?.height)
    //     if (prevVisualViewport && prevVisualViewport - 30 > currentVisualViewport) {
    //       const scrollHeight = Number(window.document.scrollingElement?.scrollHeight)
    //       const scrollTop = scrollHeight - Number(window.visualViewport?.height)
    //       window.scrollTo(0, scrollTop) // 입력창이 키보드에 가려지지 않도록 조절
    //     }
    //     prevVisualViewport = Number(window.visualViewport?.height)
    //   }
    //   if (window.visualViewport) {
    //     window.visualViewport.onresize = handleVisualViewportResize
    //   }
    // }
  }, [])

  const renderPopup = () => {
    switch (popupType) {
      case MainPopupType.None:
        return <></>
      case MainPopupType.SignIn:
        return <SignInPopup />
      case MainPopupType.SignUp:
        return <SignUpPopup />
      case MainPopupType.SignUpByCompany:
        return <SignUpPopup signupType="Company" />
      case MainPopupType.NeedToLogin:
        return <NeedToLoginPopup />
      case MainPopupType.RequestResetPassword:
        return <RequestResetPasswordPopup />
      case MainPopupType.CustomerCenter:
        return <CustomerCenterPopup />
    }
  }

  return (
    <>
      <Helmet>
        <title>바인더스</title>
      </Helmet>
      <Header />
      <main id="main" className={`${popupType !== MainPopupType.None ? 'show-main-popup' : ''}`}>
        <Outlet />
        {renderPopup()}
        <Notification />
        <PushMessage />
      </main>
      <Aside />
      <Footer />
    </>
  )
}
