import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { SocialRes } from 'src/api/types/social'
import { AccountCopyLink, CompanyCopyLink } from 'src/components/Common/CopyLink'
import { UserProfileImg } from 'src/components/Common/Profile'
import SocialListItem from 'src/components/Common/SocialListItem'
import authState from 'src/recoil/atom/auth'
import settingState from 'src/recoil/atom/setting'

export default function Index() {
  const navigate = useNavigate()

  const [cookies, setCookie, removeCookie] = useCookies(['isStudioMode'])
  const [{ isStudioMode }, setSettingState] = useRecoilState(settingState)

  const [{ account }] = useRecoilState(authState)

  useEffect(() => {
    setSettingState({ isStudioMode: false })
  }, [])

  const renderCompany = () => {
    try {
      const item = JSON.parse(account.companyJson)
      const { department, position, activityArea } = item
      if (account.companyName || department || position || activityArea) {
        const hasAllValue = account.companyName && department && position && activityArea
        return (
          <>
            {account.companyName && <p className="mt-20 fs-18 bold">{account.companyName}</p>}
            {account.companyUrlKey && (
              <CompanyCopyLink
                data={account.companyUrlKey}
                className="fs-12 color-grey-700 flex-vertical-center link"
                style={{ marginTop: 4 }}
              />
            )}
            {(department || position) && (
              <p className="mt-10 fs-14 flex" style={{ gap: 6 }}>
                <span>{department}</span>
                {department && position && <span>/</span>}
                <span>{position}</span>
              </p>
            )}
            {activityArea && (
              <p className="fs-12 color-grey-600" style={{ marginTop: 4 }}>
                {activityArea}
              </p>
            )}
            {!hasAllValue && (
              <div className="fs-14 mt-20 color-danger">
                프로필을 완성해보세요! 손쉽게 회사들과 소통을 할 수 있어요.
              </div>
            )}
          </>
        )
      }
    } catch (e) {
      // do nothing
    }

    return <div className="fs-14 mt-20 color-danger">프로필을 완성해보세요! 손쉽게 회사들과 소통을 할 수 있어요.</div>
  }

  const renderSocial = () => {
    if (!account.socials || account.socials.length === 0) {
      return <div className="fs-14 mt-20 color-danger">연락처를 등록해 보세요! 협업을 위한 소통이 쉬워져요.</div>
    }

    const socials = account.socials
    return (
      <ul className="mt-20 profile-social-list">
        {socials.map((item: SocialRes) => {
          return <SocialListItem item={item} isMine={true} />
        })}
      </ul>
    )
  }

  return (
    <div className="page user-page bg-color-grey-50">
      <div className="box-container container">
        <div style={{ position: 'relative', overflow: 'hidden' }}>
          <button
            className="btn btn-third btn-md float-right"
            style={{ width: 162 }}
            onClick={() => {
              navigate('/my/profile/edit')
            }}
          >
            내 프로필 편집
          </button>
        </div>
        <div className="box">
          <div className="user-profile-area md">
            <UserProfileImg account={account} size={100} />

            <div>
              <p className="fs-20 bold">{account.name}</p>
              <AccountCopyLink account={account} style={{ marginTop: 8 }} fontSize={14} />
            </div>
          </div>
        </div>
        <div className="box">
          <p className="fs-16 bold">기본 프로필</p>
          {renderCompany()}
        </div>
        <div className="box">
          <p className="fs-16 bold">연락처 및 소셜링크</p>
          {renderSocial()}
        </div>
      </div>
    </div>
  )
}
