import ImageCaptionBlockInput from './editorBlockInputs/ImageCaption'
import Image from 'src/components/Common/Image'
import VideoViewer from './editorBlockInputs/Video/VideoViewer';

export default function BlockEditorViewer({ data, header }: { data: string; header?: any }) {
  let nodes = []
  try {
    nodes = JSON.parse(data)
  } catch (e) {
    // Do nothing
  }

  if (nodes.length === 0) {
    return <></>
  }

  return (
    <div className="editor-viewer">
      {header}
      {nodes.map((node, index) => {
        const blockValue = node.value
        if (!blockValue) {
          return
        }

        switch (node.type) {
          case 'title':
            return (
              <div className="title-sec" key={index}>
                <hr className="hr" />
                <p className="title">{node.value}</p>
              </div>
            )
          case 'text':
            return (
              <div className="text-sec" key={index}>
                <p className="text mt-20 mx-auto">{node.value}</p>
              </div>
            )
          case 'imageCaption':
            return (
              <div className="mt-20" key={index}>
                <ImageCaptionBlockInput block={node} viewerMode={true} />
              </div>
            )
          case 'image':
            return (
              <div className="img-sec bg-white mt-20" key={index}>
                <Image img={node.value} className="mx-auto display-block" />
              </div>
            )
          case 'video':
            return (
              <div className="mt-20 video-container" key={index}>
                <VideoViewer item={node.value} />
              </div>
            )
          case 'imageLink':
            return (
              <div className="img-link-sec bg-white mt-20" key={index}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    let url = blockValue.linkUrl
                    if (url?.indexOf('http') === -1) {
                      url = 'http://' + url
                    }
                    window.open(url)
                  }}
                >
                  <Image img={node.value.image} className="mx-auto display-block" />
                </div>
              </div>
            )
          case 'textLink':
            return (
              <div className="mt-20 text-link-sec" key={index}>
                <a
                  onClick={() => {
                    let url = blockValue.linkUrl
                    if (url?.indexOf('http') === -1) {
                      url = 'http://' + url
                    }
                    window.open(url)
                  }}
                >
                  {node.value.text}
                </a>
              </div>
            )
        }
      })}
    </div>
  )
}
