import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import api from 'src/api'
import HomeMainBanner from 'src/components/Home/HomeMainBanner'
import HomeNewsSlide from 'src/components/Home/HomeNewsSlide'
import HomePopupBanner from 'src/components/Home/HomePopupBanner'
import HomeReferenceSlide from 'src/components/Home/HomeReferenceSlide'
import Reference from './Reference'
import { Category } from 'src/api/types/reference'
import Confirm from 'src/components/Popup/Confirm'
import { getMainCategoryId } from 'src/utils/Category'

export default function Home() {
  const location = useLocation()
  const navigate = useNavigate()

  const [selectedPortfolioId, setSelectedPortfolioId] = useState(null)
  const [showPopupBanner, setShowPopupBanner] = useState(true)
  const [isInit, setIsInit] = useState(false)

  const isDirect = location.pathname.indexOf('/r/') !== -1
  const [showForbiddenPopup, setShowForbiddenPopup] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsInit(true)
    }, 1000)
  }, [])

  useEffect(() => {
    if (isDirect) {
      const portfolioId = parseInt(location.pathname.split('/').pop())
      if (isInit) {
        setSelectedPortfolioId(portfolioId)
      } else {
        fetchPortfolio(portfolioId)
      }
    } else {
      setSelectedPortfolioId(null)
    }
  }, [location.pathname])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const referenceId = searchParams.get('referenceId') ? searchParams.get('referenceId') : null
    setSelectedPortfolioId(referenceId)
  }, [location.search])

  const getCategoryId = (category: Category) => {
    if (category.parent === null) {
      return category.id
    } else {
      return getCategoryId(category.parent)
    }
  }

  const fetchPortfolio = (portfolioId: number) => {
    api.portfolio
      .get(portfolioId)
      .then((res) => {
        try {
          let { categories, representativeCategory } = res.data
          categories = representativeCategory?.length > 0 ? representativeCategory : categories
          const mainCategoryId = getMainCategoryId(categories?.[0])
          if (mainCategoryId === 10) {
            navigate(`/main/creative?referenceId=${portfolioId}`)
          } else if (mainCategoryId === 11) {
            navigate(`/main/tech?referenceId=${portfolioId}`)
          } else {
            setSelectedPortfolioId(portfolioId)
          }
        } catch (e) {
          setShowForbiddenPopup(true)
        }
      })
      .catch((e) => {
        const { status } = e.response
        if (status === 403 || status === 404) {
          setShowForbiddenPopup(true)
        } else {
          setNotificationState({
            type: 'error',
            message: '네트워크 에러',
          })
        }
      })
  }

  return (
    <div className="home-page page">
      <div className="banner-section">
        <HomeMainBanner />
      </div>
      <div className="section news-section">
        <div className="section-container">
          <p className="section-title">
            <i className="ico-news"></i>
            소식
          </p>
          <HomeNewsSlide />
        </div>
      </div>
      <div className="section creative-section">
        <div className="section-container">
          <p className="section-title">
            <Link to="/main/creative">
              <i className="ico-creative"></i>크리에이티브
            </Link>
            <Link className="more-link" to="/main/creative">
              더보기
            </Link>
          </p>
          <HomeReferenceSlide type="creative" />
        </div>
      </div>
      <div className="section tech-section">
        <div className="section-container">
          <p className="section-title">
            <Link to="/main/tech">
              <i className="ico-tech"></i>테크
            </Link>
            <Link className="more-link" to="/main/tech">
              더보기
            </Link>
          </p>
          <HomeReferenceSlide type="tech" />
        </div>
      </div>
      {selectedPortfolioId && (
        <Reference
          referenceId={selectedPortfolioId}
          onHide={() => {
            // setSearchLock(true)
            navigate('/')
          }}
        />
      )}
      {showPopupBanner && (
        <HomePopupBanner
          onHide={() => {
            setShowPopupBanner(false)
          }}
        />
      )}
      {showForbiddenPopup && (
        <Confirm
          title="접근 권한이 없습니다"
          body="레퍼런스가 비공개 설정되어 있을 수 있습니다.<br/>해당 스튜디오 관리자에게 문의해 보세요."
          isBlurBg={true}
          onOk={() => {
            setShowForbiddenPopup(false)
          }}
        />
      )}
    </div>
  )
}
function setNotificationState(arg0: { type: string; message: string }) {
  throw new Error('Function not implemented.')
}
