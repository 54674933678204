import request from 'src/utils/Request'
import { ImageRes } from './types/image'

const imageApi = {
  // 프로필 이미지 변경
  upload: (uploadImage: any) => {
    return request.post<ImageRes>(
      '/v1/image',
      { uploadImage },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
  // 이미지 링크 가져오기
  get: (uuid: string) => {
    return request.get(`/v1/image/${uuid}`)
  },
}

export default imageApi
