import React from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

export default function withAuthRequired(Component: React.ComponentType, redirectUrl: string = '/login') {
  return (props: any) => {
    const { account } = useAuth()
    if (!account) {
      return <Navigate to={redirectUrl} replace />
    }

    return <Component {...props} />
  }
}
