import { useEffect, useState } from 'react'
import api from 'src/api'
import Image from 'src/components/Common/Image'
import { notificationState } from 'src/recoil/atom/notification'
import { useSetRecoilState } from 'recoil'
import Confirm from '../Popup/Confirm'
import ReferenceListItem from '../Home/ReferenceListItem'

export default function VisitListTabContent() {
  const setNotificationState = useSetRecoilState(notificationState)

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [page, setPage] = useState(null)
  const [totalPage, setTotalPage] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  useEffect(() => {
    setPage(0)
  }, [])

  useEffect(() => {
    fetchViitList()
  }, [page])

  const fetchViitList = () => {
    setLoading(true)
    setSelectedIds([])
    api.portfolio.getVisitLogs(page).then((res) => {
      setLoading(false)
      const { content, totalPages } = res.data
      setItems(content)
      setTotalPage(totalPages)
    })
  }

  const deleteVisitLogs = () => {
    api.portfolio
      .deleteVisitLogs(selectedIds)
      .then(() => {
        fetchViitList()
        setEditMode(false)
        setNotificationState({
          type: 'check',
          message: '방문 기록을 삭제했어요',
        })
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const goPrev = () => {
    if (page == 0) {
      return
    }

    setPage(page - 1)
  }

  const goNext = () => {
    if (page >= totalPage) {
      return
    }

    setPage(page + 1)
  }

  const renderHeadArea = () => {
    return (
      <div className="flex-row-space-between filter-area">
        <div className="flex">
          {!editMode && (
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setEditMode(true)
              }}
            >
              편집
            </button>
          )}
          {editMode && (
            <button
              className="btn btn-outline btn-sm"
              onClick={() => {
                setEditMode(false)
              }}
            >
              취소
            </button>
          )}
          {editMode && (
            <button
              className="ml-10 btn btn-secondary btn-sm"
              onClick={() => {
                setShowDeleteConfirm(true)
              }}
            >
              삭제
            </button>
          )}
        </div>
        <div className="visit-pagination">
          {page > 0 && <button className="prev-btn" onClick={goPrev}></button>}
          <span className="fs-12 bold">
            {page + 1} - {totalPage}
          </span>
          {page !== totalPage && <button className="next-btn" onClick={goNext}></button>}
        </div>
      </div>
    )
  }

  const renderList = () => {
    if (loading) {
      return <div className="spinner with-margin"></div>
    }

    if (items.length === 0) {
      return (
        <div className="fs-18 w-100 flex-center color-grey-400" style={{ height: 500 }}>
          방문 기록이 없어요
        </div>
      )
    }

    return (
      <ul className={`reference-items ${editMode ? 'edit-mode' : ''}`}>
        {items?.map((visit: any, index) => {
          const item = visit.portfolio
          const isSelected = selectedIds.findIndex((id) => id === visit.portfolio.id) >= 0

          return <ReferenceListItem
            item={item}
            key={index}
            className={isSelected && 'selected'}
            onCustomClick={(item) => {
              if (editMode) {
                if (isSelected) {
                  setSelectedIds(selectedIds.filter((selectedId) => selectedId !== item.id))
                } else {
                  setSelectedIds([...selectedIds, item.id])
                }
              } else {
                window.open('/r/' + item.id)
              }
            }}
          />
        })}
      </ul>
    )
  }

  return (
    <>
      {renderHeadArea()}
      {renderList()}
      {showDeleteConfirm && (
        <Confirm
          title="선택한 방문 기록을 삭제하시겠습니까?"
          body="한 번 삭제하면 되돌릴 수 없습니다"
          okText="삭제"
          cancelText="아니요"
          onOk={() => {
            deleteVisitLogs()
            setShowDeleteConfirm(false)
          }}
          onCancel={() => {
            setShowDeleteConfirm(false)
          }}
        />
      )}
    </>
  )
}
