import { useState } from 'react'
import { getBirthdayValidationMsg } from 'src/utils/Vaildiator'

type Props = {
  defaultBirthday: string
  onChange: (birthday: string) => void
}
export default function BirthdayInput({ defaultBirthday, onChange }: Props) {
  const [birthday, setBirthday] = useState(defaultBirthday?.split('-').join('') || '')
  const [birthdayErrMsg, setBirthdayErrMsg] = useState(null)

  const changeBirthday = (e: any) => {
    const birthday = String(e.target.value)
    const birthdayErrMsg = getBirthdayValidationMsg(birthday)

    setBirthday(birthday)
    setBirthdayErrMsg(birthdayErrMsg)

    onChange(birthday)
  }

  const renderBirthdayInput = () => {
    let inputClassNames = ['mt-10', 'input', 'w-100']
    if (birthdayErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (birthday?.length === 8) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="number"
          className={inputClassNames.join(' ')}
          placeholder="생년월일 (8자리 숫자로 입력)"
          required
          value={birthday}
          onChange={changeBirthday}
          autoComplete="new-password"
          min={0}
          max={999999}
        />
        {birthdayErrMsg && <p className="mt-10 color-danger">{birthdayErrMsg}</p>}
      </>
    )
  }

  return renderBirthdayInput()
}
