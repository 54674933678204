import { useEffect, useRef, useState } from 'react'
import api from 'src/api'
import { CompanySummaryRes } from 'src/api/types/company'
import CompanyProfileImg from './CompanyProfileImg'
import { UserProfileImg } from './Profile'

type Props = {
  placeholder?: string
  defaultText: string
  renderCustomItem?: (item: CompanySummaryRes) => any
  onChange: (item: CompanySummaryRes) => void
}

export default function MsgReceiverSearchInput({ defaultText, placeholder, renderCustomItem, onChange }: Props) {
  const [items, setItems] = useState<CompanySummaryRes[]>([])
  const [users, setUsers] = useState([])
  const [keyword, setKeyword] = useState(defaultText)
  const [showResult, setShowResult] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isFocus, setIsFocus] = useState(false)
  const [loading, setLoading] = useState(false)

  const timerId = useRef(null)

  let searchRequest1 = null
  let searchRequest2 = null

  useEffect(() => {
    if (items.length === 0 && users.length === 0) {
      // setShowResult(false)
    }
  }, [items, users])

  const search = (searchKeyword: string) => {
    setUsers([])
    setItems([])

    if (searchKeyword.length < 2) {
      return
    }

    searchRequest1?.abort()
    searchRequest2?.abort()
    setLoading(true)

    searchRequest1 = api.account
      .search(searchKeyword)
      .then((response) => {
        setUsers(response.data)
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setLoading(false)
      })

    searchRequest2 = api.company
      .searchByName(searchKeyword)
      .then((response) => {
        setItems(response.data)
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderResultBox = () => {
    if (!showResult || (items.length === 0 && users.length === 0 && !loading)) {
      return <></>
    }

    return (
      <div className="search-result-box">
        {loading && <div className="spinner mx-auto"></div>}
        <ul className="user-list">
          {users?.map((item, index) => {
            if (renderCustomItem) {
              return renderCustomItem(item)
            }

            return (
              <li
                key={index}
                onClick={() => {
                  console.log(item)
                  setSelectedItem(item)
                  setKeyword(item.name)
                  setShowResult(false)
                  setIsFocus(false)
                  onChange(item)
                }}
              >
                <UserProfileImg account={item} size={30} />
                <div style={{ marginLeft: 12 }}>
                  <p className="fs-14">{item.name}</p>
                  <p className="fs-12 color-grey-600">
                    {process.env.REACT_APP_BASE_URL}/u/{item.urlKey}
                  </p>
                </div>
              </li>
            )
          })}
          {items?.map((item, index) => {
            if (renderCustomItem) {
              return renderCustomItem(item)
            }

            return (
              <li
                key={index}
                onClick={() => {
                  setSelectedItem(item)
                  setKeyword(item.name)
                  setShowResult(false)
                  setIsFocus(false)
                  onChange(item)
                }}
              >
                <CompanyProfileImg company={item} size={30} />
                <div style={{ marginLeft: 12 }}>
                  <p className="fs-14">{item.name}</p>
                  <p className="fs-12 color-grey-600">
                    {process.env.REACT_APP_BASE_URL}/c/{item.urlKey}
                  </p>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const ref = useOutsideClick(() => {
    // setShowResult(false)
    if (selectedItem === null && keyword?.length > 0) {
      // setKeyword('')
      // onChange(null)
    }
  })

  return (
    <div className={`search-input ${isFocus ? 'focus' : ''}`} ref={ref}>
      <div
        className="close-btn"
        onClick={() => {
          setShowResult(false)
          setIsFocus(false)
        }}
      ></div>
      <input
        type="text"
        className="input"
        placeholder={placeholder}
        value={keyword}
        onChange={(e) => {
          const keyword = e.target.value.trim()
          clearTimeout(timerId.current)
          timerId.current = setTimeout(() => {
            if (keyword.length > 0) {
              search(keyword)
              setShowResult(true)
            } else {
              setShowResult(false)
            }
          }, 200)

          setKeyword(keyword)
        }}
        onFocus={() => {
          setIsFocus(true)
        }}
        onBlur={() => {
          // setIsFocus(false)
        }}
      />
      {renderResultBox()}
    </div>
  )
}
