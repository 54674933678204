import { useEffect, useState } from 'react'
import api from 'src/api'
import { UserType } from 'src/api/types/account'
import { SocialItemReq } from 'src/api/types/social'
import Select, { SelectOption } from '../Select/Select'

type Props = {
  defaultSocials: any
  type?: UserType
  onChange: (data: any) => void
}
export default function SocialLinkInput({ defaultSocials, onChange, type = 'Company' }: Props) {
  const [snsOptions, setSnsOptions] = useState<any>(null)
  const [selectedSnsItems, setSelectedSnsItems] = useState<SocialItemReq[]>([])
  const [socialVal, setSocialVal] = useState('')
  const [snsOption, setSnsOption] = useState('')
  const [socialMap, setSocialMap] = useState({})
  const [inputType, setInputType] = useState('text')
  const [inputPlaceholder, setInputPlaceholder] = useState('')

  const [loading, setLoading] = useState(true)
  const companyTypes = [
    'facebook',
    'insta',
    'linkdin',
    'brunch',
    'naver_blog',
    'naver_cafe',
    'notion',
    'youtube',
    'vimeo',
    'pinterest',
    'tumbler',
    'twitter',
    'email',
    'behance',
    'phone'
  ]

  const userTypes = [
    'kakao',
    'email',
    'website',
    'facebook',
    'insta',
    'linkdin',
    'brunch',
    'naver_blog',
    'naver_cafe',
    'notion',
    'youtube',
    'vimeo',
    'pinterest',
    'tumbler',
    'twitter',
    'behance',
  ]

  const fetchSocials = () => {
    api.social
      .getList()
      .then((res) => {
        const list = res.data

        let items = []
        let socialMap = {}

        const itemTypes = type === 'Company' ? companyTypes : userTypes

        for (const itemType of itemTypes) {
          const searchItems = list.filter((item) => item.icon === itemType)
          if (!searchItems || searchItems.length === 0) {
            continue
          }
          const item = searchItems[0]
          console.log(item)
          items.push({
            label: item.name,
            value: item.id,
          })

          socialMap[item.id] = item
        }

        setSnsOptions(items)
        setSocialMap(socialMap)

        const selectedItems = []
        for (const defaultItem of defaultSocials) {
          if (!socialMap[defaultItem.socialId]) {
            continue
          }

          selectedItems.push(defaultItem)
        }

        setSelectedSnsItems(selectedItems)

        setLoading(false)
      })
      .catch((e) => {
        // do nothing
      })
  }

  useEffect(() => {
    fetchSocials()
  }, [])

  const addSnsItems = () => {
    const findItems = selectedSnsItems.filter((item) => parseInt(snsOption) === item.socialId)
    if (findItems.length > 0) {
      return
    }

    setSocialVal('')
    const items = [...selectedSnsItems, { data: socialVal, socialId: parseInt(snsOption) }]
    setSelectedSnsItems(items)
    onChange(items)
  }

  const deleteSnsItem = (index: number) => {
    let items = selectedSnsItems.filter((item, i) => index !== i)
    setSelectedSnsItems(items)
    onChange(items)
  }

  const getSocialIcon = (socialId: number) => {
    return socialMap[socialId]?.icon
  }

  if (!snsOptions || loading) {
    return <></>
  }

  return (
    <div className="fs-14 mt-20 color-text-info">
      <div className="sns-input-container">
        <Select
          options={snsOptions}
          defaultLabel="선택"
          title="연락처 및 소셜 링크"
          onChange={(option: SelectOption) => {
            setSnsOption(option.value)
            setInputPlaceholder(option.label === '전화번호' ? '-없이 숫자만 입력' : '')
            setInputType(option.label === '전화번호' ? 'number' : 'text')
          }}
        />
        <div className="right-area">
          <input
            className="input"
            style={{ flexGrow: 1 }}
            type={inputType}
            placeholder={inputPlaceholder}
            value={socialVal}
            onChange={(e) => {
              const socialVal = e.target.value
              setSocialVal(socialVal)
            }}
          />
          <button
            className="btn btn-primary"
            style={{ height: 40 }}
            disabled={socialVal.length === 0 || snsOption === ''}
            onClick={addSnsItems}
          >
            <i className="ico ico-plus-wh ico-sm"></i>
            추가
          </button>
        </div>
      </div>
      <ul className="mt-20 sns-list">
        {selectedSnsItems.map((item, i) => {
          return (
            <li key={i}>
              <img src={`/static/img/sns/${getSocialIcon(item.socialId)}.svg`} />
              <span>{item.data}</span>
              <div className="flex-grow-1"></div>
              <button
                className="btn btn-outline-primary delete-btn btn-sm"
                onClick={() => {
                  deleteSnsItem(i)
                }}
              >
                삭제
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
