import api from 'src/api'
import { SocialRes } from 'src/api/types/social'
import { AccountCopyLink, CompanyCopyLink } from 'src/components/Common/CopyLink'
import { UserProfileImg } from 'src/components/Common/Profile'
import SocialListItem from 'src/components/Common/SocialListItem'
import MessageSendPopup from 'src/components/Message/SendPopup'
import authState from 'src/recoil/atom/auth'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

export default function Index() {
  const navigate = useNavigate()
  const location = useLocation()

  const [data, setData] = useState(null)
  const [showSendMessage, setShowSendMessage] = useState(false)
  const [{ waiting, account }, setAuthState] = useRecoilState(authState)
  const [{ popupType }, setPopupState] = useRecoilState(popupState)

  useEffect(() => {
    if (location.pathname.indexOf('/u/') === -1) {
      navigate('/notFound')
      return
    }

    const comps = location.pathname.split('/u/')
    if (comps.length < 2) {
      navigate('/notFound')
      return
    }

    const urlKey = comps.pop()
    fetchAccount(urlKey)

    setTimeout(() => {
      if (!account && popupType === MainPopupType.None) {
        setPopupState({ popupType: MainPopupType.NeedToLogin })
      }
    }, 2000)
  }, [])

  const fetchAccount = (urlKey: string) => {
    api.account
      .getByUrlKey(urlKey)
      .then((res) => {
        setData(res.data)
      })
      .catch((e) => {
        //navigate('/notFound')
        return
      })
  }

  const renderCompany = () => {
    try {
      const item = JSON.parse(data.companyJson)
      const { department, position, duty, activityArea } = item
      if (account.companyName || department || position || duty || activityArea) {
        return (
          <>
            {data.companyName && <p className="mt-20 fs-18 bold">{data.companyName}</p>}
            {data.companyUrlKey && (
              <CompanyCopyLink
                data={data.companyUrlKey}
                className="fs-12 color-grey-700 flex-vertical-center link"
                style={{ marginTop: 4 }}
              />
            )}
            {(department || position) && (
              <p className="mt-10 fs-14 flex" style={{ gap: 6 }}>
                <span>{department}</span>
                {department && position && <span>/</span>}
                <span>{position}</span>
              </p>
            )}
            {activityArea && (
              <p className="fs-12 color-grey-600" style={{ marginTop: 4 }}>
                {activityArea}
              </p>
            )}
          </>
        )
      }
    } catch (e) {
      // do nothing
    }

    return <div className="fs-14 mt-20 color-text-info">준비 중입니다.</div>
  }

  const renderSocial = () => {
    const socials = data.socials
    if (!socials || socials.length === 0) {
      return <div className="fs-14 mt-20 color-text-info">준비 중입니다.</div>
    }

    return (
      <ul className="mt-20 profile-social-list">
        {socials.map((item: SocialRes) => {
          return <SocialListItem item={item} />
        })}
      </ul>
    )
  }

  const renderContent = () => {
    if (!data) {
      return <div className="spinner with-margin"></div>
    }

    return (
      <>
        <div className="box">
          <UserProfileImg account={data} size={100} clickable={false} classNames={['mx-auto', 'display-block']} />
          <p className="mt-20 fs-20 bold text-center mt-20">{data.name}</p>
          <AccountCopyLink account={data} style={{ marginTop: 8, justifyContent: 'center' }} fontSize={14} />
          <button
            className="mt-20 mx-auto btn btn-primary btn-md"
            style={{ width: 162 }}
            onClick={() => {
              setShowSendMessage(true)
            }}
          >
            메시지
          </button>
        </div>
        <div className="box">
          <p className="fs-16 bold">기본 프로필</p>
          {renderCompany()}
        </div>
        <div className="box">
          <p className="fs-16 bold">연락처 및 소셜링크</p>
          {renderSocial()}
        </div>
      </>
    )
  }

  return (
    <div className="page user-profile-page bg-color-grey-50">
      <div className="box-container container">{renderContent()}</div>
      {showSendMessage && (
        <MessageSendPopup
          target={data}
          onClose={() => {
            setShowSendMessage(false)
          }}
        />
      )}
    </div>
  )
}
