import { Link, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { getUserLink, UserProfileImg } from 'src/components/Common/Profile'
import authState from 'src/recoil/atom/auth'

type Props = {
  onClick?: () => void
}

export default function UserProfile({ onClick = null }: Props) {
  const navigate = useNavigate()
  const { account } = useRecoilValue(authState)
  if (account === null) {
    return <></>
  }

  return (
    <div className="user-profile-area sm">
      <div onClick={onClick}>
        <UserProfileImg account={account} />
      </div>

      <div>
        {account.activeStatus === 'Disable' && (
          <div style={{ marginBottom: 6 }}>
            <span className="badge">비활성화</span>
          </div>
        )}
        <p className="fs-18 bold">
          <a
            onClick={() => {
              onClick?.()
              navigate('/my/profile')
            }}
          >
            {account.name}
          </a>
        </p>
        <p className="fs-12 color-text-secondary">
          <Link style={{ marginTop: 4 }} to={'/my/profile'} onClick={onClick}>
            {process.env.REACT_APP_BASE_URL}/u/{account.urlKey}
          </Link>
        </p>
      </div>
    </div>
  )
}
