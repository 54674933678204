export default function TrashCan() {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.96514 0.527208C5.29854 0.189642 5.75073 0 6.22222 0H9.77778C10.2493 0 10.7015 0.189642 11.0349 0.527208C11.3683 0.864773 11.5556 1.32261 11.5556 1.8V3.6H15.1111C15.602 3.6 16 4.00294 16 4.5C16 4.99706 15.602 5.4 15.1111 5.4H15.0499L14.3382 15.4917C14.3382 15.4918 14.3382 15.4916 14.3382 15.4917C14.2903 16.1728 13.9892 16.8104 13.4957 17.2758C13.0021 17.7413 12.3527 18 11.6782 18H4.3218C3.64732 18 2.99789 17.7413 2.50431 17.2758C2.01079 16.8104 1.70975 16.173 1.66181 15.4919C1.66181 15.4918 1.66182 15.492 1.66181 15.4919L0.950097 5.4H0.888889C0.397969 5.4 0 4.99706 0 4.5C0 4.00294 0.397969 3.6 0.888889 3.6H4.44444V1.8C4.44444 1.32261 4.63175 0.864773 4.96514 0.527208ZM6.22222 3.6H9.77778V1.8H6.22222V3.6ZM2.7324 5.4L3.43508 15.3637C3.45104 15.5908 3.5514 15.8035 3.71593 15.9586C3.88045 16.1138 4.09693 16.2 4.32178 16.2H11.6782C11.9031 16.2 12.1195 16.1138 12.2841 15.9586C12.4486 15.8035 12.5489 15.591 12.5649 15.3639L13.2676 5.4H2.7324ZM6.22222 7.2C6.71314 7.2 7.11111 7.60294 7.11111 8.1V13.5C7.11111 13.9971 6.71314 14.4 6.22222 14.4C5.7313 14.4 5.33333 13.9971 5.33333 13.5V8.1C5.33333 7.60294 5.7313 7.2 6.22222 7.2ZM9.77778 7.2C10.2687 7.2 10.6667 7.60294 10.6667 8.1V13.5C10.6667 13.9971 10.2687 14.4 9.77778 14.4C9.28686 14.4 8.88889 13.9971 8.88889 13.5V8.1C8.88889 7.60294 9.28686 7.2 9.77778 7.2Z"
        fill="currentColor"
      />
    </svg>
  )
}
