import { Link } from 'react-router-dom'

export default function StudioDeactivated() {
  return (
    <div className="popup-wrap mobile-page">
      <div className="popup">
        <p className="fs-24 bold text-center">스튜디오가 비활성화되었습니다</p>
        <Link className="mt-40 btn btn-outline-grey btn-sm mx-auto" style={{ width: 170 }} to={'/'}>
          메인으로 이동
        </Link>
      </div>
    </div>
  )
}
