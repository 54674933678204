export default function reportClickEvent(category = '', action = '', label = '', value = '') {
  const { dataLayer } = window as any
  if (!dataLayer) {
    return
  }
  console.log({
    event: 'ClickEvent',
    category,
    action,
    label,
    value,
  })

  dataLayer.push({
    event: 'ClickEvent',
    category,
    action,
    label,
    value,
  })
}
