import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import { dateFormat } from 'src/utils/Format'

export default function PaymentComplete() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [{ account }, setAuthState] = useRecoilState(authState)
  const setNotificationState = useSetRecoilState(notificationState)

  const [isGoStudio, setIsGoStudio] = useState(false)

  const [data, setData] = useState(null)

  useEffect(() => {
    setIsGoStudio(account?.company.plan.plan.id === 0)
    updateProfessionalPlan()
  }, [])

  const updateProfessionalPlan = () => {
    let customerKey = searchParams.get('customerKey')
    let authKey = searchParams.get('authKey')
    api.toss
      .success(customerKey, authKey)
      .then((res) => {
        api.company
          .updatePlan(account.company.id, 2)
          .then(() => {
            fetchData()
            fetchUser()
          })
          .catch(() => {
            setNotificationState({
              type: 'error',
              message: '네트워크 에러',
            })
          })
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
        navigate('/studio/plan')
      })
  }

  const fetchUser = () => {
    api.account
      .fetch()
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })
      })
      .catch((error) => { })
  }

  const fetchData = () => {
    api.company.getMyCompany().then((res) => {
      setData(res.data)
    })
  }

  if (!data) {
    return (
      <div className="page bg-color-grey-50">
        <div className="spinner with-margin"></div>
      </div>
    )
  }
  return (
    <div className="page bg-color-grey-50 payment-complete-page">
      <div className="container">
        <div className="box">
          <p className="mt-20 fs-24 bold">정기결제가 완료되었어요</p>
          <p className="fs-14" style={{ marginTop: 8 }}>
            오늘 첫 결제가 이루어질 예정이에요.
          </p>

          <p className="mt-20 fs-12 color-grey-700">결제일</p>
          <p className="fs-14" style={{ marginTop: 4 }}>
            프로페셔널
          </p>

          <p className="mt-20 fs-12 color-grey-700">결제일</p>
          <p className="fs-14" style={{ marginTop: 4 }}>
            {data.plan.expiresAt ? `매월 ${new Date(data.plan.expiresAt).getDate()}일 (영업일 기준)` : '-'}
          </p>

          <p className="mt-20 fs-12 color-grey-700">다음 결제일</p>
          <p className="fs-14" style={{ marginTop: 4 }}>
            {dateFormat(data.plan.expiresAt)}
          </p>

          <p className="mt-20 fs-12 color-grey-700">결제수단</p>
          <p className="fs-14" style={{ marginTop: 4 }}>
            {data?.billing?.cardCompany} {data.billing?.cardNumber}, 등록일 : {dateFormat(data.billing?.createdAt)}
          </p>

          <p className="mt-20 fs-12 color-grey-700">회사이름</p>
          <p className="fs-14" style={{ marginTop: 4 }}>
            {data.planManagerCompanyName}
          </p>

          <p className="mt-20 fs-12 color-grey-700">담당자</p>
          <p className="fs-14" style={{ marginTop: 4 }}>
            {data.planManagerName}
          </p>

          <p className="mt-20 fs-12 color-grey-700">담당자 이메일</p>
          <p className="fs-14" style={{ marginTop: 4 }}>
            {data.planManagerEmail}
          </p>

          <p className="mt-20 fs-12 color-grey-700">담당자 휴대폰 번호</p>
          <p className="fs-14" style={{ marginTop: 4 }}>
            {data.planManagerPhoneNumber}
          </p>
        </div>
        <div className="box receipt-box">
          <div className="receipt-content-area">
            <div className="receipt-box-body">
              <p className="fs-16 bold">결제금액 상세 정보</p>
              <p className="fs-12" style={{ marginTop: 4 }}>
                {dateFormat(data.billing?.createdAt)}
              </p>
              <p className="mt-30 fs-12 color-grey-700">항목</p>
              <div className="mt-20 fs-14 price-item">
                <p>월 과금액</p>
                <p>
                  <span className="fs-18 bold">26,910</span>
                  <span>원</span>
                </p>
              </div>
              <div className="mt-10 fs-14 price-item">
                <p>부가세</p>
                <p>
                  <span className="fs-18 bold">2,990</span>
                  <span>원</span>
                </p>
              </div>
              <div className="mt-16" style={{ marginTop: 16, overflow: 'hidden' }}>
                <span className="badge badge-danger float-right">beta기간 무료</span>
              </div>
              <div className="price-item" style={{ marginTop: 4 }}>
                <p className="fs-12 color-grey-700">최종 결제 금액</p>
                <p>
                  <span className="raw-price">29,000</span>
                  <span className="sale-price">0</span>
                  <span>원</span>
                </p>
              </div>
            </div>
            <div className="receipt-box-foot">
              <img src="/static/img/logo.svg" alt="" />
              <p className="fs-12" style={{ marginTop: 10 }}>
                바인더스를 이용해 주셔서 감사합니다!
              </p>
            </div>
          </div>
          {isGoStudio ? (
            <div className="pc-only btn-wrap page-foot-btn">
              <button
                className="mx-auto btn btn-primary w-100"
                style={{ maxWidth: 358, lineHeight: 48 }}
                onClick={() => {
                  navigate('/studio/profile')
                }}
              >
                스튜디오로 이동
              </button>
            </div>
          ) : (
            <div
              className="mx-auto btn btn-outline-grey btn-sm bg-white"
              style={{ maxWidth: 170 }}
              onClick={() => {
                navigate('/studio/plan')
              }}
            >
              플랜관리로 이동
            </div>
          )}
        </div>
        {isGoStudio && (
          <div className="mobile-only btn-wrap page-foot-btn">
            <button
              className="mx-auto btn btn-primary w-100"
              style={{ maxWidth: 358, lineHeight: 48 }}
              onClick={() => {
                navigate('/studio/profile')
              }}
            >
              스튜디오로 이동
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
