import { useEffect, useState } from 'react'
import api from 'src/api'
import { ImageRes } from 'src/api/types/image'

type Props = {
  img: ImageRes
  className?: string
}
export default function Image({ img, className }: Props) {
  const [imgSrc, setImgSrc] = useState(null)

  useEffect(() => {
    if (!img) {
      return
    }
    api.image.get(img.uuid).then((res) => {
      setImgSrc(res.data.url)
    })
  }, [img])

  if (!imgSrc) {
    return <div style={{ width: '100%', height: 100 }}></div>
  }

  return <img className={className} src={imgSrc} />
}
