import { useRef, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import UserProfile from 'src/components/Common/UserProfileCard'
import StudioProfile from 'src/components/Common/StudioProfileCard'
import { useCookies } from 'react-cookie'

type Props = {
  onHide: () => void
}

export default function ProfileBox({ onHide }: Props) {
  const { logout } = useAuth()
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['isStudioMode'])

  const clickLogout = () => {
    onHide()
    navigate('/')
    removeCookie('isStudioMode')
    logout()
  }

  const clickAccount = () => {
    navigate('/my/account')
    onHide()
  }

  return (
    <div>
      <div className="dim mobile-only" onClick={onHide} style={{ zIndex: 200 }}></div>
      <div className="profile-box" style={{ zIndex: 200 }}>
        <div className="close-btn mobile-only" onClick={onHide}></div>
        <UserProfile onClick={onHide} />
        <StudioProfile onClick={onHide} />
        <ul className="box-menus">
          <li onClick={clickAccount}>계정 설정</li>
          <li onClick={clickLogout}>로그아웃</li>
        </ul>
      </div>
    </div>
  )
}
