import { useEffect, useRef, useState } from 'react'

export default function Footer() {
  const [showEtcInfo, setShowEtcInfo] = useState(false)

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (e.target.closest('.etc-info-link')) {
          return
        }

        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const ref = useOutsideClick(() => {
    if (window.innerWidth < 500) {
      return
    }

    setShowEtcInfo(false)
  })

  return (
    <footer>
      <div>
        <div>
          <p className="etc-info-link">
            <span
              onClick={(e) => {
                setShowEtcInfo(!showEtcInfo)
              }}
            >
              (주) 언바운드 추가정보<i className={`arrow-down ${showEtcInfo ? 'open' : ''}`}></i>
            </span>
          </p>
          {showEtcInfo && (
            <div className="etc-layer" ref={ref}>
              (주)언바운드
              <br />
              서울시 강남구 테헤란로 19길 27 유림빌딩 301호
              <br />
              대표 : 김상은
              <br />
              사업자등록번호 : 722-86-02683
              <br />
              통신판매업신고번호 : 2022 - 서울강남 - 06238 호<br />
              호스팅서비스 : 주식회사 언바운드
              <br />
              유선연락처 : 02-554-3690
              <div className="tail"></div>
            </div>
          )}
        </div>
        <nav className="service-link">
          <a href="https://brand.bindus.kr/" target={'_blank'}>
            서비스 소개
          </a>
          <a target="_blank" href="https://brand.bindus.kr/terms-of-use">
            이용약관
          </a>
          <a target="_blank" href="https://brand.bindus.kr/privacypolicy">
            개인정보처리방침
          </a>
          <a target="_blank" href="https://forms.gle/mZh4qxDYBTsyY5ax9">
            이용문의
          </a>
        </nav>
      </div>
      <div>
        <p className="copyright fs-11 color-grey-500">Copyright © 2023 UnBound Corp.{'\n'}All rights reserved.</p>
        <nav className="sns-link">
          {/* <a href="" className="youtube">
            youtube
          </a> */}
          <a target="_blank" href="https://www.instagram.com/bindus.official/" className="instagram">
            instagram
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/people/Bindusofficial/100089099997556/"
            className="facebook"
          >
            facebook
          </a>
          <a target="_blank" href="https://www.linkedin.com/in/by-unbound-bindus-149b20251/" className="linkedin">
            linkedin
          </a>
          {/* <a href="" className="naverblog">
            naverblog
          </a> */}
        </nav>
      </div>
    </footer>
  )
}
