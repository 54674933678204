import { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { PlanRes } from 'src/api/types/plan'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import { dateFormatKr } from 'src/utils/Format'
import Confirm from './Confirm'

type Props = {
  plan: PlanRes
  onHide: () => void
  onOk: () => void
}
export default function StudioPlanDowngradeConfirm({ plan, onHide, onOk }: Props) {
  const [{ account }] = useRecoilState(authState)
  const setNotificationState = useSetRecoilState(notificationState)

  const [step, setStep] = useState(0)

  const downgrade = () => {
    api.company
      .updatePlan(account.company.id, 1)
      .then(() => {
        setStep(1)
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const renderConfirm = () => {
    return (
      <div className="popup text-center">
        <button className="popup-close close-btn" onClick={onHide} />
        <i className="ico ico-error" style={{ width: 34, height: 34, margin: '0 auto' }}></i>
        <p className="fs-18 bold" style={{ marginTop: 14 }}>
          다운그레이드 하면 업로드한 레퍼런스의 <br />
          일부가 <span className="color-red-700">비공개로 전환</span>됩니다
        </p>
        <p className="fs-14 color-grey-700" style={{ marginTop: 12 }}>
          레퍼런스는 오래된 순서대로 비공개 전환됩니다.
          <br />
          공개 설정은 레퍼런스 관리에서 변경할 수 있습니다.
        </p>
        <p className="mt-20 fs-16 color-red-700">이번 사용기간이 끝나면 자동으로 스타터플랜으로 전환됩니다.</p>
        <p className="fs-14 color-grey-700" style={{ marginTop: 14 }}>
          현재 플랜 : 프로페셔널 플랜
          <br />
          잔여기간 : {dateFormatKr(plan.expiresAt, -1)}까지
        </p>
        <div className="btn-wrap page-foot-btn">
          <button className="btn" onClick={onHide}>
            취소
          </button>
          <button className="btn btn-primary" onClick={downgrade}>
            확인
          </button>
        </div>
      </div>
    )
  }

  const renderComplete = () => {
    return (
      <Confirm
        title="스타터 플랜으로 변경 신청했어요"
        body={`<span class="color-grey-700">${dateFormatKr(plan.expiresAt)}이후 스타터플랜으로 전환됩니다.</span>`}
        onOk={() => {
          onOk()
        }}
      />
    )
  }

  return (
    <div className="popup-wrap mobile-page">
      {step === 0 && renderConfirm()}
      {step === 1 && renderComplete()}
    </div>
  )
}
