import { useState } from 'react'
import { getPhoneNumberValidationMsg } from 'src/utils/Vaildiator'

type Props = {
  defaultPhoneNumber: string
  placeholder: string
  onChange: (birthday: string) => void
}

export default function PhoneNumberInput({ defaultPhoneNumber, placeholder, onChange }: Props) {
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber)
  const [phoneNumberErrMsg, setPhoneNumberErrMsg] = useState(null)

  const changePhoneNumber = (e: any) => {
    const phoneNumber = e.target.value
    const phoneNumberErrMsg = getPhoneNumberValidationMsg(phoneNumber)

    setPhoneNumber(phoneNumber)
    setPhoneNumberErrMsg(phoneNumberErrMsg)

    onChange(phoneNumber)
  }

  const renderBirthdayInput = () => {
    let inputClassNames = ['mt-10', 'input', 'w-100']
    if (phoneNumberErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    }

    return (
      <>
        <input
          type="text"
          className={inputClassNames.join(' ')}
          placeholder={placeholder}
          value={phoneNumber}
          onChange={changePhoneNumber}
          required
        />
        {phoneNumberErrMsg && <p className="mt-10 color-danger">{phoneNumberErrMsg}</p>}
      </>
    )
  }

  return renderBirthdayInput()
}
