import { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { UserType } from 'src/api/types/account'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import MsgReceiverSearchInput from '../Common/MsgReceiverSearchInput'

type Props = {
  onClose: (isUpdated: boolean) => void
  target?: any
  senderType?: UserType
  receiverType?: UserType
}

export default function MessageSendPopup({ onClose, target, senderType = 'User', receiverType = 'User' }: Props) {
  const [{ waiting, account }, setAuthState] = useRecoilState(authState)
  const [targetId, setTargetId] = useState(target?.id)
  const [message, setMessage] = useState('')
  const setNotificationState = useSetRecoilState(notificationState)
  const [recevierType, setReceiverType] = useState(receiverType)

  const onSendSuccess = () => {
    onClose(true)

    // 모바일 깜빡거림 때문에 딜레이
    setTimeout(() => {
      setNotificationState({
        type: 'check',
        message: '전송 되었습니다',
      })
    }, 200)
  }

  const onSendFail = () => {
    // do nothing
    setNotificationState({
      type: 'error',
      message: '네트워크 에러',
    })
  }

  const send = () => {
    if (!targetId) {
      return
    }

    if (senderType === 'User') {
      if (recevierType === 'User') {
        api.message.send('user', targetId, message).then(onSendSuccess).catch(onSendFail)
      } else {
        api.message.send('company', targetId, message).then(onSendSuccess).catch(onSendFail)
      }
    } else {
      if (recevierType === 'User') {
        api.companyMessage.send(account.company.id, 'user', targetId, message).then(onSendSuccess).catch(onSendFail)
      } else {
        api.companyMessage.send(account.company.id, 'company', targetId, message).then(onSendSuccess).catch(onSendFail)
      }
    }
  }

  const changeMessage = (e: any) => {
    setMessage(e.target.value)
  }

  const renderSender = () => {
    if (recevierType == 'User') {
      return (
        <>
          <p className="fs-14 bold">받는사람</p>
          <div style={{ marginTop: 8 }}>
            {target ? (
              <input className="input w-100" readOnly={true} value={target.name}></input>
            ) : (
              <MsgReceiverSearchInput
                onChange={function (item: any): void {
                  setTargetId(item.id)
                  setReceiverType(item.entityType)
                }}
                defaultText={''}
              />
            )}
          </div>
        </>
      )
    } else {
      return (
        <>
          <p className="fs-14 bold">받는사람</p>
          <div style={{ marginTop: 8 }}>
            {target ? (
              <input className="input w-100" readOnly={true} value={target.name}></input>
            ) : (
              <MsgReceiverSearchInput
                onChange={(item: any) => {
                  setTargetId(item.id)
                }}
                defaultText={''}
              />
            )}
          </div>
        </>
      )
    }
  }

  return (
    <div
      className="popup-wrap mobile-full-page"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className="popup" id="send-message-popup">
        <p className="mobile-only" style={{ marginBottom: 40 }}>
          <a
            className="fs-14 bold flex flex-vertical-center"
            onClick={() => {
              onClose(false)
            }}
          >
            <i className="ico ico-sm ico-back-arrow" style={{ marginRight: 6 }}></i>새 메시지
          </a>
        </p>
        {renderSender()}
        <p style={{ marginTop: 24 }} className="fs-14 bold">
          메시지
        </p>
        <textarea
          className="input w-100"
          style={{ marginTop: 8 }}
          onChange={changeMessage}
          placeholder="메시지를 입력하세요"
        ></textarea>
        <div className="btn-wrap">
          <button
            className="btn btn-outline"
            onClick={() => {
              onClose(false)
            }}
          >
            취소
          </button>
          <button
            className="btn btn-primary ml-10"
            onClick={send}
            disabled={message.length === 0 || targetId?.length === 0}
          >
            보내기
          </button>
        </div>
      </div>
    </div>
  )
}
