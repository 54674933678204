type Props = {
  message?: string
}

export default function Waiting({ message }: Props) {
  return (
    <div className="full-page">
      <div className="spinner"></div>
    </div>
  )
}
