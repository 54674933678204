import { useCallback, useMemo, useState } from 'react'
import EditorBlock from './EditorBlock'
import EditorToolbar from './EditorToolbar'
import styles from './index.module.scss'
import { EditorProps, EditorBlock as TEditorBlock } from './types'

export default function CompanyProfileEditor({ className: classNameProp, blocks, onBlocksChange }: EditorProps) {
  const className = useMemo(() => [styles.editor, classNameProp].filter((item) => item).join(' '), [classNameProp])

  const [openedBlockId, setOpenedBlockId] = useState<string | null>(null)

  const generateEditorBlock = useCallback(
    (index: number) => {
      return (block: TEditorBlock) => {
        const newValue = [...blocks]
        newValue[index] = block
        onBlocksChange?.(newValue)
      }
    },
    [blocks, onBlocksChange]
  )

  const handleAddBlock = useCallback(
    (block: TEditorBlock) => {
      onBlocksChange?.([...blocks, block])
    },
    [onBlocksChange, blocks]
  )

  const handleDeleteClick = useCallback(
    (block: TEditorBlock) => {
      setOpenedBlockId(null)
      onBlocksChange?.(blocks.filter((item) => item !== block))
    },
    [blocks, onBlocksChange]
  )

  const handleMenuButtonClick = useCallback((block: TEditorBlock) => {
    setOpenedBlockId(block.id)
  }, [])

  const handleBlockMoveClick = useCallback(
    (block: TEditorBlock, direction: 'up' | 'down') => {
      const index = blocks.findIndex((item) => item === block)
      const isFirst = index === 0
      const isLast = index === blocks.length - 1
      if (index < 0 || index >= blocks.length || (isFirst && direction === 'up') || (isLast && direction === 'down')) {
        return
      }
      const swapTargetIndex = index + (direction === 'up' ? -1 : 1)
      const swapTarget = blocks[swapTargetIndex]
      const newBlocks = [...blocks]
      newBlocks[swapTargetIndex] = block
      newBlocks[index] = swapTarget
      onBlocksChange?.(newBlocks)
    },
    [blocks, onBlocksChange]
  )

  return (
    <div className={className}>
      {blocks.map((item, index) => (
        <EditorBlock
          key={item.id}
          block={item}
          onBlockChange={generateEditorBlock(index)}
          menuOpened={openedBlockId?.toString() === item.id.toString()}
          onDeleteClick={handleDeleteClick}
          onMenuButtonClick={handleMenuButtonClick}
          onMoveClick={handleBlockMoveClick}
          isFirst={index === 0}
          isLast={index === blocks.length - 1}
        />
      ))}
      <EditorToolbar onAddBlock={handleAddBlock} />
    </div>
  )
}
