import { atom } from 'recoil';

const settingState = atom<{ isStudioMode: boolean }>({
    key: 'settingState',
    default: {
        isStudioMode: false
    },
})

export default settingState;
