import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import EmailInput from 'src/components/Input/EmailInput'
import PhoneNumberInput from 'src/components/Input/PhoneNumberInput'
import TextInput from 'src/components/Input/TextInput'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import { getEmailValidationMsg, getPhoneNumberValidationMsg } from 'src/utils/Vaildiator'

export default function StudioPlanPayment() {
  const navigate = useNavigate()

  const setNotificationState = useSetRecoilState(notificationState)

  const [{ account }, setAuthState] = useRecoilState(authState)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const [canPayment, setCanPayment] = useState(false)
  const [managerName, setManagerName] = useState('')
  const [managerCompanyName, setManagerCompanyName] = useState('')
  const [managerEmail, setManagerEmail] = useState(account?.email || '')
  const [managerPhonenumber, setManagerPhoneNumber] = useState('')

  useEffect(() => {
    fetchCompany()
  }, [])

  useEffect(() => {
    let canPayment =
      managerName?.length > 0 &&
      managerCompanyName?.length > 0 &&
      managerEmail?.length > 0 &&
      managerPhonenumber?.length > 0

    if (getEmailValidationMsg(managerEmail)) {
      canPayment = false
    }

    if (getPhoneNumberValidationMsg(managerPhonenumber)) {
      canPayment = false
    }

    setCanPayment(canPayment)
  }, [managerName, managerCompanyName, managerEmail, managerPhonenumber])

  const fetchCompany = () => {
    api.company
      .getMyCompany()
      .then((res) => {
        const {
          planManagerCompanyName = '',
          planManagerName = '',
          planManagerEmail = '',
          planManagerPhoneNumber = '',
        } = res.data

        setManagerCompanyName(planManagerCompanyName)
        setManagerName(planManagerName)
        setManagerEmail(planManagerEmail)
        setManagerPhoneNumber(planManagerPhoneNumber)

        setData(res.data)
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const requestPayment = () => {
    api.company
      .updatePlanManager(data?.id, managerCompanyName, managerName, managerEmail, managerPhonenumber)
      .then(() => {
        payment()
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const payment = () => {
    api.toss
      .getInfo()
      .then((res) => {
        const { clientKey, customerKey } = res.data

        let customSuccessUrl = `https://${window.location.host}/studio/plan/payment/complete`
        let customFailUrl = `https://${window.location.host}/studio/plan/payment/fail`
        var tossPayments = (window as any).TossPayments(clientKey)
        tossPayments.requestBillingAuth('카드', { customerKey, successUrl: customSuccessUrl, failUrl: customFailUrl })
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  if (loading) {
    return (
      <div className="page bg-color-grey-50">
        <div className="spinner with-margin"></div>
      </div>
    )
  }

  return (
    <div className="page bg-color-grey-50">
      <div className="request-subs-form box-container">
        <p>
          <a
            className="fs-14 bold flex flex-vertical-center"
            onClick={() => {
              navigate(-1)
            }}
          >
            <i className="ico ico-sm ico-back-arrow" style={{ marginRight: 6 }}></i>정기 결제 신청
          </a>
        </p>
        <div className="box-wrap">
          <div className="box">
            <p className="fs-16 bold">플랜 정보</p>
            <p className="mt-20 fs-24 bold">프로페셔널 플랜</p>
            <p className="mt-20 fs-12 color-grey-700">결제형태</p>
            <p className="fs-14" style={{ marginTop: 4 }}>
              월간 정기 결제
            </p>
            <p className="mt-20 fs-12 color-grey-700">결제일</p>
            <p className="fs-14" style={{ marginTop: 4 }}>
              매월 {data?.plan?.expiresAt ? new Date(data?.plan?.expiresAt).getDate() : new Date().getDate()}일 (영업일
              기준)
            </p>
            <p className="mt-20 fs-12 color-grey-700" style={{ marginBottom: 4 }}>
              회사이름
            </p>
            <TextInput
              placeholder="회사이름"
              defaultText={managerCompanyName}
              onChange={(text: string) => {
                setManagerCompanyName(text)
              }}
            />
            <p className="mt-20 fs-12 color-grey-700" style={{ marginBottom: 4 }}>
              담당자
            </p>
            <TextInput
              placeholder="이름"
              defaultText={managerName}
              onChange={(text: string) => {
                setManagerName(text)
              }}
            />
            <p className="mt-20 fs-12 color-grey-700" style={{ marginBottom: 4 }}>
              담당자 이메일
            </p>
            <EmailInput
              placeholder="이메일"
              defaultEmail={managerEmail}
              onChange={(text: string) => {
                setManagerEmail(text)
              }}
            />
            <p className="mt-20 fs-12 color-grey-700" style={{ marginBottom: 4 }}>
              담당자 휴대폰 번호
            </p>
            <PhoneNumberInput
              placeholder="-없이 숫자만 입력"
              defaultPhoneNumber={managerPhonenumber}
              onChange={(text: string) => {
                setManagerPhoneNumber(text)
              }}
            />
          </div>
          <div className="box">
            <p className="fs-16 bold">결제금액</p>
            <div className="mt-30 flex flex-row flex-vertical-center w-100" style={{ justifyContent: 'space-between' }}>
              <p className="fs-12 color-grey-700">상품금액</p>
              <p className="flex flex-vertical-center" style={{ whiteSpace: 'nowrap', gap: 5 }}>
                <span className="fs-12">₩</span>
                <span className="fs-26 bold">26,910</span>
                <span className="fs-12">/월</span>
              </p>
            </div>
            <div className="mt-30">
              결제금액&nbsp;<span className="color-grey-500">(VAT포함)</span>
            </div>
            <div className="mt-10 sale-box">
              <span className="badge badge-danger">beta기간 무료</span>
              <span className="raw-price">29,000</span>
              <span className="sale-price">0</span>원
            </div>
            <p className="color-danger" style={{ marginTop: 8 }}>
              beta 기간 동안 프로페셔널 플랜은 무료 입니다.
            </p>
            <div className="mt-30 desc-box">
              <p className="fs-14 bold">유의사항</p>
              <p className="fs-12" style={{ marginTop: 6 }}>
                1. beta 기간 종료 후 프로페셔널 플랜은 유료 사용으로 자동 갱신됩니다.
              </p>
              <p className="fs-12">
                2. beta 기간 종료 전 메시지와 메일로 종료 알람을 드리며, 플랜을 선택하여 이용 부탁드립니다.
              </p>
              <p className="fs-12">3. 등록된 결제 수단에 문제가 있을 경우, 스타터 플랜으로 자동 전환됩니다.</p>
              <p className="fs-12">
                4. 유료 결제를 중단하고자 할 경우 결제일 하루 전까지 스타터 플랜으로 전환 또는 구독을 해지하셔야 합니다.
              </p>
            </div>
            <button
              className="btn btn-primary w-100"
              style={{ marginTop: 34 }}
              onClick={requestPayment}
              disabled={!canPayment}
            >
              다음
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
