import React from 'react'
import withAuthRequired from '../hocs/withAuthRequired'

type Props = {}

function AuthRequiredPage({}: Props) {
  return (
    <div className="page">
      <h1 className="fs-24 text-center" style={{ marginTop: 100 }}>
        인증이 필요한 페이지입니다.
      </h1>
    </div>
  )
}

export default withAuthRequired(AuthRequiredPage)
