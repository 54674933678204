import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { ReferenceRes } from 'src/api/types/reference'
import BlockEditorViewer from 'src/components/BlockEditor/Viewer'
import Tab from 'src/components/Common/Tab'
import ReferenceListItem from 'src/components/Home/ReferenceListItem'
import ManageLeftAside from 'src/components/Studio/LeftAside'
import MoreInfoBanner from 'src/components/Studio/MoreInfoBanner'
import StudioProfileArea from 'src/components/Studio/StudioProfileArea'
import Reference from 'src/pages/Reference'
import authState from 'src/recoil/atom/auth'
import popupState from 'src/recoil/atom/popup'

export default function Index() {
  const location = useLocation()
  const navigate = useNavigate()

  const [{ waiting, account }, setAuthState] = useRecoilState(authState)
  const [{ popupType }] = useRecoilState(popupState)
  const setPopupState = useSetRecoilState(popupState)

  const [tabIndex, setTabIndex] = useState(0)
  const [introductionJson, setIntroduceJson] = useState(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [loadingErrMsg, setLoadingErrMsg] = useState(null)
  const [loadingReference, setLoadingReference] = useState(true)

  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  })

  useEffect(() => {
    const comps = location.pathname.split('/c/')
    const urlKey = comps.pop()

    if (!urlKey) {
      navigate('/notFound')
      return
    }

    setTabIndex(0)
    fetchCompany(urlKey)
  }, [location])

  const fetchCompany = (urlKey: string) => {
    setData(null)
    setLoading(true)
    api.company
      .getByUrlKey(urlKey)
      .then((res) => {
        setIntroduceJson(res.data.introductionJson)
        setData(res.data)
        fetchReferenceList(res.data.id)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const fetchReferenceList = (companyId: number) => {
    setLoadingErrMsg(null)
    setLoadingReference(true)
    api.companyPortfolio
      .getList(companyId, true)
      .then((res) => {
        let items = res.data
        items = [...items].sort((a, b) => {
          return b['editedAt'] < a['editedAt'] ? -1 : 0
        })
        setItems(items)
        setLoadingReference(false)
      })
      .catch((error) => {
        setLoadingReference(false)
        setItems([])
        setLoadingErrMsg('네트워크 에러 - 잠시 후 다시 시도해주세요')
      })
  }

  const renderIntroduce = () => {
    if (!introductionJson) {
      return <></>
    }

    if (introductionJson?.length < 10) {
      return <div className="fs-18 w-100 flex-center color-grey-400 empty-view">준비 중입니다</div>
    }

    return (
      <>
        <div className="mt-20"></div>
        <BlockEditorViewer data={introductionJson} />
      </>
    )
  }

  const renderReference = () => {
    if (loadingReference) {
      return <div className="spinner with-small-margin"></div>
    }

    if (loadingErrMsg) {
      return <div className="fs-18 w-100 flex-center color-grey-400 empty-view">{loadingErrMsg}</div>
    }

    if (items.length === 0) {
      return <div className="fs-18 w-100 flex-center color-grey-400 empty-view">준비 중입니다</div>
    }

    return (
      <div className="mt-20">
        <ul className="reference-items" style={{ marginTop: 10 }}>
          {items?.map((item: ReferenceRes, index) => {
            return (
              <ReferenceListItem
                item={item}
                key={index}
                hideCompany={true}
                onCustomClick={(item) => {
                  setSelectedItem(item)
                }}
              />
            )
          })}
        </ul>
      </div>
    )
  }

  const renderContent = () => {
    let targetIndex = isMobile ? tabIndex : tabIndex + 1

    if (targetIndex === 0) {
      return <StudioProfileArea data={data} editMode={false} />
    } else if (targetIndex === 1) {
      return renderIntroduce()
    } else if (targetIndex === 2) {
      return renderReference()
    }
  }

  if (!data || loading) {
    return <div className="spinner with-margin"></div>
  }

  return (
    <div className="page studio-page bg-color-grey-50">
      {data && (
        <Helmet>
          <title>{data.name} | 바인더스</title>
        </Helmet>
      )}
      <div className="container">
        <ManageLeftAside data={data} editMode={false} />
        <div className="main-area">
          <Tab
            titles={isMobile ? ['회사 프로필', '회사소개', '레퍼런스'] : ['회사소개', '레퍼런스']}
            onChange={(index) => {
              setTabIndex(index)
            }}
          />
          <div className="tab-content">{renderContent()}</div>
        </div>
      </div>
      {selectedItem && (
        <Reference
          referenceId={selectedItem.id}
          goPrev={() => {
            let targetIndex = items.indexOf(selectedItem) - 1
            if (targetIndex < 0) {
              targetIndex = 0
            }
            setSelectedItem(items[targetIndex])
          }}
          goNext={() => {
            let targetIndex = items.indexOf(selectedItem) + 1
            if (targetIndex >= items.length) {
              targetIndex = items.length - 1
            }
            setSelectedItem(items[targetIndex])
          }}
          onHide={() => {
            setSelectedItem(null)
          }}
        />
      )}
      {!account && (
        <MoreInfoBanner
          onClose={() => {
            //setShowMoreReferenceBanner(false)
          }}
        />
      )}
    </div>
  )
}
