import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import api from 'src/api'

export default function Index() {
  const [errorCode, setErrorCode] = useState('')
  const [message, setMessage] = useState('')
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get('code')
    const message = searchParams.get('message')
    const orderId = searchParams.get('orderId')

    api.toss.fail(code, message, orderId)

    setErrorCode(code)
    setMessage(message)
  }, [])

  return (
    <div className="page bg-color-grey-300 flex-center">
      <div className="text-center" style={{ padding: 24 }}>
        <i className="ico ico-error-line" style={{ width: 60, height: 60 }}></i>
        <h1 className="mt-30 fs-24 bold text-center">결제 실패({errorCode})</h1>
        <p className="fs-16 text-center" style={{ marginTop: 50 }}>
          {message}
        </p>
        <div>
          <button
            className="mx-auto btn btn-outline-primary btn-md"
            // to="/"
            style={{ marginTop: 100, width: 162, backgroundColor: 'transparent' }}
          >
            바인더스 홈
          </button>
        </div>
      </div>
    </div>
  )
}
