import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import authState from 'src/recoil/atom/auth'
import { hexEncode } from 'src/utils/String'

export default function MyCompanyProfileImg({ size = 60, onClick = null }: { size?: number; onClick?: () => void }) {
  const authValue = useRecoilValue(authState)
  const [{ account }] = useRecoilState(authState)

  const navigate = useNavigate()
  const companyName = account.company?.name
  const typeNumber = hexEncode(companyName) % 4

  const classNames = ['initial']
  classNames.push('bg-type' + typeNumber)

  const renderContent = () => {
    if (account.company?.logo) {
      return <img src={account.company?.logo.url} alt="" />
    }

    if (size <= 32) {
      classNames.push('fs-16')
    }

    const initial = companyName.substring(0, 1).toUpperCase()
    return <div className={classNames.join(' ')}>{initial}</div>
  }

  return (
    <div
      className={`profile-img-wrap link`}
      onClick={() => {
        if (onClick) {
          onClick?.()
          return
        }

        navigate('/studio/profile')
      }}
      style={{ width: size, height: size, minWidth: size, minHeight: size, borderRadius: size / 2, overflow: 'hidden' }}
    >
      {renderContent()}
    </div>
  )
}
