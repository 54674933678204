import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import StudioPlanDowngradeConfirm from 'src/components/Popup/StudioPlanDowngradeConfirm'
import Confirm from 'src/components/Popup/Confirm'

export default function DowngradePlan() {
  const navigate = useNavigate()

  const [{ account }] = useRecoilState(authState)
  const setNotificationState = useSetRecoilState(notificationState)

  const [data, setData] = useState(null)
  const [showConfirm1, setShowConfirm1] = useState(false)
  const [showConfirm2, setShowConfirm2] = useState(false)

  useEffect(() => {
    fetchCompany()
  }, [])

  const goBack = () => {
    navigate(-1)
  }

  const fetchCompany = () => {
    api.company
      .getMyCompany()
      .then((res) => {
        const data = res.data
        setData(res.data)

        const planId = data.plan.plan.id
        if (planId !== 2) {
          navigate('/studio/plan')
          return
        }
      })
      .catch((e) => {
        // do nothing
      })
  }

  const cancelDowngrade = () => {
    api.company
      .updatePlan(account.company.id, 2)
      .then(() => {
        fetchCompany()
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  if (data === null) {
    return <></>
  }

  return (
    <div className="page bg-color-grey-50 plan-page studio-plan-page">
      <div className="box-container container">
        <p>
          <a className="fs-14 bold flex flex-vertical-center" onClick={goBack}>
            <i className="ico ico-sm ico-back-arrow" style={{ marginRight: 6 }}></i>정기 결제 신청
          </a>
        </p>
        <div className="mt-40 box-area">
          <div className="box box-left">
            <div className="title-area">
              <p className="fs-20 bold">스튜디오 플랜</p>
              <p className="fs-14" style={{ marginTop: 11 }}>
                레퍼런스 생산과 커뮤니케이션 활동
              </p>
            </div>
            <div className="content-area">
              <div>
                <div className="badge">무료</div>
                <p className="mt-20 fs-24 bold">스타터</p>
                <p className="fs-12 color-text-info" style={{ marginTop: 6 }}>
                  바인더스에서 회사를 효과적으로 홍보하세요
                </p>
                <p className="mt-20 fs-12 flex flex-center" style={{ gap: 5 }}>
                  <span>₩</span>
                  <span className="fs-26 bold">0</span>
                  <span>/월</span>
                </p>
                <button
                  className="mt-30 btn btn-outline-grey w-100 color-primary"
                  onClick={() => {
                    const nextPlanId = data.plan.nextPlan.id
                    if (nextPlanId === 2) {
                      setShowConfirm2(true)
                    } else {
                      setShowConfirm1(true)
                    }
                  }}
                >
                  {data.plan.nextPlan.id === 2 ? '다운그레이드' : '플랜 변경 취소'}
                </button>
                <ul className="fs-14 plan-list">
                  <li>회사 프로필 페이지</li>
                  <li>레퍼런스 작성을 위한 기본 위젯 기능 제공</li>
                  <li>레퍼런스 작성 무제한</li>
                  <li>메인에 노출되는 레퍼런스 3개</li>
                  <li>레퍼런스 검색을 위한 태그 설정 5개</li>
                  <li>레퍼런스 검색을 위한 카테고리 설정 3개</li>
                  <li>100개 메시지 보관</li>
                  <li>회사 노출을 위한 태그 설정 5개</li>
                </ul>
              </div>
              <div>
                <div className="badge">beta기간 무료 (~10월 05일까지)</div>
                <p className="mt-20 fs-24 bold">프로페셔널</p>
                <p className="fs-12 color-text-info" style={{ marginTop: 6 }}>
                  더 많은 레퍼런스 노출로 회사를 홍보하세요
                </p>
                <p className="mt-20 fs-12 flex-center" style={{ gap: 5 }}>
                  <span>₩</span>
                  <span className="fs-26 bold color-grey-400 red-stroke">29,000</span>
                  <span className="fs-26 bold">0</span>
                  <span>/월</span>
                </p>

                <button className="mt-30 btn btn-outline-grey w-100 color-grey-500">현재플랜</button>
                <ul className="fs-14 plan-list">
                  <li>회사 프로필 페이지</li>
                  <li>레퍼런스 작성을 위한 기본 위젯 기능 제공</li>
                  <li>레퍼런스 작성 무제한</li>
                  <li>
                    <span className="plus">27개</span>
                    메인에 노출되는 레퍼런스 30개
                  </li>
                  <li>
                    <span className="plus">10개</span>
                    레퍼런스 검색을 위한 태그 설정 15개
                  </li>
                  <li>
                    <span className="plus">2개</span>레퍼런스 검색을 위한 카테고리 설정 5개
                  </li>
                  <li>
                    <span className="plus">900</span>
                    1000개 메시지 보관
                  </li>
                  <li>
                    <span className="plus">5개</span>
                    회사 노출을 위한 태그 설정 10개
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="box box-right text-center">
            <div className="title-area">
              <p className="fs-20 bold">마케팅 플랜</p>
              <p className="fs-14" style={{ marginTop: 11 }}>
                회사 홍보와 인지도 상승
              </p>
              <hr className="hr mt-30" />
              <span className="mt-30 coming-soon-badge">Coming Soon</span>
              <p className="mt-30 fs-16 bold">
                마케팅 플랜은
                <br />
                베타서비스 기간 이후 오픈 예정이에요
              </p>
              <p className="mt-10 fs-12 color-text-info">조금만 기다려주세요</p>
              <p className="mt-20">
                <a href={process.env.REACT_APP_SERVICE_UPDATE_URL} className="link bold fs-12" target={'_blank'}>
                  서비스 업데이트 알림 받기 ＞
                </a>
              </p>
              <img className="mx-auto" src="/static/img/marketing_img.png" style={{ marginTop: 26 }} />
            </div>
          </div>
        </div>
      </div>
      {showConfirm1 && (
        <Confirm
          title="스타터 플랜으로 변경을 취소할까요?"
          body={`<span class="color-grey-700">다시 프로페셔널 플랜으로 돌아갑니다.</span>`}
          cancelText="아니요"
          okText="변경 취소"
          onCancel={() => {
            setShowConfirm1(false)
          }}
          onOk={() => {
            setShowConfirm1(false)
            cancelDowngrade()
          }}
        />
      )}
      {showConfirm2 && (
        <StudioPlanDowngradeConfirm
          plan={data.plan}
          onHide={function (): void {
            setShowConfirm2(false)
          }}
          onOk={() => {
            setShowConfirm2(false)
            fetchCompany()
          }}
        />
      )}
    </div>
  )
}
