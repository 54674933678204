import { useSetRecoilState } from 'recoil'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'

type Props = {
    onClose: () => void
}
export default function MoreInfoBanner({ onClose }: Props) {
    const setPopupState = useSetRecoilState(popupState)

    const signUp = () => {
        setPopupState({ popupType: MainPopupType.SignUp })
        onClose()
    }

    return (
        <div className="more-info-banner">
            <p className="fs-24 bold text-center" style={{ marginTop: 90 }}>
                더 많은 정보가 필요하세요?
            </p>
            <p className="mt-10 fs-16 text-center">회원가입 후 회사정보를 확인하세요!</p>
            <button className="mt-20 btn btn-primary mx-auto w-100" style={{ maxWidth: 342 }} onClick={signUp}>
                회원가입
            </button>
        </div>
    )
}
