import { useEffect } from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import api from 'src/api'
import authState from '../recoil/atom/auth'

import MainLayout from '../layouts/MainLayout'

import AuthRequired from 'src/pages/AuthRequired'
import NotFound from 'src/pages/NotFound'
import Waiting from 'src/pages/Waiting'

import Home from 'src/pages/Home'

import ResetPassword from 'src/pages/ResetPassword'
import SignUpComplete from 'src/pages/SignUp/Complete'
import SignUpRequestAuth from 'src/pages/SignUp/RequestAuth'
import Withdraw from 'src/pages/Withdraw'

import MyAccount from 'src/pages/MyAccount'
import MyLibrary from 'src/pages/MyLibrary'
import MyMessages from 'src/pages/MyMessages'
import MyProfile from 'src/pages/MyProfile'
import MyProfileEdit from 'src/pages/MyProfile/Edit'

import StudioBannerEdit from 'src/pages/Studio/Banner/Edit'
import StudioBanner from 'src/pages/Studio/Banner/index'
import CreateStudio from 'src/pages/Studio/Create'
import StudioMessages from 'src/pages/Studio/Messages'
import StudioProfileEdit from 'src/pages/Studio/Profile/Edit'
import StudioProfileVisit from 'src/pages/Studio/Profile/Visit'
import StudioProfile from 'src/pages/Studio/Profile/index'
import StudioReferenceEdit from 'src/pages/Studio/Reference/Edit'
import StudioReference from 'src/pages/Studio/Reference/index'
import StudioIntroduceEdit from 'src/pages/Studio/StudioIntroduceEdit'
import StudioSetting from 'src/pages/Studio/StudioSetting'
import UserProfile from 'src/pages/UserProfile'

import ActivateAccount from 'src/pages/Account/Activate'
import Deactivated from 'src/pages/Account/Deactivated'
import Main from 'src/pages/Main'
import ActivateStudio from 'src/pages/Studio/Activate'
import StudioDeleted from 'src/pages/Studio/Deleted'
import StudioPlanChange from 'src/pages/Studio/Plan/Change'
import StudioPlanDowngrade from 'src/pages/Studio/Plan/Downgrade'
import StudioPlanPaymentMethod from 'src/pages/Studio/Plan/Payment'
import StudioPlanPaymentComplete from 'src/pages/Studio/Plan/Payment/Complete'
import StudioPlanPaymentFailed from 'src/pages/Studio/Plan/Payment/Failed'
import StudioPlanUpgrade from 'src/pages/Studio/Plan/Upgrade'
import StudioPlan from 'src/pages/Studio/Plan/index'
import Token from 'src/pages/Token'
import StudioDeactivated from './Popup/StudioDeactivated'
import { useCookies } from 'react-cookie'

export default function AppRoutes() {
  const [searchParams] = useSearchParams()
  const [cookies, setCookie, removeCookie] = useCookies(['bindus_user_id'])
  const [{ account, waiting }, setAuthState] = useRecoilState(authState)

  useEffect(() => {
    if (searchParams.get('token')) {
      setAuthState({
        account: null,
        waiting: false,
      })
      return
    }
    api.account
      .fetch()
      .then((response) => {
        setCookie('bindus_user_id', response.data.id)
        setAuthState({
          account: response.data,
          waiting: false,
        })
      })
      .catch((error) => {
        removeCookie('bindus_user_id')
        setAuthState({
          account: null,
          waiting: false,
        })
      })
  }, [])

  if (waiting) {
    return <Waiting message="인증정보를 불러오는 중" />
  }

  const getAccountRoutes = () => {
    if (account) {
      return null
    }

    return (
      <>
        {/* <Route path="account/signUpRequestAuth" element={<SignUpRequestAuth />} />
        <Route path="account/signUpComplete" element={<SignUpComplete />} />
        <Route path="account/resetPassword" element={<ResetPassword />} />
        <Route path="account/withdraw" element={<Withdraw />} /> */}
        <Route path="signup/requestAuth" element={<SignUpRequestAuth />} />
        <Route path="withdraw" element={<Withdraw />} />
        <Route path="resetPassword" element={<ResetPassword />} />
      </>
    )
  }

  const getMyRoutes = () => {
    if (!account) {
      return null
    }

    if (account.activeStatus === 'Disable') {
      return (
        <>
          <Route path="my/*" element={<ActivateAccount />} />
        </>
      )
    }

    return (
      <>
        <Route path="my/*" element={<ActivateAccount />} />
        <Route path="my/profile" element={<MyProfile />} />
        <Route path="my/profile/edit" element={<MyProfileEdit />} />
        <Route path="my/library" element={<MyLibrary />} />
        <Route path="my/account" element={<MyAccount />} />
      </>
    )
  }

  const getStudioRoutes = () => {
    if (account?.company === null) {
      return (
        <>
          <Route path="studio/create" element={<CreateStudio />} />
          <Route path="studio/deleted" element={<StudioDeleted />} />
        </>
      )
    }

    if (account?.company?.activeStatus === 'Disable') {
      return (
        <>
          <Route path="studio/*" element={<ActivateStudio />} />
          <Route path="studio/deactivated" element={<StudioDeactivated />} />
        </>
      )
    }

    if (account?.company?.plan?.plan?.id === 0) {
      return (
        <>
          <Route path="studio/create" element={<CreateStudio />} />
          <Route path="studio/plan/change" element={<StudioPlanChange />} />
          <Route path="studio/*" element={<Navigate to="/studio/plan/change" replace />} />
          <Route path="studio/plan/payment" element={<StudioPlanPaymentMethod />} />
          <Route path="studio/plan/payment/complete" element={<StudioPlanPaymentComplete />} />
          <Route path="studio/plan/payment/failed" element={<StudioPlanPaymentFailed />} />
        </>
      )
    }

    return (
      <>
        <Route path="studio/create" element={<CreateStudio />} />
        <Route path="studio/profile" element={<StudioProfile />} />
        <Route path="studio/profile/edit" element={<StudioProfileEdit />} />
        <Route path="studio/introduce/create" element={<StudioIntroduceEdit />} />
        <Route path="studio/introduce/edit" element={<StudioIntroduceEdit />} />
        <Route path="studio/reference" element={<StudioReference />} />
        <Route path="studio/reference/create" element={<StudioReferenceEdit />} />
        <Route path="studio/reference/edit" element={<StudioReferenceEdit />} />
        <Route path="studio/messages" element={<StudioMessages />} />
        <Route path="studio/banner" element={<StudioBanner />} />
        <Route path="studio/banner/create" element={<StudioBannerEdit />} />
        <Route path="studio/banner/edit" element={<StudioBannerEdit />} />
        <Route path="studio/setting" element={<StudioSetting />} />
        <Route path="studio/plan" element={<StudioPlan />} />
        <Route path="studio/plan/change" element={<StudioPlanChange />} />
        <Route path="studio/plan/downgrade" element={<StudioPlanDowngrade />} />
        <Route path="studio/plan/upgrade" element={<StudioPlanUpgrade />} />
        <Route path="studio/plan/payment" element={<StudioPlanPaymentMethod />} />
        <Route path="studio/plan/payment/complete" element={<StudioPlanPaymentComplete />} />
        <Route path="studio/plan/payment/failed" element={<StudioPlanPaymentFailed />} />
      </>
    )
  }

  if (account && (!account.email || !account.name || !account.birthday || account.categories?.length === 0)) {
    return (
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Navigate to="signup/complete" replace />} />
          <Route path="signup/complete" element={<SignUpComplete />} />
        </Route>
        <Route path="*" element={<Navigate to="signup/complete" replace />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />

        <Route path="auth-required" element={<AuthRequired />} />
        <Route path="signup/complete" element={<SignUpComplete />} />
        <Route path="my/messages" element={<MyMessages />} />
        <Route path="my/deactivated" element={<Deactivated />} />
        {getAccountRoutes()}
        {getMyRoutes()}
        {getStudioRoutes()}

        <Route path="token" element={<Token />} />
        <Route path="c/*" element={<StudioProfileVisit />} />
        <Route path="u/*" element={<UserProfile />} />
        <Route path="r/*" element={<Home />} />

        <Route path="main/creative" element={<Main />} />
        <Route path="main/tech" element={<Main />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
