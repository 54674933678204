import { useState } from 'react'
import UserActivate from 'src/components/Popup/UserActivate'

export default function ActivateAccount() {
  const [showActivatePopup, setShowActivatePopup] = useState(true)

  return (
    <>
      {showActivatePopup && (
        <UserActivate
          onHide={() => {
            setShowActivatePopup(false)
          }}
        />
      )}
    </>
  )
}
