import { atom } from 'recoil'

export enum MainPopupType {
  None = 0,
  SignIn,
  SignUp,
  SignUpByCompany,
  RequestResetPassword,
  CustomerCenter,
  NeedToLogin,
}

const mainPopupState = atom<{
  popupType: MainPopupType
}>({
  key: 'mainPopupState',
  default: {
    popupType: MainPopupType.None,
  },
})

export default mainPopupState
