import getVideoId from 'get-video-id'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Confirm from 'src/components/Popup/Confirm'
import { getVideoUrl, parseNaverBlogId, parseNaverId, parseVimeoId } from 'src/utils/Video'
import buttonStyles from '../../common/button.module.scss'
import VideoIcon from '../../icons/Video'
import { EditorBlockInputProps } from '../../types'
import VideoViewer from './VideoViewer'
import styles from './index.module.scss'

type Props = {} & EditorBlockInputProps

const VideoBlockInput = forwardRef(({ block, onBlockChange }: Props, ref) => {
  const [url, setUrl] = useState('')
  const containerRef = useRef<HTMLDivElement>(null)
  const [divWidth, setDivWidth] = useState(containerRef.current?.clientWidth ?? 360)
  const [showInvalidAlert, setShowInvalidAlert] = useState(false)

  useImperativeHandle(ref, () => ({
    startEdit() {
      setUrl(getVideoUrl(block.value?.toString()))
      onBlockChange?.({
        ...block,
        type: 'video',
        value: null,
      })
    },
  }))

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value)
  }, [])

  const handleEmbedClick = useCallback(() => {
    if (!url) {
      return
    }

    if (url.indexOf('vimeo') !== -1) {
      const vimeoId = parseVimeoId(url)
      onBlockChange?.({
        ...block,
        type: 'video',
        value: 'vimeo|' + vimeoId,
      })
    } else if (url.indexOf('tv.naver') !== -1) {
      const naverId = parseNaverId(url)
      onBlockChange?.({
        ...block,
        type: 'video',
        value: 'naver|' + naverId,
      })
    } else if (url.indexOf('serviceapi.nmv.naver.com') !== -1) {
      const nvaerBlogId = parseNaverBlogId(url)
      onBlockChange?.({
        ...block,
        type: 'video',
        value: 'naver_blog|' + nvaerBlogId,
      })
    } else {
      const { id } = getVideoId(url)
      if (!id) {
        setShowInvalidAlert(true)
      } else {
        onBlockChange?.({
          ...block,
          type: 'video',
          value: 'youtube|' + id,
        })
      }
    }
  }, [block, onBlockChange, url])

  useEffect(() => {
    setDivWidth(containerRef.current?.clientWidth ?? 360)
    const handleResize = () => {
      setDivWidth(containerRef.current?.clientWidth ?? 360)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  let blockValue = block.value as string
  let videoId = null
  let videoType = 'youtube'
  if (blockValue) {
    const comps = blockValue?.split('|')
    if (comps.length > 1) {
      videoType = comps[0]
      videoId = comps[1]
    } else {
      videoId = blockValue
    }
  }

  return (
    <div className={styles.video} ref={containerRef}>
      {block.value === null ? (
        <>
          {' '}
          <input
            type="text"
            placeholder="동영상 URL 혹은 임베드 코드를 입력해 주세요"
            value={url}
            onChange={handleInputChange}
          ></input>
          <button
            className={[buttonStyles.button, styles.uploadButton].join(' ')}
            disabled={url?.length === 0}
            onClick={handleEmbedClick}
          >
            <VideoIcon />
            동영상 임베드
          </button>
        </>
      ) : (
        <VideoViewer item={block.value?.toString()} />
      )}
      {showInvalidAlert && (
        <Confirm
          title="유효하지 않은 URL 입니다"
          body="올바른 링크인지 확인후 다시 시도해 주세요."
          onOk={() => {
            setShowInvalidAlert(false)
          }}
        />
      )}
    </div>
  )
})

export default VideoBlockInput
