import { useEffect } from 'react'
import api from 'src/api'
import { UserType } from 'src/api/types/account'
import { MessageSender } from 'src/api/types/message'

type Props = {
  onClose: () => void
  data: any
  isSent: boolean
  type: UserType
  onReply: (sender: MessageSender) => void
}

export default function MessageDetailPopup({ onClose, data, isSent, type = 'User', onReply }: Props) {
  const createdAt = new Date(data.createdAt).toLocaleString()

  const user = isSent ? data.receiver : data.sender
  const userType = user.type === 'Company' ? '회사' : '사람'

  useEffect(() => {
    if (isSent) {
      return
    }

    api.message.readMessage(data.id).catch((e) => {
      // Do nothing
    })
  }, [])

  const renderLink = () => {
    const isLocalhost = process.env.REACT_APP_BASE_URL.indexOf('localhost') !== -1
    const prefix = isLocalhost ? 'http://' : 'https://'
    const urlType = userType === '사람' ? 'u' : 'c'
    const urlKey = isSent ? data.receiver.urlKey : data.sender.urlKey

    return `${prefix}${process.env.REACT_APP_BASE_URL}/${urlType}/${urlKey}`
  }

  return (
    <div className="popup-wrap">
      <div className="popup" id="message-detail-popup">
        <ul className="items">
          <li>
            <div className="fs-14 color-text-info">
              {isSent ? '받은' : '보낸'}
              {userType}
            </div>
            <div>
              <p>
                <a className="fs-18 bold" href={renderLink()} target={'_blank'}>
                  {user.name}
                </a>
                {!isSent && (
                  <button
                    className="reply-btn"
                    onClick={() => {
                      onClose()
                      onReply(data.sender)
                    }}
                  ></button>
                )}
              </p>
              <p className="fs-12">{renderLink()}</p>
              {user.companyName && <p className="fs-12">{user.companyName}</p>}
            </div>
          </li>
          <li>
            <div className="fs-14 color-text-info">{isSent ? '보낸시간' : '받은시간'}</div>
            <div className="fs-12">{createdAt}</div>
          </li>
        </ul>
        <div className="content bg-color-grey-50 fs-14 w-100" style={{ padding: '10px 12px' }}>
          {data.message}
        </div>
        <button className="mt-20 btn btn-primary float-right ok-btn" onClick={onClose}>
          확인
        </button>
      </div>
    </div>
  )
}
