import request from 'src/utils/Request'

const configApi = {
  // 회사 정보
  getDefaultMainSort: () => {
    return request.get(`/v1/config/defaultMainSort`)
  },
}

export default configApi
