import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import api from 'src/api'
import { SearchItemRes } from 'src/api/types/search'
import CompanyProfileImg from '../Common/CompanyProfileImg'

export default function SearchInput() {
  const location = useLocation()
  const navigate = useNavigate()

  const [isFocus, setIsFocus] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [searchItems, setSearchItems] = useState<SearchItemRes[]>(null)
  const [showSearchResult, setShowSearchResult] = useState(false)
  const [categoryName, setCategoryName] = useState('creative')
  const [isHide, setIsHide] = useState(true)

  const searchTimer = useRef(null)

  const search = (keyword: string, categoryName: string) => {
    setShowSearchResult(false)

    navigate({
      pathname: categoryName === 'creative' ? '/main/creative' : '/main/tech',
      search: `?keyword=${encodeURIComponent(keyword)}`,
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    let keyword = searchParams.get('keyword')
    if (keyword) {
      setKeyword(keyword)
    } else {
      setKeyword('')
    }

    setIsHide(
      location.pathname !== '/' &&
        location.pathname.indexOf('/main/tech') === -1 &&
        location.pathname.indexOf('/main/creative') === -1
    )
    setCategoryName(location.pathname.indexOf('/main/tech') !== -1 ? 'tech' : 'creative')
  }, [location])

  useEffect(() => {
    clearTimeout(searchTimer.current)
    searchTimer.current = setTimeout(() => {
      fetchSearchItems()
    }, 400)
  }, [keyword])

  useEffect(() => {
    if (isFocus) {
      setShowSearchResult(true)
    }
  }, [isFocus])

  const fetchSearchItems = () => {
    api.search
      .getList(keyword)
      .then((res) => {
        const items = res.data
        setSearchItems(items)
      })
      .catch(() => {
        setSearchItems([])
      })
  }

  const renderSearchResultItem = (item) => {
    if (item.type === 'Company') {
      return (
        <>
          <CompanyProfileImg company={{ name: item.name, logo: { url: item.image } }} size={32} />
          <span style={{ marginLeft: 12 }}>{item.name}</span>
        </>
      )
    } else if (item.type === 'Tag') {
      return (
        <>
          <i className="ico ico-search-gr ico-md"></i>
          <span style={{ marginLeft: 16 }}>{item.name}</span>
        </>
      )
    } else {
      return (
        <>
          {item.type} - {item.name}
        </>
      )
    }
  }

  const renderSearchResultBox = () => {
    if (!searchItems || searchItems?.length === 0) {
      return <></>
    }

    return (
      <div className="search-result-box">
        <ul className="w-100">
          {searchItems?.map((item) => {
            return (
              <li
                onClick={() => {
                  setShowSearchResult(false)
                  if (item.type === 'Tag') {
                    setKeyword(item.name)
                    search(item.name, categoryName)
                  } else if (item.type === 'Company') {
                    navigate('/c/' + item.urlKey)
                  }
                }}
              >
                {renderSearchResultItem(item)}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const ref = useOutsideClick(() => {
    setShowSearchResult(false)
  })

  if (isHide) {
    return <></>
  }

  return (
    <div ref={ref}>
      <div className="m-search-bar">
        <button
          className="search-btn"
          onClick={() => {
            search(keyword, categoryName)
          }}
        />
        <input
          type="text"
          placeholder={`${categoryName === 'creative' ? '크리에이티브' : '테크'} 검색`}
          className="fs-16 bold"
          value={keyword}
          onFocus={() => {
            setIsFocus(true)
            document.body.classList.add('focus-header')
          }}
          onBlur={() => {
            document.body.classList.remove('focus-header')
            setTimeout(() => {
              setIsFocus(false)
            }, 200)
          }}
          onKeyUp={(e) => {
            if (e.key !== 'Enter') {
              return
            }
            search(keyword, categoryName)
          }}
          onChange={(e) => {
            setKeyword(e.target.value)
          }}
        />
        {isFocus && (
          <span
            className="fs-14 bold color-grey-500 mr-10"
            style={{ minWidth: 28 }}
            onClick={() => {
              setKeyword('')
              setShowSearchResult(false)
            }}
          >
            취소
          </span>
        )}
        <div className="category-area">
          <button
            className={`${categoryName === 'creative' ? 'active' : ''}`}
            onClick={() => {
              setCategoryName('creative')

              if (keyword) {
                search(keyword, 'creative')
              }
            }}
          >
            크리에이티브
          </button>
          <button
            className={`${categoryName === 'tech' ? 'active' : ''}`}
            onClick={() => {
              setCategoryName('tech')

              if (keyword) {
                search(keyword, 'tech')
              }
            }}
          >
            테크
          </button>
        </div>
      </div>
      {showSearchResult && renderSearchResultBox()}
    </div>
  )
}
