import { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import configApi from 'src/api/config'
import GuideOverlay from 'src/components/Guide/Overlay'
import MoreReferenceBanner from 'src/components/Main/MoreReferenceBanner'
import ReferenceListItem from 'src/components/Home/ReferenceListItem'
import FilterContainer from 'src/components/Main/FilterContainer'
import authState from 'src/recoil/atom/auth'
import { pushMessageState } from 'src/recoil/atom/pushMessage'
import BannerListItem from '../components/Main/BannerListItem'
import Reference from './Reference'

export default function Main() {
    const location = useLocation()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const [cookies, setCookie, removeCookie] = useCookies(['needGuide1', 'defaultMainSort'])

    const [{ waiting, account }] = useRecoilState(authState)

    const [page, setPage] = useState(0)

    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(true)
    const [selectedPortfolioId, setSelectedPortfolioId] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(-1)

    const [categoryName, setCategoryName] = useState(null)

    const [showMoreReferenceBanner, setShowMoreReferenceBanner] = useState(false)
    const [showGuide, setShowGuide] = useState(false)

    const setPushMessageState = useSetRecoilState(pushMessageState)

    const scrollRef = useRef(null)

    useEffect(() => {
        if (account) {
            setPushMessageState({
                id: 2,
            })
        } else {
            setPushMessageState({
                id: 1,
            })
        }
    }, [account])

    useEffect(() => {
        setCategoryName(location.pathname.indexOf('/main/tech') !== -1 ? 'tech' : 'creative')

        if (cookies.needGuide1 && account) {
            setShowGuide(true)
            removeCookie('needGuide1')
        }

        const referenceId = searchParams.get('referenceId')
        if (referenceId) {
            setSelectedPortfolioId(referenceId)
        }
        else {
            setSelectedPortfolioId(null)
            refresh();
        }

    }, [location])
    
    const refresh = async () => {
        setItems([])
        setHasMore(true)
        setShowMoreReferenceBanner(false)
        if (!cookies.defaultMainSort) {
            fetchSetting()
        }
        getList(0, [])
    }

    const fetchSetting = async () => {
        try {
            const res = await configApi.getDefaultMainSort()
            setCookie('defaultMainSort', res.data.value)
        } catch {
            setCookie('defaultMainSort', 'Recent')
        }
    }

    const getList = async (page = 0, items = []) => {
        setHasMore(true)
        const mainCategoryId = location.pathname.indexOf('/main/tech') !== -1 ? 11 : 10
        let keyword = searchParams.get('keyword')
        if (keyword) {
            keyword = decodeURIComponent(keyword)
        }

        let categoryId = searchParams.get('categoryId') ? parseInt(searchParams.get('categoryId')) : null
        let subCategoryId = searchParams.get('subCategoryId') ? parseInt(searchParams.get('subCategoryId')) : null
        let searchType = searchParams.get('searchType')
        let tagId = searchParams.get('tagId') ? parseInt(searchParams.get('tagId')) : null

        if (subCategoryId) {
            categoryId = subCategoryId
        }

        if (!searchType) {
            searchType = (categoryId || keyword) ? 'Recent' : cookies.defaultMainSort
        }

        if (!account) {
            const maxItemCount = searchType === 'Recommend' ? 50 : 150
            if (items.length >= maxItemCount) {
                setShowMoreReferenceBanner(true)
                setHasMore(false)
                return
            }
        }

        setLoading(true)
        if (page === 0) {
            setItems([])
        }
        api.portfolio
            .getList(categoryId ? categoryId : mainCategoryId, tagId, keyword ? keyword.toLocaleLowerCase() : null, searchType, page)
            .then((res) => {
                const { content = [], totalPages } = res.data
                setHasMore(page + 1 < totalPages)
                setItems(items.concat(content))
                setPage(page + 1)
            })
            .catch((e) => {
                // Todo: check
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const renderEmpty = () => {
        if (loading || items?.length > 0) {
            return <></>
        }

        const searchParams = new URLSearchParams(location.search)
        let keyword = searchParams.get('keyword')
        if (keyword && items.length === 0) {
            return (
                <div className="text-center" style={{ paddingTop: 150 }}>
                    <p className="fs-18 bold color-grey-600">검색어와 일치하는 레퍼런스가 없어요!</p>
                    <p className="fs-16 color-grey-600" style={{ marginTop: 50 }}>
                        다른 검색어를 사용해 보세요.
                        <br />
                        검색어의 단어 수를 줄이거나, 보다 일반적인 검색어로 다시 검색해 보세요.
                    </p>
                </div>
            )
        } else {
            return (
                <div className="text-center" style={{ paddingTop: 150 }}>
                    <p className="fs-18 bold color-grey-600">일치하는 레퍼런스가 없어요!</p>
                </div>
            )
        }
    }

    const renderList = () => {
        return (
            <>
                {renderEmpty()}
                <InfiniteScroll
                    dataLength={items.length}
                    next={() => {
                        getList(page, items)
                    }}
                    hasMore={hasMore}
                    // loader={<div className="spinner with-margin"></div>}
                    loader={<div></div>}
                    endMessage={<p></p>}
                    refreshFunction={refresh}
                    scrollableTarget={'home-list-container'}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    onScroll={(e: MouseEvent) => {
                        if (account || hasMore) {
                            return
                        }

                        clearTimeout(scrollRef.current)
                        scrollRef.current = setTimeout(() => {
                            const target = e.target as HTMLElement
                            const { scrollHeight, scrollTop, offsetHeight } = target
                            const isBottom = scrollHeight - scrollTop - 30 < offsetHeight
                            if (isBottom) {
                                setShowMoreReferenceBanner(true)
                            }
                        }, 30)
                    }}
                    style={{ overflowX: 'hidden' }}
                >
                    {
                        <ul className="reference-items">
                            <BannerListItem key={0} onCustomClick={(item) => {
                                setSelectedIndex(null)
                                setSelectedPortfolioId(item.id)
                            }} />
                            {items?.map((item, index) => {
                                return <ReferenceListItem item={item} key={item.id} onCustomClick={() => {
                                    setSelectedIndex(index)
                                    setSelectedPortfolioId(item.id)
                                }} />
                            })}
                        </ul>
                    }
                </InfiniteScroll>
            </>
        )
    }

    if (waiting) {
        return <></>
    }

    return (
        <div className={`main-page page ${categoryName}-main-page`}>
            <FilterContainer />
            <div className="container" id="home-list-container">
                {renderList()}
            </div>
            {showMoreReferenceBanner && !account && (
                <MoreReferenceBanner
                    onClose={() => {
                        setShowMoreReferenceBanner(false)
                    }}
                />
            )}
            {selectedPortfolioId && (
                <Reference
                    referenceId={selectedPortfolioId}
                    onHide={() => {
                        const referenceId = searchParams.get('referenceId')
                        if (referenceId) {
                            navigate('/main/' + categoryName)
                        }
                        else {
                            setSelectedIndex(null)
                            setSelectedPortfolioId(null)
                        }
                    }}
                    goNext={() => {
                        const targetIndex = selectedIndex + 1
                        if (targetIndex > items.length) {
                            getList(page, items)
                            return
                        }
                        setSelectedIndex(targetIndex)
                        setSelectedPortfolioId(items[targetIndex].id)
                    }}
                    goPrev={() => {
                        const targetIndex = selectedIndex - 1
                        if (targetIndex < 0) {
                            return
                        }
                        setSelectedIndex(targetIndex)
                        setSelectedPortfolioId(items[targetIndex].id)
                    }}
                />
            )}
            {showGuide && (
                <GuideOverlay
                    onClose={() => {
                        setShowGuide(false)
                    }}
                />
            )}
        </div>
    )
}
