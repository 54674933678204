type Props = {
  onCancel: () => void
  onOk: () => void
}
export default function SaveCancelPopup({ onCancel, onOk }: Props) {
  return (
    <div className="popup-wrap">
      <div className="popup text-center">
        <button
          className="close-btn popup-close"
          onClick={() => {
            onCancel()
          }}
        ></button>
        <p className="popup-title bold">변경사항을 취소하시겠습니까?</p>
        <div className="btn-wrap">
          <button
            className="btn"
            onClick={() => {
              onCancel()
            }}
          >
            아니요
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              onOk()
            }}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  )
}
