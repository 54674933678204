import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { Message } from 'src/api/types/message'
import { getUserLink, UserProfileImg } from 'src/components/Common/Profile'
import Tab from 'src/components/Common/Tab'
import MessageDetailPopup from 'src/components/Message/DetailPopup'
import MessageListItem from 'src/components/Message/MessageListItem'
import MessageSendPopup from 'src/components/Message/SendPopup'
import Select, { SelectOption } from 'src/components/Select/Select'
import authState from 'src/recoil/atom/auth'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'

export default function Index() {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const [{ account }] = useRecoilState(authState)
  const [waiting, setWaiting] = useState(false)

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [selectedItem, setSelectedItem] = useState(null)
  const [showSendPopup, setShowSendPopup] = useState(false)
  const [items, setItems] = useState<Message[]>([])
  const [replyReceiver, setReplyReceiver] = useState(null)

  const setPopupState = useSetRecoilState(popupState)

  const selectOptions: SelectOption[] = [
    { label: '최신 순', value: 'createdAt' },
    { label: '보낸사람 순', value: 'sender' },
  ]
  const [selectedOptionValue, setSelectedOptionValue] = useState('createdAt')

  useEffect(() => {
    if (account) {
      getList()
    } else {
      setPopupState({ popupType: MainPopupType.SignIn })
    }
  }, [account])

  useEffect(() => {
    checkSearchParam()
  }, [items])

  useEffect(() => {
    checkSearchParam()
  }, [location.search])

  useEffect(() => {
    getCurrentList()
  }, [selectedTabIndex])

  const getCurrentList = () => {
    if (selectedTabIndex === 1) {
      getSentList()
    } else {
      getList()
    }
  }

  const checkSearchParam = () => {
    const selectedId = searchParams.get('id') ? parseInt(searchParams.get('id')) : null
    if (!selectedId) {
      setSelectedItem(null)
      return
    }

    const data = items.filter((item) => item.id === selectedId)
    if (data.length > 0) {
      setSelectedItem(data[0])
    }
  }

  const getList = () => {
    setWaiting(true)
    setItems([])
    api.message
      .getList(false, 0)
      .then((res) => {
        const items = res.data.content

        setItems(items)
        setWaiting(false)
      })
      .catch((e) => {
        console.log(e)
        setWaiting(false)
      })
  }

  const getSentList = () => {
    setWaiting(true)
    setItems([])
    api.message
      .getSentList(0)
      .then((res) => {
        const items = res.data.content

        setItems(items)
        setWaiting(false)
      })
      .catch((e) => {
        console.log(e)
        setWaiting(false)
      })
  }

  const renderEmpty = () => {
    if (waiting) {
      return <div className="spinner with-margin"></div>
    }

    return (
      <div className="fs-18 w-100 flex-center color-grey-400" style={{ height: 500 }}>
        {selectedTabIndex === 0 ? '받은 메시지가 없어요' : '보낸 메시지가 없어요'}
      </div>
    )
  }

  if (!account) {
    return (
      <div className="page">
        <div className="spinner with-margin"></div>
      </div>
    )
  }

  return (
    <div className="page message-page bg-color-grey-50">
      <div className="container top-area">
        <div className="mobile-only mt-30">
          <div className="user-profile-area sm mobile-row">
            <UserProfileImg account={account} />
            <div>
              <p className="fs-18 bold">
                <Link to={getUserLink(account)}>{account.name}</Link>
              </p>
              <p className="fs-12 color-text-secondary">
                <Link style={{ marginTop: 4 }} to={getUserLink(account)}>
                  {process.env.REACT_APP_BASE_URL}/u/{account.urlKey}
                </Link>
              </p>
            </div>
          </div>
        </div>
        <button
          className="btn btn-outline-primary btn-md new-message-btn mx-auto"
          onClick={() => {
            setShowSendPopup(true)
          }}
        >
          <i className="ico ico-modify-bl"></i>새 메시지
        </button>
      </div>
      <div className="container tab-area">
        <Tab
          titles={['받은 메시지', '보낸 메시지']}
          onChange={(index: number) => {
            setSelectedTabIndex(index)
          }}
        />
        <button
          className="btn btn-outline-primary btn-md new-message-btn mx-auto pc-only"
          onClick={() => {
            setShowSendPopup(true)
          }}
        >
          <i className="ico ico-modify-bl"></i>새 메시지
        </button>
        <div className="filter-wrap">
          <div className="flex-grow-1"></div>
          <ul className='order-filters'>
            {selectOptions.map(option => {
              return <li className={option.value === selectedOptionValue ? 'active' : ''} onClick={() => {
                const value = option.value
                setSelectedOptionValue(value)
                setItems(
                  [...items].sort((a, b) => {
                    if (value == 'sender') {
                      return b['sender']['id'] < a['sender']['id'] ? -1 : 0
                    } else {
                      return b[value] < a[value] ? -1 : 0
                    }
                  })
                )
              }}>{option.label}</li>
            })}
          </ul>
        </div>
      </div>
      <div className="container">

        {items.length === 0 && renderEmpty()}
        <ul className="message-list">
          {items.map((item) => {
            return (
              <MessageListItem
                key={item.id}
                isSent={selectedTabIndex === 1}
                data={item}
                onClick={() => {
                  navigate('/my/messages?id=' + item.id)
                }}
              />
            )
          })}
        </ul>
        {selectedItem && (
          <MessageDetailPopup
            type="User"
            data={selectedItem}
            isSent={selectedTabIndex === 1}
            onReply={(sender) => {
              setReplyReceiver(sender)
            }}
            onClose={() => {
              // getCurrentList()
              navigate('/my/messages')

              // searchParams.set('id', '123')
              // setSelectedItem(null)
            }}
          />
        )}
        {(showSendPopup || replyReceiver) && (
          <MessageSendPopup
            receiverType={replyReceiver?.type}
            senderType={'User'}
            target={replyReceiver}
            onClose={(isUpdated = false) => {
              if (isUpdated) {
                getCurrentList()
              }
              setShowSendPopup(false)
              setReplyReceiver(null)
            }}
          />
        )}
      </div>
    </div>
  )
}
