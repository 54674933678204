import request from 'src/utils/Request'
import { ReferenceRes } from './types/reference'

// 회사 포트폴리오
const companyPortfolioApi = {
  // 포트폴리오 추가
  create: (
    id: number,
    title: string,
    contentJson: string,
    categoryIds: number[],
    representativeCategoryId: number,
    tagIds: number[],
    thumbnailFileUUID: string,
    period: string,
    scope: string,
    customer: string,
    partner: string
  ) => {
    return request.post(`/v1/company/${id}/portfolio`, {
      title,
      contentJson,
      categoryIds,
      representativeCategoryId,
      tagIds,
      thumbnailFileUUID,
      period,
      scope,
      customer,
      partner,
    })
  },
  // 포트폴리오 수정
  update: (
    companyId: number,
    portfolioId: number,
    title: string,
    contentJson: string,
    categoryIds: number[],
    representativeCategoryId: number,
    tagIds: number[],
    thumbnailFileUUID: string,
    period: string,
    scope: string,
    customer: string,
    partner: string
  ) => {
    return request.put(`/v1/company/${companyId}/portfolio/${portfolioId}`, {
      title,
      contentJson,
      categoryIds,
      representativeCategoryId,
      tagIds,
      thumbnailFileUUID,
      period,
      scope,
      customer,
      partner,
    })
  },
  // 포트폴리오 삭제
  delete: (companyId: number, portfolioId: number) => {
    return request.delete(`/v1/company/${companyId}/portfolio/${portfolioId}`)
  },
  //포트폴리오 리스트
  getList: (companyId: number, onlyPublished: boolean, isCount: boolean = true) => {
    return request.get<ReferenceRes[]>(`/v1/company/${companyId}/portfolio`, { params: { onlyPublished: onlyPublished, isCount } })
  },
  // 포트폴리오 게시
  publish: (companyId: number, portfolioId: number) => {
    return request.post(`/v1/company/${companyId}/portfolio/${portfolioId}/publish`)
  },
  // 포트폴리오 게시 취소
  cancelPublish: (companyId: number, portfolioId: number) => {
    return request.delete(`/v1/company/${companyId}/portfolio/${portfolioId}/publish`)
  },
  // 포트폴리오 게시 취소
  duplicate: (companyId: number, portfolioId: number) => {
    return request.post(`/v1/company/${companyId}/portfolio/${portfolioId}/duplicate`)
  },
  // 배너 추가
  addBanner: (
    companyId: number,
    title: string,
    contentJson: string,
    categoryIds: number[],
    thumbnailFileUUID: string
  ) => {
    return request.post(`/v1/company/${companyId}/portfolio/banner`, {
      title,
      contentJson,
      categoryIds,
      thumbnailFileUUID,
    })
  },
  // 메인 배너 게시
  updateMainBanner: (companyId: number, categoryId: number, portfolioId: number) => {
    return request.post(`/v1/company/${companyId}/portfolio/banner/main`, null, { params: { categoryId, portfolioId } })
  },
}

export default companyPortfolioApi
