import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import Confirm from './Confirm'

type Props = {
  onHide: () => void
  companyId: number
}
export default function Index({ onHide, companyId }: Props) {
  const navigate = useNavigate()

  const setNotificationState = useSetRecoilState(notificationState)
  const [{ account }, setAuthState] = useRecoilState(authState)

  const [step, setStep] = useState(1)
  const [selectedIdxs, setSelectedIdxs] = useState([])
  const [showEtc, setShowEtc] = useState(false)
  const [etcReason, setEtcReason] = useState('')

  const reasons = [
    '이용하고 싶은 서비스가 없어요',
    '레퍼런스 내용이 부족해요',
    '협업하고 싶은 회사가 부족해요',
    '협업이 이루어지지 않아요',
    '개인 정보 유출이 우려돼요',
    '중복 계정이 있어요',
    '사이트 이용 방법이 어려워요',
    '대체 가능한 서비스가 있어요',
    '방문 횟수가 거의 없어요',
  ]

  const toggleSelectReasonAtIndex = (i: number) => {
    const hasValue = selectedIdxs.indexOf(i) !== -1
    if (hasValue) {
      setSelectedIdxs(selectedIdxs.filter((idx) => idx !== i))
    } else {
      setSelectedIdxs([...selectedIdxs, i])
    }
  }

  const renderReasons = () => {
    return reasons.map((reason, i) => {
      return (
        <div
          className="checkbox-wrap"
          style={{ marginTop: i === 0 ? 30 : 16 }}
          onClick={() => {
            toggleSelectReasonAtIndex(i)
          }}
        >
          <p className="w-100">{reason}</p>
          <input type="checkbox" className="checkbox green" id="dataCheck1" checked={selectedIdxs.indexOf(i) !== -1} />
          <label htmlFor="dataCheck"></label>
        </div>
      )
    })
  }

  const renderEtcReason = () => {
    return (
      <>
        <div
          className="checkbox-wrap"
          style={{ marginTop: 16 }}
          onClick={() => {
            setShowEtc(!showEtc)
          }}
        >
          <p className="w-100">기타</p>
          <input type="checkbox" className="checkbox green" id="dataCheck1" checked={showEtc} />
          <label htmlFor="dataCheck"></label>
        </div>
        {showEtc && (
          <input
            type="text"
            onChange={(e) => {
              setEtcReason(e.target.value)
            }}
            className="input w-100"
            placeholder="기타 의견을 적어주세요"
            style={{ marginTop: 12 }}
          />
        )}
      </>
    )
  }

  const getTotalReasons = () => {
    let items = []

    for (const index of selectedIdxs) {
      items.push(reasons[index])
    }

    if (showEtc && etcReason.trim().length > 0) {
      items.push(etcReason.trim())
    }

    return items
  }

  const deactivate = () => {
    api.company
      .updateActivateState(companyId, 'Disable', getTotalReasons())
      .then((res) => {
        fetchUser()
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const fetchUser = () => {
    api.account
      .fetch()
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })
        navigate('/studio/deactivated')
      })
      .catch((error) => {
        setAuthState({
          account: null,
          waiting: false,
        })
        onHide()
      })
  }

  if (step === 1) {
    return (
      <>
        <Confirm
          okText="비활성화"
          cancelText="취소"
          title="스튜디오를 비활성화하시겠습니까?"
          body="회사 정보와 레퍼런스가 공개되지 않습니다.<br/>이후 언제든지 활성화할 수 있습니다."
          onOk={() => {
            setStep(2)
          }}
          onCancel={onHide}
        />
      </>
    )
  } else if (step === 2) {
    return (
      <div className="popup-wrap mobile-page">
        <div className="popup">
          <button
            className="close-btn popup-close"
            onClick={() => {
              onHide()
            }}
          ></button>
          <p className="fs-18 bold text-center">
            더 좋은 바인더스가 될 수 있도록
            <br />
            스튜디오 비활성화 이유를 알려주세요!
          </p>
          <p className="fs-12 color-grey-700 text-center" style={{ marginTop: 8 }}>
            중복 선택 가능
          </p>
          {renderReasons()}
          {renderEtcReason()}
          <div className="btn-wrap" style={{ marginTop: 30 }}>
            <button
              className="btn btn-primary w-100"
              onClick={() => {
                deactivate()
              }}
              disabled={getTotalReasons().length === 0}
            >
              제출
            </button>
          </div>
        </div>
      </div>
    )
  }
}
