import request from 'src/utils/Request'

const socialApi = {
  // 소셜 리스트
  getList: () => {
    return request.get('/v1/social')
  },
}

export default socialApi
