import request from 'src/utils/Request'
import { SearchItemRes } from './types/search'

const searchApi = {
  // 태그 검색
  getList: (keyword: string) => {
    return request.get<SearchItemRes[]>(`/v1/search`, { params: { keyword: keyword.toLowerCase() } })
  },
}

export default searchApi
