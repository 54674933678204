type Props = {
  onClick: () => void
  onHide: () => void
}
export default function Index({ onHide, onClick }: Props) {
  return (
    <div className="popup-wrap">
      <div className="popup text-center">
        <button
          className="close-btn popup-close"
          onClick={() => {
            onHide()
          }}
        ></button>
        <p className="popup-title bold">레퍼런스를 정말 삭제하시겠습니까?</p>
        <div className="popup-body">삭제하면 모든 데이터와 내용을 복구할 수 없습니다.</div>
        <div className="btn-wrap">
          <button
            className="btn"
            onClick={() => {
              onHide()
            }}
          >
            아니요
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              onClick()
            }}
          >
            삭제
          </button>
        </div>
      </div>
    </div>
  )
}
