import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  onClose: () => void
}
export default function GuideOverlay({ onClose }: Props) {
  const navigate = useNavigate()
  const [step, setStep] = useState(1)

  const goNext = (e) => {
    e.stopPropagation()
    setStep(step + 1)

    if (step == 3) {
      navigate('/studio/create')
    }
  }

  const renderGuideItem = () => {
    if (step === 1) {
      return (
        <div className="step-1">
          <img className="img pc-only" src="/static/img/guide/w_guide_main_01.png" onClick={goNext} />
          <img className="img mobile-only" src="/static/img/guide/m_guide_main_07.png" onClick={goNext} />
          <div className="message-box">
            <img src="/static/img/guide/guide_main_b_01.png" />
            <div className="message-box-content">
              <p className="fs-16">
                바인더스 메인에 있는 다양한 레퍼런스를 통해 각 회사마다 어떤 일을 잘 하는지 알 수 있어요.
              </p>
            </div>
            <div className="message-box-footer">
              <span className="fs-13 bold color-grey-500 link" onClick={onClose}>
                SKIP
              </span>
              <span className="w-100"></span>
              <span className="fs-13 bold color-grey-500">3/1</span>
              <span className="fs-13 bold link" onClick={goNext}>
                다음 ＞
              </span>
            </div>
          </div>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="step-2">
          <img className="img" src="/static/img/guide/w_guide_main_02.png" onClick={goNext} />
          <div className="message-box">
            <img src="/static/img/guide/guide_main_b_02.png" />
            <div className="message-box-content">
              <p className="fs-16">
                위 버튼을 클릭하여 마이메뉴를 꺼내고,
                <br />
                아카이브 / 메시지 / 계정을 관리해 보세요.
              </p>
            </div>
            <div className="message-box-footer">
              <span className="fs-13 bold color-grey-500 link" onClick={onClose}>
                SKIP
              </span>
              <span className="w-100"></span>
              <span className="fs-13 bold color-grey-500">3/2</span>
              <span className="fs-13 bold link" onClick={goNext}>
                다음 ＞
              </span>
            </div>
          </div>
        </div>
      )
    } else if (step === 3) {
      return (
        <div className="step-3">
          <img className="img" src="/static/img/guide/w_guide_main_03.png" onClick={goNext} />
          <div className="message-box">
            <img src="/static/img/guide/guide_main_b_03.png" />
            <div className="message-box-content">
              <p className="fs-16">
                회사를 홍보하고 싶으신가요?
                <br />
                스튜디오를 개설하고 레퍼런스를 만들어 관리해 보세요.
              </p>
            </div>
            <div className="message-box-footer">
              <a className="fs-13 bold color-grey-500 link" href="https://brand.bindus.kr/howto" target={'_blank'}>
                활용방안 보러가기 ＞
              </a>
              <span className="w-100"></span>
              <span className="fs-13 bold color-grey-500">3/3</span>
              <span className="fs-13 bold link" onClick={onClose}>
                닫기
              </span>
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }

  return <div className="guide-overlay">{renderGuideItem()}</div>
}
