import { ChangeEvent, forwardRef, useCallback } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { EditorBlockInputProps } from '../../types'
import styles from './index.module.scss'

const TitleEditorBlock = forwardRef(({ menuOpened, block: blockValue, onBlockChange }: EditorBlockInputProps, ref) => {
  const handleBlockChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onBlockChange?.({
        ...blockValue,
        value: e.target.value as any,
      })
    },
    [onBlockChange, blockValue]
  )

  return (
    <div className={[styles.title, menuOpened && styles.menuOpened].filter((item) => item).join(' ')}>
      <TextareaAutosize
        className="fs-20 bold"
        value={blockValue.value as string}
        onChange={handleBlockChange}
        placeholder="타이틀을 입력해 주세요."
        autoFocus
      />
    </div>
  )
})

export default TitleEditorBlock
