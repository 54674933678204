import { useEffect, useState } from 'react';
import api from 'src/api';
import { BannerItemRes } from 'src/api/types/banner';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function HomeMainBanner() {
    const [banners, setBanners] = useState<BannerItemRes[]>(null)

    useEffect(() => {
        fetchMainBanner()
    }, [])

    const fetchMainBanner = () => {
        api.banner.getList('Main').then(res => {

            setBanners(res.data)
        }).catch(e => {
            // do nothing
        })
    }

    return <>
        <Swiper className="banner-swiper"
            initialSlide={1}
            loop={true}
            pagination={{
                el: '.banner-section .page-text',
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    function zeroPadding(number, length) {
                        let str = String(number);
                        while (str.length < length) {
                            str = '0' + str;
                        }
                        return str;
                    }
                    return zeroPadding(current, 2) + ' / ' + zeroPadding(total, 2);
                }
            }}
            navigation={{
                prevEl: '.banner-section .swiper-button-prev',
                nextEl: '.banner-section .swiper-button-next',
            }}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            onChange={(e) => {
                console.log(e)
            }}
        >
            {
                banners?.map((item) => {
                    return (
                        <SwiperSlide style={{ background: '#' + item.backgroundColor }} key={item.id}>
                            <div className="section-container">
                                <a href={item.link}>
                                    <img className="w-100 pc-only" src={item.fileUrl} alt={item.title} />
                                    <img className="w-100 mobile-only" src={item.mobileFileUrl} alt={item.title} />
                                </a>
                            </div>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
        <span className="page-text fs-12 bold"></span>
        <div className="swiper-button-prev pc-only"></div>
        <div className="swiper-button-next pc-only"></div>
    </>
}