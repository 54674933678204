import { useNavigate } from 'react-router-dom'
import { hexEncode } from 'src/utils/String'

export default function CompanyProfileImg({
  company,
  size = 60,
  onClick = null,
  clickable = true
}: {
  company?: any
  size?: number
  onClick?: () => void
  clickable?: boolean
}) {
  const navigate = useNavigate()

  const classNames = ['initial']

  const renderContent = () => {
    if (company.logo?.url) {
      return <img src={company.logo?.url} />
    }

    if (size > 32) {
      classNames.push('fs-18')
    } else if (size > 20) {
      classNames.push('fs-16')
    } else {
      classNames.push('fs-11')
    }

    const typeNumber = company?.name ? hexEncode(company?.name) % 4 : 1
    classNames.push('bg-type' + typeNumber)
    const initial = company?.name.substring(0, 1).toUpperCase()
    return <div className={classNames.join(' ')}>{initial}</div>
  }

  return (
    <div
      className={`profile-img-wrap link`}
      onClick={(e) => {
        if (!clickable) {
          return
        }

        if (onClick) {
          e.stopPropagation()
          onClick?.()
          return
        }

        navigate(`/c/${company.urlKey}`)
      }}
      style={{ width: size, height: size, minWidth: size, minHeight: size, borderRadius: size / 2, overflow: 'hidden' }}
    >
      {renderContent()}
    </div>
  )
}
