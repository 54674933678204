export default function Image() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.75 4H6.75C6.02065 4 5.32118 4.28973 4.80546 4.80546C4.28973 5.32118 4 6.02065 4 6.75V17.75C4 18.4793 4.28973 19.1788 4.80546 19.6945C5.32118 20.2103 6.02065 20.5 6.75 20.5H17.75C18.4793 20.5 19.1788 20.2103 19.6945 19.6945C20.2103 19.1788 20.5 18.4793 20.5 17.75V6.75C20.5 6.02065 20.2103 5.32118 19.6945 4.80546C19.1788 4.28973 18.4793 4 17.75 4ZM6.75 5.83333H17.75C17.9931 5.83333 18.2263 5.92991 18.3982 6.10182C18.5701 6.27373 18.6667 6.50688 18.6667 6.75V14.4133L15.7333 11.9108C15.2788 11.5369 14.7086 11.3324 14.12 11.3324C13.5314 11.3324 12.9612 11.5369 12.5067 11.9108L5.83333 17.475V6.75C5.83333 6.50688 5.92991 6.27373 6.10182 6.10182C6.27373 5.92991 6.50688 5.83333 6.75 5.83333V5.83333Z"
        fill="currentColor"
      />
      <path
        d="M8.58594 10.416C9.34533 10.416 9.96094 9.80041 9.96094 9.04102C9.96094 8.28162 9.34533 7.66602 8.58594 7.66602C7.82655 7.66602 7.21094 8.28162 7.21094 9.04102C7.21094 9.80041 7.82655 10.416 8.58594 10.416Z"
        fill="currentColor"
      />
    </svg>
  )
}
