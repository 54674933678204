import { ChangeEvent, useCallback, useRef, useState } from 'react'
import api from 'src/api'

type Props = {
  defaultThumb: any
  onChange: (thumb: any) => void
}
export default function ReferenceEditThumb({ defaultThumb = null, onChange }: Props) {
  const fileInput = useRef<HTMLInputElement>(null)
  const [thumbnail, setThumbnail] = useState(defaultThumb)

  const handleFileUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    const uploadFile = e.target.files[0]

    const blob = new Blob([uploadFile], { type: uploadFile.type })

    const formData = new FormData()
    formData.append('image', uploadFile)

    api.image.upload(uploadFile).then((response) => {
      setThumbnail(response.data)
      onChange(response.data)
    })
  }, [])

  const renderUploadButton = () => {
    if (thumbnail) {
      return (
        <button
          onClick={() => {
            fileInput.current?.click()
          }}
          className="edit-button"
        ></button>
      )
    }

    return (
      <button
        className="upload-button"
        onClick={() => {
          fileInput.current?.click()
        }}
      >
        <i className="ico ico-image" style={{ marginRight: 6 }}></i>
        이미지 업로드
      </button>
    )
  }

  return (
    <div className="image-upload-box mt-20">
      {thumbnail && <img src={thumbnail.url} alt="" />}
      {renderUploadButton()}
      <input ref={fileInput} type="file" onChange={handleFileUpload} accept="image/png, image/jpeg, image/gif" hidden />
    </div>
  )
}
