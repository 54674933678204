import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { ReferenceRes } from 'src/api/types/reference'
import BlockEditorViewer from 'src/components/BlockEditor/Viewer'
import CompanyMedals from 'src/components/Common/CompanyMedals'
import CompanyProfileImg from 'src/components/Common/CompanyProfileImg'
import DetailGuide from 'src/components/Guide/DetailGuide'
import MessageSendPopup from 'src/components/Message/SendPopup'
import Confirm from 'src/components/Popup/Confirm'
import ReferenceDetailInfo from 'src/components/Studio/ReferenceDetailInfo'
import ReferenceDetailWidget from 'src/components/Studio/ReferenceDetailWidget'
import authState from 'src/recoil/atom/auth'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import { getMainCategoryId, getMainPath } from 'src/utils/Category'
import { dateFormat } from 'src/utils/Format'
import { replaceSpecialCharacters } from 'src/utils/String'

type Props = {
  referenceId: number
  goPrev?: () => void
  goNext?: () => void
  onHide: () => void
}

export default function Reference({ referenceId, goPrev, goNext, onHide }: Props) {
  const navigate = useNavigate()
  const location = useLocation()

  const [cookies, setCookie, removeCookie] = useCookies(['needGuide2'])

  const [data, setData] = useState<ReferenceRes>(null)
  const [loading, setLoading] = useState(true)
  const [{ account }] = useRecoilState(authState)
  const setPopupState = useSetRecoilState(popupState)
  const [showSendMessage, setShowSendMessage] = useState(false)
  const [showGuide, setShowGuide] = useState(false)
  const [showForbiddenPopup, setShowForbiddenPopup] = useState(false)

  useEffect(() => {
    if (cookies.needGuide2 && account) {
      setShowGuide(true)
      removeCookie('needGuide2')
    }

    document.querySelector('body').style.overflow = 'hidden'
    return () => {
      document.querySelector('body').style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    setData(null)

    api.portfolio
      .get(referenceId)
      .then((response) => {
        const data = response.data
        setData(data)

        const dataLayer = (window as any).dataLayer
        if (dataLayer && data) {
          dataLayer.push({
            event: 'view_content',
            content_id: data.id,
            content_title: data.title,
            content_author: data.company.urlKey,
            location_pathname: location.pathname,
          })
        }

        setTimeout(() => {
          setLoading(false)
        }, 400)
      })
      .catch((e) => {
        const { status } = e.response
        if (status === 403 || status === 404) {
          setShowForbiddenPopup(true)
        } else {
          onHide()
        }
      })
  }, [referenceId])

  const moveToCompany = () => {
    navigate('/c/' + data.company.urlKey)
  }

  const sendMessage = () => {
    if (!account) {
      setPopupState({ popupType: MainPopupType.NeedToLogin })
      return
    }

    setShowSendMessage(true)
  }

  const renderLoading = () => {
    if (!loading) {
      return <></>
    }
    return <div className="spinner fixed-center"></div>
  }

  const renderContent = () => {
    if (!data) {
      return <></>
    }

    return (
      <div className={`container ${loading && 'loading'}`}>
        <div className={`float-container`}>
          <ReferenceDetailWidget
            item={data}
            goPrev={goPrev}
            goNext={goNext}
            onHide={onHide}
            moveToCompany={moveToCompany}
            sendMessage={sendMessage}
          />
        </div>
        <div
          className={`content-container`}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className="container-header">
            <h1 className="title">{replaceSpecialCharacters(data.title)}</h1>
            <p className="company" style={{ marginTop: 6 }}>
              <a onClick={moveToCompany}>{data.company.name}</a>
            </p>
            <div className="counter-area mt-10">
              <img src="/static/img/ico/view_contents_wh.svg" />
              <span>{data.clickCount}</span>
              <img src="/static/img/ico/like_contents_wh.svg" />
              <span>{data.saveCount}</span>
              <img src="/static/img/ico/share_contents_wh.svg" />
              <span>{data.shareCount || 0}</span>
            </div>
          </div>
          <div className="mt-30">
            {data && (
              <BlockEditorViewer
                data={replaceSpecialCharacters(data?.contentJson)}
                header={
                  <ReferenceDetailInfo
                    period={data?.period}
                    scope={data?.scope}
                    customer={data?.customer}
                    partner={data?.partner}
                  />
                }
              />
            )}
          </div>
          <div className="box-wrap">
            <div className="box flex-vertical-center">
              <CompanyMedals medals={data.company.medals} mobileSize={28} align="right" />
              <CompanyProfileImg company={data.company} onClick={moveToCompany} />
              <p className="fs-16 bold" style={{ marginLeft: 14 }}>
                <a onClick={moveToCompany}>{data.company?.name}</a>
              </p>
            </div>
            <div className="box counter-box">
              <div>
                <p className="fs-16 bold" style={{ wordBreak: 'break-all' }}>
                  {data.title}
                </p>
                {data.publishedAt && (
                  <p className="fs-12 color-grey-600" style={{ marginTop: 6 }}>
                    게시일: {dateFormat(data.publishedAt)}
                  </p>
                )}
              </div>
              <div className="counter-area">
                <img src="/static/img/ico/view_contents_bk.svg" />
                <span>{data.clickCount}</span>
                <img src="/static/img/ico/like_contents_bk.svg" />
                <span>{data.saveCount}</span>
                <img src="/static/img/ico/share_contents_gk.svg" />
                <span>{data.shareCount}</span>
              </div>
            </div>
            {data.type !== 'Banner' && (
              <div className="box">
                <p className="fs-16 bold">카테고리</p>
                <ul className="categories">
                  {data.categories.map((item, index) => {
                    return (
                      <li
                        className="link"
                        onClick={() => {
                          const mainCategoryId = getMainCategoryId(item)
                          const mainPath = mainCategoryId === 10 ? 'creative' : 'tech'
                          if (mainPath) {
                            window.open(`/main/${mainPath}?categoryId=${item.id}`)
                          }
                        }}
                        style={{ background: `url(${item.imageUri}) no-repeat center`, backgroundSize: 'cover' }}
                        key={index}
                      >
                        <span>{item.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
            {data.type !== 'Banner' && (
              <div className="box">
                <p className="fs-16 bold">태그</p>
                <ul className="tags">
                  {data.tags.map((item, index) => {
                    return (
                      <li
                        className="link"
                        onClick={() => {
                          const mainPath = getMainPath(data.categories?.[0])
                          if (mainPath) {
                            const keyword = encodeURIComponent(item.name)
                            window.open(`${mainPath}?keyword=${keyword}`)
                          }
                        }}
                        key={index}
                      >
                        {item.name}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {data && (
        <Helmet>
          <title>{data.title} | 바인더스</title>
        </Helmet>
      )}
      <div
        className="reference-page mobile-page"
        onClick={(e) => {
          e.preventDefault()
          onHide()
        }}
      >
        {renderContent()}
        {renderLoading()}
        {showSendMessage && (
          <MessageSendPopup
            target={data.company}
            receiverType="Company"
            onClose={() => {
              setShowSendMessage(false)
            }}
          />
        )}
      </div>
      {showGuide && (
        <DetailGuide
          onClose={() => {
            setShowGuide(false)
          }}
        />
      )}
      {showForbiddenPopup && (
        <Confirm
          title="접근 권한이 없습니다"
          body="레퍼런스가 비공개 설정되어 있을 수 있습니다.<br/>해당 스튜디오 관리자에게 문의해 보세요."
          isBlurBg={true}
          onOk={() => {
            //setShowForbiddenPopup(false)
            onHide()
          }}
        />
      )}
    </div>
  )
}
