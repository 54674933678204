export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email)
}

export function isValidPassword(password: string) {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&^()_+])[A-Za-z\d$@$!%*#?&^()_+]{8,}$/.test(password)
}

export function isValidBirthday(birthday: string) {
  return birthday.length === 8
}

export function isValidUsername(username: string) {
  return /^(?=.*[A-Za-z0-9가-힣\s])[A-Za-z0-9가-힣\s]{2,16}$/.test(username)
}

export function isValidCompanyUrlKey(urlKey: string) {
  return /^(?=.*[a-z0-9])[a-z0-9]{3,16}$/.test(urlKey)
}

export function isValidPhoneNumber(phoneNumber: string) {
  return phoneNumber?.length >= 9
  // return /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{3,4})$/.test(phoneNumber)
}

export function getEmailValidationMsg(email: string): string | null {
  if (email === null || email === undefined) {
    return null
  }

  if (email?.length === 0) {
    return '이메일 주소를 입력해 주세요'
  }

  if (!isValidEmail(email)) {
    return '이메일 주소가 맞는지 다시 확인해 주세요'
  }

  return null
}

export function getPasswordValidationMsg(password: string): string | null {
  if (password === null || password === undefined) {
    return null
  }
  if (password.length === 0) {
    return '비밀번호를 입력해 주세요'
  }

  if (!isValidPassword(password)) {
    return '영문, 숫자, 특수기호를 조합하여 8자 이상으로 정해 주세요'
  }

  return null
}

export function getBirthdayValidationMsg(birthday: string): string | null {
  if (birthday === null || birthday === undefined) {
    return null
  }

  if (birthday.length === 0) {
    return '생년월일을 입력해 주세요'
  }

  if (!isValidBirthday(birthday)) {
    return '8자리 숫자로 입력해 주세요'
  }

  return null
}

export function getUsernameValidationMsg(username: string): string | null {
  if (username === null || username === undefined) {
    return null
  }

  if (username.length === 0) {
    return '사용자명을 입력해 주세요'
  }

  if (!isValidUsername(username)) {
    return '한글, 영어, 숫자만 사용하여 2자 이상 16자 이하로 정해 주세요'
  }

  return null
}

export function getCompanyUrlKeyValidationMsg(urlKey: string): string | null {
  if (urlKey === null || urlKey === undefined) {
    return null
  }

  if (urlKey.length === 0) {
    return '스튜디오 주소를 입력해 주세요'
  }

  if (!isValidCompanyUrlKey(urlKey)) {
    return '영어 소문자, 숫자만 사용하며 공백 없이 3글자 이상으로 정해 주세요.'
  }

  return null
}

export function getPhoneNumberValidationMsg(phoneNumber: string): string | null {
  if (phoneNumber === null || phoneNumber === undefined) {
    return null
  }

  if (phoneNumber.length === 0) {
    return '휴대폰 번호를 입력해 주세요'
  }

  if (!isValidPhoneNumber(phoneNumber)) {
    return '휴대폰 번호를 정확히 입력해 주세요.'
  }

  return null
}
