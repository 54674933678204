import { useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { EditorToolbarProps } from './types'

export default function EditorToolbar({ onAddBlock }: EditorToolbarProps) {
  const [showLinkSelectPopup, setShowLinkSelectPopup] = useState(false)

  const generateButtonClickHandler = useCallback(
    (type: string) => {
      switch (type) {
        case 'title':
        case 'text':
          return () => {
            onAddBlock?.({
              id: v4(),
              type: type,
              value: '',
            })
          }
        case 'imageCaption':
          return () => {
            onAddBlock?.({
              id: v4(),
              type: 'imageCaption',
              value: {
                imageSrc: null,
                comments: null,
              },
            })
          }
        case 'image':
          return () => {
            onAddBlock?.({
              id: v4(),
              type: 'image',
              value: null,
            })
          }
        case 'video':
          return () => {
            onAddBlock?.({
              id: v4(),
              type: 'video',
              value: null,
            })
          }
        case 'textLink':
          return () => {
            setShowLinkSelectPopup(false)
            onAddBlock?.({
              id: v4(),
              type: 'textLink',
              value: null,
            })
          }
        case 'imageLink':
          return () => {
            setShowLinkSelectPopup(false)
            onAddBlock?.({
              id: v4(),
              type: 'imageLink',
              value: null,
            })
          }
      }
    },
    [onAddBlock]
  )

  return (
    <>
      <section className="add-widget-section">
        <p className="fs-16 bold">위젯추가</p>
        <ul>
          <li onClick={generateButtonClickHandler('title')}>
            <i className="ico-widget-title"></i>
            <p className="fs-13 bold">타이틀</p>
          </li>
          <li onClick={generateButtonClickHandler('text')}>
            <i className="ico-widget-text"></i>
            <p className="fs-13 bold">텍스트</p>
          </li>
          <li
            onClick={() => {
              setShowLinkSelectPopup(true)
            }}
          >
            <i className="ico-widget-link"></i>
            <p className="fs-13 bold">링크</p>
          </li>
          <li onClick={generateButtonClickHandler('video')}>
            <i className="ico-widget-video"></i>
            <p className="fs-13 bold">동영상</p>
          </li>
          <li onClick={generateButtonClickHandler('image')}>
            <i className="ico-widget-pic"></i>
            <p className="fs-13 bold">이미지</p>
          </li>
          <li onClick={generateButtonClickHandler('imageCaption')}>
            <i className="ico-widget-caption"></i>
            <p className="fs-13 bold">캡션</p>
          </li>
        </ul>
      </section>
      {showLinkSelectPopup && (
        <div className="popup-wrap">
          <div className="popup link-select-popup">
            <button
              className="close-btn popup-close"
              onClick={() => {
                setShowLinkSelectPopup(false)
              }}
            ></button>
            <ul>
              <li onClick={generateButtonClickHandler('textLink')}>
                <i className="ico-widget-text-link"></i>
                <p>텍스트+링크</p>
              </li>
              <li onClick={generateButtonClickHandler('imageLink')}>
                <i className="ico-widget-image-link"></i>
                <p>이미지+링크</p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}
