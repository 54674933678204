import { useEffect, useRef, useState } from 'react'

type Props = {
  defaultText?: string
  placeholder?: string
  disabled?: boolean
  style?: any
  onChange: (text: string) => void
}

export default function TextArea({ defaultText, placeholder, disabled, style, onChange }: Props) {
  const [text, setText] = useState(defaultText)
  const textAreaRef = useRef(null)

  useEffect(() => {
    textAreaRef.current.style.height = 'inherit'
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
  }, [text])

  const renderTextArea = () => {
    let inputClassNames = ['fs-14', 'input', 'w-100']

    return (
      <textarea
        ref={textAreaRef}
        className={inputClassNames.join(' ')}
        placeholder={placeholder}
        value={text}
        rows={1}
        onChange={(e) => {
          const text = String(e.target.value)
          setText(text)
          onChange(text)
        }}
        disabled={disabled}
        autoComplete="off" 
        style={style}
      />
    )
  }

  return renderTextArea()
}
