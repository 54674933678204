export function dateTimeFormat(dateString: string) {
  const date = new Date(dateString)
  let retVal = date.toLocaleString()
  const comps = retVal.split(' ')
  const comps2 = comps[4].split(':')
  return `${comps[0]}${comps[1]}${comps[2]} ${comps[3]} ${comps2[0]}시 ${comps2[1]}분`
}

export function dateFormat(dateString: string, splitter = '.') {
  const date = new Date(dateString)
  return `${date.getFullYear()}${splitter}${date.getMonth() + 1}${splitter}${date.getDate()}`
}

export function dateFormatKr(dateString: string, dayOffset: number = 0) {
  let date = new Date(dateString)

  if (dayOffset !== 0) {
    date.setDate(date.getDate() + dayOffset)
  }

  return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`
}
