import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { ImageRes } from 'src/api/types/image'
import { Tag } from 'src/api/types/reference'
import { CompanyCopyLink } from 'src/components/Common/CopyLink'
import MyCompanyProfileImg from 'src/components/Common/MyCompanyProfileImg'
import SocialInput from 'src/components/Common/SocialInput'
import WebsiteUrlInput from 'src/components/Common/WebsiteUrlInput'
import SaveCancelPopup from 'src/components/Popup/SaveCancel'
import Select from 'src/components/Select/Select'
import ReferenceEditTag from 'src/components/Studio/ReferenceEditTag'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'

export default function Index() {
  const [{ waiting }, setAuthState] = useRecoilState(authState)
  const setNotificationState = useSetRecoilState(notificationState)

  const navigate = useNavigate()
  const fileInput = useRef<HTMLInputElement>(null)
  const [{ account }] = useRecoilState(authState)
  const [profileImgSrc, setProfileImageSrc] = useState(null)

  const [showSaveCancelPopup, setShowSaveCancelPopup] = useState(false)

  const [loading, setLoading] = useState(true)

  const [companyName, setCompanyName] = useState('')
  const [logo, setLogo] = useState<ImageRes>(null)
  const [businessAreaIntroduction, setBusinessAreaIntroduction] = useState('')
  const [tags, setTags] = useState<Tag[]>([])
  const [urls, setUrls] = useState<string[]>([])
  const [socials, setSocials] = useState<any>([])

  useEffect(() => {
    fetchCompany()
  }, [])

  const fetchCompany = (fetchOnlyLogo: boolean = false) => {
    api.company
      .get(account.company.id)
      .then((res) => {
        const { name, tags, socials, urlJson, businessAreaIntroduction, logo } = res.data

        try {
          if(fetchOnlyLogo) {
            setLogo(logo)
            return
          }
          
          
          setCompanyName(name)
          setBusinessAreaIntroduction(businessAreaIntroduction)
          setTags(tags)
  
          let items = []
          for (const social of socials) {
            items.push({
              socialId: social.social.id,
              data: social.data,
            })
          }
          setSocials(items)
  
          setUrls(JSON.parse(urlJson))
        }
        catch(e) {
          // Do nothing
        }
        finally {
          setLoading(false)
        }
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const fetchUser = () => {
    api.account
      .fetch()
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })
      })
      .catch((error) => {
        // Do nothing
      })
  }

  const save = () => {
    let tagIds = []
    for (const tag of tags) {
      tagIds.push(tag.id)
    }

    api.company
      .updateProfile(account.company.id, companyName, businessAreaIntroduction, tagIds, urls, socials)
      .then((res) => {
        setNotificationState({
          type: 'check',
          message: '저장되었습니다',
        })
        navigate('/studio/profile')
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '저장 실패',
        })
      })
  }

  const uploadLogo = (uploadFile: File) => {
    setLogo(null)
    api.company
      .updateLogo(account.company.id, uploadFile)
      .then((res) => {
        fetchUser()
        fetchCompany(true)
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: uploadFile ? '저장 실패' : '기본 이미지로 변경 실패',
        })
      })
  }

  const handleFileUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    const uploadFile = e.target.files[0]

    const blob = new Blob([uploadFile], { type: uploadFile.type })
    const imageSrc = window.URL.createObjectURL(blob)
    setProfileImageSrc(imageSrc)

    const formData = new FormData()
    formData.append('image', uploadFile)

    uploadLogo(uploadFile)
  }, [])

  if (!account) {
    return <></>
  }

  if (loading) {
    return <></>
  }

  return (
    <div className="page bg-color-grey-50 profile-edit-page">
      <div className="box-container">
        <div className="box">
          <div
            className="box-inner-container box-inner-container-md flex flex-center profile-box"
            style={{ margin: 0 }}
          >
            <div className="profile-img-wrap lg">
              {/* {logo && <Image img={logo} />} */}
              <MyCompanyProfileImg size={100} />
              <Select
                options={[
                  { label: '사진 변경', value: '1' },
                  { label: '기본 이미지로 변경', value: '0' },
                ]}
                customLabel={<button className="change-btn"></button>}
                onChange={(option) => {
                  if (option.label == '사진 변경') {
                    fileInput.current?.click()
                  } else {
                    setProfileImageSrc(null)
                    uploadLogo(null)
                  }
                }}
              />
              <input ref={fileInput} type="file" onChange={handleFileUpload} accept="image/png, image/jpeg" hidden />
            </div>
            <div className="w-100">
              <input
                type="text"
                className="input fs-14 w-100 mb-10"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value)
                }}
              />
              <CompanyCopyLink data={account.company.urlKey} />
            </div>
          </div>
        </div>
        <div className="box">
          <p className="fs-16 bold">
            사업영역 소개
            <span className="float-right fs-12 color-grey-600">{businessAreaIntroduction.length}/95 자</span>
          </p>
          <textarea
            className="input w-100 mt-20"
            placeholder="회사가 어떤 일을 하는지 알기 쉽게 설명해 주세요"
            value={businessAreaIntroduction}
            onChange={(e) => {
              let text = e.target.value
              if (text.length > 95) {
                text = text.substring(0, 95)
              }
              setBusinessAreaIntroduction(text)
            }}
            style={{ width: '100%', height: 80 }}
          />
        </div>
        <div className="box">
          <p className="fs-16 bold">회사태그</p>
          <p className="fs-14 mb-20" style={{ marginTop: 8 }}>
            검색 결과로 노출 되기 위해, 회사와 가장 관련있는 단어를 태그로 선정해 주세요.
          </p>
          <ReferenceEditTag
            defaultTags={tags}
            maxCount={account.company.plan.plan.maxCompanyTag}
            onChange={(tags: Tag[]) => {
              setTags(tags)
            }}
          />
        </div>
        <div className="box">
          <p className="fs-16 bold">웹사이트</p>
          <p className="fs-14 mb-20" style={{ marginTop: 8 }}>
            웹사이트 링크를 등록해 보세요! 회사를 더 알릴 수 있어요.
          </p>
          <WebsiteUrlInput
            defaultUrls={urls}
            maxCount={50}
            onChange={function (urls: string[]): void {
              setUrls(urls)
            }}
          />
        </div>
        <div className="box" style={{ marginTop: 12 }}>
          <p className="fs-16 bold">연락처 및 소셜링크</p>
          <SocialInput
            defaultSocials={socials}
            onChange={(socials) => {
              setSocials(socials)
            }}
          />
        </div>
        <div className="btn-wrap page-foot-btn">
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              setShowSaveCancelPopup(true)
            }}
          >
            취소
          </button>
          <button
            className="ml-10 btn btn-primary"
            onClick={() => {
              save()
            }}
          >
            저장
          </button>
        </div>
      </div>
      {showSaveCancelPopup && (
        <SaveCancelPopup
          onCancel={function (): void {
            setShowSaveCancelPopup(false)
          }}
          onOk={function (): void {
            navigate('/studio/profile')
          }}
        />
      )}
    </div>
  )
}
