import { useEffect, useRef } from 'react'
import { useCookies } from 'react-cookie'
import { isAndroid, isMobile } from 'react-device-detect'
import { Link, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useAddToHomeScreenPrompt } from 'src/hooks/useAddToHomeScreenPrompt'
import Footer from 'src/layouts/MainLayout/Footer'
import authState from 'src/recoil/atom/auth'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import reportClickEvent from 'src/utils/Gtm'
import CompanyMedals from '../Common/CompanyMedals'
import { AccountCopyLink, CompanyCopyLink } from '../Common/CopyLink'
import MyCompanyProfileImg from '../Common/MyCompanyProfileImg'
import { UserProfileImg } from '../Common/Profile'
import settingState from 'src/recoil/atom/setting'

export default function Aside() {
  const [cookies, setCookie] = useCookies(['isStudioMode'])

  const setPopupState = useSetRecoilState(popupState)
  const { waiting, account } = useRecoilValue(authState)
  const showAddhome = isMobile && isAndroid
  const location = useLocation()
  const [prompt, promptToInstall] = useAddToHomeScreenPrompt()

  const [{ isStudioMode }, setSettingState] = useRecoilState(settingState)

  useEffect(() => {
    checkActiveNav()
  }, [location.pathname])

  const signIn = () => {
    onHide()
    setPopupState({ popupType: MainPopupType.SignIn })

    reportClickEvent('aside', 'login', '로그인')
  }

  const signUp = () => {
    onHide()
    setPopupState({ popupType: MainPopupType.SignUp })

    reportClickEvent('aside', 'signup', '회원가입')
  }

  const checkActiveNav = () => {
    const currentPath = location.pathname;

    if (currentPath.length <= 1) {
      return;
    }

    const navLinks = document.querySelectorAll('aside .body-area nav a');

    navLinks.forEach((link) => {
      const linkPath = link.getAttribute('href');
      const isActive = linkPath.includes(currentPath);

      link.classList.toggle('active', isActive);
    });
  };

  const onHide = () => {
    document.body.classList.remove('show-aside')
  }

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (ref.current && !(ref.current as any).contains(e.target)) {
          onHide()
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const ref = useOutsideClick(onHide)

  const renderAsideBody = () => {
    if (waiting) {
      return <></>
    }

    if (account === null) {
      return (
        <>
          <div className="head-area">
            <p className="mt-30 fs-18 bold color-grey-800">바인더스에 가입하고 다양한 회사들을 만나보세요</p>
            <button className="mt-30 btn btn-bindus-gradient w-100" onClick={signIn}>
              로그인
            </button>
            <button className="mt-10 btn btn-orange-gradient w-100" onClick={signUp}>
              회원가입
            </button>
          </div>
        </>
      )
    } else if (isStudioMode && account?.company) {
      return (
        <>
          <div className="head-area">
            <CompanyMedals medals={account.company.medals} mobileSize={36} />
            <MyCompanyProfileImg size={60} />
            <p className="mt-20 fs-18 bold text-center">
              <Link to={'/studio/profile'}>{account.company.name}</Link>
            </p>
            <CompanyCopyLink data={account.company.urlKey} style={{ marginTop: 4, justifyContent: 'center' }} />
            <Link className="mt-20 mx-auto btn btn-third btn-md bold" to="/my/profile" style={{ maxWidth: 192 }}>
              개인으로 전환
            </Link>
          </div>
          <div className="mt-30 body-area">
            <nav>
              <Link to="/studio/profile">회사 프로필 관리</Link>

              {account.company.type === 'Admin' ? (
                <Link to="/studio/banner">배너 관리</Link>
              ) : (
                <Link to="/studio/reference">레퍼런스 관리</Link>
              )}

              <Link to="/studio/messages">메시지</Link>
              <Link to="/studio/setting">계정설정</Link>
              <Link to="/studio/plan">플랜관리</Link>
            </nav>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="head-area">
            <UserProfileImg account={account} size={60} />
            <p className="mt-20 fs-18 bold text-center">
              <Link to={'/my/profile'}>{account.name}</Link>
            </p>
            <AccountCopyLink account={account} style={{ marginTop: 4, justifyContent: 'center' }} />

            {account?.company ? (
              <Link className="mt-20 mx-auto btn btn-third btn-md bold" to="/studio/profile" style={{ maxWidth: 192 }}>
                스튜디오 전환
              </Link>
            ) : (
              <Link
                className="mt-20 mx-auto btn btn-studio-gradient btn-md bold"
                to="/studio/create"
                style={{ maxWidth: 192 }}
              >
                스튜디오 가입
              </Link>
            )}
          </div>
          <div className="mt-30 body-area">
            <nav>
              <Link to="/my/profile">내 프로필 관리</Link>
              <Link to="/my/messages">메시지</Link>
              <Link to="/my/library">라이브러리</Link>
              <Link to="/my/account">계정설정</Link>
            </nav>
          </div>
        </>
      )
    }
  }

  return (
    <aside ref={ref}>
      {renderAsideBody()}
      <div className="foot-area">
        {showAddhome && (
          <button
            className="btn btn-primary fs-13 w-100"
            onClick={promptToInstall}
            style={{ marginTop: 14, height: 34, boxShadow: 'none' }}
          >
            <i className="ico ico-add-home"></i>홈 화면에 바로가기 추가
          </button>
        )}
        <Footer />
      </div>
    </aside>
  )
}
