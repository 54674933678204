import request from 'src/utils/Request'
import { PushMessageItemRes } from './types/pushMessage'

const pushmessageApi = {
  // 팝업 조회
  getList: () => {
    return request.get<PushMessageItemRes[]>(`/v1/popup`)
  },
}

export default pushmessageApi
