import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { isMobile } from 'react-device-detect'
import { useRecoilState } from 'recoil'
import api from 'src/api'
import { BannerItemRes } from 'src/api/types/banner'
import mainPopupState, { MainPopupType } from 'src/recoil/atom/popup'
import reportClickEvent from 'src/utils/Gtm'

type Props = {
  onHide: () => void
}
export default function HomePopupBanner({ onHide }: Props) {
  const [popupState] = useRecoilState(mainPopupState)

  const [showBanner, setShowBanner] = useState(false)
  const [fadeIn, setShowFadeIn] = useState(false)
  const [items, setItems] = useState<BannerItemRes[]>(null)
  const [cookies, setCookie, removeCookie] = useCookies(['hideHomePopupBanner'])
  const [hidePopupBannerChecked, setHidePopupBannerChecked] = useState(false)

  const closeBanner = () => {
    if (hidePopupBannerChecked) {
      setCookie('hideHomePopupBanner', true, { path: '/', expires: getExpiredDate() })
    } else {
      removeCookie('hideHomePopupBanner')
    }

    setShowBanner(false)
    onHide()
    reportClickEvent('main_banner', 'close', '종료')
  }

  useEffect(() => {
    if (cookies.hideHomePopupBanner) {
      return
    }
    fetchBanner()
  }, [])

  const fetchBanner = () => {
    api.banner.getList("Popup").then((res) => {

      const items = res.data
      setItems(items)
      if (items.length === 0) {
        return
      }

      setTimeout(
        () => {
          if (popupState.popupType === MainPopupType.None) {
            setShowBanner(true)

            setTimeout(() => {
              setShowFadeIn(true)
            }, 200)
          }
        },
        isMobile ? 3000 : 0
      )
    })
  }

  const getExpiredDate = () => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }

  if (!showBanner) {
    return <></>
  }

  return (
    <>
      <div className="transparent-bg mobile-only" onClick={closeBanner}></div>
      <div className={`popup-banner ${fadeIn ? 'fade-in' : ''}`}>
        <div className="popup-banner-header">
          <div className="input-wrap">
            <input
              type="checkbox"
              id="use-popup-banner-check"
              className="checkbox green"
              onChange={(e) => {
                setHidePopupBannerChecked(e.target.checked)
              }}
            />
            <label htmlFor="use-popup-banner-check">오늘 하루 다시 보지 않기</label>
          </div>
          <div className="close-btn" onClick={closeBanner} />
        </div>
        <ul className="banners">
          {items?.map((item) => {
            return (
              <li
                key={item.id}
                onClick={() => {
                  if (!item.link) {
                    return
                  }
                  window.location.href = item.link
                }}
              >
                <img className="w-100" src={item.fileUrl} alt='' />
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}
