import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { ImageComment } from '../../../types'
import styles from './index.module.scss'

type Props = {
  value: ImageComment
  onChange: (value: ImageComment) => void
  onRemoveClick: (value: ImageComment) => void
  isOpened: boolean
  onOpenRequest: (id: string) => void
  viewerMode?: boolean
}

export default function Comment({ value, onChange, onRemoveClick, isOpened, onOpenRequest, viewerMode }: Props) {
  const commentRef = useRef<HTMLDivElement>(null)
  const commentBoxRef = useRef<HTMLDivElement>(null)
  const [commentBoxPosition, setCommentBoxPosition] = useState<{
    left: number
    direction: 'above' | 'below'
  }>({
    left: 0,
    direction: 'below',
  })

  const commentBoxStyle = useMemo(() => {
    const style: React.CSSProperties = { left: commentBoxPosition.left }
    if (commentBoxPosition.direction === 'below') {
      style.top = distanceFromPlusButtonToBox
    } else {
      style.bottom = style.bottom = distanceFromPlusButtonToBox
    }
    return style
  }, [commentBoxPosition])

  const commentBoxTextAreaStyle = useMemo(() => {
    if (!commentRef.current || !isOpened) {
      return undefined
    }
    const parent = commentRef.current.parentElement as HTMLDivElement
    const imageHeight = parent.clientHeight
    let maxHeight = Math.max(65, imageHeight / 2 - distanceFromPlusButtonToBox)
    return { maxHeight }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened, commentBoxPosition])

  const style = useMemo(
    () => ({
      top: `${value.topPercent}%`,
      left: `${value.leftPercent}%`,
    }),
    [value.leftPercent, value.topPercent]
  )

  //#region handlers
  const handleIconClick = useCallback(() => {
    onOpenRequest(value.id)
  }, [onOpenRequest, value.id])

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(value)
  }, [onRemoveClick, value])

  const handleCompleteClick = useCallback(() => {
    onChange?.({
      ...value,
      editCompleted: true,
    })
  }, [onChange, value])

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.({
        ...value,
        text: e.target.value,
      })
    },
    [onChange, value]
  )
  //#endregion

  useEffect(() => {
    if (!commentRef.current || !commentBoxRef.current || !isOpened) {
      return
    }
    const parent = commentRef.current.parentElement as HTMLDivElement
    const imageWidth = parent.clientWidth
    const imageHeight = parent.clientHeight
    const commentX = Math.floor((imageWidth * value.leftPercent) / 100)
    const commentY = Math.floor((imageHeight * value.topPercent) / 100)
    const commentBoxWidth = commentBoxRef.current.clientWidth
    const commentBoxHeight = commentBoxRef.current.clientHeight
    let left: number
    //#region calculate Left
    if (commentX < commentBoxWidth / 2) {
      left = -commentX
    } else if (commentX + commentBoxWidth / 2 > imageWidth) {
      left = -commentBoxWidth + (imageWidth - commentX)
    } else {
      left = commentBoxWidth / -2
    }
    //#endregion

    //#region calculate VerticalDirection;
    let direction: 'above' | 'below' = 'below'
    if (commentY + distanceFromPlusButtonToBox + commentBoxHeight > imageHeight) {
      direction = 'above'
    }
    //#endregion

    const adjust = () => {
      setCommentBoxPosition({ left, direction })
    }
    adjust()
    window.addEventListener('resize', adjust)
    return () => {
      window.removeEventListener('resize', adjust)
    }
  }, [isOpened, value])

  return (
    <div ref={commentRef} className={styles.comment} style={style}>
      {isOpened && (
        <div ref={commentBoxRef} className={styles.commentBox} style={commentBoxStyle}>
          {!viewerMode &&
            (value.editCompleted ? (
              <button className={styles.topRightButton} onClick={handleRemoveClick}>
                삭제
              </button>
            ) : (
              <button
                className={styles.topRightButton}
                onClick={handleCompleteClick}
                disabled={value.text.length === 0}
              >
                완료
              </button>
            ))}
          {value.editCompleted ? (
            <p className={[styles.commentP, viewerMode && styles.viewerMode].filter((item) => item).join(' ')}>
              {value.text}
            </p>
          ) : (
            <TextareaAutosize
              value={value.text}
              onChange={handleTextChange}
              placeholder="설명을 입력해 주세요"
              maxRows={1000}
            />
          )}
        </div>
      )}
      <button
        className={[styles.commentButton, isOpened && styles.opened, isOpened && styles[commentBoxPosition.direction]]
          .filter((item) => item)
          .join(' ')}
        onClick={handleIconClick}
      ></button>
    </div>
  )
}

const distanceFromPlusButtonToBox = 26
