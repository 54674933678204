import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from 'src/api';
import { BannerItemRes } from 'src/api/types/banner';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function HomeNewsSlide() {

    const [items, setItems] = useState<BannerItemRes[]>(null)

    useEffect(() => {
        fetchMainBanner()
    }, [])

    const fetchMainBanner = () => {
        api.banner.getList('MainBottom').then(res => {

            setItems(res.data)
        }).catch(e => {
            // do nothing
        })
    }

    return <div style={{ position: 'relative' }}>
        <Swiper className="news-swiper"
            initialSlide={0}
            breakpoints={{
                0: {
                    width: 157,
                    spaceBetween: 16,
                    slidesPerGroup: 1
                },
                1025: {
                    width: 227,
                    spaceBetween: 26,
                    slidesPerGroup: 5
                },
            }}
            centeredSlides={true}
            navigation={{
                prevEl: '.news-section .swiper-button-prev',
                nextEl: '.news-section .swiper-button-next',
            }}>
            {
                items?.map((item, index) => {
                    return <SwiperSlide key={index}>
                        <Link to={item.link} target='_blank'>
                            <div className="thumb-wrap">
                                <img src={item.fileUrl} alt="" />
                            </div>
                            <p className="title fs-16 max-line-2" style={{ marginTop: 12 }}>{item.title}</p>
                        </Link>
                    </SwiperSlide>
                })
            }

        </Swiper>
        <div className="swiper-button-prev pc-only"></div>
        <div className="swiper-button-next pc-only"></div>
    </div>
}