import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import api from 'src/api'
import { notificationState } from 'src/recoil/atom/notification'

export default function RequestAuth() {
  const setNotificationState = useSetRecoilState(notificationState)

  const { state } = useLocation()
  const { email, type } = state as any

  useEffect(() => {
    if (type === 'signin') {
      requestResend(false)
    }
  }, [])

  if (!email) {
    return (
      <div className="page">
        <div className="box-container">
          <div className="popup text-center" id="auth-mail-popup">
            <p className="fs-24 bold">잘못된 접근입니다</p>
          </div>
        </div>
      </div>
    )
  }

  const requestResend = (showPopup: boolean = true) => {
    api.account
      .sendVerifyEmail(email)
      .then(() => {
        if (showPopup) {
          setNotificationState({
            type: 'check',
            message: '전송 되었습니다',
          })
        }
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  return (
    <div className="page">
      <div className="box-container">
        <div className="popup text-center" id="auth-mail-popup">
          <p className="fs-24 bold">
            <span className="color-primary">{(state as any)?.email}</span>(으)로 인증 메일을 보냈어요
          </p>
          <p className="mt-30 fs-12">
            인증메일을 받지 못하셨나요?
            <br />
            스팸 편지함 확인 또는{' '}
            <span
              className="link bold"
              onClick={() => {
                requestResend(true)
              }}
            >
              인증 메일 재전송
            </span>
          </p>
          <hr className="hr mt-30" />
          <p className="mt-30 fs-12">
            다른 어려움이 있으신가요?{' '}
            <a className="link bold" target="_blank" href="https://forms.gle/mZh4qxDYBTsyY5ax9">
              바인더스 고객지원 센터
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
