import { useCallback } from "react";
import Arrow from "../../icons/Arrow";
import DotDotDot from "../../icons/DotDotDot";
import Image from "../../icons/Image";
import Edit from '../../icons/Edit';
import TrashCan from "../../icons/TrashCan";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  open: boolean;
  onOpenClick: () => void;
  onEditClick?: () => void;
  onVideoClick?: () => void;
  onDeleteClick: () => void;
  onMoveClick: (direction: "up" | "down") => void;
  editIconType?: "image" | "video"
  isFirst: boolean;
  isLast: boolean;
};

export default function BlockOption({
  className,
  open,
  onOpenClick,
  onEditClick,
  onDeleteClick,
  onMoveClick,
  editIconType = 'image',
  isFirst,
  isLast,
}: Props) {
  const handleOpenClick = useCallback(() => {
    onOpenClick();
  }, [onOpenClick]);

  const handleUpClick = useCallback(() => {
    onMoveClick("up");
  }, [onMoveClick]);

  const handleDownClick = useCallback(() => {
    onMoveClick("down");
  }, [onMoveClick]);

  const handleEditClick = useCallback(() => {
    onEditClick();
  }, [onEditClick]);

  const handleDeleteClick = useCallback(() => {
    onDeleteClick();
  }, [onDeleteClick]);


  return (
    <div className={[styles.menu, className].filter((item) => item).join(" ")}>
      {open ? (
        <>
          <button className={styles.delete} onClick={handleDeleteClick}>
            <TrashCan />
            delete
          </button>
          {
            onEditClick &&
            <button className={styles.edit} onClick={handleEditClick}>
              {editIconType === 'image' ? <Image /> : <Edit />}
            </button>
          }
          <button
            className={styles.up}
            disabled={isFirst}
            onClick={handleUpClick}
          >
            <Arrow direction="up" />
            up
          </button>
          <button
            className={styles.down}
            disabled={isLast}
            onClick={handleDownClick}
          >
            <Arrow direction="down" />
            down
          </button>
        </>
      ) : (
        <button className={styles.open} onClick={handleOpenClick}>
          <DotDotDot />
          open
        </button>
      )}
    </div>
  );
}
