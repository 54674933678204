import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import authState from 'src/recoil/atom/auth'
import MyCompanyProfileImg from './MyCompanyProfileImg'

export default function StudioProfileCard({ onClick }: { onClick?: () => void }) {
  const [{ account }] = useRecoilState(authState)
  if (!account || !account.company) {
    return <></>
  }

  return (
    <div className="user-profile-area sm">
      <div
        onClick={() => {
          onClick?.()
        }}
      >
        <MyCompanyProfileImg />
      </div>

      <div>
        <p className="fs-14 bold">
          {account.company.activeStatus === 'Disable' && (
            <div style={{ marginBottom: 6 }}>
              <span className="badge">비활성화</span>
            </div>
          )}
          <Link to={'/studio/profile'} onClick={onClick}>
            {account.company.name}
          </Link>
        </p>
        <p className="fs-12 bold color-primary" style={{ marginTop: 6 }}>
          <Link to={'/studio/profile'} className="link" onClick={onClick}>
            스튜디오 가기 ＞
          </Link>
        </p>
      </div>
    </div>
  )
}
