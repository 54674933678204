import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ImageBlockInput from '../editorBlockInputs/Image'
import ImageCaptionBlockInput from '../editorBlockInputs/ImageCaption'
import ImageLinkBlock from '../editorBlockInputs/ImageLink'
import TextEditorBlock from '../editorBlockInputs/Text'
import TextLinkBlock from '../editorBlockInputs/TextLink'
import TitleEditorBlock from '../editorBlockInputs/Title'
import VideoEditorBlock from '../editorBlockInputs/Video'
import { EditorBlockProps } from '../types'
import styles from './index.module.scss'
import Menu from './Menu'

export default function EditorBlock({
  menuOpened,
  onMenuButtonClick,
  onDeleteClick,
  onMoveClick,
  isFirst,
  isLast,
  ...props
}: EditorBlockProps) {

  const inputRef = useRef(null)
  const [isEdit, setIsEdit] = useState(false)

  let editType = null
  const blockType = String(props.block.type)
  if (['image', 'imageCaption'].includes(blockType) || isEdit) {
    editType = 'image'
  }
  else if (['video', 'textLink', 'imageLink'].includes(blockType)) {
    editType = 'edit'
  }

  const InputComponent = useMemo(() => {

    switch (props.block.type) {
      case 'title':
        return TitleEditorBlock
      case 'text':
        return TextEditorBlock
      case 'video':
        return VideoEditorBlock
      case 'imageCaption':
        return ImageCaptionBlockInput
      case 'image':
        return ImageBlockInput
      case 'textLink':
        return TextLinkBlock
      case 'imageLink':
        return ImageLinkBlock
    }
  }, [props.block.type])

  useEffect(() => {
    setIsEdit(false)
  }, [props.block])

  const handleMenuClick = useCallback(() => {
    onMenuButtonClick(props.block)
  }, [onMenuButtonClick, props.block])

  const handleDeleteClick = useCallback(() => {
    onDeleteClick(props.block)
  }, [onDeleteClick, props.block])

  const handleMoveClick = useCallback(
    (direction: 'up' | 'down') => {
      onMoveClick(props.block, direction)
    },
    [onMoveClick, props.block]
  )

  let handleEditClick = useCallback(() => {
    inputRef.current.startEdit?.()

    if (blockType === 'imageLink') {
      setIsEdit(true)
    }
  }, [props.block])


  return (
    <section className={styles.editorBlock}>
      <InputComponent ref={inputRef} menuOpened={menuOpened} {...props} />
      <Menu
        className={styles.menu}
        open={menuOpened}
        onOpenClick={handleMenuClick}
        onDeleteClick={handleDeleteClick}
        onMoveClick={handleMoveClick}
        onEditClick={editType !== null ? handleEditClick : null}
        editIconType={editType}
        isFirst={isFirst}
        isLast={isLast}
      />
    </section>
  )
}
