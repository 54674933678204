import { useEffect, useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import api from 'src/api'
import { Tag } from 'src/api/types/reference'
import { notificationState } from 'src/recoil/atom/notification'

type Props = {
  maxCount: number
  showMaxPopup: () => void
  tags?: Tag[]
  onChange: (tags: Tag[]) => void
  onClose: () => void
}

export default function ReferenceEditTagSearchLayer({ maxCount, tags, showMaxPopup, onChange, onClose }: Props) {
  const setNotificationState = useSetRecoilState(notificationState)

  const [searchKeyword, setSearchKeyword] = useState('')

  const [selectedTags, setSelectedTags] = useState(tags)
  const [searchTags, setSearchTags] = useState([])

  const [loading, setLoading] = useState(false)

  const inputRef = useRef(null)
  const referenceTagRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus()
    }, 100)
  }, [])

  useEffect(() => {
    onChange(selectedTags)
  }, [selectedTags])

  const timerId = useRef(null)
  let request = null

  const changeSearchkeyword = (e: any) => {
    const searchKeyword = e.target.value

    const searchComps = searchKeyword?.split(',')
    if (searchComps?.length > 1) {
      addSearchKeyword(searchComps[0])
      setSearchKeyword('')
    } else {
      setSearchKeyword(searchKeyword)
      fetchSearchTags(searchKeyword)
    }
  }

  const fetchSearchTags = (searchKeyword: string) => {
    try {
      request?.abort()

      clearTimeout(timerId.current)
      timerId.current = setTimeout(() => {
        request = api.tag
          .getList(searchKeyword, 0)
          .then((response) => {
            const tags = response.data.content

            let items = []
            for (const tag of tags) {
              let isFound = false
              for (const exceptTag of selectedTags) {
                if (tag.id === exceptTag.id) {
                  isFound = true
                }
              }

              if (!isFound) {
                items.push(tag)
              }
            }

            setSearchTags(items)
          })
          .catch((e) => {
            console.log(e)
            setSearchTags([])
          })
      })
    } catch (e) {
      console.log(e)
    }
  }

  const addSearchKeyword = (searchKeyword: string) => {
    setLoading(true)
    api.tag
      .addTag(encodeURIComponent(searchKeyword))
      .then((res) => {
        addTag(res.data)
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: e.response.data?.message || '네트워크 에러',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const addTag = (tag: Tag) => {
    if (maxCount <= selectedTags.length) {
      showMaxPopup()
      return
    }

    const items = selectedTags.filter((item) => item.id === tag.id)
    if (items.length > 0) {
      return
    }

    setSelectedTags([...selectedTags, tag])
    setSearchKeyword('')
  }

  const deleteTag = (tag: Tag) => {
    const items = tags.filter((item) => item.id !== tag.id)
    setSelectedTags(items)
  }

  const renderTagList = () => {
    if (selectedTags.length === 0) {
      return <></>
    }

    return (
      <div className="mt-10 tag-box">
        {selectedTags.map((tag) => {
          return (
            <div key={tag.id} className="btn btn-sm"
            onMouseEnter={(e) => {
              referenceTagRef.current = (new Date()).getTime();
              (e.target as any).classList.add('active')
            }}
            onMouseLeave={(e) => {
              const timeDiff = (new Date().getTime()) - referenceTagRef.current
              if (timeDiff > 100) {
                (e.target as any).classList.remove('active')
              }
            }}>
              {tag.name}
              <div
                className="delete-ico"
                onClick={(e) => {
                  if (window.innerWidth > 500) {
                    deleteTag(tag)
                    return
                  }

                  let classList = e.currentTarget.classList
                  if (classList.contains('active') && window.innerWidth < 500) {
                    deleteTag(tag)
                  } else {
                    classList.add('active')
                  }
                }}
              ></div>
            </div>
          )
        })}
      </div>
    )
  }

  const highlight = (innerHTML: string) => {
    var index = innerHTML.toLowerCase().indexOf(searchKeyword.toLowerCase())
    if (index >= 0) {
      innerHTML =
        innerHTML.substring(0, index) +
        "<span class='color-primary'>" +
        innerHTML.substring(index, index + searchKeyword.length) +
        '</span>' +
        innerHTML.substring(index + searchKeyword.length)
    }

    return innerHTML
  }

  const renderSearchList = () => {
    if (searchKeyword?.length === 0 || searchTags.length === 0) {
      return <></>
    }

    return (
      <div className="search-tag-area" style={{ marginTop: selectedTags?.length === 0 ? 24 : 0 }}>
        <ul className="search-tag-list">
          {searchTags.slice(0, 10).map((tag: Tag, index) => {
            return (
              <li
                className="fs-14"
                onClick={() => {
                  addTag(tag)
                }}
                key={index}
                dangerouslySetInnerHTML={{ __html: highlight(tag.name) }}
              ></li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div className="tag-search-layer mobile-full-page popup-wrap">
      <div className="popup" style={{height: '100%'}}>
        <div className="flex flex-row" style={{ margin: '0 6px' }}>
          <div className="tag-search-input w-100">
            <input
              type="text"
              className="input w-100"
              placeholder={`태그 추가 (최대20자)`}
              maxLength={20}
              value={searchKeyword || ''}
              ref={inputRef}
              onChange={changeSearchkeyword}
            />
          </div>
          <button
            className="ml-10 btn btn-primary btn-md"
            disabled={!searchKeyword || searchKeyword?.length === 0}
            onClick={() => {
              addSearchKeyword(searchKeyword)
            }}
          >
            <i className="ico ico-plus-wh ico-sm"></i>
            추가
          </button>
        </div>
        {renderTagList()}
        {renderSearchList()}
        <button className="popup-close close-btn show" onClick={onClose} />
        <div className="btn-wrap page-foot-btn">
          <button
            className="btn btn-primary"
            onClick={() => {
              onClose()
            }}
          >
            완료
          </button>
        </div>
      </div>
    </div>
  )
}
