import { useEffect, useRef, useState } from 'react'
import api from 'src/api'
import { Tag } from 'src/api/types/reference'

type Props = {
  onFocus?: () => void
  onChange: (tag: Tag) => void
  changeSearchKeyword: (searchKeyword: string) => void
  addSearchKeyword?: (searchKeyword: string) => void
  searchKeyword: string
  exceptTags?: Tag[]
}

export default function TagSearchInput({
  onFocus,
  onChange,
  changeSearchKeyword,
  addSearchKeyword,
  searchKeyword,
  exceptTags = [],
}: Props) {
  const [tags, setTags] = useState<Tag[]>([])

  const timerId = useRef(null)
  let request = null

  useEffect(() => { }, [searchKeyword])

  const changeSearchkeyword = (e: any) => {
    const searchKeyword = e.target.value

    const searchComps = searchKeyword?.split(',')
    if (searchComps?.length > 1) {
      addSearchKeyword(searchComps[0])
      changeSearchKeyword(null)
    } else {
      changeSearchKeyword(searchKeyword)
      searchTags(searchKeyword)
    }
  }

  const searchTags = (searchKeyword: string) => {
    try {
      request?.abort()

      clearTimeout(timerId.current)
      timerId.current = setTimeout(() => {
        request = api.tag
          .getList(searchKeyword, 0)
          .then((response) => {
            const tags = response.data.content

            let items = []
            for (const tag of tags) {
              let isFound = false
              for (const exceptTag of exceptTags) {
                if (tag.id === exceptTag.id) {
                  isFound = true
                }
              }

              if (!isFound) {
                items.push(tag)
              }
            }

            setTags(items)
          })
          .catch((e) => {
            console.log(e)
            setTags([])
          })
      }, 400)
    } catch (e) {
      console.log(e)
    }
  }

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const onHide = () => {
    setTags([])
  }

  const ref = useOutsideClick(onHide)

  const renderResultBox = () => {
    if (searchKeyword?.length === 0 || tags.length === 0) {
      return <></>
    }

    return (
      <div className="tag-search-result-container" ref={ref}>
        <p className="fs-12 bold">추천 태그</p>
        <div className="button-wrap">
          {tags.slice(0, 10).map((tag: Tag, index) => {
            return (
              <button
                className="btn btn-sm"
                onClick={() => {
                  onChange(tag)
                }}
                key={index}
              >
                {tag.name}
              </button>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="tag-search-input w-100">
      <input
        type="text"
        className="input w-100"
        placeholder={`태그 추가 (최대20자)`}
        value={searchKeyword || ''}
        maxLength={20}
        onChange={changeSearchkeyword}
        onFocus={() => {
          onFocus?.()
        }}
      />
      {renderResultBox()}
    </div>
  )
}
