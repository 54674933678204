import { useSetRecoilState } from 'recoil'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'

export default function Index() {
  const setPopupState = useSetRecoilState(popupState)
  const email = 'ksesans@gmail.com'

  return (
    <div className="popup-wrap">
      <div className="popup customer-popup">
        <p className="fs-24 bold text-center">바인더스 고객지원 센터</p>
        <div className="mt-30 flex flex-row flex-center bg-color-grey-50">
          <img className="ico ico-notion" src="/static/img/sns/notion.svg" />
          <span className="w-100">{email}</span>
          <button
            className="btn btn-outline btn-sm"
            onClick={() => {
              const t = document.createElement('textarea')
              document.body.appendChild(t)
              t.value = email
              t.select()
              document.execCommand('copy')
              document.body.removeChild(t)
            }}
          >
            복사
          </button>
        </div>
        <div className="mt-30 flex flex-row flex-center bg-color-grey-50" style={{ marginTop: 8 }}>
          <img className="ico ico-notion" src="/static/img/sns/notion.svg" />
          <a href="https://forms.gle/mZh4qxDYBTsyY5ax9" className="w-100 color-text-regular" target="_blank">
            https://forms.gle/mZh4qxDYBTsyY5ax9
          </a>
        </div>
        <button
          className="mt-30 w-100 btn btn-outline-primary"
          onClick={() => {
            setPopupState({ popupType: MainPopupType.None })
          }}
        >
          닫기
        </button>
      </div>
    </div>
  )
}
