import request from 'src/utils/Request'

const jsonApi = {
  // JSON 가져오기
  getJson: () => {
    return request.get('/v1/json')
  },
  // 텍스트 가져오기
  getText: () => {
    return request.get('/v1/json/text')
  },
}

export default jsonApi
