import { atom, RecoilState } from 'recoil'

export interface INotificationState {
  message: string
  type: string
}

export const notificationState: RecoilState<INotificationState> = atom({
  key: 'notificationState',
  default: null,
})
