import { atom } from 'recoil'
import { Account } from 'src/api/types/account'

const authState = atom<{
  waiting: boolean
  account: Account
}>({
  key: 'authState',
  default: {
    waiting: true,
    account: null,
  },
})

export default authState
