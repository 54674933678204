import { atom, RecoilState } from 'recoil'

export interface IPushMessageState {
  id: number
}

export const pushMessageState: RecoilState<IPushMessageState> = atom({
  key: 'pushMessageState',
  default: null,
})
