import { useRecoilValue } from 'recoil'
import authState from 'src/recoil/atom/auth'
import ConfirmPopup from './Confirm'

type Props = {
  onHide: () => void
  maxCount: number
}
export default function StudioMaxPortfolioTagConfirm({ onHide, maxCount }: Props) {
  const { account } = useRecoilValue(authState)

  return (
    <>
      {account.company.plan.plan.name === 'starter' && (
        <ConfirmPopup
          showCloseBtn={true}
          title={`태그를 <span class="color-primary">${maxCount}</span>개까지 등록할 수 있어요`}
          body={`더 많은 태그를 등록하기 위해,<br/>프로페셔널 플랜으로 업그레이드해 보세요.<br/>
                  <span class="color-danger">23.05.31까지 프로페셔널 플랜이 무료</span>예요!`}
          okText="플랜관리"
          cancelText="이어서 작성"
          okClassName="btn"
          cancelClassName="btn btn-primary"
          onOk={() => {
            window.open('/studio/plan')
            onHide()
          }}
          onCancel={() => {
            onHide()
          }}
        />
      )}
      {account.company.plan.plan.name === 'professional' && (
        <ConfirmPopup
          showCloseBtn={true}
          title={`태그를 <span class="color-primary">${maxCount}</span>개까지 등록할 수 있어요`}
          body="조금만 기다려 주세요!<br/>더 풍부한 서비스로 보답할게요."
          okText="서비스 업데이트 알림 받기"
          onOk={() => {
            window.open(process.env.REACT_APP_SERVICE_UPDATE_URL)
            onHide()
          }}
          onCancel={() => {
            onHide()
          }}
        />
      )}
    </>
  )
}
