import { useEffect, useRef, useState } from 'react'
import { Category } from 'src/api/types/reference'
import StudioMaxPortfolioCategoryConfirm from '../Popup/StudioMaxCategoryConfirm'
import CategorySelect from './ReferenceEditCategorySelect'

type Props = {
  defaultCategories: Category[]
  defaultRepresentativeCategoryId?: number
  onChange: (categories: Category[]) => void
  onChangeRepresentId: (categroyId: number) => void
  maxCount: number
}
export default function ReferenceEditCategory({
  defaultCategories,
  defaultRepresentativeCategoryId = null,
  maxCount = 5,
  onChange,
  onChangeRepresentId,
}: Props) {
  const [selectedCategories, setSelectedCategories] = useState<Category[]>(defaultCategories)
  const [currentCategory, setCurrentCategory] = useState<Category>(null)
  const [categorySelectLabel, setCategorySelectLabel] = useState(null)

  const [showMaxPopup, setShowMaxPopup] = useState(false)
  const [representativeCategoryId, setRepresentativeCategoryId] = useState(defaultRepresentativeCategoryId)

  useEffect(() => {
    onChange(selectedCategories)

    const isFound = selectedCategories.find((category) => category.id === representativeCategoryId)
    if (!isFound && selectedCategories?.length > 0) {
      setRepresentativeCategoryId(selectedCategories[0].id)
    }
  }, [selectedCategories])

  useEffect(() => {
    onChangeRepresentId(representativeCategoryId)
  }, [representativeCategoryId])

  return (
    <>
      {selectedCategories.length > 0 && (
        <ul className="category-list">
          {selectedCategories.map((item) => {
            return (
              <li
                key={item.id}
                className={representativeCategoryId === item.id ? 'active' : ''}
                onClick={() => {
                  setRepresentativeCategoryId(item.id)
                }}
              >
                <div className="radio-area">
                  <i className="ico"></i>
                  <span className="badge">대표</span>
                  <span>{`${item.parent.name} > ${item.name}`}</span>
                </div>
                <button
                  className="delete-btn"
                  onClick={(e) => {
                    e.stopPropagation()

                    const categories = selectedCategories.filter((temp) => item.id !== temp.id)
                    setSelectedCategories(categories)
                  }}
                >
                  <i className="ico ico-trash"></i>
                </button>
              </li>
            )
          })}
        </ul>
      )}
      <div className="mt-20 flex flex-row">
        <div className="category-select w-100" style={{ position: 'relative' }}>
          <CategorySelect
            label={categorySelectLabel}
            value={currentCategory}
            maxCount={maxCount}
            onChange={(category) => {
              setCategorySelectLabel(category.parent.name + ' > ' + category.name)
              setCurrentCategory(category)
            }}
          />
        </div>

        <button
          className="ml-10 btn btn-primary btn-md"
          disabled={currentCategory === null}
          onClick={() => {
            setCategorySelectLabel(null)
            if (maxCount <= selectedCategories.length) {
              setShowMaxPopup(true)
              return
            }

            const items = selectedCategories.filter((item) => item.id === currentCategory.id)
            if (items.length > 0) {
              return
            }

            if (selectedCategories?.length === 0) {
              setRepresentativeCategoryId(currentCategory.id)
            }

            const categories = [...selectedCategories, currentCategory]
            setSelectedCategories(categories)
            setCurrentCategory(null)
          }}
        >
          <i className="ico ico-plus-wh ico-sm"></i>
          추가
        </button>
        {showMaxPopup && (
          <StudioMaxPortfolioCategoryConfirm
            onHide={() => {
              setShowMaxPopup(false)
            }}
          />
        )}
      </div>
    </>
  )
}
