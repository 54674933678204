export default function TrashCan() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.5 8H12.5C11.0413 8 9.64236 8.57946 8.61091 9.61091C7.57946 10.6424 7 12.0413 7 13.5V35.5C7 36.9587 7.57946 38.3576 8.61091 39.3891C9.64236 40.4205 11.0413 41 12.5 41C21.0915 41 25.9085 41 34.5 41C35.9587 41 37.3576 40.4205 38.3891 39.3891C39.4205 38.3576 40 36.9587 40 35.5V13.5C40 12.0413 39.4205 10.6424 38.3891 9.61091C37.3576 8.57946 35.9587 8 34.5 8Z"
        fill="currentColor"
      />
      <path
        d="M34.5 8H12.5C11.0413 8 9.64236 8.57946 8.61091 9.61091C7.57946 10.6424 7 12.0413 7 13.5V35.5C7 36.9587 7.57946 38.3576 8.61091 39.3891C9.64236 40.4205 11.0413 41 12.5 41C21.0915 41 25.9085 41 34.5 41C35.9587 41 37.3576 40.4205 38.3891 39.3891C39.4205 38.3576 40 36.9587 40 35.5V13.5C40 12.0413 39.4205 10.6424 38.3891 9.61091C37.3576 8.57946 35.9587 8 34.5 8Z"
        fill="currentColor"
      />
      <path
        d="M24.715 21.9201L20.9139 25.7211C20.734 25.8996 20.6328 26.1425 20.6328 26.3958C20.6328 26.6492 20.734 26.8921 20.9139 27.0705C21.0923 27.2504 21.3352 27.3516 21.5886 27.3516C21.842 27.3516 22.0848 27.2504 22.2633 27.0705L26.0643 23.2695C26.437 22.8968 26.437 22.2927 26.0643 21.9201C25.6917 21.5475 25.0876 21.5475 24.715 21.9201Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M23.757 29.6273L22.5407 30.8342C21.127 32.2908 18.8452 32.4544 17.2382 31.2143C16.4086 30.5306 15.9072 29.5276 15.8583 28.4537C15.8094 27.3798 16.2176 26.3354 16.9816 25.5792L18.331 24.2203C18.5109 24.0419 18.6121 23.799 18.6121 23.5456C18.6121 23.2922 18.5109 23.0494 18.331 22.8709C18.1526 22.691 17.9097 22.5898 17.6563 22.5898C17.403 22.5898 17.1601 22.691 16.9816 22.8709L15.7748 24.0873C13.6722 26.1234 13.4001 29.4009 15.1381 31.7559C16.1471 33.0648 17.6698 33.8772 19.3189 33.9863C20.968 34.0954 22.5844 33.4907 23.757 32.3261L25.1064 30.9767C25.479 30.6041 25.479 29.9999 25.1064 29.6273C24.7338 29.2547 24.1297 29.2547 23.757 29.6273Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M30.7685 16.1534C28.3968 14.3969 25.0899 14.6735 23.0429 16.7996L22.0166 17.8449C21.7794 18.0173 21.6264 18.2823 21.5956 18.5739C21.5648 18.8656 21.6591 19.1566 21.855 19.3748C22.0335 19.5547 22.2764 19.6559 22.5297 19.6559C22.7831 19.6559 23.026 19.5547 23.2044 19.3748L24.4398 18.13C25.8463 16.6662 28.1315 16.5021 29.7327 17.7498C30.5686 18.4337 31.0742 19.4408 31.1232 20.5196C31.1721 21.5985 30.7599 22.6472 29.9893 23.4039L28.6399 24.7628C28.46 24.9412 28.3588 25.1841 28.3588 25.4375C28.3588 25.6909 28.46 25.9338 28.6399 26.1122C28.8184 26.2921 29.0612 26.3933 29.3146 26.3933C29.568 26.3933 29.8109 26.2921 29.9893 26.1122L31.3387 24.7628C32.5 23.5904 33.1026 21.9762 32.9935 20.3296C32.8845 18.6831 32.0743 17.1624 30.7685 16.1534Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  )
}
