export default function DotDotDot() {
  return (
    <svg width="21" height="4" viewBox="0 0 21 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.42811 1.71405C3.42811 2.05306 3.32758 2.38445 3.13924 2.66633C2.9509 2.94821 2.6832 3.1679 2.36999 3.29763C2.0568 3.42737 1.71215 3.46131 1.37966 3.39518C1.04717 3.32903 0.741757 3.16579 0.502029 2.92608C0.262319 2.68635 0.0990723 2.38094 0.032927 2.04845C-0.0332184 1.71596 0.000737039 1.37131 0.130474 1.05812C0.26021 0.74491 0.479901 0.477227 0.761777 0.288869C1.04365 0.100529 1.37505 0 1.71405 0C2.16865 0 2.60462 0.180593 2.92608 0.502029C3.24751 0.823483 3.42811 1.25945 3.42811 1.71405Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9984 1.71405C11.9984 2.05306 11.8979 2.38445 11.7096 2.66633C11.5212 2.94821 11.2535 3.1679 10.9403 3.29763C10.6271 3.42737 10.2825 3.46131 9.94997 3.39518C9.61748 3.32903 9.31207 3.16579 9.07234 2.92608C8.83263 2.68635 8.66938 2.38094 8.60324 2.04845C8.53709 1.71596 8.57105 1.37131 8.70079 1.05812C8.83052 0.74491 9.05021 0.477227 9.33209 0.288869C9.61397 0.100529 9.94536 0 10.2844 0C10.739 0 11.1749 0.180593 11.4964 0.502029C11.8178 0.823483 11.9984 1.25945 11.9984 1.71405Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5687 1.71405C20.5687 2.05306 20.4682 2.38445 20.2799 2.66633C20.0915 2.94821 19.8238 3.1679 19.5106 3.29763C19.1974 3.42737 18.8528 3.46131 18.5203 3.39518C18.1878 3.32903 17.8824 3.16579 17.6427 2.92608C17.4029 2.68635 17.2397 2.38094 17.1736 2.04845C17.1074 1.71596 17.1414 1.37131 17.2711 1.05812C17.4008 0.74491 17.6205 0.477227 17.9024 0.288869C18.1843 0.100529 18.5157 0 18.8547 0C19.3093 0 19.7452 0.180593 20.0667 0.502029C20.3881 0.823483 20.5687 1.25945 20.5687 1.71405Z"
        fill="currentColor"
      />
    </svg>
  )
}
