import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import { getEmailValidationMsg } from 'src/utils/Vaildiator'

export default function SignIn() {
  const location = useLocation()
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies([
    'userEmail',
    'userPassword',
    'needGuide1',
    'needGuide2',
    'bindus_user_id',
  ])

  const setPopupState = useSetRecoilState(popupState)
  const setAuthState = useSetRecoilState(authState)

  const [email, setEmail] = useState(cookies.userEmail)
  const [password, setPassword] = useState(cookies.userPassword)

  const [emailErrMsg, setEmailErrMsg] = useState(null)
  const [passwordErrMsg, setPasswordErrMsg] = useState(null)

  const [canSubmit, setCanSubmit] = useState(false)
  const [isRemember, setIsRemember] = useState(false)

  useEffect(() => {
    if (cookies.userEmail && cookies.userPassword) {
      setEmail(cookies.userEmail)
      setPassword(cookies.userPassword)
      setIsRemember(true)
      setCanSubmit(true)
    }

    document.querySelector('body').style.overflow = 'hidden'
    return () => {
      document.querySelector('body').style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    const emailErrMsg = getEmailValidationMsg(email)
    const passwordErrMsg = password?.length === 0 ? '비밀번호를 입력해 주세요' : null
    setEmailErrMsg(emailErrMsg)
    setPasswordErrMsg(passwordErrMsg)
    setCanSubmit(!emailErrMsg && !passwordErrMsg)
  }, [email, password])

  const changeEmail = (e: any) => {
    const email = e.target.value
    setEmail(email)
  }

  const changePassword = (e: any) => {
    const password = e.target.value
    setPassword(password)
  }

  const login = (e: any) => {
    setCanSubmit(false)

    api.account
      .login(email, password)
      .then((response) => {
        try {
          const account = response.data
          setCookie('bindus_user_id', account.id)
          if (account.verifiedAt === null) {
            navigate('/signup/requestAuth', { state: { email, type: 'signin' } })
            return
          }

          setAuthState({
            account: account,
            waiting: false,
          })

          if (!account.email || !account.name || !account.birthday || account.categories.length === 0) {
            navigate('/signup/complete')
            return
          }

          if (account.activeStatus === 'Disable') {
            navigate('/my/profile')
            return
          }

          //window.location.reload()
        } catch (e) {
          removeCookie('bindus_user_id')
          setPasswordErrMsg(`내부 에러: 관리자에게 문의해주세요`)
        } finally {
          if (isRemember) {
            setCookie('userEmail', email)
            setCookie('userPassword', password)
          } else {
            removeCookie('userEmail')
            removeCookie('userPassword')
          }

          closePopup()
        }
      })
      .catch((err) => {
        const { status } = err.response
        if (status === 400) {
          setEmailErrMsg('')
          setPasswordErrMsg('이메일 혹은 비밀번호가 맞지 않습니다. 다시 확인해 주세요.')
        } else {
          setEmailErrMsg('')
          setPasswordErrMsg(`내부 에러[${status}]: 관리자에게 문의해주세요`)
        }
      })
      .finally(() => {
        setCanSubmit(true)
      })
  }

  const closePopup = () => {
    // const isBlock = location.pathname.indexOf('/c/') !== -1 || location.pathname.indexOf('/u/') !== -1
    // if (isBlock) {
    //   navigate('/')
    // }
    if (!isRemember) {
      removeCookie('userEmail')
      removeCookie('userPassword')
    }

    setPopupState({ popupType: MainPopupType.None })
  }

  const resetPassword = () => {
    setPopupState({ popupType: MainPopupType.RequestResetPassword })
  }

  const signUp = () => {
    setPopupState({ popupType: MainPopupType.SignUp })
  }

  const renderEmailInput = () => {
    let inputClassNames = ['mt-20', 'input', 'input-lg', 'w-100']
    if (emailErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (email?.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="email"
          className={inputClassNames.join(' ')}
          placeholder="이메일"
          required
          value={email}
          onChange={changeEmail}
          autoComplete="new-password"
        />
        {emailErrMsg && emailErrMsg.length > 0 && <p className="mt-10 color-danger">{emailErrMsg}</p>}
      </>
    )
  }

  const renderPasswordInput = () => {
    let inputClassNames = ['mt-10', 'input', 'input-lg', 'w-100']
    if (passwordErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (password?.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="password"
          className={inputClassNames.join(' ')}
          placeholder="비밀번호"
          required
          value={password}
          onChange={changePassword}
          autoComplete="new-password"
        />
        {passwordErrMsg && <p className="mt-10 color-danger">{passwordErrMsg}</p>}
      </>
    )
  }

  return (
    <div className="popup-wrap blur mobile-page main-popup-wrap">
      <div className="popup text-center" id="login-popup">
        <button className="popup-close close-btn" onClick={closePopup} />
        <p className="fs-24 bold">로그인</p>
        <form className="popup-body">
          {renderEmailInput()}
          {renderPasswordInput()}
          <div className="checkbox-wrap" style={{ marginTop: 16 }}>
            <input
              type="checkbox"
              id="remember-login-checkbox"
              className="checkbox"
              checked={isRemember}
              onChange={(e) => {
                setIsRemember(e.target.checked)
              }}
            />
            <label className="fs-12" htmlFor="remember-login-checkbox">
              로그인 유지
            </label>
          </div>
          <button className="mt-30 btn btn-primary w-100" disabled={!canSubmit} onClick={login}>
            로그인
          </button>
        </form>
        <hr className="mt-20 hr" />
        <a className="mt-20 btn kakao-btn w-100" href={process.env.REACT_APP_KAKAO_LOGIN}>
          <i className="ico ico-kakao" />
          카카오로 시작하기
        </a>
        <p className="mt-20 desc">
          비밀번호를 잊으셨나요?
          <span className="ml-10 link bold" onClick={resetPassword}>
            비밀번호 재설정
          </span>
        </p>
        <p className="desc">
          바인더스가 처음이신가요?
          <span className="ml-10 link bold" onClick={signUp}>
            회원가입
          </span>
        </p>
      </div>
    </div>
  )
}
