export default function Video() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.75 4H6.75C6.02065 4 5.32118 4.28973 4.80546 4.80546C4.28973 5.32118 4 6.02065 4 6.75V17.75C4 18.4793 4.28973 19.1788 4.80546 19.6945C5.32118 20.2103 6.02065 20.5 6.75 20.5C11.0458 20.5 13.4542 20.5 17.75 20.5C18.4793 20.5 19.1788 20.2103 19.6945 19.6945C20.2103 19.1788 20.5 18.4793 20.5 17.75V6.75C20.5 6.02065 20.2103 5.32118 19.6945 4.80546C19.1788 4.28973 18.4793 4 17.75 4Z"
        fill="currentColor"
      />
      <path
        d="M15.5594 12.7948L10.5883 15.6792C10.1664 15.9237 9.625 15.6276 9.625 15.1346V9.3659C9.625 8.87371 10.1656 8.57683 10.5883 8.82215L15.5594 11.7065C15.6553 11.7613 15.7351 11.8405 15.7906 11.9361C15.8461 12.0316 15.8753 12.1402 15.8753 12.2507C15.8753 12.3612 15.8461 12.4697 15.7906 12.5653C15.7351 12.6608 15.6553 12.74 15.5594 12.7948Z"
        fill="white"
      />
    </svg>
  )
}
