import { ChangeEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import api from 'src/api'
import Image from 'src/components/Common/Image'
import buttonStyles from '../../common/button.module.scss'
import ImageIcon from '../../icons/Image'
import LinkIcon from '../../icons/Link'
import { EditorBlockInputProps, ImageLinkBlock } from '../../types'
import styles from './index.module.scss'

const ImageLinkBlockInput = forwardRef(({ onBlockChange, block: blockProps }: EditorBlockInputProps, ref) => {
  const block = blockProps as unknown as {
    id: string
  } & ImageLinkBlock

  const fileInput = useRef<HTMLInputElement>(null)
  const [image, setImage] = useState(block?.value?.image)
  const [linkUrl, setLinkUrl] = useState(block?.value?.linkUrl)
  const [isInserted, setIsInserted] = useState(block?.value?.image ? true : false)

  useImperativeHandle(ref, () => ({
    startEdit() {
      if (isInserted) {
        setIsInserted(false)
      }
      else {
        handleFileInputClick()
      }
    }
  }));

  const handleFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return
      }

      const uploadFile = e.target.files[0]

      const formData = new FormData()
      formData.append('image', uploadFile)

      api.image.upload(uploadFile).then((response) => {
        setImage(response.data)
      })
    },
    [onBlockChange, block.id]
  )

  const handleFileInputClick = useCallback(() => {
    if (fileInput) {
      fileInput.current?.click()
    }
  }, [])

  const insert = () => {
    setIsInserted(true)
    onBlockChange?.({
      id: block.id,
      type: 'imageLink',
      value: {
        image: image,
        linkUrl: linkUrl,
      },
    })
  }

  if (isInserted) {
    return (
      <div className={styles.imageBlock}>
        <div
          className={styles.imageWrapper}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            let url = linkUrl
            if (url?.indexOf('http') === -1) {
              url = 'http://' + url
            }
            window.open(url)
          }}
        >
          <Image img={image} />
        </div>
      </div>
    )
  }

  if (!image) {
    return (
      <div className={styles.imageBlock}>
        <div className={styles.uploadGuide}>
          <p>
            이미지를 화면 가로에 맞춰 채우려면 <br />
            너비가 최소 1400픽셀 이상인 이미지를 업로드해 주세요.
          </p>
          <input ref={fileInput} type="file" onChange={handleFileUpload} accept="image/png, image/jpeg" />
          <button className={buttonStyles.button} onClick={handleFileInputClick}>
            <ImageIcon />
            이미지 업로드
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.imageBlock}>
      <div className={styles.imageWrapper}>
        <input ref={fileInput} type="file" onChange={handleFileUpload} accept="image/png, image/jpeg" style={{ display: 'none' }} />
        <Image img={image} />
      </div>
      <div className={styles.linkInputContainer}>
        <input
          type="input"
          className="input"
          value={linkUrl}
          onChange={(e) => {
            setLinkUrl(e.target.value)
          }}
        />
        <button className="btn btn-md" disabled={!linkUrl || linkUrl?.length === 0} onClick={insert}>
          <LinkIcon />
          링크 삽입
        </button>
      </div>
    </div>
  )
})

export default ImageLinkBlockInput