import React, { ChangeEvent, useCallback, useMemo, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { v4 } from 'uuid'
import { EditorBlockInputProps, ImageComment, ImageCaptionBlock } from '../../types'
import Comment from './Comment'
import styles from './index.module.scss'
import buttonStyles from '../../common/button.module.scss'
import Image from 'src/components/Common/Image'
import ImageIcon from '../../icons/Image'
import api from 'src/api'

type Prop = EditorBlockInputProps & {
  viewerMode?: boolean
}

const ImageCaptionBlockInput = forwardRef(({ onBlockChange, block: blockProps, viewerMode = false }: Prop, ref) => {

  const [openedCommentId, setOpenedCommentId] = useState<string | null>(null)

  const block = blockProps as unknown as {
    id: string
  } & ImageCaptionBlock

  const fileInput = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    startEdit() {
      handleFileInputClick()
    }
  }));

  const commentChangeHandlers = useMemo(() => {
    const value = block.value
    if (value === null || typeof value === 'string') {
      return []
    }

    return (
      value.comments?.map((comment, key) => {
        return (newComment: ImageComment) => {
          const newComments = [...(value.comments ?? [])]
          newComments[key] = newComment
          onBlockChange?.({
            ...block,
            type: 'imageCaption',
            value: {
              comments: newComments,
              imageSrc: value.imageSrc,
            },
          })
        }
      }) ?? []
    )
  }, [block, onBlockChange])

  const handleFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return
      }

      const uploadFile = e.target.files[0]

      // const blob = new Blob([uploadFile], { type: uploadFile.type })
      // const imageSrc = window.URL.createObjectURL(blob)

      const formData = new FormData()
      formData.append('image', uploadFile)

      api.image.upload(uploadFile).then((response) => {
        onBlockChange?.({
          id: block.id,
          type: 'imageCaption',
          value: {
            imageSrc: response.data,
            comments: [],
          },
        })
      })
    },
    [onBlockChange, block.id]
  )

  const handleImageWrapperClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (viewerMode) {
        return
      }
      const target = e.target as HTMLDivElement
      const rect = target.getBoundingClientRect()
      if (openedCommentId === null) {
        const newComment: ImageComment = {
          id: v4(),
          text: '',
          images: [],
          leftPercent: ((e.clientX - rect.left) * 100) / target.clientWidth,
          topPercent: ((e.clientY - rect.top) * 100) / target.clientHeight,
          editCompleted: false,
        }

        if (block.value.imageSrc !== null) {
          onBlockChange?.({
            ...block,
            value: {
              ...block.value,
              comments: [...(block.value.comments ?? []), newComment],
            },
          })
          setOpenedCommentId(newComment.id)
        }
      } else {
        setOpenedCommentId(null)
      }
    },
    [viewerMode, openedCommentId, block, onBlockChange]
  )

  const handleFileInputClick = useCallback(() => {
    if (fileInput) {
      fileInput.current?.click()
    }
  }, [])

  const handleCommentRemoveClick = useCallback(
    (comment: ImageComment) => {
      if (block?.value.comments) {
        const newBlock = {
          ...block,
        }
        newBlock.value.comments = block.value.comments.filter((item) => item !== comment)
        setOpenedCommentId(null)
        onBlockChange?.(newBlock)
      }
    },
    [block, onBlockChange]
  )

  const handleCommentOpen = useCallback(
    (id: string) => {
      if (openedCommentId !== id) {
        setOpenedCommentId(id)
      } else {
        setOpenedCommentId(null)
      }
    },
    [openedCommentId]
  )

  if (!block.value.imageSrc) {
    if (viewerMode) {
      return <p>이미지가 없습니다.</p>
    }
    return (
      <div className={styles.imageBlock}>
        <div className={styles.uploadGuide}>
          <strong>이미지 업로드 후 캡션을 추가하세요!</strong>
          <p>
            이미지를 화면 가로에 맞춰 채우려면 <br />
            너비가 최소 1400픽셀 이상인 이미지를 업로드해 주세요.
          </p>
          <input ref={fileInput} type="file" onChange={handleFileUpload} accept="image/png, image/jpeg" />
          <button className={buttonStyles.button} onClick={handleFileInputClick}>
            <ImageIcon />
            이미지 업로드
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className={[styles.imageBlock].join(' ')}>
      <div className={styles.imageWrapper}>
        <input ref={fileInput} type="file" onChange={handleFileUpload} accept="image/png, image/jpeg" style={{ display: 'none' }} />
        <Image img={block.value.imageSrc} />
        {!viewerMode && <div onClick={handleImageWrapperClick} className={styles.clickLayer} />}
        {block.value.comments.map((item, key) => (
          <Comment
            key={item.id}
            value={item}
            onChange={commentChangeHandlers[key]}
            onRemoveClick={handleCommentRemoveClick}
            isOpened={openedCommentId === item.id}
            onOpenRequest={handleCommentOpen}
            viewerMode={viewerMode}
          />
        ))}
      </div>
      {!viewerMode && <p className={styles.captionGuide}>캡션을 추가하려면 이미지를 누르세요.</p>}
    </div>
  )
});

export default ImageCaptionBlockInput