import { useState } from 'react'
import AdminUserSearchInput from '../Studio/AdminUserSearchInput'

type Props = {
  onHide: () => void
  onRequest: (userId: number) => void
}
export default function StudioSettingInvitePopup({ onHide, onRequest }: Props) {
  const [user, setuser] = useState(null)

  return (
    <div className="popup-wrap mobile-page">
      <div className="popup studio-invite-popup">
        <button className="popup-close close-btn" onClick={onHide} />
        <p className="title fs-14 mb-20 bold">관리자로 초대할 사용자를 검색하세요</p>
        <AdminUserSearchInput
          placeholder="이름, 이메일, 프로필 주소 입력"
          onChange={(user) => {
            setuser(user)
          }}
          defaultText={''}
        />
        <div className="btn-wrap page-foot-btn">
          <button className="btn" onClick={onHide}>
            취소
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              onRequest(user.id)
            }}
            disabled={!user}
          >
            보내기
          </button>
        </div>
      </div>
    </div>
  )
}
