import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import ResetPasswordPopup from 'src/components/Popup/ResetPassword'

export default function ResetPassword() {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [token, setToken] = useState(null)

  useEffect(() => {
    const token = searchParams.get('token')
    if (!token || token.length === 0) {
      navigate('/notFound')
    }
    setToken(token)
  })

  if (!token) {
    return <></>
  }

  return (
    <div className="page">
      <ResetPasswordPopup
        token={token}
        onHide={() => {
          navigate('/')
        }}
      />
    </div>
  )
}
