import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'

export default function Token() {
  const [searchParams] = useSearchParams()

  const [{ account }, setAuthState] = useRecoilState(authState)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const setPopupState = useSetRecoilState(popupState)

  const token = searchParams.get('token')
  const type = searchParams.get('type')

  const [error, setError] = useState('')

  useEffect(() => {
    if (type !== 'inviteStudio') {
      navigate('/notFound')
      return
    }

    fetchUser()
  }, [])

  useEffect(() => {
    if (!account) {
      return
    }

    if (account.company) {
      navigate('/studio/profile')
      return
    }

    acceptAuthToken()
  }, [account])

  const fetchUser = async () => {
    api.account
      .fetch()
      .then((response) => {
        const account = response.data
        setAuthState({
          account,
          waiting: false,
        })
      })
      .catch((error) => {
        setAuthState({
          account: null,
          waiting: false,
        })
        setLoading(false)

        setPopupState({ popupType: MainPopupType.SignIn })
      })
  }

  const acceptAuthToken = async () => {
    api.company
      .useAuthToken(token)
      .then((res) => {
        fetchUser()
      })
      .catch((e) => {
        const { status } = e.response
        if (status == 404) {
          setError('유효한 키를 찾을 수 없습니다')
        } else {
          setError(e.response.data?.message || '네트워크 에러')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="box">
          <div className="spinner mx-auto"></div>
        </div>
      )
    }

    if (error) {
      return (
        <div className="box">
          <div className="mx-auto box-inner-container box-inner-container-sm text-center">
            <p className="fs-24 bold">{error}</p>
            {error && <p className="mt-20 fs-14">관리자에게 문의하거나 다시 시도해주세요</p>}
          </div>
        </div>
      )
    }

    return <></>
  }

  return (
    <div className="page signup-complete-page bg-color-grey-50">
      <div className="box-container">{renderContent()}</div>
    </div>
  )
}
