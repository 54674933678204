import { useEffect, useState } from 'react';
import api from 'src/api';
import { Category, ReferenceRes } from 'src/api/types/reference';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReferenceListItem from './ReferenceListItem';
import { useNavigate } from 'react-router-dom';

type Props = {
    type: 'creative' | 'tech'
}

export default function HomeReferenceSlide({ type }: Props) {
    const navigate = useNavigate()

    const [categories, setCategories] = useState<Category[]>(null)
    const [items, setItems] = useState<ReferenceRes[]>(null)
    const mainCategoryId = type === 'creative' ? 10 : 11
    const [activeCategoryId, setActiveCategoryId] = useState(mainCategoryId)

    useEffect(() => {
        fetchCategories()
        fetchPortfolios(mainCategoryId)
    }, [])

    const fetchCategories = () => {
        api.category.getList(mainCategoryId).then((res) => {
            let items = [{ id: mainCategoryId, name: '전체' }]

            setCategories([...items, ...res.data])
        })
    }

    const fetchPortfolios = (parentId: number) => {
        setItems(null);
        api.portfolio.getList(parentId, null, null, 'Recommend', null, 20).then(res => {

            setItems(res.data.content || [])
        }).catch(e => {
            // do nothing
        })
    }

    const swiperClassName = `${type}-reference-swiper`;

    const renderSlides = () => {
        if (!items) {
            return <div className="spinner with-small-margin"></div>
        }

        if (items.length === 0) {
            return <></>
        }

        const itemGroups = [];
        const totalCount = items.length > 20 ? 20 : items.length
        for (let i = 0; i < totalCount; i += 4) {
            itemGroups.push(items.slice(i, i + 4));
        }

        return itemGroups?.map((itemGroup, index) => {
            return <SwiperSlide key={index}>
                <ul>
                    {
                        itemGroup.map((item) => {
                            return <ReferenceListItem item={item} key={item.id} />
                        })
                    }
                </ul>
            </SwiperSlide>
        })
    }

    return <>
        <div className='category-btn-list-wrap'>
            <Swiper className='category-btn-list'
                freeMode={true}
                slidesPerView={'auto'}>
                {
                    categories?.map((category, index) => {
                        return <SwiperSlide
                            key={category.id}
                            className={category.id === activeCategoryId && 'active'}
                            onClick={() => {
                                // setActiveCategoryId(category.id)
                                // fetchPortfolios(category.id)
                                navigate({
                                    pathname: '/main/' + type,
                                    search: `?categoryId=${category.id}`,
                                })
                            }}>{category.name}</SwiperSlide>
                    })
                }
            </Swiper>
        </div>
        <div className={`mt-30 reference-swiper-wrap ${swiperClassName}`}>
            <Swiper
                initialSlide={1}
                slidesPerView={1}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                loop={true}
                navigation={{
                    prevEl: `.${swiperClassName} .swiper-button-prev`,
                    nextEl: `.${swiperClassName} .swiper-button-next`,
                }}
                pagination={{ el: `.${swiperClassName} .swiper-pagination` }}>
                {renderSlides()}
            </Swiper>
            <div className="mt-30 swiper-pagination"></div>
            <div className="swiper-button-prev pc-only"></div>
            <div className="swiper-button-next pc-only"></div>
        </div>
    </>
}