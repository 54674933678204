type Props = {
  period?: string
  scope?: string
  customer?: string
  partner?: string
}
export default function ReferenceDetailInfo({ period, scope, customer, partner }: Props) {
  const renderPeriod = () => {
    if (!period) {
      return
    }

    return (
      <div className="period-wrap">
        <i className="ico-date"></i>
        <span>{period}</span>
      </div>
    )
  }
  const renderTable = () => {
    if (!scope && !customer && !partner) {
      return
    }
    return (
      <table>
        {scope && (
          <tr>
            <th>수행 범위</th>
            <td>{scope}</td>
          </tr>
        )}
        {customer && (
          <tr>
            <th>고객사</th>
            <td>{customer}</td>
          </tr>
        )}
        {partner && (
          <tr>
            <th>협력사</th>
            <td>{partner}</td>
          </tr>
        )}
      </table>
    )
  }

  if (!period && !scope && !customer && !partner) {
    return
  }

  return (
    <div className="reference-detail-info fs-16">
      {renderPeriod()}
      {renderTable()}
    </div>
  )
}
