import { useRecoilState } from 'recoil'
import api from '../api'
import authState from '../recoil/atom/auth'

export default function useAuth() {
  const [auth, setAuth] = useRecoilState(authState)
  const response = {
    logout: async () => {
      try {
        await api.account.signout()
        setAuth({ waiting: false, account: null })
      } catch (error) {
        try {
          await api.account.fetch()
          throw error
        } catch (_) {
          setAuth({ waiting: false, account: null })
        }
      }
    },
    ...auth,
  }
  return response
}
