import React from "react";
import styles from "./index.module.scss";

type Props = {
  direction?: "up" | "down";
};

export default function Arrow({ direction }: Props) {
  return (
    <svg
      className={direction === "up" ? styles.up : undefined}
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.19819 14.9952L11.9451 11.5095C12.4777 11.1159 12.605 10.3481 12.2299 9.79132C12.0483 9.5213 11.7706 9.33807 11.4586 9.28236C11.1466 9.22665 10.8261 9.30308 10.5685 9.49466L7.69661 11.6207L7.69661 1.62084C7.69661 0.938176 7.1653 0.384765 6.50988 0.384765C5.85447 0.384765 5.32315 0.938176 5.32315 1.62084L5.32315 11.5095L2.47499 9.28453C2.2232 9.08783 1.9067 9.00337 1.59512 9.04973C1.28355 9.09609 1.00241 9.26948 0.813569 9.53174C0.659504 9.7457 0.576222 10.0059 0.576222 10.2734C0.576222 10.6625 0.752089 11.0288 1.05092 11.2622L5.79784 14.9705C6.2097 15.2953 6.776 15.3053 7.19819 14.9952Z"
        fill="currentColor"
      />
    </svg>
  );
}
