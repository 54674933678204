import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { ReferenceRes } from 'src/api/types/reference'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import { getMainCategoryId } from 'src/utils/Category'
import CompanyProfileImg from '../Common/CompanyProfileImg'
import BannerCategoryBadge from '../Etc/BannerCategoryBadge'
import { replaceSpecialCharacters } from 'src/utils/String'

type Props = {
  item: ReferenceRes
  className?: string
  hideCompany?: boolean
  onCustomClick?: (item: any) => void
}
export default function ReferenceListItem({ className = '', item, hideCompany = false, onCustomClick }: Props) {
  const navigate = useNavigate()
  const [{ account }] = useRecoilState(authState)
  const setPopupState = useSetRecoilState(popupState)
  const [isInterest, setIsInterest] = useState(item.isInterest)
  const setNotificationState = useSetRecoilState(notificationState)
  const [mainCategoryName, setMainCategoryName] = useState(null)

  const link = process.env.REACT_APP_BASE_URL + '/r/' + item.id
  const categories = item.representativeCategory?.length > 0 ? item.representativeCategory : item.categories

  useEffect(() => {
    document.addEventListener('onUserInterestUpdated', onUserInterestUpdated, true)
    const mainCategoryId = getMainCategoryId(categories?.[0])
    if (mainCategoryId === 10) {
      setMainCategoryName('creative')
    } else if (mainCategoryId === 11) {
      setMainCategoryName('tech')
    }

    return () => {
      document.removeEventListener('onUserInterestUpdated', onUserInterestUpdated, true)
    }
  }, [])

  const onUserInterestUpdated = (e: CustomEvent) => {
    const { referenceId, isInterest } = e.detail
    if (item.id !== referenceId) {
      return
    }
    setIsInterest(isInterest)
  }

  const toggleInterest = (e) => {
    e.stopPropagation()

    if (!account) {
      setPopupState({ popupType: MainPopupType.NeedToLogin })
      return
    }

    if (isInterest) {
      api.userInterest
        .deletePortflio(item.id)
        .then(() => {
          setIsInterest(false)
        })
        .catch((e) => {
          setNotificationState({
            type: 'error',
            message: '네트워크 에러',
          })
        })
    } else {
      api.userInterest
        .addPortfolio(item.id)
        .then(() => {
          setNotificationState({
            type: 'like_contents_on',
            message: '내 라이브러리에 저장헀어요',
          })
          setIsInterest(true)
        })
        .catch((e) => {
          setNotificationState({
            type: 'error',
            message: '네트워크 에러',
          })
        })
    }
  }

  const copyLink = (e) => {
    e.stopPropagation()

    api.portfolio
      .getShare(item.id)
      .then(() => {})
      .catch((e) => {
        // do nothing
      })
    const t = document.createElement('textarea')
    document.body.appendChild(t)
    t.value = link
    t.select()
    document.execCommand('copy')
    document.body.removeChild(t)

    setNotificationState({
      type: 'link-copy-bl',
      message: '링크를 복사했어요!',
    })
  }

  const getItemClassName = () => {
    let classList = [mainCategoryName]
    if (className) {
      classList.push(className)
    }

    if (!item.publishedAt) {
      classList.push('deactive')
    }

    if (item.type === 'Banner') {
      classList.push('banner')
    }

    return classList.join(' ')
  }

  return (
    <li className={getItemClassName()}>
      <div
        className="thumb-wrap border-light"
        onClick={() => {
          if (onCustomClick) {
            onCustomClick(item)
          } else {
            navigate(`/r/${item.id}`)
          }
        }}
      >
        {item.thumbnailFile?.url && <img alt="thumb" src={item.thumbnailFile?.url} />}
        <div className="pc-only">
          <button className={`like-btn ${isInterest ? 'active' : ''}`} onClick={toggleInterest}></button>
          <button className="share-btn" onClick={copyLink}></button>
        </div>
      </div>
      <BannerCategoryBadge categories={categories} type={item.type} />
      <p className="title fs-16 bold max-line-2">
        <span
          onClick={() => {
            if (onCustomClick) {
              onCustomClick(item)
            } else {
              navigate(`/r/${item.id}`)
            }
          }}
        >
          {replaceSpecialCharacters(item.title)}
        </span>
      </p>
      {!hideCompany && (
        <div
          className="company"
          onClick={() => {
            navigate('/c/' + item.company.urlKey)
          }}
          style={{ cursor: 'pointer' }}
        >
          <CompanyProfileImg company={item.company} size={20} />
          <span>{item.company?.name}</span>
        </div>
      )}
    </li>
  )
}
