import request from 'src/utils/Request'
import { Category } from './types/reference'

const categoryApi = {
  // 카테고리 목록
  getList: (parentId?: number) => {
    return request.get<Category[]>('/v1/category', { params: { parentId } })
  },
  // 카테고리 검색
  getListBySearch: () => {
    return request.get('/v1/category/search')
  },
  // 관심분야
  getInterest: () => {
    return request.get('/v1/category/interest')
  },
  // 배너 카테고리
  getBanners: () => {
    return request.get('/v1/category/banner')
  },
}

export default categoryApi
