import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Message } from 'src/api/types/message'
import { dateTimeFormat } from 'src/utils/Format'

type Props = {
  onClick: () => void
  data: Message
  isSent: boolean
}

export default function MessageListItem({ onClick, data, isSent }: Props) {
  const [searchParams] = useSearchParams()

  const [isRead, setIsRead] = useState(!data.unread)

  useEffect(() => {
    const selectedId = searchParams.get('id') ? parseInt(searchParams.get('id')) : null
    if (data.id === selectedId) {
      setIsRead(true)
    }
  }, [searchParams])

  const renderSend = () => {
    const isCompany = data.sender.type === 'Company'
    return (
      <>
        <div>
          <div className="fs-14 color-text-info">보낸 {isCompany ? '회사' : '사람'}</div>
          <div>
            <p className="fs-18 bold">{data.sender.name}</p>
            <p className="fs-12" style={{ marginTop: 6 }}>
              bindus.kr/{isCompany ? 'c' : 'u'}/{data.sender.urlKey}
            </p>
            {data.sender.companyName ? <p className="fs-12">{data.sender.companyName}</p> : <></>}
          </div>
        </div>
        <div>
          <div className="fs-14 color-text-info">받은시간</div>
          <div className="fs-12">{dateTimeFormat(data.createdAt)}</div>
        </div>
      </>
    )
  }

  const renderSent = () => {
    const isCompany = data.receiver.type === 'Company'

    return (
      <>
        <div>
          <div className="fs-14 color-text-info">받는 {isCompany ? '회사' : '사람'}</div>
          <div>
            <p className="fs-18 bold">{data.receiver.name}</p>
            <p className="fs-12" style={{ marginTop: 6 }}>
              bindus.kr/{isCompany ? 'c' : 'u'}/{data.receiver.urlKey}
            </p>
            {data.sender.companyName ? <p className="fs-12">{data.sender.companyName}</p> : <></>}
          </div>
        </div>
        <div>
          <div className="fs-14 color-text-info">받은시간</div>
          <div className="fs-12">{dateTimeFormat(data.createdAt)}</div>
        </div>
      </>
    )
  }

  return (
    <li
      className={!isSent && !isRead && 'new'}
      onClick={() => {
        onClick()
      }}
    >
      {isSent ? renderSent() : renderSend()}
    </li>
  )
}
