import TextArea from 'src/components/Input/TextArea'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import LinkIcon from '../../icons/Link'
import { EditorBlockInputProps } from '../../types'
import styles from './index.module.scss'

const TextLinkBlock = forwardRef(({ menuOpened, block: blockValue, onBlockChange }: EditorBlockInputProps, ref) => {
  const value = blockValue?.value as any

  const [text, setText] = useState(value?.text)
  const [linkUrl, setLinkUrl] = useState(value?.linkUrl)
  const [canInsert, setCanInsert] = useState(false)
  const [isInserted, setIsInserted] = useState(value?.text ? true : false)

  useEffect(() => {
    setCanInsert(text?.length > 0 && linkUrl?.length > 0)
  }, [text, linkUrl])

  useImperativeHandle(ref, () => ({
    startEdit() {
      setIsInserted(false)
    }
  }));

  if (isInserted) {
    return (
      <div className={[styles.insertedBlock, menuOpened && styles.menuOpened].filter((item) => item).join(' ')}>
        <a
          href={linkUrl}
          target="_blank"
          onClick={() => {
            let url = linkUrl
            if (url?.indexOf('http') === -1) {
              url = 'http://' + url
            }
            window.open(url)
          }}
        >
          {text}
        </a>
      </div>
    )
  }

  return (
    <div className={[styles.textLinkBlock, menuOpened && styles.menuOpened].filter((item) => item).join(' ')}>
      <p className="fs-14 mb-20">입력된 텍스트 전체에 링크가 삽입됩니다.</p>
      <TextArea
        defaultText={text}
        onChange={(text) => {
          setText(text)
        }}
        placeholder="텍스트를 입력해 주세요."
        style={{ minHeight: 60 }}
      />
      <input
        type="text"
        className={'mt-10 input fs-14 w-100'}
        placeholder={'URL을 입력해 주세요.'}
        value={linkUrl}
        onChange={(e) => {
          setLinkUrl(e.target.value)
        }}
      />
      <button
        className="mt-20 mx-auto btn btn-md bold"
        style={{ width: 162 }}
        onClick={() => {
          setIsInserted(true)
          onBlockChange?.({
            id: blockValue.id,
            type: 'textLink',
            value: {
              text: text,
              linkUrl: linkUrl,
            } as any,
          })
        }}
        disabled={!canInsert}
      >
        <LinkIcon />
        링크 삽입
      </button>
    </div>
  )
})

export default TextLinkBlock
