import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { Category } from 'src/api/types/reference'
import CompanyProfileEditor from 'src/components/BlockEditor'
import { EditorBlocks } from 'src/components/BlockEditor/types'
import ConfirmPopup from 'src/components/Popup/Confirm'
import StudioMaxPortfolioConfirm from 'src/components/Popup/StudioMaxPortfolioPublishConfirm'
import Select from 'src/components/Select/Select'
import ReferenceEditThumb from 'src/components/Studio/ReferenceEditThumb'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'

export default function BannerEdit() {
  const setNotificationState = useSetRecoilState(notificationState)
  const navigate = useNavigate()
  const { account } = useRecoilValue(authState)
  const [title, setTitle] = useState('')
  const [editorValue, setEditorValue] = useState<EditorBlocks>([])
  const handleOnChange = useCallback((newValue: EditorBlocks) => {
    setEditorValue(newValue)
  }, [])
  const [categories, setCategories] = useState<Category[]>([])
  const [thumbnail, setThumbnail] = useState(null)
  const location = useLocation()
  const [portfolioId, setPortfolioId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showCancelPopup, setShowCancelPopup] = useState(false)
  const [showPublishPopup, setShowPublishPopup] = useState(false)
  const [showMaxPopup, setShowMaxPopup] = useState(false)
  const [categoryOptions, setCategoryOptions] = useState(null)
  const [mainCategoryId, setMainCategoryId] = useState(null)

  let saveLoading = false

  useEffect(() => {
    if (!location.state) {
      fetchCategories()
      return
    }

    const { portfolioId } = location.state as any
    if (portfolioId) {
      setPortfolioId(portfolioId)
      fetchPortfolio(portfolioId)
    }
  }, [])

  const fetchCategories = () => {
    api.category.getBanners().then((res) => {
      let categoryOptions = []
      for (const item of res.data) {
        categoryOptions.push({
          value: String(item.id),
          label: item.name,
        })
      }
      setCategoryOptions(categoryOptions)
      setLoading(false)
    })
  }

  const fetchPortfolio = (portfolioId: number) => {
    setLoading(true)
    api.portfolio
      .get(portfolioId)
      .then((res) => {
        const data = res.data

        setTitle(data.title)
        setEditorValue(JSON.parse(data.contentJson))
        setCategories(data.categories)
        setThumbnail(data.thumbnailFile)

        if (data.categories.find(item => item.id === 10)) {
          setMainCategoryId(10)
        }
        else if (data.categories.find(item => item.id === 11)) {
          setMainCategoryId(11)
        }
        fetchCategories()
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const save = (callback = null) => {
    if (saveLoading) {
      return
    }

    if (!title || title?.length === 0) {
      setNotificationState({
        type: 'error',
        message: '배너 제목을 입력해주세요',
      })
      return
    }

    if (!thumbnail) {
      setNotificationState({
        type: 'error',
        message: '배너 썸네일 이미지를 등록해 주세요',
      })
      return
    }

    if (categories.length === 0) {
      setNotificationState({
        type: 'error',
        message: '배너 카테고리를 등록해 주세요',
      })
      return
    }

    if (!editorValue || editorValue.length === 0) {
      setNotificationState({
        type: 'error',
        message: '내용을 등록해 주세요',
      })
      return
    }

    let categoryIds = [mainCategoryId, categories[0].id]
    let representativeCategoryId = categories[0].id

    saveLoading = true
    if (portfolioId) {
      api.companyPortfolio
        .update(
          account.company.id,
          portfolioId,
          title,
          JSON.stringify(editorValue),
          categoryIds,
          representativeCategoryId,
          [],
          thumbnail?.uuid,
          null,
          null,
          null,
          null
        )
        .then(() => {
          if (callback) {
            callback(portfolioId)
          } else {
            setNotificationState({
              type: 'check',
              message: '저장되었습니다',
            })
          }
        })
        .catch((e) => {
          setNotificationState({
            type: 'error',
            message: '네트워크 에러',
          })
        })
        .finally(() => {
          saveLoading = false
        })
    } else {
      api.companyPortfolio
        .addBanner(account.company.id, title, JSON.stringify(editorValue), categoryIds, thumbnail?.uuid)
        .then((res) => {
          const portfolioId = res.data.id
          setPortfolioId(portfolioId)
          if (callback) {
            callback(portfolioId)
          } else {
            setNotificationState({
              type: 'check',
              message: '저장되었습니다',
            })
          }
        })
        .catch((e) => {
          setNotificationState({
            type: 'error',
            message: e.response?.data?.message || '네트워크 에러',
          })
        })
        .finally(() => {
          saveLoading = false
        })
    }
  }

  const saveAndActivate = () => {
    save((portfolioId: number) => {
      api.companyPortfolio
        .publish(account.company.id, portfolioId)
        .then(() => {
          setNotificationState({
            type: 'check',
            message: '게시되었습니다',
          })

          navigate('/studio/banner')
        })
        .catch((e) => {
          // if (e.response?.status === 400) {
          //   setShowMaxPopup(true)
          //   return
          // }
          setNotificationState({
            type: 'error',
            message: e.response.data.message || '네트워크 에러',
          })
        })
    })
  }

  if (loading) {
    return <div className="spinner with-margin"></div>
  }

  return (
    <div className="page reference-edit-page bg-color-grey-50 studio-edit-page studio-page">
      <div className="container box-container">
        <div className="right-area w-100">
          <div>
            <input
              type="text"
              className="input input-lg mb-20 w-100 fs-22 title-input"
              placeholder="배너의 제목을 입력 해 주세요."
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
            <CompanyProfileEditor blocks={editorValue} onBlocksChange={handleOnChange} />
          </div>
        </div>
        <div className="left-area">
          <div className="box">
            <p className="fs-16 bold">썸네일 이미지</p>
            <ReferenceEditThumb
              defaultThumb={thumbnail}
              onChange={(thumbnail) => {
                setThumbnail(thumbnail)
              }}
            />
            <p className="mt-30 fs-16 bold" style={{ marginBottom: 14 }}>
              배너 위치
            </p>
            <div className="input-wrap" style={{ display: 'flex', gap: 19 }}>
              <input
                type="checkbox"
                id="creative-check"
                className="checkbox green"
                checked={mainCategoryId === 10}
                onChange={(e) => {
                  setMainCategoryId(mainCategoryId === 10 ? null : 10)
                }}
              />
              <label htmlFor="creative-check">크리에이티브</label>
              <input
                type="checkbox"
                id="tech-check"
                className="checkbox green"
                checked={mainCategoryId === 11}
                onChange={(e) => {
                  setMainCategoryId(mainCategoryId === 11 ? null : 11)
                }}
              />
              <label htmlFor="tech-check">테크</label>
            </div>
            <p className="mt-30 fs-16 bold" style={{ marginBottom: 14 }}>
              카테고리
            </p>
            {categoryOptions && (
              <Select
                defaultLabel="카테고리 선택"
                options={categoryOptions}
                value={categories.length > 0 ? String(categories[0].id) : null}
                onChange={(option) => {
                  setCategories([
                    {
                      id: parseInt(option.value),
                      name: option.label,
                    },
                  ])
                }}
              />
            )}
            <div className="btn-wrap" style={{ marginTop: 50 }}>
              <button
                className="btn"
                onClick={() => {
                  setShowCancelPopup(true)
                }}
              >
                취소
              </button>
              <button
                className="btn"
                onClick={() => {
                  save()
                }}
              >
                임시저장
              </button>
            </div>
            <button className="mt-10 btn btn-primary w-100" onClick={saveAndActivate}>
              게시
            </button>
          </div>
        </div>
      </div>
      <div className="pc-only w-100" style={{ height: 150 }}></div>
      {showCancelPopup && (
        <ConfirmPopup
          title="배너 작성을 취소하시겠습니까?"
          okText="취소"
          cancelText="아니요"
          onOk={() => {
            navigate('/studio/banner')
          }}
          onCancel={() => {
            setShowCancelPopup(false)
          }}
        />
      )}
      {showPublishPopup && (
        <ConfirmPopup
          title="배너를 게시했어요"
          onOk={() => {
            setShowPublishPopup(false)
          }}
          onCancel={() => {
            setShowPublishPopup(false)
          }}
        />
      )}
      {showMaxPopup && (
        <StudioMaxPortfolioConfirm
          onHide={() => {
            setShowMaxPopup(false)
          }}
        />
      )}
    </div>
  )
}
