import { useEffect, useState } from 'react'
import api from 'src/api'

type Props = {
  onChange: any
}

export default function FavoriteSelector({ onChange }: Props) {
  const [selectedIds, setSelectedIds] = useState([])
  const [items, setItems] = useState([])

  useEffect(() => {
    api.category
      .getInterest()
      .then((res) => {
        setItems(res.data)
      })
      .catch((e) => {
        // do nothing
      })
  }, [])

  const isActiveItem = (item: any): boolean => {
    return selectedIds.indexOf(item.id) !== -1
  }

  const toggleSelect = (item: any) => {
    let ids = selectedIds

    if (isActiveItem(item)) {
      ids = ids.filter((selectedId) => {
        return selectedId !== item.id
      })
    } else {
      ids = [...ids, item.id]
    }

    setSelectedIds(ids)
    onChange(ids)
  }

  const renderItems = () => {
    return items.map((item) => {
      const isActive = isActiveItem(item)
      return (
        <li
          className={isActive ? 'active' : ''}
          onClick={() => {
            toggleSelect(item)
          }}
        >
          <div className="thumb">
            {item.imageUri && item.imageUri.length > 0 && <img src={item.imageUri} loading="lazy" />}
          </div>
          <p>{item.name}</p>
        </li>
      )
    })
  }

  return (
    <div className="favorite-list-wrap">
      <ul className="favorite-list w-100">{renderItems()}</ul>
    </div>
  )
}
