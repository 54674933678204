import { notificationState } from 'src/recoil/atom/notification'
import { useSetRecoilState } from 'recoil'
import { Account } from 'src/api/types/account'
import { getUserLink } from './Profile'

type Props = {
  data: string
  className?: string
  style?: any
}

export function AccountCopyLink({
  account,
  style = null,
  fontSize = 12,
}: {
  account: Account
  style?: any
  fontSize?: number
}) {
  const setNotificationState = useSetRecoilState(notificationState)

  const fontClass = `fs-${fontSize}`
  const link = process.env.REACT_APP_BASE_URL + getUserLink(account)

  return (
    <p
      className={`color-grey-800 flex-vertical-center link ${fontClass}`}
      onClick={() => {
        const t = document.createElement('textarea')
        document.body.appendChild(t)
        t.value = link
        t.select()
        document.execCommand('copy')
        document.body.removeChild(t)

        setNotificationState({
          type: 'link-copy-bl',
          message: '링크를 복사했어요',
        })
      }}
      style={style}
    >
      {link}
      <i className="ico ico-link-copy" style={{ marginLeft: 6, width: 12, height: 12 }}></i>
    </p>
  )
}

export function ReferenceCopyLink({ data, style = null }: Props) {
  const setNotificationState = useSetRecoilState(notificationState)

  const link = process.env.REACT_APP_BASE_URL + '/r/' + data

  return (
    <p
      className="fs-12 color-grey-800 flex-vertical-center link"
      onClick={() => {
        const t = document.createElement('textarea')
        document.body.appendChild(t)
        t.value = link
        t.select()
        document.execCommand('copy')
        document.body.removeChild(t)

        setNotificationState({
          type: 'link-copy-bl',
          message: '링크를 복사했어요',
        })
      }}
      style={style}
    >
      <span>{link}</span>
      <i className="ico ico-link-copy" style={{ marginLeft: 6, width: 12, height: 12 }}></i>
    </p>
  )
}

export function CompanyCopyLink({ data, className = null, style = null }: Props) {
  const setNotificationState = useSetRecoilState(notificationState)

  const link = process.env.REACT_APP_BASE_URL + '/c/' + data

  return (
    <p
      className={className || 'fs-14 color-grey-700 flex-vertical-center link'}
      onClick={() => {
        const t = document.createElement('textarea')
        document.body.appendChild(t)
        t.value = link
        t.select()
        document.execCommand('copy')
        document.body.removeChild(t)

        setNotificationState({
          type: 'link-copy-bl',
          message: '링크를 복사했어요',
        })
      }}
      style={style}
    >
      {link}
      <i className="ico ico-link-copy" style={{ marginLeft: 6, width: 12, height: 12 }}></i>
    </p>
  )
}
