import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'

export default function NeedToLoginPopup() {
  const location = useLocation()
  const navigate = useNavigate()
  const [isBlur, setIsBlur] = useState(false)

  useEffect(() => {
    setIsBlur(location.pathname.indexOf('/c/') !== -1 || location.pathname.indexOf('/u/') !== -1)
  }, [])

  const setPopupState = useSetRecoilState(popupState)

  const onHide = () => {
    setPopupState({ popupType: MainPopupType.None })
  }

  const signIn = () => {
    setPopupState({ popupType: MainPopupType.SignIn })
  }

  const signUp = () => {
    setPopupState({ popupType: MainPopupType.SignUp })
  }

  return (
    <div className={`main-popup-wrap popup-wrap ${isBlur ? 'blur' : ''}`}>
      <div className="popup" style={{ top: 80, maxWidth: 550, transform: 'translateX(-50%' }}>
        <button
          className="popup-close close-btn"
          onClick={() => {
            // const isBlock = location.pathname.indexOf('/c/') !== -1 || location.pathname.indexOf('/u/') !== -1
            // if (isBlock) {
            //   navigate('/')
            // }
            onHide()
          }}
        />
        <p className="popup-title bold text-center">
          {isBlur ? '회원가입하고 정보를 확인해 보세요!' : '회원가입하고 사용할 수 있어요'}
        </p>
        <button className="mt-20 btn btn-primary mx-auto w-100" onClick={signUp} style={{ maxWidth: 310 }}>
          회원가입
        </button>
        <p className="mt-30 desc text-center">
          이미 회원이신가요?
          <span className="ml-10 link bold" onClick={signIn}>
            로그인
          </span>
        </p>
      </div>
    </div>
  )
}
