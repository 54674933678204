import request from 'src/utils/Request'
import { CompanyActiveStatus, CompanyRes } from './types/company'
import { SocialItemReq } from './types/social'
import qs from 'qs'

const companyApi = {
  // 회사 정보
  get: (id: number) => {
    return request.get<CompanyRes>(`/v1/company/${id}`)
  },
  // 회사 추가
  create: (body: any) => {
    return request.post('/v1/company', body)
  },
  // 회사 수정
  update: (
    id: number,
    name: string,
    businessAreaIntroduction: string,
    tagIds: number[],
    urls: string[],
    socials: SocialItemReq[]
  ) => {
    return request.put(`/v1/company/${id}`, { name, businessAreaIntroduction, tagIds, urls, socials })
  },
  // 회사 삭제
  delete: (companyId: number, reason: string[]) => {
    return request.delete(`/v1/company/${companyId}`, {
      params: {
        reason,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false })
      },
    })
  },
  updateProfile: (
    id: number,
    name: string,
    businessAreaIntroduction: string,
    tagIds: number[],
    urls: string[],
    socials: SocialItemReq[]
  ) => {
    return request.put(`/v1/company/${id}/profile`, { name, businessAreaIntroduction, tagIds, urls, socials })
  },
  // 회사 플랜 변경
  updatePlan: (id: number, planId: number) => {
    return request.put(`/v1/company/${id}/plan/${planId}`)
  },
  // 회사 플랜 담당자 변경
  updatePlanManager: (companyId: number, companyName: string, name: string, email: string, phoneNumber: string) => {
    return request.put(`/v1/company/${companyId}/plan/manager`, { name, companyName, email, phoneNumber })
  },
  // 회사 로고 수정
  updateLogo: (companyId: number, uploadImage: File) => {
    return request.put(
      `/v1/company/${companyId}/logo`,
      { uploadImage },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
  // 회사 관리자 변경 상태
  getAuth: (companyId: number) => {
    return request.get(`/v1/company/${companyId}/auth`)
  },
  // 회사 관리자 변경 요청
  updateAuth: (companyId: number, userId: number) => {
    return request.post(`/v1/company/${companyId}/auth?userId=${userId}`)
  },
  // 회사 관리자 변경 취소
  cancelAuth: (companyId: number, userId: number) => {
    return request.delete(`/v1/company/${companyId}/auth?userId=${userId}`)
  },
  // 관리자 초대 토큰 불러오기
  getAuthToken: (companyId: number) => {
    return request.get(`/v1/company/${companyId}/auth/token`)
  },
  useAuthToken: (token: string) => {
    return request.post(`/v1/company/auth/token?token=${token}`)
  },
  // 회사 활성 상태 변경
  updateActivateState: (id: number, activeState: CompanyActiveStatus, disableReason: string[]) => {
    return request.post(`/v1/company/${id}/active-state`, null, {
      params: {
        activeState,
        disableReason,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false })
      },
    })
  },
  // 회사 소개 수정
  updateIntroductionJson: (id: number, introductionJson: string) => {
    return request.patch(`/v1/company/${id}/introductionJson`, introductionJson, {
      headers: { 'content-type': 'text/plain' },
    })
  },
  // 회사 플랜
  getPlan: (id: number) => {
    return request.get(`/v1/company/${id}/plan`)
  },
  // 회사 결제 이력
  getPlanHistory: (id: number) => {
    return request.get(`/v1/company/${id}/plan/history`)
  },
  // 회사 UrlKey 검색
  getByUrlKey: (urlKey: string) => {
    return request.get(`/v1/company/urlKey`, { params: { urlKey } })
  },
  // 회사 UrlKey 중복 체크
  checkUrlKeyDuplicate: (urlKey: string) => {
    return request.get('/v1/company/urlKey/check', { params: { urlKey } })
  },
  // 회사명 검색
  searchByName: (name: string, page: number = 0) => {
    return request.get(`/v1/company/search`, { params: { name: name.toLowerCase(), page } })
  },
  // 회사명 가져오기
  getListByName: (name: string) => {
    return request.get(`/v1/company/name`, { params: { name: name } })
  },
  // 내 회사 정보
  getMyCompany: () => {
    return request.get('/v1/company/my')
  },
}

export default companyApi
