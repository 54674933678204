import { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import api from 'src/api'
import { UserType } from 'src/api/types/account'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import { getEmailValidationMsg, getPasswordValidationMsg } from 'src/utils/Vaildiator'
import { isMobile } from 'react-device-detect'

type Props = {
  signupType?: UserType
}

export default function SignUp({ signupType = 'User' }: Props) {
  const location = useLocation()
  const navigate = useNavigate()
  const setPopupState = useSetRecoilState(popupState)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [emailErrMsg, setEmailErrMsg] = useState(null)
  const [passwordErrMsg, setPasswordErrMsg] = useState(null)

  const [isAllAgree, setIsAllAgree] = useState(false)
  const [agree1, setAgree1] = useState(false)
  const [agree2, setAgree2] = useState(false)
  const [agree3, setAgree3] = useState(false)

  const timeout = useRef(null)

  const [canSubmit, setCanSubmit] = useState(false)

  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden'
    return () => {
      document.querySelector('body').style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    setIsAllAgree(agree1 && agree2 && agree3)
    setCanSubmit(agree1 && agree2 && email.length > 0 && password.length > 0 && !emailErrMsg && !passwordErrMsg)
  }, [email, password, agree1, agree2, agree3])

  useEffect(() => {
    checkEmail(email)
  }, [email])

  const changeEmail = (e: any) => {
    const email = e.target.value
    const emailErrMsg = getEmailValidationMsg(email)
    setEmailErrMsg(emailErrMsg)
    setEmail(email)
  }

  const changePassword = (e: any) => {
    const password = e.target.value
    const passwordErrMsg = getPasswordValidationMsg(password)
    setPasswordErrMsg(passwordErrMsg)
    setPassword(password)
  }

  const checkEmail = (email: string) => {
    if (!email || email?.length === 0) {
      setEmailErrMsg(null)
      return
    }

    clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      api.account
        .checkEmailDuplicate(email)
        .then((_) => {})
        .catch((err) => {
          const { status } = err.response
          if (status === 409) {
            setEmailErrMsg('이미 가입된 이메일입니다')
          }
        })
    }, 200)
  }

  const signUp = () => {
    setCanSubmit(false)

    api.account
      .create(email, password, signupType, agree3)
      .then((_) => {
        navigate('/signup/requestAuth', { state: { email, type: 'signup' } })
        closePopup()
      })
      .catch((error) => {
        setCanSubmit(true)

        const { status } = error.response
        if (status === 409) {
          setEmailErrMsg('이미 가입된 이메일입니다')
        } else {
          setEmailErrMsg(`내부 에러[${status}]: 관리자에게 문의해주세요`)
        }
      })
  }

  const showSignInPopup = () => {
    setPopupState({ popupType: MainPopupType.SignIn })
  }

  const closePopup = () => {
    const isBlock = location.pathname.indexOf('/u/') !== -1
    if (isBlock) {
      navigate('/')
    }

    setPopupState({ popupType: MainPopupType.None })
  }

  const onInputFocus = () => {
    if (isMobile) {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 1000)
    }
  }

  const renderEmailInput = () => {
    let inputClassNames = ['mt-20', 'input', 'input-lg', 'w-100']
    if (emailErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (email.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="email"
          className={inputClassNames.join(' ')}
          placeholder="이메일"
          value={email}
          onChange={changeEmail}
          onFocus={onInputFocus}
          autoComplete="new-password"
        />
        {emailErrMsg && emailErrMsg.length > 0 && <p className="mt-10 color-danger">{emailErrMsg}</p>}
      </>
    )
  }

  const renderPasswordInput = () => {
    let inputClassNames = ['mt-10', 'input', 'input-lg', 'w-100']
    if (passwordErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (password.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="password"
          className={inputClassNames.join(' ')}
          placeholder="비밀번호"
          value={password}
          onChange={changePassword}
          onFocus={onInputFocus}
          autoComplete="new-password"
        />
        {passwordErrMsg && <p className="mt-10 color-danger">{passwordErrMsg}</p>}
      </>
    )
  }

  const toggleAllCheck = (e) => {
    const isChecked = e.target.checked
    setIsAllAgree(isChecked)
    setAgree1(isChecked)
    setAgree2(isChecked)
    setAgree3(isChecked)
  }

  const renderAgreementInput = () => {
    return (
      <div className="agreement-area">
        <div className="checkbox-wrap" style={{ marginTop: 32 }}>
          <p className="w-100 fs-14 bold">
            <span onClick={toggleAllCheck}>약관 전체 동의</span>
          </p>
          <input type="checkbox" className="checkbox " id="allAgree" onChange={toggleAllCheck} checked={isAllAgree} />
          <label htmlFor="allAgree"></label>
        </div>
        <div className="checkbox-wrap" style={{ marginTop: 24 }}>
          <p className="w-100 fs-14">
            <a target={'_blank'} href="https://brand.bindus.kr/terms-of-use">
              <span className="color-danger">(필수)</span>&nbsp;서비스 이용약관 동의
              <i className="ico-next-arrow"></i>
            </a>
          </p>
          <input
            type="checkbox"
            className="checkbox"
            id="signupAgree1"
            onChange={(e) => {
              setAgree1(e.target.checked)
            }}
            checked={agree1}
          />
          <label htmlFor="signupAgree1"></label>
        </div>
        <div className="checkbox-wrap" style={{ marginTop: 16 }}>
          <p className="w-100 fs-14">
            <a target={'_blank'} href="https://brand.bindus.kr/privacypolicy">
              <span className="color-danger">(필수)</span>&nbsp;개인정보 수집 및 이용동의
              <i className="ico-next-arrow"></i>
            </a>
          </p>
          <input
            type="checkbox"
            className="checkbox "
            id="signupAgree2"
            onChange={(e) => {
              setAgree2(e.target.checked)
            }}
            checked={agree2}
          />
          <label htmlFor="signupAgree2"></label>
        </div>
        <div className="checkbox-wrap" style={{ marginTop: 16 }}>
          <p className="w-100 fs-14">
            <a target={'_blank'} href="https://brand.bindus.kr/marketing-reception">
              <span className="color-grey-700">(선택)</span>&nbsp;마케팅 정보 수신 및 활용 동의
              <i className="ico-next-arrow"></i>
            </a>
          </p>
          <input
            type="checkbox"
            className="checkbox"
            id="signupAgree3"
            onChange={(e) => {
              setAgree3(e.target.checked)
            }}
            checked={agree3}
          />
          <label htmlFor="signupAgree3"></label>
        </div>
        <p className="fs-11 color-grey-600" style={{ marginLeft: 34 }}>
          다양한 회사의 최신 소식을 제공합니다.
        </p>
      </div>
    )
  }

  return (
    <div className="popup-wrap blur mobile-page main-popup-wrap">
      <div className="popup text-center" id="signup-popup">
        <button className="popup-close close-btn" onClick={closePopup} />
        <img src="/static/img/logo.svg" className="mx-auto" alt="logo" />
        <div className="popup-body">
          {renderEmailInput()}
          {renderPasswordInput()}
          {renderAgreementInput()}
          <button className="mt-30 btn btn-primary w-100" disabled={!canSubmit} onClick={signUp}>
            가입하기
          </button>
        </div>
        <hr className="mt-20 hr" />
        <a href={process.env.REACT_APP_KAKAO_LOGIN} className="mt-20 btn kakao-btn w-100">
          <i className="ico ico-kakao" />
          카카오로 시작하기
        </a>
        <p className="mt-30 desc text-center">
          이미 회원이신가요?
          <span className="ml-10 link bold" onClick={showSignInPopup}>
            로그인
          </span>
        </p>
      </div>
    </div>
  )
}
