import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { CompanyRes } from 'src/api/types/company'
import { Tag } from 'src/api/types/reference'
import { SocialRes } from 'src/api/types/social'
import { notificationState } from 'src/recoil/atom/notification'

type Props = {
  data: CompanyRes
  editMode: boolean
}

export default function StudioProfileArea({ data, editMode }: Props) {
  const setNotificationState = useSetRecoilState(notificationState)

  const [introductionJson, setIntroduceJson] = useState(null)
  const [businessAreaIntroduction, setBusinessAreaIntroduction] = useState(null)
  const [tags, setTags] = useState<Tag[]>([])
  const [socials, setSocials] = useState<SocialRes[]>([])
  const [urls, setUrls] = useState([])
  const [urlKey, setUrlKey] = useState('')

  useEffect(() => {
    const { name, tags, logo, socials, urlJson, businessAreaIntroduction, urlKey, isInterest } = data
    setBusinessAreaIntroduction(businessAreaIntroduction)
    setTags(tags)
    setSocials(socials)
    setUrlKey(urlKey)

    setUrls(JSON.parse(urlJson))
  }, [])

  const renderBox = (title: string, renderer: any) => {
    return (
      <div className="box border-light">
        <p className="fs-16 bold">{title}</p>
        <div className="mt-20 fs-14 color-text-info">{renderer()}</div>
      </div>
    )
  }

  const renderBusinessBox = () => {
    return renderBox('사업 영역 소개', () => {
      if (businessAreaIntroduction) {
        return businessAreaIntroduction
      }

      if (editMode) {
        return '회사가 어떤 일을 하는지 빠르게 알려줄 수 있어요.'
      } else {
        return '준비 중입니다.'
      }
    })
  }

  const renderTagBox = () => {
    return renderBox('회사태그', () => {
      if (tags.length > 0) {
        return (
          <ul className="tags">
            {tags.map((tag, index) => {
              return <li key={index}>{tag.name}</li>
            })}
          </ul>
        )
      }

      if (editMode) {
        return '태그를 등록해 보세요! 검색 결과로 노출이 쉬워져요.'
      } else {
        return '준비 중입니다.'
      }
    })
  }

  const renderWebsiteBox = () => {
    return renderBox('웹사이트', () => {
      if (urls.length > 0) {
        return urls.map((url, index) => {
          if (url?.indexOf('http') === -1) {
            url = 'http://' + url
          }
          return (
            <p key={index}>
              <a className="color-grey-600" href={url} target="_blank">
                {url}
              </a>
            </p>
          )
        })
      }

      if (editMode) {
        return '웹사이트를 링크를 등록해 보세요! 회사를 더 알릴 수 있어요.'
      } else {
        return '준비 중입니다.'
      }
    })
  }

  const renderSocialBox = () => {
    if (socials.length === 0) {
      return <></>
    }

    return (
      <div className="box text-center">
        {socials.map((social, index) => {
          let message = '링크를 복사했어요'
          if (social.social.icon === 'kakao') {
            message = '카카오톡 ID를 복사했어요'
          } else if (social.social.icon === 'email') {
            message = '이메일 주소를 복사했어요'
          }

          return (
            <img
              key={index}
              className="link"
              src={`/static/img/sns/${social.social.icon}.svg`}
              alt={social.social.icon}
              style={{ width: 42 }}
              onClick={() => {
                const t = document.createElement('textarea')
                document.body.appendChild(t)
                t.value = social.data
                t.select()
                document.execCommand('copy')
                document.body.removeChild(t)

                setNotificationState({
                  type: 'link-copy-bl',
                  message: message,
                })
              }}
            />
          )
        })}
      </div>
    )
  }

  return (
    <div className="content-box-wrap bg-color-grey-50">
      {renderBusinessBox()}
      {renderTagBox()}
      {renderWebsiteBox()}
      {renderSocialBox()}
    </div>
  )
}
