import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import api from 'src/api'
import { notificationState } from 'src/recoil/atom/notification'
import { getPasswordValidationMsg } from 'src/utils/Vaildiator'

type Props = {
  onHide?: () => void
  token?: string
}

export default function ResetPassword({ onHide, token }: Props) {
  const navigate = useNavigate()

  const setNotificationState = useSetRecoilState(notificationState)

  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')

  const [newPasswordErrMsg, setNewPasswordErrMsg] = useState(null)
  const [newPasswordConfirmErrMsg, setNewPasswordConfirmErrMsg] = useState(null)

  const [canSubmit, setCanSubmit] = useState(false)

  const changeNewPassword = (e: any) => {
    const password = e.target.value

    const passwordErrMsg = getPasswordValidationMsg(password)
    setNewPasswordErrMsg(passwordErrMsg)

    setCanSubmit(!passwordErrMsg && newPassword === newPasswordConfirm)

    setNewPassword(password)
  }

  const changeNewPasswordConfirm = (e: any) => {
    const passwordConfirm = e.target.value

    if (newPassword === passwordConfirm) {
      setNewPasswordConfirmErrMsg(null)
    } else {
      setNewPasswordConfirmErrMsg('새 비밀번호와 일치하지 않습니다')
    }

    setCanSubmit(newPassword === passwordConfirm)

    setNewPasswordConfirm(passwordConfirm)
  }

  const onRequestFailed = (err: any) => {
    setCanSubmit(true)
    const { status } = err.response
    if (status === 400) {
      setNewPasswordConfirmErrMsg('')
      setNewPasswordConfirmErrMsg('')
    } else {
      setNewPasswordConfirmErrMsg(`내부 에러[${status}]: 관리자에게 문의해주세요`)
    }
  }

  const requestResetPassword = () => {
    setCanSubmit(false)
    if (token) {
      api.account
        .resetPasswordByToken(token, newPassword)
        .then(() => {
          setNotificationState({
            type: 'check',
            message: '비밀번호가 재설정되었습니다',
          })

          navigate('/')
        })
        .catch(onRequestFailed)
    } else {
      api.account
        .resetPassword(newPassword)
        .then(() => {
          if (onHide) {
            onHide()
          } else {
            navigate('/')
          }

          setNotificationState({
            type: 'check',
            message: '비밀번호가 재설정되었습니다',
          })
        })
        .catch(onRequestFailed)
    }
  }

  const renderNewPassword = () => {
    let inputClassNames = ['mt-40', 'input', 'input-lg', 'w-100']
    if (newPasswordErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    }

    return (
      <>
        <input
          type="password"
          className={inputClassNames.join(' ')}
          placeholder="새 비밀번호"
          required
          onChange={changeNewPassword}
          autoComplete="new-password"
        />
        {newPasswordErrMsg && <p className="mt-10 color-danger">{newPasswordErrMsg}</p>}
      </>
    )
  }

  const renderNewPasswordConfirm = () => {
    let inputClassNames = ['mt-10', 'input', 'input-lg', 'w-100']
    if (newPasswordConfirmErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    }

    return (
      <>
        <input
          type="password"
          className={inputClassNames.join(' ')}
          placeholder="비밀번호 재입력"
          required
          onChange={changeNewPasswordConfirm}
          autoComplete="new-password-confirm"
        />
        {newPasswordConfirmErrMsg && <p className="mt-10 color-danger">{newPasswordConfirmErrMsg}</p>}
      </>
    )
  }

  return (
    <div className="popup-wrap mobile-page">
      <div className="popup">
        {onHide && <button className="popup-close close-btn" onClick={onHide} />}
        <p className="fs-24 bold text-center">새 비밀번호 설정</p>
        <p className="mt-10 fs-14 text-center">새로운 비밀번호를 입력해주세요</p>
        <form className="popup-body">
          {renderNewPassword()}
          {renderNewPasswordConfirm()}
          <button className="mt-30 btn btn-primary w-100" disabled={!canSubmit} onClick={requestResetPassword}>
            확인
          </button>
          <hr className="hr mt-40" />
          <p className="mt-40 fs-12 text-center">
            다른 어려움이 있으신가요?{' '}
            <a className="link bold" target="_blank" href="https://forms.gle/mZh4qxDYBTsyY5ax9">
              바인더스 고객지원 센터
            </a>
          </p>
        </form>
      </div>
    </div>
  )
}
