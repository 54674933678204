import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { notificationState } from 'src/recoil/atom/notification'
import { UserProfileImg } from 'src/components/Common/Profile'
import authState from 'src/recoil/atom/auth'
import { getCompanyUrlKeyValidationMsg } from 'src/utils/Vaildiator'
import { AccountCopyLink } from 'src/components/Common/CopyLink'
import CompanyProfileImg from 'src/components/Common/CompanyProfileImg'

export default function SignUpComplete() {
  const [{ account }, setAuthState] = useRecoilState(authState)
  const setNotificationState = useSetRecoilState(notificationState)

  const [step, setStep] = useState(0)
  const [checkedAll, setCheckedAll] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [urlKey, setUrlKey] = useState('')
  const [urlKeyErrMsg, setUrlKeyErrMsg] = useState(null)
  const [sameNameCompanies, setSameNameCompanies] = useState(null)

  let companyNameTimer = null
  let urlKeyTimer = null
  useEffect(() => {
    clearTimeout(urlKeyTimer)
    urlKeyTimer = setTimeout(() => {
      checkDuplicateCompany(urlKey)
    }, 400)
  }, [urlKey])

  useEffect(() => {
    clearTimeout(companyNameTimer)
    companyNameTimer = setTimeout(() => {
      searchCompanyByName(companyName)
    }, 400)
  }, [companyName])

  const changeCompanyName = (e: any) => {
    const companyName = e.target.value
    setCompanyName(companyName)
  }

  const fetchUser = () => {
    api.account
      .fetch()
      .then((response) => {
        setAuthState({
          account: response.data,
          waiting: false,
        })
      })
      .catch((error) => {
        setAuthState({
          account: null,
          waiting: false,
        })
      })
  }

  const checkDuplicateCompany = (urlKey: string) => {
    if (urlKey.length === 0) {
      return
    }

    api.company
      .checkUrlKeyDuplicate(urlKey)
      .then((res) => {
        // setUrlKeyErrMsg('')
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setUrlKeyErrMsg('이미 존재하는 주소에요! 다른 주소로 기입해 주세요.')
        } else {
          setNotificationState({
            type: 'error',
            message: '네트워크 에러',
          })
        }
      })
  }

  const createCompany = () => {
    api.company
      .create({
        name: companyName,
        urlKey: urlKey,
      })
      .then(() => {
        setStep(2)
        fetchUser()
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const goPrevStep = () => {
    setStep(step - 1)
  }

  const goNextStep = () => {
    setStep(step + 1)
  }

  const searchCompanyByName = (name: string) => {
    setSameNameCompanies([])

    if (name.length === 0) {
      return
    }

    api.company
      .getListByName(name)
      .then((res) => {
        setSameNameCompanies(res.data.content || [])
      })
      .catch(() => {
        //
      })
  }

  const renderCompanyNameInput = () => {
    return (
      <>
        <input
          type="text"
          className="input mt-10 w-100"
          placeholder="회사이름 입력"
          onChange={changeCompanyName}
          value={companyName}
        />
        {sameNameCompanies?.length > 0 && (
          <div className="mt-10 bg-color-grey-50" style={{ padding: 16 }}>
            <p className="fs-12">
              같은 이름으로 <span className="color-danger">{sameNameCompanies.length}</span>개의 회사가 등록되어
              있습니다.
            </p>
            <ul className="same-company-list">
              {sameNameCompanies.map((item) => {
                return (
                  <li>
                    <CompanyProfileImg
                      company={item}
                      size={44}
                      onClick={() => {
                        window.open('/c/' + item.urlKey)
                      }}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </>
    )
  }

  const renderUrlKeyInput = () => {
    let inputClassNames = ['input', 'input-lg', 'w-100']
    if (urlKeyErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (urlKey.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="text"
          className={inputClassNames.join(' ')}
          placeholder="스튜디오 주소 입력"
          style={{ paddingLeft: 126 }}
          onChange={(e) => {
            const urlKey = e.target.value.toLowerCase()
            setUrlKeyErrMsg(getCompanyUrlKeyValidationMsg(urlKey))
            setUrlKey(urlKey)
          }}
          value={urlKey}
        />
      </>
    )
  }

  const renderBox = () => {
    if (step === 0) {
      return (
        <div className="box ">
          <div className="mx-auto box-inner-container box-inner-container-md">
            <p className="fs-24 bold pc-text-center">
              스튜디오를 만들고
              <br className="pc-only" /> 회사를 알리기 위한 활동을 시작해 보세요
            </p>
            <p className="mt-40 fs-14 bold">회사이름</p>
            {renderCompanyNameInput()}
            <p className="mt-30 studio-key-title-wrap">
              <span className="fs-14 bold">스튜디오 주소</span>
              <span className="fs-12 color-grey-700">*주소는 개설 후 변경할 수 없으니 신중하게 만들어 주세요.</span>
            </p>
            <div className="mt-10" style={{ position: 'relative' }}>
              {renderUrlKeyInput()}
              <span className="fs-14 color-text-info" style={{ position: 'absolute', top: 14, left: 12 }}>
                www.bindus.kr/c/
              </span>
              {urlKeyErrMsg && (
                <p className="fs-14 color-danger" style={{ marginTop: 6 }}>
                  {urlKeyErrMsg}
                </p>
              )}
            </div>
            <p className="mt-40 fs-14 bold" style={{ marginTop: 60 }}>
              이용약관 및 개인정보처리 방침 동의
            </p>
            <hr className="hr" style={{ marginTop: 8 }} />
            <div style={{ position: 'relative', overflow: 'hidden' }}>
              <div className="checkbox-wrap" style={{ marginRight: 15, marginTop: 16 }}>
                <p className="w-100">
                  <a target={'_blank'} href="https://brand.bindus.kr/rights-and-responsibility">
                    <span className="color-danger bold">(필수)</span> 권리 및 책임 정책 동의 ＞
                    <i className="ico-next-arrow"></i>
                  </a>
                </p>
                <input
                  type="checkbox"
                  className="checkbox ml-10"
                  id="dataCheckAll"
                  checked={checkedAll}
                  onChange={(e) => {
                    setCheckedAll(e.target.checked)
                  }}
                />
                <label htmlFor="dataCheckAll" />
              </div>
            </div>
          </div>
          <div className="mx-auto box-inner-container box-inner-container-sm" style={{ marginTop: 50 }}>
            <div className="btn-wrap">
              <Link className="btn" to="/">
                취소
              </Link>
              <button
                className="btn btn-primary"
                onClick={goNextStep}
                disabled={!checkedAll || urlKeyErrMsg || companyName.trim().length === 0}
              >
                다음
              </button>
            </div>
          </div>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="box">
          <div className="mx-auto box-inner-container box-inner-container-md">
            <p className="fs-24 text-center">관리자 확인</p>
            <p className="text-center mt-10">
              <span className="color-primary bold">{companyName}</span>의 관리자님
            </p>
            <hr className="hr mt-40" />
            <div className="profile-container text-center">
              <UserProfileImg account={account} />
              <p className="mt-20 fs-20 bold">{account.name}</p>
              <p className="mt-10 color-text-info">
                <AccountCopyLink account={account} fontSize={14} />
              </p>
            </div>
            <hr className="hr" />
            <p className="mt-20 fs-14 bold color-text-info text-center">
              스튜디오 관리자는 계정 설정 메뉴에서 변경할 수 있어요.
            </p>
          </div>
          <div className="mx-auto box-inner-container box-inner-container-sm btn-wrap" style={{ marginTop: 50 }}>
            <button className="btn" onClick={goPrevStep}>
              이전
            </button>
            <button className="btn btn-primary" onClick={createCompany}>
              다음
            </button>
          </div>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="box mx-auto" style={{ maxWidth: 864 }}>
          <div className="mx-auto box-inner-container text-center">
            <p className="fs-24 bold">
              이제 플랜 선택만 하면 <span className="color-primary">{companyName}</span>의<br />
              스튜디오가 만들어져요!
            </p>
          </div>
          <div className="mt-20 mx-auto box-inner-container box-inner-container-sm text-center">
            <p className="fs-16">회사소개와 레퍼런스를 만들고, 회사를 알려 보세요.</p>
            <img src="/static/img/join_02@2x.png" className="mt-30 w-100" alt="studio created" />
            <Link className="btn btn-primary" to="/studio/plan/change" style={{ marginTop: 50 }}>
              다음
            </Link>
          </div>
        </div>
      )
    }

    return <></>
  }

  return (
    <div className="page create-studio-page">
      <div className="box-container">{renderBox()}</div>
    </div>
  )
}
