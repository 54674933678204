import { useEffect, useRef, useState } from 'react'
import api from 'src/api'
import { Category } from 'src/api/types/reference'

type Props = {
  label?: string
  value: Category
  defaultSubValue?: string
  onChange?: (category: Category) => void
  showTitle?: boolean
  size?: 'small' | 'default'
  maxCount: number
}

export default function ReferenceEditCategorySelect({
  value = null,
  label,
  onChange,
  showTitle = false,
  size = 'default',
  maxCount = 0,
}: Props) {
  const [categories, setCategories] = useState<Category[]>([])
  const [subCategories, setSubCategories] = useState<Category[]>([])
  const [selectedCategory, setSelectedCategory] = useState(value)
  const [showOption, setShowOption] = useState(false)
  const [mainCategoryNameInfo, setMainCategoryNameInfo] = useState(null)

  const subCategoriesRef = useRef()

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    if (!subCategoriesRef.current) {
      return
    }
    ; (subCategoriesRef.current as any).scrollTo({ top: 0, behavior: 'smooth' })
  }, [subCategories])

  const fetchCategories = () => {
    api.category
      .getList()
      .then((res) => {
        const items = res.data
        const categories: Category[] = []
        let mainNameInfo = {}

        let data: any = {}
        let mainKeys = []
        for (const item of items) {
          const isMain = [10, 11].includes(item.parent?.id)
          if (isMain) {
            data[item.id] = item
            data[item.id].children = []
            mainNameInfo[item.id] = item.name
            mainKeys.push(item.id)
          } else if (data[item.parent?.id]) {
            data[item.parent?.id].children.push(item)
          }
        }

        for (const key of mainKeys) {
          const item = data[key]
          let category: Category = {
            id: item.id,
            name: item.name,
            subCategories: [],
          }

          for (const subItem of item.children) {
            category.subCategories.push(subItem)
          }

          categories.push(category)
        }

        setMainCategoryNameInfo(mainNameInfo)
        setCategories(categories)
      })
      .finally(() => { })
  }

  const toggleShow = () => {
    if (!showOption) {
      setSubCategories([])
    }
    setShowOption(!showOption)
  }

  const renderLabel = () => {
    return (
      <div className="label" onClick={toggleShow}>
        {label ? label : <span className="color-grey-500">카테고리 추가</span>}
      </div>
    )
  }

  const renderOptions = () => {
    return (
      <ul>
        {categories.map((category: Category, index: number) => {
          const isSeleced = selectedCategory?.id === category.id

          return (
            <li
              key={index}
              className={isSeleced ? 'selected' : ''}
              onClick={() => {
                setSelectedCategory(category)
                setSubCategories(category.subCategories)
              }}
            >
              {category.name}
            </li>
          )
        })}
      </ul>
    )
  }

  const renderSubOptions = () => {
    if (subCategories?.length === 0) {
      return <></>
    }

    return (
      <ul ref={subCategoriesRef}>
        {subCategories?.map((category: Category, index: number) => {
          return (
            <li
              key={index}
              onClick={() => {
                setShowOption(false)
                setSelectedCategory(null)
                onChange?.(category)
              }}
            >
              {category.name}
            </li>
          )
        })}
      </ul>
    )
  }

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const ref = useOutsideClick(() => {
    setShowOption(false)
    setSelectedCategory(null)
  })

  let classNames = ['select', 'category-select', 'fs-14', 'has-sub-category']
  if (showOption) {
    classNames.push('show')
  }

  if (showTitle) {
    classNames.push('show-title')
  }

  if (size === 'small') {
    classNames.push('select-sm')
  }

  return (
    <div className={classNames.join(' ')} ref={ref}>
      {renderLabel()}
      {showOption && <div className="mobile-only dim"></div>}
      {showOption && (
        <div className="option-wrap shadow">
          {renderOptions()}
          {renderSubOptions()}
        </div>
      )}
    </div>
  )
}
