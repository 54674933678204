import { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import api from 'src/api'
import { AccountUpdateReq } from 'src/api/types/account'
import FavoriteSelector from 'src/components/FavoriteSelector'
import useAuth from 'src/hooks/useAuth'
import authState from 'src/recoil/atom/auth'
import { getEmailValidationMsg, getBirthdayValidationMsg, getUsernameValidationMsg } from 'src/utils/Vaildiator'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

export default function SignUpComplete() {
  const [searchParams] = useSearchParams()

  const [cookies, setCookie, removeCookie] = useCookies(['needGuide1', 'needGuide2', 'needGuide3', 'bindus_user_id'])

  const [{ account }, setAuthState] = useRecoilState(authState)
  const { logout } = useAuth()

  const timeout = useRef(null)

  const [step, setStep] = useState(-2)
  const [maxStep, setMaxStep] = useState(0)

  const [email, setEmail] = useState(null)
  const [username, setUsername] = useState(null)
  const [birthday, setBirthday] = useState(null)
  const [companyName, setCompanyName] = useState(null)

  const [emailErrMsg, setEmailErrMsg] = useState(null)
  const [usernameErrMsg, setUsernameErrMsg] = useState(null)
  const [birthdayErrMsg, setBirthdayErrMsg] = useState(null)
  const [companyNameErrMsg, setCompanyNameErrMsg] = useState(null)

  const [canChangeEmail, setCanChangeEmail] = useState(false)

  const [isDuplicate, setIsDuplicate] = useState(false)

  const [favoriteIds, setFavoriteIds] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      // 로그인 과정 거치기
      verifyEmail(token)
      setCookie('needGuide1', true)
      setCookie('needGuide2', true)
    } else if (account) {
      setStep(1)
    }

    return () => {}
  }, [])

  useEffect(() => {
    if (account) {
      setEmail(account.email)
      setCanChangeEmail(account.loginType === 'OAuth2')
    } else {
      navigate('/')
    }
  }, [account])

  useEffect(() => {
    const emailErrMsg = getEmailValidationMsg(email)

    const usernameErrMsg = getUsernameValidationMsg(username)
    setUsernameErrMsg(usernameErrMsg)

    const birthdayErrMsg = getBirthdayValidationMsg(birthday)
    setBirthdayErrMsg(getBirthdayValidationMsg(birthday))

    let isPass = true
    if (email === null || email?.length === 0 || emailErrMsg) {
      isPass = false
    }

    if (username === null || username?.length === 0 || usernameErrMsg) {
      isPass = false
    }

    if (birthday === null || birthday?.length === 0 || birthdayErrMsg) {
      isPass = false
    }

    if (companyName === null || companyName?.length === 0) {
      isPass = false
    }

    setMaxStep(isPass ? 2 : 1)
  }, [email, username, birthday, companyName])

  useEffect(() => {
    const emailErrMsg = getEmailValidationMsg(email)
    setEmailErrMsg(emailErrMsg)

    if (!emailErrMsg && canChangeEmail) {
      checkEmailDuplicate()
    }
  }, [email])

  const checkEmailDuplicate = () => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      api.account
        .checkEmailDuplicate(email)
        .then((_) => {
          setIsDuplicate(false)
        })
        .catch((err) => {
          const { status } = err.response
          if (status === 409) {
            setEmailErrMsg('이미 가입된 이메일입니다')
            setMaxStep(1)
            setIsDuplicate(true)
          }
        })
    }, 200)
  }

  const verifyEmail = async (token: string) => {
    await logout()
    api.account
      .verifyEmail(token)
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })
        setStep(1)
      })
      .catch((err) => {
        const { status } = err.response
        if (status === 400) {
          setStep(-1)
        }
      })
  }

  const fetchUser = async () => {
    try {
      const res = await api.account.fetch()
      setCookie('bindus_user_id', res.data.id)
      setAuthState({
        account: res.data,
        waiting: false,
      })
    } catch (e) {
      removeCookie('bindus_user_id')
      setAuthState({
        account: null,
        waiting: false,
      })
    }
  }

  const updateUserInfo = () => {
    const parsedBirthday = birthday.substring(0, 4) + '-' + birthday.substring(4, 6) + '-' + birthday.substring(6, 8)

    const body: AccountUpdateReq = {
      email,
      name: username,
      companyUrlKey: null,
      companyName: companyName,
      companyJson: '[]',
      birthday: parsedBirthday,
      country: null,
      language: null,
      sex: null,
      socials: [],
      acceptMarketing: account.marketingAgreedAt !== null,
    }

    api.account
      .update(body)
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })

        setStep(2)
      })
      .catch((err) => {
        setUsernameErrMsg('')
        setBirthdayErrMsg('업데이트에 실패했습니다. [에러코드: ' + err.response.status + ']')
      })
  }

  const updateCategory = () => {
    api.account.updateCategory(favoriteIds).then(async (res) => {
      await fetchUser()
      if (account.signupType === 'Company') {
        navigate('/studio/create')
      } else {
        setStep(3)
      }
    })
  }

  const goPrevStep = () => {
    setStep(step - 1)
  }

  const goNextStep = () => {
    if (step === 1) {
      updateUserInfo()
    } else if (step === 2) {
      updateCategory()
    } else {
      setStep(step + 1)
    }
  }

  const renderEmail = () => {
    let inputClassNames = ['mt-40', 'input', 'input-lg w-100']
    if (canChangeEmail) {
      if (emailErrMsg !== null) {
        inputClassNames = inputClassNames.concat(['error', 'with-icon'])
      } else if (email?.length > 0) {
        inputClassNames = inputClassNames.concat(['success', 'with-icon'])
      }
    } else {
      inputClassNames.push('disabled')
    }

    return (
      <>
        <input
          type="email"
          className={inputClassNames.join(' ')}
          placeholder="이메일"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          disabled={!canChangeEmail}
          style={{ marginTop: 8 }}
        />
        {emailErrMsg && <p className="mt-10 color-danger">{emailErrMsg}</p>}
      </>
    )
  }

  const renderCompanyName = () => {
    let inputClassNames = ['mt-10', 'input', 'input-lg w-100']
    if (companyNameErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (companyName?.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="text"
          className={inputClassNames.join(' ')}
          placeholder="회사 이름 또는 소속"
          value={companyName}
          onChange={(e) => {
            setCompanyName(e.target.value)
          }}
          style={{ marginTop: 8 }}
        />
        {companyNameErrMsg && <p className="mt-10 color-danger">{companyNameErrMsg}</p>}
      </>
    )
  }

  const renderUserName = () => {
    let inputClassNames = ['mt-10', 'input', 'input-lg w-100']
    if (usernameErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (username?.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="text"
          className={inputClassNames.join(' ')}
          placeholder="사용할 이름"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value)
          }}
          style={{ marginTop: 8 }}
        />
        {usernameErrMsg && <p className="mt-10 color-danger">{usernameErrMsg}</p>}
      </>
    )
  }

  const renderBirthdayInput = () => {
    let inputClassNames = ['mt-10', 'input', 'input-lg w-100']
    if (birthdayErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (birthday?.length === 8) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="number"
          className={inputClassNames.join(' ')}
          placeholder="생년월일 (8자리 숫자로 입력)"
          required
          onChange={(e) => {
            const birthday = String(e.target.value)
            setBirthday(birthday)
          }}
          autoComplete="new-password"
          value={birthday}
          min={0}
          max={999999}
        />
        {birthdayErrMsg && <p className="mt-10 color-danger">{birthdayErrMsg}</p>}
      </>
    )
  }

  const renderBox = () => {
    if (step === -1) {
      return (
        <div className="box">
          <div className="mx-auto box-inner-container box-inner-container-sm text-center">
            <p className="fs-24 bold">이메일 인증이 실패했어요</p>
            <p className="mt-20 fs-14">관리자에게 문의하거나 다시 시도해주세요</p>
          </div>
        </div>
      )
    } else if (step === 0) {
      return (
        <div className="box">
          <div className="mx-auto box-inner-container box-inner-container-sm text-center">
            <p className="fs-24 bold">이메일 인증이 완료되었어요</p>
            <p className="mt-20 fs-14">
              이제 몇 가지 기본 정보를 입력하고
              <br /> 바인더스를 이용하세요.
            </p>
            <button className="btn btn-primary mx-auto w-100" style={{ marginTop: 50 }} onClick={goNextStep}>
              다음
            </button>
          </div>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="box">
          <div className="mx-auto box-inner-container box-inner-container-sm">
            <ul className="circle-pagination">
              <li className="active" />
              <li />
            </ul>
            <p className="fs-24 bold text-center" style={{ marginTop: 50 }}>
              필수 정보 입력
            </p>
            {renderEmail()}
            {renderUserName()}
            {renderBirthdayInput()}
            {renderCompanyName()}
            <button
              className="btn btn-primary w-100"
              style={{ marginTop: 50 }}
              onClick={goNextStep}
              disabled={isDuplicate || maxStep < 2}
            >
              다음
            </button>
          </div>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="box">
          <div className="mx-auto box-inner-container box-inner-container-lg">
            <ul className="circle-pagination">
              <li />
              <li className="active" />
            </ul>
            <p className="fs-24 bold text-center" style={{ marginTop: 50 }}>
              나의 관심 분야
            </p>
            <p className="fs-16 mt-20 text-center">맞춤 추천을 위해 5개 이상 선택해 주세요!</p>
            <hr className="mt-40 hr" />
            <FavoriteSelector
              onChange={(selectedIds: [number]) => {
                setMaxStep(selectedIds.length >= 5 ? 3 : 2)
                setFavoriteIds(selectedIds)
              }}
            />
          </div>
          <div className="mx-auto box-inner-container flex btn-wrap page-foot-btn w-100">
            <button className="btn" onClick={goPrevStep}>
              이전
            </button>
            <button className="btn btn-primary ml-10" onClick={goNextStep} disabled={maxStep < 3}>
              다음
            </button>
          </div>
        </div>
      )
    } else if (step === 3) {
      return (
        <div className="box">
          <div className="mx-auto box-inner-container box-inner-container-md text-center">
            <p className="fs-24 bold">
              바인더스의 회원이 되었어요!
              <br />
              프로필을 완성하면 <br className="mobile-only" />
              <span className="color-primary">맞춤 정보를 추천</span>드려요!
            </p>
            <button
              className="mt-20 mx-auto btn btn-third btn-sm"
              onClick={() => {
                navigate('/my/profile/edit', { state: { showAside: true } })
              }}
            >
              프로필 완성하기
            </button>
            <hr className="hr mt-40" />
            <p className="mt-40 fs-16 bold">
              회사를 운영하고 계시나요?
              <br />
              바인더스에서 회사를 알려 보세요
            </p>
            <Link className="mt-20 mx-auto btn btn-primary w-100" to="/studio/create" style={{ maxWidth: 358 }}>
              스튜디오 만들기
            </Link>
            <div className="mt-20 mx-auto w-100" style={{ maxWidth: 330 }}>
              <Swiper slidesPerView={1} pagination={{ clickable: true }} loop={true} autoplay={true}>
                <SwiperSlide>
                  <img src="/static/img/join_01@2x.png" className="mb-20 mx-auto w-100" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/static/img/join_02@2x.png" className="mb-20 mx-auto w-100" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/static/img/join_03@2x.png" className="mb-20 mx-auto w-100" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }

  return (
    <div className="page signup-complete-page">
      <div className="box-container">{renderBox()}</div>
    </div>
  )
}
