import { useSetRecoilState } from 'recoil'
import { SocialRes } from 'src/api/types/social'
import { notificationState } from 'src/recoil/atom/notification'

type Props = {
  item: SocialRes
  isMine?: boolean
}

export default function SocialListItem({ item, isMine = false }: Props) {
  const setNotificationState = useSetRecoilState(notificationState)

  const { icon } = item.social
  const isCopy = icon === 'kakao' || icon === 'email'

  let message = '링크를 복사했어요'
  let text = item.data
  if (icon === 'kakao') {
    message = '카카오톡 ID를 복사했어요'

    if (!isMine) {
      text = '친구 추가'
    }
  } else if (icon === 'email') {
    message = '이메일 주소를 복사했어요'

    if (!isMine) {
      text = '이메일'
    }
  }

  return (
    <li
      className={isCopy ? '' : 'link'}
      onClick={() => {
        if (isCopy) {
          return
        }

        if (icon === 'website') {
          if (item.data?.indexOf('http') === -1) {
            item.data = 'https://' + item.data
          }
        }
        window.open(item.data)
      }}
    >
      <div className="flex flex-vertical-center">
        <img src={`/static/img/sns/${icon}.svg`} style={{ width: 42 }} />
        <p className="fs-14" style={{ marginLeft: 6, marginRight: 10, wordBreak: 'break-word' }}>
          {text}
        </p>
      </div>
      {isCopy && (
        <button
          className="btn btn-sm"
          onClick={() => {
            const t = document.createElement('textarea')
            document.body.appendChild(t)
            t.value = item.data
            t.select()
            document.execCommand('copy')
            document.body.removeChild(t)

            setNotificationState({
              type: 'link-copy-bl',
              message: message,
            })
          }}
        >
          복사
        </button>
      )}
    </li>
  )
}
