import { Transition } from 'history'
import { useCallback, useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

export function useBlocker(blocker, when = true) {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) return

    const { push } = navigator;
    navigator.push = (...args: Parameters<typeof push>) => {
      blocker(() => {
        push(...args);
      });
    };

    return () => {
      navigator.push = push;
    };


  }, [navigator, blocker, when])
}

export function useBlockerCallback(callback, when = true) {
  const blocker = useCallback(
    (tx: Transition) => {
      callback?.(tx)
    },
    [callback]
  )

  useBlocker(blocker, when)
}
