import request from 'src/utils/Request'

const oauth2Api = {
  // AccessToken으로 로그인
  loginWithToken: (registrationId: string) => {
    return request.get(`/v1/oauth2/authorization/${registrationId}/token`)
  },
}

export default oauth2Api
