import { useSetRecoilState } from 'recoil'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'

type Props = {
  onClose: () => void
}
export default function MoreReferenceBanner({ onClose }: Props) {
  const setPopupState = useSetRecoilState(popupState)

  const signUp = () => {
    setPopupState({ popupType: MainPopupType.SignUp })
    onClose()
  }

  return (
    <div className="more-reference-banner">
      <p className="fs-24 bold text-center" style={{ marginTop: 90 }}>
        더 많은 레퍼런스가 필요하세요?
      </p>
      <p className="mt-10 fs-16 text-center">필요한 정보를 얻고, 협업할 회사를 찾아볼 수 있어요.</p>
      <button className="mt-20 btn btn-primary mx-auto w-100" style={{ maxWidth: 342 }} onClick={signUp}>
        필요해요!
      </button>
    </div>
  )
}
