import request from 'src/utils/Request'

const tagApi = {
  // 태그 검색
  getList: (tag: string, page: number = 0, cancelToken?: any) => {
    return request.get(`/v1/tag`, {
      cancelToken,
      params: { tag: tag.toLowerCase(), page },
    })
  },
  // 태그 추가
  addTag: (tag: string) => {
    return request.post('/v1/tag?tag=' + tag)
  },
}

export default tagApi
