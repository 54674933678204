import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'

export default function StudioPlanUpgraded() {
  const setNotificationState = useSetRecoilState(notificationState)
  const [{ account }, setAuthState] = useRecoilState(authState)

  const isGoStudio = account?.company.plan.plan.id === 0

  useEffect(() => {
    api.company
      .updatePlan(account.company.id, 2)
      .then(() => {
        fetchUser()
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }, [])

  const fetchUser = async () => {
    api.account
      .fetch()
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })
      })
      .catch((error) => {})
  }

  return (
    <div className="popup-wrap mobile-page">
      <div className="popup">
        <p className="fs-24 bold text-center">프로페셔널 플랜으로 설정했어요</p>
        <p className="mt-20 fs-14 text-center">
          프로페셔널 플랜은 <span className="color-red-700">23.10.05</span>까지 무료입니다.
        </p>
        <Link
          className="mt-40 btn btn-outline-grey btn-sm mx-auto"
          style={{ width: 170 }}
          to={isGoStudio ? '/studio/profile' : '/studio/plan'}
        >
          {isGoStudio ? '스튜디오로 이동' : '플랜관리로 이동'}
        </Link>
      </div>
    </div>
  )
}
