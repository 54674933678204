import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { CompanyRes } from 'src/api/types/company'
import MessageSendPopup from 'src/components/Message/SendPopup'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import CompanyMedals from '../Common/CompanyMedals'
import CompanyProfileImg from '../Common/CompanyProfileImg'
import { CompanyCopyLink } from '../Common/CopyLink'
import StudioProfileArea from './StudioProfileArea'

type Props = {
  data: CompanyRes
  editMode: boolean
}

export default function StudioLeftAside({ data, editMode }: Props) {
  const setNotificationState = useSetRecoilState(notificationState)
  const setPopupState = useSetRecoilState(popupState)
  const [{ waiting, account }, setAuthState] = useRecoilState(authState)
  const [isInterest, setIsInterest] = useState(data.isInterest)
  const [showSendMessage, setShowSendMessage] = useState(false)

  const isMobile = useMediaQuery({
    query: '(max-width:1024px)',
  })

  const toggleInterest = () => {
    if (!account) {
      setPopupState({ popupType: MainPopupType.NeedToLogin })
      return
    }

    if (isInterest) {
      api.userInterest
        .deleteCompany(data.id)
        .then(() => {
          setNotificationState({
            type: 'bookmark',
            message: '라이브러리에 관심회사에서 해제했어요',
          })
          setIsInterest(false)
        })
        .catch((e) => {
          // do nothing
        })
    } else {
      api.userInterest
        .addCompany(data.id)
        .then(() => {
          setNotificationState({
            type: 'bookmark',
            message: '라이브러리에 관심회사로 등록했어요',
          })
          setIsInterest(true)
        })
        .catch((e) => {
          // do nothing
        })
    }
  }

  const renderProfileBoxButtons = () => {
    if (editMode) {
      return (
        <Link className="mt-20 mx-auto btn btn-third btn-md" to="/studio/profile/edit">
          회사 프로필 편집
        </Link>
      )
    }

    return (
      <div className="mt-20 flex" style={{ gap: 10 }}>
        <button className="btn btn-primary w-100" onClick={toggleInterest} style={{ zIndex: 8 }}>
          {isInterest ? '관심 해제' : '관심'}
        </button>
        <button
          className="btn btn-primary w-100"
          onClick={() => {
            if (!account) {
              setPopupState({ popupType: MainPopupType.NeedToLogin })
              return
            }

            setShowSendMessage(true)
          }}
          style={{ zIndex: 8 }}
        >
          메시지
        </button>
      </div>
    )
  }

  const renderProfileBox = () => {
    return (
      <div className="profile-box box text-center">
        <CompanyMedals medals={data?.medals} />
        <CompanyProfileImg company={data} size={100} />
        <p className="mt-20 fs-24 bold">{data.name}</p>
        <CompanyCopyLink data={data.urlKey} style={{ marginTop: 8, justifyContent: 'center' }} />
        {renderProfileBoxButtons()}
      </div>
    )
  }

  return (
    <div className="side-area">
      {renderProfileBox()}
      {!isMobile && <StudioProfileArea data={data} editMode={editMode} />}
      {showSendMessage && (
        <MessageSendPopup
          target={data}
          receiverType="Company"
          onClose={() => {
            setShowSendMessage(false)
          }}
        />
      )}
    </div>
  )
}
