import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import api from 'src/api'
import { SelectOption } from 'src/components/Select/Select'
import ReferenceListItem from 'src/components/Studio/ReferenceListItem'
import authState from 'src/recoil/atom/auth'

export default function Index() {
  const navigate = useNavigate()
  const [{ account }, setAuthState] = useRecoilState(authState)
  const [portfolioList, setPortfolioList] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([
    { label: '수정일순', value: 'editedAt' },
    { label: '등록순', value: 'createdAt' },
    { label: '이름순', value: 'title' },
  ])
  const [selectedOptionValue, setSelectedOptionValue] = useState('editedAt');

  useEffect(() => {
    getReferenceList()
  }, [])

  const getReferenceList = () => {
    if (!account.company) {
      return
    }
    api.companyPortfolio.getList(account.company.id, false, false).then((response) => {
      let list = response.data
      list = [...list].sort((a, b) => {
        return b['createdAt'] < a['createdAt'] ? -1 : 0
      })
      setPortfolioList(list)
      setLoading(false)
    })
  }

  const renderEmptyBox = () => {
    return (
      <div className="box empty-box">
        <p className="fs-24 bold">레퍼런스를 등록하여 회사를 홍보하세요!</p>
        <p className="mt-20 fs-16">
          레퍼런스를 등록해야 바인더스에 회사가 노출돼요!
          <br />
          회사의 역량을 보여줄 수 있는 레퍼런스, 지금 바로 만들어 보세요.
        </p>
        <button
          className="mt-40 btn btn-third btn-md pc-only"
          onClick={() => {
            navigate('/studio/reference/create')
          }}
        >
          <i className="ico ico-plus"></i>새 레퍼런스
        </button>
      </div>
    )
  }

  const renderList = () => {
    if (loading) {
      return <div className="spinner with-margin"></div>
    }

    if (portfolioList.length === 0) {
      return renderEmptyBox()
    }

    return (
      <>
        <div className="filter-wrap">
          <button
            className="btn btn-third btn-md pc-only"
            onClick={() => {
              navigate('/studio/reference/create')
            }}
          >
            <i className="ico ico-plus"></i>새 레퍼런스
          </button>
          <div className="flex-grow-1"></div>
          <ul className='order-filters'>
            {selectOptions.map(option => {
              return <li className={option.value === selectedOptionValue ? 'active' : ''} onClick={() => {
                const value = option.value
                setSelectedOptionValue(value)
                setPortfolioList(
                  [...portfolioList].sort((a, b) => {
                    return b[value] < a[value] ? -1 : 0
                  })
                )
              }}>{option.label}</li>
            })}
          </ul>
        </div>
        {portfolioList.map((item) => {
          return (
            <ReferenceListItem
              item={item}
              companyId={account.company.id}
              key={item.id}
              onRefresh={() => {
                getReferenceList()
              }}
            />
          )
        })}
      </>
    )
  }

  return (
    <div className={`page studio-reference-page ${portfolioList?.length === 0 ? 'empty-page' : ''}`}>
      <div className="box-container container">
        {renderList()}
      </div>
      <div className="pc-only" style={{ height: 50 }}></div>
      <div className="btn-wrap page-foot-btn mobile-only">
        <button className="btn btn-third"
          onClick={() => {
            navigate('/studio/reference/create')
          }}>
          <i className="ico ico-plus"></i>새 레퍼런스
        </button>
      </div>
    </div>
  )
}
