import request from 'src/utils/Request'

const userInterestApi = {
  // 관심 포트폴리오 추가
  addPortfolio: (portfolioId: number) => {
    return request.post(`/v1/account/interest/portfolio/${portfolioId}`)
  },
  // 관심 포트폴리오 삭제
  deletePortflio: (portfolioId: number) => {
    return request.delete(`/v1/account/interest/portfolio/${portfolioId}`)
  },
  // 관심 회사 추가
  addCompany: (companyId: number) => {
    return request.post(`/v1/account/interest/company/${companyId}`)
  },
  // 관심 회사 삭제
  deleteCompany: (companyId: number) => {
    return request.delete(`/v1/account/interest/company/${companyId}`)
  },
  // 내 관심 포트폴리오
  getPortfolioList: () => {
    return request.get('/v1/account/interest/portfolio')
  },
  // 내 관심 회사
  getCompanyList: () => {
    return request.get('/v1/account/interest/company')
  },
}

export default userInterestApi
