import { useState } from 'react'

type Props = {
  defaultText?: string
  placeholder?: string
  onChange: (birthday: string) => void
}

export default function TextInput({ defaultText, placeholder, onChange }: Props) {
  const [text, setText] = useState(defaultText)

  const renderTextInput = () => {
    let inputClassNames = ['input', 'w-100']
    if (text !== null && text?.length === 0) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    }

    return (
      <input
        type="text"
        className={inputClassNames.join(' ')}
        placeholder={placeholder}
        value={text}
        onChange={(e) => {
          const text = String(e.target.value)
          setText(text)
          onChange(text)
        }}
        required
      />
    )
  }

  return renderTextInput()
}
