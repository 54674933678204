import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSetRecoilState } from 'recoil'
import api from 'src/api'
import { Tag } from 'src/api/types/reference'
import { notificationState } from 'src/recoil/atom/notification'
import TagSearchInput from '../Common/TagSearchInput'
import StudioMaxTagConfirm from '../Popup/StudioMaxTagConfirm'
import ReferenceEditTagSearchLayer from './ReferenceEditTagSearchLayer'

type Props = {
  defaultTags: Tag[]
  maxCount: number
  onChange: (tags: Tag[]) => void
}
export default function ReferenceEditTag({ defaultTags, maxCount = 15, onChange }: Props) {
  const setNotificationState = useSetRecoilState(notificationState)

  const [tags, setTags] = useState(defaultTags)
  const [searchKeyword, setSearchKeyword] = useState(null)
  const [showMaxPopup, setShowMaxPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showTagSearchLayer, setShowTagSearchLayer] = useState(false)
  const referenceTagRef = useRef(null)

  useEffect(() => {
    onChange(tags)
  }, [tags])

  const addSearchKeyword = () => {
    setLoading(true)
    api.tag
      .addTag(encodeURIComponent(searchKeyword))
      .then((res) => {
        addTag(res.data)
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: e.response.data?.message || '네트워크 에러',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const addTag = (tag: Tag) => {
    if (maxCount <= tags.length) {
      setShowMaxPopup(true)
      return
    }

    const items = tags.filter((item) => item.id === tag.id)
    if (items.length > 0) {
      return
    }

    const newTags = [...tags, tag]
    setTags(newTags)
    setSearchKeyword('')
  }

  const deleteTag = (tag: Tag) => {
    const items = tags.filter((item) => item.id !== tag.id)
    setTags(items)
  }

  return (
    <div>
      {tags.length > 0 && (
        <div className="mt-10 tag-box">
          {tags.map((tag) => {
            return (
              <div key={tag.id} className="btn btn-sm"
                onMouseEnter={(e) => {
                  referenceTagRef.current = (new Date()).getTime();
                  (e.target as any).classList.add('active')
                }}
                onMouseLeave={(e) => {
                  const timeDiff = (new Date().getTime()) - referenceTagRef.current
                  if (timeDiff > 100) {
                    (e.target as any).classList.remove('active')
                  }
                }}
              >
                {tag.name}
                <div
                  className="delete-ico"
                  onClick={(e) => {
                    if (window.innerWidth > 500) {
                      deleteTag(tag)
                      return
                    }

                    let classList = e.currentTarget.classList
                    if (classList.contains('active') && window.innerWidth < 500) {
                      deleteTag(tag)
                    } else {
                      classList.add('active')
                    }
                  }}
                ></div>
              </div>
            )
          })}
        </div>
      )}
      <div className="mt-10 flex flex-row">
        <TagSearchInput
          searchKeyword={searchKeyword}
          changeSearchKeyword={(keyword) => {
            setSearchKeyword(keyword)
          }}
          exceptTags={tags}
          addSearchKeyword={addSearchKeyword}
          onFocus={() => {
            setShowTagSearchLayer(true)
          }}
          onChange={(tag) => {
            addTag(tag)
          }}
        />
        <button
          className="ml-10 btn btn-primary btn-md"
          disabled={!searchKeyword || searchKeyword?.length === 0}
          onClick={addSearchKeyword}
        >
          <i className="ico ico-plus-wh ico-sm"></i>
          추가
        </button>
      </div>
      {isMobile && showTagSearchLayer && (
        <ReferenceEditTagSearchLayer
          tags={tags}
          maxCount={maxCount}
          showMaxPopup={() => {
            setShowMaxPopup(true)
          }}
          onChange={(tags) => {
            setTags(tags)
          }}
          onClose={() => {
            setShowTagSearchLayer(false)
          }}
        />
      )}
      {showMaxPopup && (
        <StudioMaxTagConfirm
          maxCount={maxCount}
          onHide={() => {
            setShowMaxPopup(false)
          }}
        />
      )}
    </div>
  )
}
