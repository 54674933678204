import request from 'src/utils/Request'
import { Account, AccountActiveStatus, AccountOption, AccountUpdateReq, UserType } from './types/account'
import qs from 'qs'

const accountApi = {
  // 내 정보
  fetch: () => {
    return request.get<Account>('/v1/account')
  },
  // 내 정보 수정
  update: (body: AccountUpdateReq) => {
    return request.put('/v1/account', body)
  },
  // 회원가입
  create: (username: string, password: string, signupType: UserType, acceptMarketing: boolean) => {
    return request.post('/v1/account', { username, password, signupType, acceptMarketing })
  },
  // 계정 삭제
  delete: (reason: string[]) => {
    return request.delete('/v1/account', {
      params: {
        reason,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false })
      },
    })
  },
  // 비밀번호 수정
  resetPassword: (password: string) => {
    return request.put('/v1/account/password', password, { headers: { 'content-type': 'text/plain' } })
  },
  updateOption: (option: AccountOption) => {
    if (!option.needGuide) {
      option.needGuide = false
    }

    return request.put('/v1/account/option', option)
  },
  // 프로필 이미지 변경
  updateProfileImage: (image: any) => {
    return request.put(
      '/v1/account/image',
      { image },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
  // 내 관심 태그 설정
  updateTag: (tags: number[]) => {
    return request.post('/v1/account/tag', tags)
  },
  // 로그인
  login: (username: string, password: string) => {
    return request.post<Account>('/v1/account/login', { username, password })
  },
  // 이메일 인증 메일 보내기
  sendVerifyEmail: (email: string) => {
    return request.post(`/v1/account/email/verify/send?email=${email}`)
  },
  // 로그아웃
  logout: () => {
    return request.post('/v1/account/logout')
  },
  // 토큰으로 비밀번호 재설정
  resetPasswordByToken: (token: string, password: string) => {
    return request.post('/v1/account/email/passwordreset?token=' + token, password, {
      headers: { 'content-type': 'text/plain' },
    })
  },
  // 비밀번호 재설정 메일 보내기
  sendResetPasswordEmail: (email: string) => {
    return request.post(`/v1/account/email/passwordreset/send?email=${email}`)
  },
  // 내 관심 카테고리 설정
  updateCategory: (categories: number[]) => {
    return request.post('/v1/account/category', categories)
  },
  // 계정 활성 상태 변경
  updateActivateStatus: (activeState: AccountActiveStatus, disableReason?: string[]) => {
    return request.post(`/v1/account/active-state`, null, {
      params: {
        activeState,
        disableReason,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false })
      },
    })
  },
  // 사용자 정보
  get: (accountId: number) => {
    return request.get(`/v1/account/${accountId}`)
  },
  // 사용자 urlKey 검색
  getByUrlKey: (urlKey: string) => {
    return request.get(`/v1/account/urlKey`, { params: { urlKey } })
  },
  // 사용자 검색
  search: (keyword: string) => {
    return request.get(`/v1/account/search`, { params: { keyword: keyword.toLowerCase() } })
  },
  // 이메일 인증
  verifyEmail: (token: string) => {
    return request.get<Account>('/v1/account/email/verify', { params: { token } })
  },
  // 이메일 가입 여부 확인
  checkEmailDuplicate: (email: string) => {
    return request.get('/v1/account/email/check', { params: { email } })
  },
  // 로그아웃
  signout: () => {
    return request.get('/v1/logout')
  },
}

export default accountApi
