import request from 'src/utils/Request'
import { Message, MessageListRes } from './types/message'

const companyMessageApi = {
  // 메시지 보내기
  send: (companyId: number, type: 'user' | 'company', id: number, message: string) => {
    return request.post(`/v1/company/${companyId}/message/${type}/${id}`, message, {
      headers: { 'content-type': 'text/plain' },
    })
  },
  // 메시지 리스트
  getList: (companyId: number, onlyRead: boolean, page: number) => {
    return request.get<MessageListRes>(`/v1/company/${companyId}/message`, { params: { onlyRead, page } })
  },
  // 읽지 않은 메시지
  getUnreadList: (companyId: number) => {
    return request.get<Message[]>(`/v1/company/${companyId}/message/unread`)
  },
  // 보낸 메시지 리스트
  getSentList: (companyId: number, page: number) => {
    return request.get<MessageListRes>(`/v1/company/${companyId}/message/sent`, { params: { page } })
  },
}

export default companyMessageApi
