import request from 'src/utils/Request'
import { ReferenceRes } from './types/reference'

const portfolio = {
  // 포트폴리오 검색
  getList: (categoryId?: number, tagId?: number, keyword?: string, searchType?: string, page: number = 0, size: number = 50) => {
    return request.get<any>(`/v1/portfolio`, { params: { categoryId, tagId, keyword, searchType, page, size } })
  },
  // 포트폴리오 상세
  get: (portfolioId: number) => {
    return request.get<ReferenceRes>(`/v1/portfolio/${portfolioId}`)
  },
  // 포트폴리오 공유
  getShare: (portfolioId: number) => {
    return request.get(`/v1/portfolio/${portfolioId}/share`)
  },
  // 포트폴리오 저장 사용자 목록
  getInterestUsers: (portfolioId: number) => {
    return request.get(`/v1/portfolio/${portfolioId}/interest/user`)
  },
  // 포트폴리오 방문 기록
  getVisitLogs: (page: number) => {
    return request.get('/v1/portfolio/visit', { params: { page } })
  },
  // 포트 폴리오 방문기록 삭제
  deleteVisitLogs: (ids: number[]) => {
    let params = new URLSearchParams()
    for (const id of ids) {
      params.append('ids', String(id))
    }

    return request.delete('/v1/portfolio/visit?' + params.toString())
  },
  // 포트폴리오 검색 개요
  getSummary: (categoryId?: number, tagId?: string, keyword?: string) => {
    return request.get('/v1/portfolio/summary', { params: { categoryId, tagId, keyword } })
  },
  // 메인 배너
  getMainBanner: (categoryId: number) => {
    return request.get('/v1/portfolio/banner', { params: { categoryId } })
  },
}

export default portfolio
