import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import authState from 'src/recoil/atom/auth'
import useAuth from 'src/hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import ResetPasswordPopup from 'src/components/Popup/ResetPassword'
import DeactiveConfirmPopup from 'src/components/Popup/UserDeactivate'
import SignoutConfirmPopup from 'src/components/Popup/UserDelete'
import api from 'src/api'
import BirthdayInput from 'src/components/Input/BirthdayInput'
import { useSetRecoilState } from 'recoil'
import { notificationState } from 'src/recoil/atom/notification'
import { getUserLink, UserProfileImg } from 'src/components/Common/Profile'
import EmailInput from 'src/components/Input/EmailInput'

export default function Index() {
  const { logout } = useAuth()
  const navigate = useNavigate()
  const [{ account }, setAuthState] = useRecoilState(authState)

  const [isModifyMode, setIsModifyMode] = useState(false)

  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false)
  const [showDeactiveConfirmPopup, setShowDeactiveConfirmPopup] = useState(false)
  const [showSignoutConfirmPopup, setShowSignoutConfirmPoup] = useState(false)

  const [email, setEmail] = useState(account?.email)
  const [birthday, setBirthday] = useState(account?.birthday?.split('-').join('') || '')
  const [sex, setSex] = useState(account.sex !== null ? account.sex : -1)

  const setNotificationState = useSetRecoilState(notificationState)

  const parseBirthday = (birthday: string) => {
    if (birthday.length < 8) {
      return '정보 없음'
    }
    return birthday.substring(0, 4) + '-' + birthday.substring(4, 6) + '-' + birthday.substring(6, 8)
  }

  const updateAccountInfo = () => {
    if (birthday.length < 8) {
      return
    }

    const parsedBirthday = parseBirthday(birthday)

    let items = []
    for (const social of account.socials) {
      items.push({
        socialId: social.social.id,
        data: social.data,
      })
    }

    let data: any = {
      name: account?.name,
      sex,
      birthday: parsedBirthday,
      companyName: account.companyName,
      companyJson: account.companyJson,
      socials: items,
    }

    if (account.loginType === 'OAuth2') {
      if (!email || email?.length === 0) {
        setNotificationState({
          type: 'error',
          message: '이메일 주소를 입력해 주세요',
        })
        return
      }

      data.email = email
    }

    api.account
      .update(data)
      .then((res) => {
        setNotificationState({
          type: 'check',
          message: '저장되었습니다',
        })

        setAuthState({
          account: res.data,
          waiting: false,
        })

        setIsModifyMode(false)
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const confirmDeactivate = () => {
    setShowDeactiveConfirmPopup(true)
  }

  const confirmSignout = () => {
    setShowSignoutConfirmPoup(true)
  }

  const renderModifyInfo = () => {
    return (
      <div className="box-inner-container box-inner-container-sm">
        {account.loginType === 'OAuth2' && (
          <>
            <p className="fs-14 mt-20 mb-10 color-text-info">이메일</p>
            <EmailInput
              defaultEmail={email}
              showSuccessIcon={true}
              needDuplicateCheck={true}
              onChange={(email) => {
                setEmail(email)
              }}
            />
          </>
        )}
        <p className="fs-14 mt-20 color-text-info">생년월일</p>
        <BirthdayInput
          defaultBirthday={birthday}
          onChange={(birthday) => {
            setBirthday(birthday)
          }}
        />
        {/* <p className="fs-14 mt-20 color-text-info">국가 및 언어</p>
        <input type="text" className="input mt-10" /> */}
        <p className="fs-14 mt-20 color-text-info">성별</p>
        <div className="mt-10 checkbox-wrap" style={{ gap: 20 }}>
          <input
            type="checkbox"
            id="sex0-check"
            className="checkbox green"
            checked={sex === 0}
            onChange={(e) => {
              setSex(0)
            }}
          />
          <label htmlFor="sex0-check">여자</label>
          <input
            type="checkbox"
            id="sex1-check"
            className="checkbox green"
            checked={sex === 1}
            onChange={(e) => {
              setSex(1)
            }}
          />

          <label htmlFor="sex1-check">남자</label>
        </div>
      </div>
    )
  }

  const renderInfo = () => {
    return (
      <>
        {account.loginType === 'OAuth2' && (
          <>
            <p className="fs-14 mt-20 color-text-info">이메일</p>
            <p className="fs-16 mt-10">{account.email}</p>
          </>
        )}
        <p className="fs-14 mt-20 color-text-info">생년월일</p>
        <p className="fs-16 mt-10">{parseBirthday(birthday)}</p>
        {/* <p className="fs-14 mt-20 color-text-info">국가 및 언어</p>
        <p className="fs-16 mt-10">대한민국</p> */}
        <p className="fs-14 mt-20 color-text-info">성별</p>
        <p className="fs-16 mt-10">{{ 0: '여자', 1: '남자' }[sex] ? { 0: '여자', 1: '남자' }[sex] : '정보 없음'}</p>
      </>
    )
  }

  return (
    <div className="page bg-color-grey-50">
      <div className="box-container container mobile-p-0">
        <div className="mobile-only bg-white" style={{ padding: '30px 0' }}>
          <div className="user-profile-area mobile-row">
            <UserProfileImg account={account} />
            <div>
              <p className="fs-18 bold">
                <Link to={getUserLink(account)}>{account.name}</Link>
              </p>
              <p className="fs-12 color-text-secondary">
                <Link style={{ marginTop: 4 }} to={getUserLink(account)}>
                  {process.env.REACT_APP_BASE_URL}
                  {getUserLink(account)}
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="box">
          <p className="fs-16 bold">계정 관리</p>
          {account.loginType === 'OAuth2' ? (
            <>
              <p className="fs-14 mt-20 color-text-info">이메일</p>
              <p className="fs-16 mt-10 flex flex-vertical-center">
                <img src="/static/img/ico/kakao2.svg" style={{ width: 22, height: 22, marginRight: 8 }} />
                카카오톡 계정 연결
              </p>
              <p className="fs-14 mt-20 color-text-info">비밀번호</p>
              <p className="fs-12 mt-10 color-danger">카카오톡 계정에서 변경하실 수 있습니다</p>
            </>
          ) : (
            <>
              <p className="fs-14 mt-20 color-text-info">이메일</p>
              <p className="fs-16 mt-10"> {account?.username} </p>
              <p className="fs-14 mt-20 color-text-info">비밀번호</p>
              <button
                className="mt-10 btn btn-outline-grey btn-sm"
                onClick={() => {
                  setShowResetPasswordPopup(true)
                }}
              >
                비밀번호 재설정
              </button>
            </>
          )}
        </div>
        <div className="box">
          <p className="fs-16 bold">개인정보</p>
          <div style={{ position: 'absolute', top: 24, right: 16 }}>
            {!isModifyMode && (
              <button
                className="btn btn-third btn-sm"
                onClick={() => {
                  setIsModifyMode(true)
                }}
              >
                개인정보 수정
              </button>
            )}
            {isModifyMode && (
              <div className="flex">
                <button
                  className="btn btn-sm"
                  onClick={() => {
                    setIsModifyMode(false)
                  }}
                >
                  취소
                </button>
                <button className="ml-10 btn btn-primary btn-sm" onClick={updateAccountInfo}>
                  수정
                </button>
              </div>
            )}
          </div>

          {isModifyMode ? renderModifyInfo() : renderInfo()}
        </div>
        {account?.company ? (
          <></>
        ) : (
          <div className="box">
            <p className="fs-16 bold">비즈니스 계정</p>
            <p className="fs-16 mt-10">바인더스 스튜디오를 만들고, 우리 회사를 알려보세요!</p>
            <p style={{ marginTop: 12 }}>
              <a className="color-danger" href="https://brand.bindus.kr/#company" target="_blank">
                바인더스 스튜디오 알아보기 ＞
              </a>
            </p>
            <Link
              className="mt-20 btn btn-studio-gradient btn-lg"
              style={{ width: '100%', maxWidth: 358 }}
              to="/studio/create"
            >
              스튜디오 만들기
            </Link>
          </div>
        )}
        <div className="box">
          <p className="fs-16 bold">약관 및 정책</p>
          <p className="mt-20 fs-14">
            <a href="https://brand.bindus.kr/terms-of-use" target={'_blank'}>
              서비스 이용약관 전문 보기 ＞
            </a>
          </p>
          <p className="fs-14" style={{ marginTop: 16 }}>
            <a href="https://brand.bindus.kr/privacypolicy" target={'_blank'}>
              개인정보 취급 정책 전문 보기 ＞
            </a>
          </p>
          <p className="fs-14" style={{ marginTop: 16 }}>
            <a href="https://brand.bindus.kr/marketing-reception" target={'_blank'}>
              마케팅 정보 수신 및 활용 ＞
            </a>
          </p>
        </div>
        <div className="box">
          <p className="fs-16 bold">계정 비활성화 및 회원 탈퇴</p>
          <button
            className="mt-10 btn btn-outline btn-sm"
            style={{ position: 'absolute', top: 16, right: 16 }}
            onClick={() => {
              logout()
              navigate('/')
            }}
          >
            로그아웃
          </button>
          <button className="mt-10 btn btn-outline-grey btn-sm" onClick={confirmDeactivate}>
            계정 비활성화
          </button>
          <p className="fs-14 color-text-info" style={{ marginTop: 8 }}>
            계정을 비활성화하면, 내 프로필이 비공개로 전환됩니다.
          </p>
          <button className="mt-10 btn btn-outline-grey btn-sm" onClick={confirmSignout}>
            회원탈퇴
          </button>
          <p className="fs-14 color-danger" style={{ marginTop: 8 }}>
            회원 탈퇴하면, 모든 데이터가 삭제되며 복구할 수 없습니다.
          </p>
        </div>
      </div>
      {showResetPasswordPopup && (
        <ResetPasswordPopup
          onHide={() => {
            setShowResetPasswordPopup(false)
          }}
        />
      )}
      {showDeactiveConfirmPopup && (
        <DeactiveConfirmPopup
          accountId={account?.id}
          onHide={() => {
            setShowDeactiveConfirmPopup(false)
          }}
        />
      )}
      {showSignoutConfirmPopup && (
        <SignoutConfirmPopup
          onHide={() => {
            setShowSignoutConfirmPoup(false)
          }}
        />
      )}
    </div>
  )
}
