import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  onClose: () => void
}
export default function DetailGuide({ onClose }: Props) {
  const navigate = useNavigate()
  const [step, setStep] = useState(4)

  const goNext = (e) => {
    e.stopPropagation()
    setStep(step + 1)

    if (step == 6) {
      navigate('/my/library')
    }
  }

  const renderGuideItem = () => {
    if (step === 4) {
      return (
        <div className="step-4">
          <div>
            <img className="pc-only img" src="/static/img/guide/w_guide_ref_04.png" onClick={goNext} />
            <img className="mobile-only img" src="/static/img/guide/m_guide_ref_08.png" onClick={goNext} />
          </div>
          <div>
            <div className="message-box">
              <img className="pc-only" src="/static/img/guide/guide_ref_b_04.png" />
              <img className="mobile-only" src="/static/img/guide/guide_ref_b_05.png" />
              <div className="message-box-content">
                <p className="fs-16">
                  회사 로고를 클릭하여, <br />
                  관심 있는 회사 단독 페이지에 방문해 보세요.
                </p>
              </div>
              <div className="message-box-footer">
                <span className="fs-13 bold color-grey-500 link" onClick={onClose}>
                  SKIP
                </span>
                <span className="w-100"></span>
                <span className="fs-13 bold color-grey-500">3/1</span>
                <span className="fs-13 bold link" onClick={goNext}>
                  다음 ＞
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (step === 5) {
      return (
        <div className="step-5">
          <div>
            <img className="pc-only img" src="/static/img/guide/w_guide_ref_05.png" onClick={goNext} />
            <img className="mobile-only img" src="/static/img/guide/m_guide_ref_09.png" onClick={goNext} />
          </div>
          <div>
            <div className="message-box">
              <img className="pc-only" src="/static/img/guide/guide_ref_b_04.png" />
              <img className="mobile-only" src="/static/img/guide/guide_ref_b_06.png" />
              <div className="message-box-content">
                <p className="fs-16">
                  메시지를 보내보세요. <br />
                  궁금한 점을 회사에 직접 문의할 수 있어요.
                </p>
              </div>
              <div className="message-box-footer">
                <span className="fs-13 bold color-grey-500 link" onClick={onClose}>
                  SKIP
                </span>
                <span className="w-100"></span>
                <span className="fs-13 bold color-grey-500">3/2</span>
                <span className="fs-13 bold link" onClick={goNext}>
                  다음 ＞
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (step === 6) {
      return (
        <div className="step-6">
          <div>
            <img className="pc-only img" src="/static/img/guide/w_guide_ref_06.png" onClick={goNext} />
            <img className="mobile-only img" src="/static/img/guide/m_guide_ref_10.png" onClick={goNext} />
          </div>
          <div>
            <div className="message-box">
              <img className="pc-only" src="/static/img/guide/guide_ref_b_04.png" />
              <img className="mobile-only" src="/static/img/guide/guide_ref_b_07.png" />
              <div className="message-box-content">
                <p className="fs-16">
                  마음에 드는 레퍼런스는 번개를 클릭하여,
                  <br />
                  마이메뉴 ＞ 아카이브에 모을 수 있어요.
                </p>
              </div>
              <div className="message-box-footer">
                <a className="fs-13 bold color-grey-500 link" href="https://brand.bindus.kr/howto" target={'_blank'}>
                  활용방안 보러가기 ＞
                </a>
                <span className="w-100"></span>
                <span className="fs-13 bold color-grey-500">3/3</span>
                <span className="fs-13 bold link" onClick={onClose}>
                  닫기
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }

  return (
    <div className="guide-overlay">
      <div className="detail-guide">{renderGuideItem()}</div>
    </div>
  )
}
