import account from './account'
import accountCompany from './accountCompany'
import banner from './banner'
import category from './category'
import company from './company'
import companyMessage from './companyMessage'
import companyPortfolio from './companyPortfolio'
import config from './config'
import image from './image'
import json from './json'
import message from './message'
import oauth2 from './oauth2'
import portfolio from './portfolio'
import pushMessage from './pushMessage'
import search from './search'
import social from './social'
import tag from './tag'
import toss from './toss'
import userInterest from './userInterest'

const api = {
  config,
  companyMessage,
  company,
  companyPortfolio,
  userInterest,
  accountCompany,
  account,
  message,
  image,
  tag,
  social,
  portfolio,
  oauth2,
  json,
  category,
  toss,
  search,
  banner,
  pushMessage,
}

export default api
