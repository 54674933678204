import { useEffect, useState, ReactNode, useRef } from 'react'

export type SelectOption = {
  value: string
  label: string
  subOptions?: SelectOption[]
}

type Props = {
  title?: string
  options: SelectOption[]
  defaultLabel?: string
  value?: string
  hasSubOption?: boolean
  onChange?: (option: SelectOption) => void
  customLabel?: ReactNode
  showTitle?: boolean
  size?: 'small' | 'default'
  className?: string
}

export default function Select({
  title,
  options,
  defaultLabel,
  value,
  onChange,
  hasSubOption = false,
  customLabel = null,
  showTitle = false,
  size = 'default',
  className = '',
}: Props) {
  const [label, setLabel] = useState(defaultLabel)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedSubOption, setSelectedSubOption] = useState(null)
  const [showOption, setShowOption] = useState(false)
  const [subOptions, setSubOptions] = useState<SelectOption[]>()

  useEffect(() => {
    if (hasSubOption && options.length > 0) {
      setSubOptions(options[0].subOptions)
    }

    if (value) {
      for (const option of options) {
        if (option.value === value) {
          setSelectedOption(option.value === value)
          setLabel(option.label)
        }
      }
    }
  }, [])

  const clickOption = (option: SelectOption) => {
    setShowOption(false)
    onChange?.(option)
  }

  const toggleShow = () => {
    setShowOption(!showOption)
  }

  const renderLabel = () => {
    if (customLabel) {
      return (
        <div className="custom-label" onClick={toggleShow}>
          {customLabel}
        </div>
      )
    }

    if (hasSubOption && selectedOption && selectedSubOption) {
      return (
        <div className="label" onClick={toggleShow}>
          {selectedOption?.label}
        </div>
      )
    }

    return <div className="label" onClick={toggleShow} dangerouslySetInnerHTML={{ __html: label }}></div>
  }

  const renderOptions = (options: SelectOption[]) => {
    return (
      <ul>
        {options.map((option: SelectOption, index: number) => {
          return (
            <li
              key={index}
              className={selectedOption?.value === option.value ? 'selected' : ''}
              onMouseOver={() => {
                if (hasSubOption) {
                  setSubOptions(option.subOptions || [])
                }
              }}
              onClick={() => {
                setSelectedOption(option)
                if (hasSubOption && option.subOptions) {
                  setSubOptions(option.subOptions)
                } else {
                  setLabel(option.label)
                  clickOption(option)
                }
              }}
              dangerouslySetInnerHTML={{ __html: option.label }}
            ></li>
          )
        })}
      </ul>
    )
  }

  const renderSubOptions = () => {
    if (!hasSubOption) {
      return
    }

    return (
      <ul>
        {subOptions?.map((option: SelectOption, index: number) => {
          return (
            <li
              className={selectedSubOption?.value === option.value ? 'selected' : ''}
              onClick={() => {
                clickOption(option)
                setSelectedSubOption(option)
              }}
              key={index}
              dangerouslySetInnerHTML={{ __html: option.label }}
            ></li>
          )
        })}
      </ul>
    )
  }

  const getClassName = () => {
    let classNames = [className, 'select', 'fs-14']
    if (showOption) {
      classNames.push('show')
    }

    if (hasSubOption) {
      classNames.push('has-sub-option')
    }

    if (showTitle) {
      classNames.push('show-title')
    }

    if (size === 'small') {
      classNames.push('select-sm')
    }

    return classNames.join(' ')
  }

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const ref = useOutsideClick(() => {
    setShowOption(false)
  })

  return (
    <div className={getClassName()} ref={ref}>
      {renderLabel()}
      {showOption && (
        <div
          className="mobile-only dim"
          onClick={() => {
            setShowOption(false)
          }}
        ></div>
      )}
      <div className="option-wrap shadow">
        <div className="title bold color-text-regular">
          {title}
          <div
            className="close-btn mobile-only"
            onClick={() => {
              setShowOption(false)
            }}
          />
        </div>
        {renderOptions(options)}
        {renderSubOptions()}
      </div>
    </div>
  )
}
