import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from 'src/api'
import { getUserLink, UserProfileImg } from '../Common/Profile'

type Props = {
  portfolioId: number
  onHide: () => void
}
export default function ReferenceListItemSaver({ portfolioId, onHide }: Props) {
  const navigate = useNavigate()

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchList()
  }, [])

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const ref = useOutsideClick(onHide)

  const fetchList = () => {
    api.portfolio
      .getInterestUsers(portfolioId)
      .then((res) => {
        // let testUsers = []
        // for (let i = 0; i < 10; i++) {
        //   testUsers = testUsers.concat(res.data)
        // }
        // setUsers(testUsers)
        setUsers(res.data)
        setLoading(false)
      })
      .catch((e) => {
        // Todo: 팝업
        setLoading(false)
        onHide()
      })
  }

  const renderUsers = () => {
    if (users?.length === 0) {
      return <></>
    }

    return (
      <ul className="user-list">
        {users.map((item, i) => {
          const isUnkownUser = item.activeStatus === 'Delete' || item.activeStatus === 'Disable'
          return (
            <li
              key={i}
              onClick={() => {
                if (isUnkownUser) {
                  return
                }

                navigate(getUserLink(item))
              }}
            >
              {isUnkownUser ? (
                <>
                  <UserProfileImg account={item} size={32} />
                  <div style={{ marginLeft: 12 }}>
                    <p className="fs-14 color-grey-600">알 수 없는 사용자</p>
                  </div>
                </>
              ) : (
                <>
                  <UserProfileImg account={item} size={32} />
                  <div style={{ marginLeft: 12 }}>
                    <p className="fs-14">{item.name ? item.name : '-'}</p>
                    {item.company && <p className="fs-12 color-primary">{item.company?.name}</p>}
                  </div>
                </>
              )}
            </li>
          )
        })}
      </ul>
    )
  }

  const renderContent = () => {
    if (loading) {
      return <div className="spinner with-small-margin"></div>
    }

    return (
      <>
        <div className="title-area">
          <p className="fs-16 bold">{users.length} 명이 저장했어요</p>
          <button className="close-btn" onClick={onHide}></button>
        </div>
        {renderUsers()}
      </>
    )
  }

  return (
    <div className="reference-saver-layer" ref={ref}>
      {renderContent()}
    </div>
  )
}
