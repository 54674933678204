import { useEffect } from 'react'
import { notificationState, INotificationState } from 'src/recoil/atom/notification'
import { useRecoilState } from 'recoil'

export function useNotification() {
  const [notification, setNotification] = useRecoilState<INotificationState>(notificationState)

  const showNotification = (props: any) => {
    setNotification({
      ...notification,
      ...props,
    })
  }

  return { notification, setNotification, showNotification }
}

export default function Notification() {
  const { notification, setNotification } = useNotification()
  let timer = null

  if (!notification) {
    return <div className="toast-wrap"></div>
  }

  clearTimeout(timer)
  timer = setTimeout(() => {
    setNotification(null)
  }, 3000)

  return (
    <div className="toast-wrap">
      <div className="toast">
        <i className={'ico ico-' + notification.type}></i>
        {notification.message}
      </div>
    </div>
  )
}
