import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import api from 'src/api'
import { notificationState } from 'src/recoil/atom/notification'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import { getEmailValidationMsg } from 'src/utils/Vaildiator'

export default function RequestResetPassword() {
  const location = useLocation()
  const navigate = useNavigate()

  const setPopupState = useSetRecoilState(popupState)
  const setNotificationState = useSetRecoilState(notificationState)

  const [email, setEmail] = useState('')
  const [emailErrMsg, setEmailErrMsg] = useState(null)
  const [canSubmit, setCanSubmit] = useState(false)

  const changeEmail = (e: any) => {
    const email = e.target.value
    const emailErrMsg = getEmailValidationMsg(email)

    setEmail(email)
    setEmailErrMsg(emailErrMsg)
    setCanSubmit(!emailErrMsg)
  }

  const requestChangeEmail = () => {
    setCanSubmit(false)
    api.account
      .sendResetPasswordEmail(email)
      .then(() => {
        setNotificationState({
          type: 'send',
          message: '비밀번호 재설정 메일이 전송되었어요',
        })
        setPopupState({ popupType: MainPopupType.None })
      })
      .catch((err) => {
        setCanSubmit(true)
        const { status } = err.response
        if (status === 400) {
          setEmailErrMsg('해당 메일 주소를 찾을 수 없습니다')
        } else {
          setEmailErrMsg(`내부 에러[${status}]: 관리자에게 문의해주세요`)
        }
      })
  }

  const closePopup = () => {
    // const isBlock = location.pathname.indexOf('/c/') !== -1 || location.pathname.indexOf('/u/') !== -1
    // if (isBlock) {
    //   navigate('/')
    // }
    setPopupState({ popupType: MainPopupType.None })
  }

  const renderEmailInput = () => {
    let inputClassNames = ['mt-30', 'input', 'input-lg', 'w-100']
    if (emailErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (email.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="email"
          className={inputClassNames.join(' ')}
          placeholder="이메일"
          required
          onChange={changeEmail}
          autoComplete="new-password"
        />
        {emailErrMsg && emailErrMsg.length > 0 && <p className="mt-10 color-danger">{emailErrMsg}</p>}
      </>
    )
  }

  return (
    <div className="popup-wrap mobile-page">
      <div className="popup">
        <button className="popup-close close-btn" onClick={closePopup} />
        <p className="fs-24 bold text-center">비밀번호 재설정</p>
        <p className="mt-10 fs-14 text-center">가입할 때 등록했던 이메일을 입력해 주세요.</p>
        <form className="popup-body">
          {renderEmailInput()}
          <button className="mt-30 btn btn-primary w-100" disabled={!canSubmit} onClick={requestChangeEmail}>
            비밀번호 재설정 이메일 보내기
          </button>
        </form>
      </div>
    </div>
  )
}
