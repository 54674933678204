import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { CompanyAuth } from 'src/api/types/company'
import { Message } from 'src/api/types/message'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import { dateTimeFormat } from 'src/utils/Format'

type Props = {
  userMessages: Message[]
  companyMessages: Message[]
  auth: CompanyAuth
  onHide: () => void
  onChangeAdmin: () => void
}

export default function MessageBox({ onHide, onChangeAdmin, userMessages, companyMessages, auth }: Props) {
  const navigate = useNavigate()

  const [{ account }, setAuthState] = useRecoilState(authState)
  const [tabIndex, setTabIndex] = useState(0)

  const setNotificationState = useSetRecoilState(notificationState)

  const fetchUser = () => {
    api.account
      .fetch()
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })
      })
      .catch((error) => {
        setAuthState({
          account: null,
          waiting: false,
        })
        onHide()
      })
  }

  const acceptInvite = () => {
    api.accountCompany
      .acceptInvite(auth.company.id)
      .then(() => {
        onChangeAdmin()

        setTimeout(() => {
          fetchUser()
        }, 1000)
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const denyInvite = () => {
    api.accountCompany
      .denyInvite(auth.company.id)
      .then(() => {
        setNotificationState({
          type: 'error-line',
          message: '관리자 초대를 거절했어요',
        })

        setTimeout(() => {
          fetchUser()
        }, 1000)
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
  }

  const renderTitle = () => {
    if (account.company) {
      return (
        <ul className="tabs">
          <li
            className={tabIndex === 0 ? 'active' : ''}
            onClick={() => {
              setTabIndex(0)
            }}
          >
            <span className="fs-16 bold color-text-regular">개인 메시지</span>
            {userMessages.length > 0 && <span className="badge">{userMessages.length}</span>}
          </li>
          <li
            className={tabIndex === 1 ? 'active' : ''}
            onClick={() => {
              setTabIndex(1)
            }}
          >
            <span className="fs-16 bold color-text-regular">회사 메시지</span>
            {companyMessages.length > 0 && <span className="badge">{companyMessages.length}</span>}
          </li>
        </ul>
      )
    } else {
      return (
        <ul className="tabs">
          <li>
            <span className="fs-16 bold color-text-regular">메시지</span>
            {userMessages.length > 0 && <span className="badge">{userMessages.length}</span>}
          </li>
        </ul>
      )
    }
  }

  const renderInvite = () => {
    if (!auth) {
      return <></>
    }

    return (
      <li className="auth-item">
        <p className="mt-30 fs-18 text-center">
          <span className="bold">{auth.company.admin.name}</span>님이 <span className="bold">{account.name}</span>님을
          <br />
          <span className="color-primary">{auth.company.name}</span>의 관리자로 초대 하였습니다
        </p>
        <div className="mt-20 btn-wrap">
          <button className="btn btn-outline" onClick={denyInvite}>
            거절
          </button>
          <button className="btn btn-outline-red" onClick={acceptInvite}>
            수락
          </button>
        </div>
        <hr className="mt-30 hr" />
      </li>
    )
  }

  const renderMessages = (messages: Message[]) => {
    if (messages.length === 0) {
      return (
        <div className="w-100 flex flex-center h-100" style={{ minHeight: 194 }}>
          <p className="fs-16 color-grey-500">받은 메시지가 없어요</p>
        </div>
      )
    }
    return messages.map((item) => {
      return (
        <li
          key={item.id}
          onClick={() => {
            // const items = messages.filter((message) => item.id != message.id)
            onHide()
            navigate({
              pathname: tabIndex === 0 ? '/my/messages' : 'studio/messages',
              search: `?id=${item.id}`,
            })
          }}
        >
          <p className="fs-16">{item.sender?.name || '알수없음'}님이 메시지를 보냈어요</p>
          <p className="fs-12 color-text-info" style={{ marginTop: 6 }}>
            {dateTimeFormat(item.createdAt)}
          </p>
        </li>
      )
    })
  }

  return (
    <>
      <div className="message-box">
        <div className="close-btn mobile-only" onClick={onHide}></div>
        {renderTitle()}
        <ul className="message-list" onClick={onHide}>
          {renderInvite()}
          {renderMessages(tabIndex === 0 ? userMessages : companyMessages)}
        </ul>
      </div>
    </>
  )
}
