type Props = {
  categories: any
  style?: any
  type: 'Banner' | 'Reference'
}
export default function BannerCategoryBadge({ categories, style, type }: Props) {
  const renderCategory = () => {
    if (!categories || categories.length === 0) {
      return '카테고리를 등록해 주세요'
    }

    const mainCategory = categories[0]

    let categoryText = ''
    if (mainCategory.parent) {
      categoryText += mainCategory.parent.name + ' > '
    }

    categoryText += mainCategory.name

    return categoryText
  }

  return (
    <p className={`category-badge category fs-13 bold ${type.toLowerCase()}`} style={style}>
      <span style={{ padding: '2px 6px', borderRadius: 12, }} >
        {renderCategory()}
      </span>
    </p>
  )
}
