import { useEffect, useState } from 'react'

type Props = {
  defaultUrls: string[]
  onChange: (urls: string[]) => void
  maxCount: number
}

export default function WebsiteUrlInput({ defaultUrls, onChange, maxCount = 10 }: Props) {
  const [urls, setUrls] = useState<string[]>(defaultUrls)
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    onChange(urls)
  }, [urls])

  const addUrl = () => {
    const items = urls.filter((item) => item === keyword)
    if (items.length > 0) {
      return
    }

    const data = [...urls, keyword]
    setUrls(data)
    setKeyword('')
  }

  return (
    <>
      <div className="flex flex-row">
        <input
          className="input w-100"
          placeholder="링크입력"
          value={keyword}
          onChange={(e: any) => {
            const keyword = e.target.value
            setKeyword(keyword)
          }}
        />
        <button className="ml-10 btn btn-primary btn-md" disabled={keyword.length === 0} onClick={addUrl}>
          <i className="ico ico-plus-wh ico-sm"></i>
          추가
        </button>
      </div>
      <ul className="mt-20 sns-list w-100">
        {urls.map((item, i) => {
          return (
            <li>
              <span>{item}</span>
              <div className="flex-grow-1"></div>
              <button
                className="btn btn-outline-primary delete-btn btn-sm"
                onClick={() => {
                  const items = urls.filter((url) => url !== item)
                  setUrls(items)
                }}
              >
                삭제
              </button>
            </li>
          )
        })}
      </ul>
    </>
  )
}
