import request from 'src/utils/Request'
import { CompanyAuth } from './types/company'

const accountCompanyApi = {
  // 관리자 초대 수락
  acceptInvite: (companyId: number) => {
    return request.post(`/v1/account/company/${companyId}/auth`)
  },
  // 관리자 초대 거절
  denyInvite: (companyId: number) => {
    return request.delete(`/v1/account/company/${companyId}/auth`)
  },
  // 관리자 초대 확인
  getInvite: () => {
    return request.get<CompanyAuth[]>('/v1/account/company/auth')
  },
}

export default accountCompanyApi
