import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import api from 'src/api'
import CompanyProfileImg from 'src/components/Common/CompanyProfileImg'
import Image from 'src/components/Common/Image'

type Props = {
  onCustomClick?: (item: any) => void
}

export default function BannerListItem({ onCustomClick }: Props) {
  const navigate = useNavigate()
  const location = useLocation()

  const [item, setItem] = useState(null)
  const [thumbHeight, setThumbHeight] = useState(100)
  const [show, setShow] = useState(false)

  const bannerRef = useRef(null)

  useEffect(() => {
    getMainBainner(location.pathname.indexOf('/main/tech') !== -1 ? 11 : 10)
    window.addEventListener('resize', updateBannerHeight)

    return () => {
      window.removeEventListener('resize', updateBannerHeight)
    }
  }, [])

  useEffect(() => {
    updateBannerHeight()
  }, [item])

  useEffect(() => {
    checkShowMainBanner()

    getMainBainner(location.pathname.indexOf('/main/tech') !== -1 ? 11 : 10)
  }, [location])

  useEffect(() => {
    updateBannerHeight()
  }, [show])

  const getMainBainner = function (categoryId: number) {
    setItem(null)
    api.portfolio.getMainBanner(categoryId).then((res) => {
      setItem(res.data[0])
    })
  }

  const updateBannerHeight = function () {
    if (!bannerRef.current) {
      return
    }
    const bannerWidth = bannerRef.current.offsetWidth
    setThumbHeight(((bannerWidth - 24) / 2) * 0.688)
  }

  const checkShowMainBanner = () => {
    const { search, pathname } = location
    const isReference = pathname.indexOf('/r/') !== -1
    if (isReference) {
      return
    }
    if (!search && !isReference) {
      setShow(true)
    } else {
      const searchParams = new URLSearchParams(search)
      if (searchParams.get('utm_campaign')) {
        setShow(true)
      } else if (searchParams.get('searchType') && Array.from(searchParams).length === 1) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }

  const renderCategory = () => {
    const categories = item.representativeCategory?.length > 0 ? item.representativeCategory : item.categories
    if (!categories || categories.length === 0) {
      return <></>
    }

    const mainCategory = categories[0]

    let categoryText = ''
    if (mainCategory.parent) {
      categoryText += mainCategory.parent.name + ' > '
    }

    categoryText += mainCategory.name
    return categoryText
  }

  if (!item || !show) {
    return <></>
  }

  return (
    <li className="banner" style={{ gridColumnEnd: 'span 2' }} ref={bannerRef}>
      <div
        className="thumb-wrap border-light"
        onClick={() => {
          if (onCustomClick) {
            onCustomClick(item)
          } else {
            navigate(`/r/${item.id}`)
          }
        }}
        style={{ paddingTop: thumbHeight }}
      >
        <Image img={item.thumbnailFile} />
      </div>
      <p className="category fs-13 bold category-badge banner">
        <span style={{ color: 'var(--color-red-700)', backgroundColor: '#FFE4EC' }}>{renderCategory()}</span>
      </p>
      <p className="title fs-16 bold">
        <span
          onClick={() => {
            if (onCustomClick) {
              onCustomClick(item)
            } else {
              navigate(`/r/${item.id}`)
            }
          }}
        >
          {item.title}
        </span>
      </p>
      <div
        className="company"
        onClick={() => {
          navigate('/c/' + item.company.urlKey)
        }}
      >
        <CompanyProfileImg company={item.company} size={20} />
        <span>{item.company?.name}</span>
      </div>
    </li>
  )
}
