import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import api from 'src/api'
import { dateFormat, dateFormatKr } from 'src/utils/Format'

export default function Index() {
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const [planHistory, setPlanHistory] = useState(null)

  useEffect(() => {
    fetchCompany()
  }, [])

  const fetchCompany = () => {
    setData(null)
    api.company
      .getMyCompany()
      .then((res) => {
        const data = res.data
        setData(res.data)
        fetchPlanHistory(data.id)
      })
      .catch((e) => {
        // do nothing
      })
  }

  const fetchPlanHistory = (companyId: number) => {
    api.company.getPlanHistory(companyId).then((res) => setPlanHistory(res.data.content || []))
  }

  const renderCurrentBox = () => {
    const isProfessional = data.plan?.plan?.id === 2
    const isNextPlanIsBasic = data.plan?.nextPlan?.id === 1

    if (isProfessional) {
      return (
        <div className="box">
          <p className="fs-16 bold">현재 플랜</p>
          <p className="mt-20">
            <div className="flex" style={{ alignItems: 'flex-end' }}>
              <span className="fs-24 bold">프로페셔널 플랜</span>
            </div>
            <span className="float-right flex-vertical-center fs-12 color-grey-700" style={{ gap: 4 }}>
              ₩<span className="color-primary fs-26 bold">29,000</span>
              /월
            </span>
          </p>
          <a className="mt-10 fs-12 color-grey-700" target={'_blank'} href="https://brand.bindus.kr/pricing">
            프로페셔널 플랜 상세 내용 보기 ＞
          </a>
          <button
            className="mt-20 btn btn-third btn-sm"
            style={{ width: 102 }}
            onClick={() => {
              navigate('/studio/plan/downgrade')
            }}
          >
            플랜 변경
          </button>
          {isNextPlanIsBasic && (
            <p className="mt-10 color-red-700 fs-14">
              {dateFormatKr(data.plan.expiresAt)}이후 스타터 플랜으로 전환됩니다.
            </p>
          )}
        </div>
      )
    } else {
      return (
        <div className="box">
          <p className="fs-16 bold">현재 플랜</p>
          <p className="mt-20 fs-24 bold">
            <span>스타터 플랜</span>
            <span className="float-right flex-vertical-center fs-12 color-grey-700" style={{ gap: 4 }}>
              ₩<span className="color-primary fs-26 bold">0</span>
              /월
            </span>
          </p>
          <a className="mt-10 fs-12 color-grey-700" target={'_blank'} href="https://brand.bindus.kr/pricing">
            프로페셔널 플랜 상세 내용 보기 ＞
          </a>
          <button
            className="mt-20 btn btn-third btn-sm"
            style={{ width: 102 }}
            onClick={() => {
              navigate('/studio/plan/upgrade')
            }}
          >
            플랜 변경
          </button>
        </div>
      )
    }
  }

  const renderPaymentBox = () => {
    const isBasic = data.plan?.nextPlan?.id === 1

    return (
      <div className="box">
        <p className="fs-16 bold">결제수단</p>
        <p className="mt-20 fs-12 color-grey-700">등록된 결제수단</p>
        <p className="fs-14" style={{ marginTop: 4 }}>
          {!data?.billing && '-'}
          {data?.billing?.cardCompany} {data.billing?.cardNumber}
        </p>
        <p className="mt-20 fs-12 color-grey-700">정기 결제일</p>
        <p className="fs-14" style={{ marginTop: 4 }}>
          {data.plan.expiresAt ? `매월 ${new Date(data.plan.expiresAt).getDate()}일(영업일 기준)` : '-'}
        </p>
        <p className="mt-20 fs-12 color-grey-700">다음 결제일</p>
        <p className="fs-14" style={{ marginTop: 4 }}>
          {isBasic ? '-' : data.plan.expiresAt}
        </p>
        {!isBasic && (
          <button
            className="mt-30 btn btn-third btn-sm"
            onClick={() => {
              navigate('/studio/plan/payment')
            }}
          >
            결제수단 변경
          </button>
        )}
      </div>
    )
  }

  const renderManagerBox = () => {
    return (
      <div className="box">
        <p className="fs-16 bold">사용자 정보</p>
        <p className="mt-20 fs-12 color-grey-700">담당자</p>
        <p className="fs-14" style={{ marginTop: 4 }}>
          {data.planManagerName || '-'}
        </p>
        <p className="mt-20 fs-12 color-grey-700">담당자 이메일</p>
        <p className="fs-14" style={{ marginTop: 4 }}>
          {data.planManagerEmail || '-'}
        </p>
        <p className="mt-20 fs-12 color-grey-700">담당자 연락처</p>
        <p className="fs-14" style={{ marginTop: 4 }}>
          {data.planManagerPhoneNumber || '-'}
        </p>
      </div>
    )
  }

  const renderBillBox = () => {
    return (
      <div className="box" style={{ marginTop: 12 }}>
        <p className="fs-16 bold">
          청구 이력
          <span className="color-grey-700 fs-12" style={{ marginLeft: 20 }}>
            최근 1년간의 청구이력
          </span>
        </p>
        <ul className="mt-20 payment-list">
          {planHistory?.length === 0 && (
            <p className="color-grey-400 fs-18" style={{ margin: '32px 0' }}>
              결제 이력이 없습니다
            </p>
          )}
          {planHistory?.map((item) => {
            return (
              <li>
                <div>
                  <span className="fs-14">{dateFormat(item.createdAt, '-')}</span>
                  <span className="badge">beta기간 무료</span>
                </div>
                <div>
                  <span className="discount-price">29,000</span>
                  <span className="price">0</span>
                  <span className="price-unit">원</span>
                  {/* <span className="link">청구서 보기</span> */}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const renderContent = () => {
    if (!data) {
      return <div className="spinner with-margin"></div>
    }

    return (
      <>
        {renderCurrentBox()}
        <div className="box-wrap flex" style={{ marginTop: 12, gap: 12 }}>
          {renderPaymentBox()}
          {renderManagerBox()}
        </div>
        {renderBillBox()}
      </>
    )
  }

  return (
    <div className="page plan-page bg-color-grey-50">
      <div className="box-container container">{renderContent()}</div>
    </div>
  )
}
