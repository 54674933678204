import { useEffect, useRef, useState } from 'react'
import TextArea from '../Input/TextArea'

type Props = {
  defaultPeriod?: string
  defaultScope?: string
  defaultCustomer?: string
  defaultPartner?: string
  defaultIsChecked: boolean
  onChange: (period: string, scope: string, customer: string, partner: string, isChecked: boolean) => void
}
export default function ReferenceEditDetailInfo({
  defaultPeriod,
  defaultScope,
  defaultCustomer,
  defaultPartner,
  defaultIsChecked,
  onChange,
}: Props) {
  const [period, setPeriod] = useState(defaultPeriod || '')
  const [scope, setScope] = useState(defaultScope || '')
  const [customer, setCustomer] = useState(defaultCustomer || '')
  const [partner, setPartner] = useState(defaultPartner || '')
  const [isChecked, setIsChecked] = useState(defaultIsChecked)
  const [periodErrMsg, setPeriodErrMsg] = useState(null)

  useEffect(() => {
    if (isChecked) {
      onChange(null, null, null, null, isChecked)
    } else {
      onChange(period, scope, customer, partner, isChecked)
    }
  }, [period, scope, customer, partner, isChecked, onChange])

  useEffect(() => {
    if (!period) {
      setPeriodErrMsg(null)
      return
    }
    const passChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '-', ' ']
    for (const char of period) {
      if (passChars.indexOf(char) === -1) {
        setPeriodErrMsg('숫자,대시(-),마침표(.)만 사용할 수 있습니다.')
        onChange(null, null, null, null, isChecked)
        return
      }
    }
    setPeriodErrMsg(null)
  }, [period])

  const renderPeriodInput = () => {
    let inputClassNames = ['input', 'w-100', 'fs-14']
    if (periodErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    }

    return (
      <>
        <input
          type="text"
          className={inputClassNames.join(' ')}
          placeholder={'ex) 2022.10 - 2023.02 또는 2023.02'}
          value={period}
          onChange={(e) => {
            setPeriod(e.target.value)
          }}
          disabled={isChecked}
          style={{ marginTop: 4, backgroundColor: 'white' }}
        />
        {periodErrMsg && <p className="mt-10 color-danger">{periodErrMsg}</p>}
      </>
    )
  }

  const renderBox = () => {
    return (
      <div
        className={`detail-info-box w-100 bg-color-grey-100 ${isChecked && 'disabled'}`}
        style={{ padding: 16, borderRadius: 5 }}
      >
        <p className="fs-14">수행 기간 또는 오픈 날짜</p>
        {renderPeriodInput()}
        <p className="fs-14" style={{ margin: '14px 0 4px' }}>
          수행 범위
        </p>
        <TextArea
          placeholder={'ex) HW설치, 시스템 설계, 영상기획, 화면 디자인 등'}
          defaultText={scope}
          onChange={(text) => {
            setScope(text)
          }}
          disabled={isChecked}
        />
        <p className="fs-14" style={{ margin: '14px 0 4px' }}>
          고객사
        </p>
        <TextArea
          placeholder={'고객사(클라이언트)가 있을 경우 입력해 주세요'}
          defaultText={customer}
          onChange={(text) => {
            setCustomer(text)
          }}
          disabled={isChecked}
        />
        <p className="fs-14" style={{ margin: '14px 0 4px' }}>
          협력사
        </p>
        <TextArea
          placeholder={'협업한 회사(파트너)가 있을 경우 입력해 주세요'}
          defaultText={partner}
          onChange={(text) => {
            setPartner(text)
          }}
          disabled={isChecked}
        />
      </div>
    )
  }

  return (
    <>
      {renderBox()}
      <div className="checkbox-wrap" style={{ marginTop: 14 }}>
        <input
          type="checkbox"
          className="checkbox green"
          id="detailInfoCheck"
          checked={isChecked}
          onChange={(e) => {
            const isChecked = e.target.checked
            setIsChecked(isChecked)
          }}
        />
        <label htmlFor="detailInfoCheck">입력할 상세정보가 없습니다</label>
      </div>
    </>
  )
}
