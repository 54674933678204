import { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import StudioSettingInvite from 'src/components/Popup/StudioSettingInvite'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'

export default function ChangeAuthButton() {
  const [{ account }] = useRecoilState(authState)
  const setNotificationState = useSetRecoilState(notificationState)

  const [loading, setLoading] = useState(false)
  const [authData, setAuthData] = useState(null)
  const [showInvitePopup, setShowInvitePopup] = useState(false)

  useEffect(() => {
    getAuth()
  }, [])

  const getAuth = () => {
    setLoading(true)
    api.company
      .getAuth(account.company.id)
      .then((res) => {
        setAuthData(res.data ? res.data[0] : null)
      })
      .catch(() => {
        setNotificationState({
          type: 'error',
          message: '네트워크 에러',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const invite = (userId: number) => {
    api.company
      .updateAuth(account.company.id, userId)
      .then(() => {
        setNotificationState({
          type: 'send',
          message: '스튜디오 관리자로 초대했어요',
        })
        getAuth()
        setShowInvitePopup(false)
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: e.response.data?.message || '네트워크 에러',
        })
      })
  }

  const cancelInvite = () => {
    api.company
      .cancelAuth(account.company.id, authData.user.id)
      .then(() => {
        getAuth()
        setNotificationState({
          type: 'error-line',
          message: '관리자 변경이 취소되었어요',
        })
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: e.response.data?.message || '네트워크 에러',
        })
      })
  }

  if (loading) {
    return <></>
  }

  return (
    <>
      {authData ? (
        <button
          className="btn btn-outline-red btn-sm"
          onClick={() => {
            cancelInvite()
          }}
        >
          관리자 변경 취소
        </button>
      ) : (
        <button
          className="btn btn-outline-grey btn-sm float-right"
          onClick={() => {
            setShowInvitePopup(true)
          }}
        >
          관리자 변경
        </button>
      )}
      {showInvitePopup && (
        <StudioSettingInvite
          onHide={() => {
            setShowInvitePopup(false)
          }}
          onRequest={(userId) => {
            invite(userId)
          }}
        />
      )}
    </>
  )
}
