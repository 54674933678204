import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { AccountUpdateReq } from 'src/api/types/account'
import { AccountCopyLink } from 'src/components/Common/CopyLink'
import { UserProfileImg } from 'src/components/Common/Profile'
import SocialInput from 'src/components/Common/SocialInput'
import CompanyUrlKeyInput from 'src/components/Input/CompanyUrlKeyInput'
import SaveCancelPopup from 'src/components/Popup/SaveCancel'
import Select from 'src/components/Select/Select'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import { getUsernameValidationMsg } from 'src/utils/Vaildiator'

export default function Home() {
  const navigate = useNavigate()

  const { state } = useLocation()

  const [{ account }, setAuthState] = useRecoilState(authState)
  const setNotificationState = useSetRecoilState(notificationState)

  const [username, setUsername] = useState(account.name)
  const [socials, setSocials] = useState([])
  const [usernameErrMsg, setUsernameErrMsg] = useState('')

  const [showSaveCancelPopup, setShowSaveCancelPopup] = useState(false)
  const [companyName, setCompanyName] = useState(null)
  const [companyNameErrMsg, setCompanyNameErrMsg] = useState(null)
  const [companyUrlKey, setCompanyUrlKey] = useState(null)
  const [companyActivityArea, setCompanyActivityArea] = useState('')
  const [companyDepartment, setCompanyDepartment] = useState('')
  const [companyDuty, setCompanyDuty] = useState('')
  const [companyPosition, setCompanyPosition] = useState('')
  const [profileImg, setProfileImage] = useState(null)
  const [loading, setLoading] = useState(true)

  const [canSubit, setCanSubmit] = useState(false)

  const fileInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // if (!isMobile && state && (state as any).showAside) {
    //   setTimeout(() => {
    //     window.document.body.classList.add('show-aside')
    //   })
    // }

    fetchUser()
  }, [])

  useEffect(() => {
    const usernameErrMsg = getUsernameValidationMsg(username)
    setUsernameErrMsg(usernameErrMsg)
  }, [username])

  useEffect(() => {
    if (companyName?.length === 0) {
      setCompanyNameErrMsg('회사 이름 또는 소속을 입력해 주세요')
    } else {
      setCompanyNameErrMsg(null)
    }
  }, [companyName])

  useEffect(() => {
    setCanSubmit(!usernameErrMsg && !companyNameErrMsg)
  }, [usernameErrMsg, companyNameErrMsg])

  const fetchUser = () => {
    api.account
      .fetch()
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })
        const { companyName, companyUrlKey, companyJson, socials, profileImage } = res.data
        setProfileImage(profileImage)
        setCompanyName(companyName || '')
        setCompanyUrlKey(companyUrlKey)

        try {
          const data = JSON.parse(companyJson)
          setCompanyActivityArea(data['activityArea'] || '')
          setCompanyDepartment(data['department'] || '')
          setCompanyPosition(data['position'] || '')
          setCompanyDuty(data['duty'] || '')

          let items = []
          for (const social of socials) {
            items.push({
              socialId: social.social.id,
              data: social.data,
            })
          }
          setSocials(items)
        } catch (e) {
          // do nothing
        }
      })
      .catch((error) => {
        setAuthState({
          account: null,
          waiting: false,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const uploadProfileImage = (uploadFile: File) => {
    const formData = new FormData()
    formData.append('image', uploadFile)

    setProfileImage(null)
    api.account
      .updateProfileImage(uploadFile)
      .then((res) => {
        setProfileImage(res.data.profileImage)

        setAuthState({
          account: res.data,
          waiting: false,
        })
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: e.response.data?.message || '네트워크 에러',
        })
      })
  }

  const save = () => {
    const body: AccountUpdateReq = {
      name: username,
      companyName: companyName,
      email: account.email,
      companyJson: JSON.stringify({
        activityArea: companyActivityArea,
        department: companyDepartment,
        duty: companyDuty,
        position: companyPosition,
      }),
      socials,
      sex: account.sex,
      birthday: account.birthday,
      acceptMarketing: account.marketingAgreedAt !== null,
      companyUrlKey: companyUrlKey ? companyUrlKey : null,
      country: null,
      language: null,
    }

    api.account
      .update(body)
      .then((res) => {
        setAuthState({
          account: res.data,
          waiting: false,
        })

        setNotificationState({
          type: 'check',
          message: '저장되었습니다',
        })

        navigate('/my/profile')
      })
      .catch((e) => {
        setNotificationState({
          type: 'error',
          message: e.response.data?.message || '네트워크 에러',
        })
      })

    return false
  }

  if (!account || loading) {
    return <div className="spinner with-margin"></div>
  }

  const renderNameInput = () => {
    let inputClassNames = ['input', 'fs-14', 'w-100']
    if (usernameErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (usernameErrMsg?.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="text"
          className={inputClassNames.join(' ')}
          value={username}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const username = e.target.value
            setUsername(username)
          }}
        />
        {usernameErrMsg && usernameErrMsg.length > 0 && (
          <p className="color-danger" style={{ marginTop: 6 }}>
            {usernameErrMsg}
          </p>
        )}
      </>
    )
  }

  const renderCompanyNameInput = () => {
    let inputClassNames = ['input', 'fs-14', 'w-100']
    if (companyNameErrMsg !== null) {
      inputClassNames = inputClassNames.concat(['error', 'with-icon'])
    } else if (companyNameErrMsg?.length > 0) {
      inputClassNames = inputClassNames.concat(['success', 'with-icon'])
    }

    return (
      <>
        <input
          type="text"
          className={inputClassNames.join(' ')}
          placeholder=""
          value={companyName}
          onChange={(e) => {
            setCompanyName(e.target.value)
          }}
          style={{ marginTop: 4 }}
        />
        {companyNameErrMsg && companyNameErrMsg.length > 0 && (
          <p className="color-danger" style={{ marginTop: 6 }}>
            {companyNameErrMsg}
          </p>
        )}
      </>
    )
  }

  return (
    <div className="page user-page bg-color-grey-50 profile-edit-page">
      <div className="box-container">
        <div className="box">
          <div
            className="box-inner-container box-inner-container-md flex flex-center profile-box"
            style={{ margin: 0 }}
          >
            <div className="profile-img-wrap user lg">
              {profileImg && <UserProfileImg account={account} size={100} clickable={false} />}
              <Select
                options={[
                  { label: '사진 변경', value: '1' },
                  { label: '기본 이미지로 변경', value: '0' },
                ]}
                customLabel={<button className="change-btn"></button>}
                onChange={(option) => {
                  if (option.label == '사진 변경') {
                    fileInput.current?.click()
                  } else {
                    uploadProfileImage(null)
                  }
                }}
              />
              <input
                ref={fileInput}
                type="file"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  uploadProfileImage(e.target?.files?.[0])
                }}
                accept="image/png, image/jpeg"
                hidden
              />
            </div>
            <div className="w-100">
              {renderNameInput()}
              <div className="mt-10">
                <AccountCopyLink account={account} fontSize={14} />
              </div>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="box-inner-container box-inner-container-md" style={{ margin: 0 }}>
            <p className="fs-16 bold">기본 프로필</p>
            <p className="mt-20 fs-14 color-grey-700" style={{ marginBottom: 4 }}>
              회사 이름 또는 소속
            </p>
            {renderCompanyNameInput()}
            <p className="mt-20 fs-14 color-grey-700">회사 스튜디오 주소</p>
            <p className="color-grey-600 fs-12" style={{ marginTop: 2, marginBottom: 4 }}>
              *회사 스튜디오가 있을 경우 입력해 주세요{' '}
            </p>
            <CompanyUrlKeyInput
              defaultUrlKey={companyUrlKey}
              needDuplicateCheck={true}
              placeholder="스튜디오 주소 입력"
              onChange={(urlKey) => {
                setCompanyUrlKey(urlKey)
              }}
            />
            <div className="flex mt-20 fs-14 color-grey-700">
              <p className="w-100">부서</p>
              <p className="ml-10 w-100">직책 또는 직위</p>
            </div>
            <div className="flex" style={{ marginTop: 4 }}>
              <input
                type="text"
                className="input w-100"
                value={companyDepartment}
                onChange={(e) => {
                  setCompanyDepartment(e.target.value)
                }}
              />
              <input
                type="text"
                className="ml-10 input w-100"
                value={companyPosition}
                onChange={(e) => {
                  setCompanyPosition(e.target.value)
                }}
              />
            </div>
            <p className="mt-20 fs-14 color-grey-700">
              내 활동 분야 및 전문분야
              <span className="float-right fs-12 color-grey-600">{companyActivityArea.length}/100 자</span>
            </p>
            <input
              type="text"
              className="input w-100"
              placeholder="ex) Website Mobile Web, App  Brand Promotion"
              value={companyActivityArea}
              onChange={(e) => {
                let companyActivityArea = e.target.value
                if (companyActivityArea.length > 100) {
                  companyActivityArea = companyActivityArea.substring(0, 100)
                }
                setCompanyActivityArea(companyActivityArea)
              }}
              style={{ marginTop: 4 }}
            />
          </div>
        </div>
        <div className="box" style={{ marginTop: 12 }}>
          <p className="fs-16 bold">연락처 및 소셜링크</p>
          <SocialInput
            type="User"
            defaultSocials={socials}
            onChange={(socials) => {
              setSocials(socials)
            }}
          />
        </div>
        <div className="btn-wrap page-foot-btn">
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              setShowSaveCancelPopup(true)
            }}
          >
            취소
          </button>
          <button className="ml-10 btn btn-primary" onClick={save} disabled={!canSubit}>
            저장
          </button>
        </div>
      </div>
      {showSaveCancelPopup && (
        <SaveCancelPopup
          onCancel={function (): void {
            setShowSaveCancelPopup(false)
          }}
          onOk={function (): void {
            navigate('/my/profile')
          }}
        />
      )}
    </div>
  )
}
