import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import StudioPlanUpgraded from 'src/components/Popup/Temp/StudioPlanUpgraded'

export default function UpgradePlan() {
  const navigate = useNavigate()
  const [showCompletePopup, setShowCompletePopup] = useState(false)

  return (
    <div className="page bg-color-grey-50 plan-page studio-plan-page">
      <div className="box-container container">
        <div className="mt-40 box-area">
          <div className="box box-left">
            <div className="title-area">
              <p className="fs-20 bold">스튜디오 플랜</p>
              <p className="fs-14" style={{ marginTop: 11 }}>
                레퍼런스 생산과 커뮤니케이션 활동
              </p>
            </div>
            <div className="content-area">
              <div>
                <div className="badge">무료</div>
                <p className="mt-20 fs-24 bold">스타터</p>
                <p className="fs-12 color-text-info" style={{ marginTop: 6 }}>
                  바인더스에서 회사를 효과적으로 홍보하세요
                </p>
                <p className="mt-20 fs-12 flex flex-center" style={{ gap: 5 }}>
                  <span>₩</span>
                  <span className="fs-26 bold">0</span>
                  <span>/월</span>
                </p>
                <button className="mt-30 btn btn-outline-grey w-100 color-grey-500">현재 플랜</button>
                <ul className="fs-14 plan-list">
                  <li>회사 프로필 페이지</li>
                  <li>레퍼런스 작성을 위한 기본 위젯 기능 제공</li>
                  <li>레퍼런스 작성 무제한</li>
                  <li>메인에 노출되는 레퍼런스 3개</li>
                  <li>레퍼런스 검색을 위한 태그 설정 5개</li>
                  <li>레퍼런스 검색을 위한 카테고리 설정 3개</li>
                  <li>100개 메시지 보관</li>
                  <li>회사 노출을 위한 태그 설정 5개</li>
                </ul>
              </div>
              <div>
                <div className="badge">beta기간 무료 (~10월 05일까지)</div>
                <p className="mt-20 fs-24 bold">프로페셔널</p>
                <p className="fs-12 color-text-info" style={{ marginTop: 6 }}>
                  더 많은 레퍼런스 노출로 회사를 홍보하세요
                </p>
                <p className="mt-20 fs-12 flex-center" style={{ gap: 5 }}>
                  <span>₩</span>
                  <span className="fs-26 bold color-grey-400 red-stroke">29,000</span>
                  <span className="fs-26 bold">0</span>
                  <span>/월</span>
                </p>

                <button
                  className="mt-30 btn btn-studio-gradient w-100"
                  onClick={() => {
                    const nowDate = new Date()
                    const expireDate = new Date('2023-08-01')
                    if (nowDate < expireDate) {
                      setShowCompletePopup(true)
                    } else {
                      navigate('/studio/plan/payment')
                    }
                  }}
                >
                  업그레이드
                </button>
                <ul className="fs-14 plan-list">
                  <li>회사 프로필 페이지</li>
                  <li>레퍼런스 작성을 위한 기본 위젯 기능 제공</li>
                  <li>레퍼런스 작성 무제한</li>
                  <li>
                    <span className="plus">27개</span>
                    메인에 노출되는 레퍼런스 30개
                  </li>
                  <li>
                    <span className="plus">10개</span>
                    레퍼런스 검색을 위한 태그 설정 15개
                  </li>
                  <li>
                    <span className="plus">2개</span>레퍼런스 검색을 위한 카테고리 설정 5개
                  </li>
                  <li>
                    <span className="plus">900</span>
                    1000개 메시지 보관
                  </li>
                  <li>
                    <span className="plus">5개</span>
                    회사 노출을 위한 태그 설정 10개
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="box box-right text-center">
            <div className="title-area">
              <p className="fs-20 bold">마케팅 플랜</p>
              <p className="fs-14" style={{ marginTop: 11 }}>
                회사 홍보와 인지도 상승
              </p>
              <hr className="hr mt-30" />
              <span className="mt-30 coming-soon-badge">Coming Soon</span>
              <p className="mt-30 fs-16 bold">
                마케팅 플랜은
                <br />
                베타서비스 기간 이후 오픈 예정이에요
              </p>
              <p className="mt-10 fs-12 color-text-info">조금만 기다려주세요</p>
              <p className="mt-20">
                <a href={process.env.REACT_APP_SERVICE_UPDATE_URL} className="link bold fs-12" target={'_blank'}>
                  서비스 업데이트 알림 받기 ＞
                </a>
              </p>
              <img className="mx-auto" src="/static/img/marketing_img.png" style={{ marginTop: 26 }} />
            </div>
          </div>
        </div>
      </div>
      {showCompletePopup && <StudioPlanUpgraded />}
    </div>
  )
}
