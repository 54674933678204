import { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import api from 'src/api'
import { CompanySummaryRes } from 'src/api/types/company'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import { UserProfileImg } from '../Common/Profile'

type Props = {
  placeholder?: string
  defaultText: string
  renderCustomItem?: (item: CompanySummaryRes) => any
  onChange: (item: CompanySummaryRes) => void
}

export default function AdminUserSearchInput({ defaultText, placeholder, renderCustomItem, onChange }: Props) {
  const { account } = useRecoilValue(authState)

  const [users, setUsers] = useState([])
  const [token, setToken] = useState([])

  const [keyword, setKeyword] = useState(defaultText)
  const [showResult, setShowResult] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isFocus, setIsFocus] = useState(false)
  const [loading, setLoading] = useState(false)

  const timerId = useRef(null)

  const setNotificationState = useSetRecoilState(notificationState)

  useEffect(() => {
    fetchAuthToken()
  }, [])
  useEffect(() => {
    if (!isFocus && !selectedItem) {
      setKeyword('')
    }
  }, [isFocus])

  const fetchAuthToken = () => {
    api.company.getAuthToken(account.company.id).then((res) => {
      setToken(res.data.token)
    })
  }

  const changeSearchkeyword = (e: any) => {
    const searchKeyword = e.target.value.trim()

    setKeyword(searchKeyword)
    setSelectedItem(null)
    clearTimeout(timerId.current)
    timerId.current = setTimeout(() => {
      if (searchKeyword.length > 0) {
        search(searchKeyword)
        setShowResult(true)
      } else {
        setShowResult(false)
      }
    }, 200)
  }

  const search = (searchKeyword: string) => {
    setUsers([])
    if (searchKeyword.length < 2) {
      return
    }

    setLoading(true)
    api.account
      .search(searchKeyword)
      .then((response) => {
        setUsers(response.data)
      })
      .catch(() => {
        //
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const copyLink = () => {
    const t = document.createElement('textarea')
    document.body.appendChild(t)
    t.value = `${process.env.REACT_APP_BASE_URL}/token?type=inviteStudio&token=${token}` as any
    t.select()
    document.execCommand('copy')
    document.body.removeChild(t)

    setNotificationState({
      type: 'link-copy-bl',
      message: '링크를 복사했어요',
    })
  }

  const renderResultBox = () => {
    if (!showResult) {
      return <></>
    }

    return (
      <div className="search-result-box">
        {loading && <div className="spinner mx-auto"></div>}
        <ul className="user-list">
          {users?.map((item, index) => {
            if (renderCustomItem) {
              return renderCustomItem(item)
            }

            return (
              <li
                key={index}
                onClick={() => {
                  setSelectedItem(item)

                  let keyword = item.name
                  if (item.companyName) {
                    keyword += ` [${item.companyName}]`
                  }

                  setKeyword(keyword)
                  setShowResult(false)
                  // setIsFocus(false)

                  onChange(item)
                }}
              >
                <UserProfileImg account={item} size={30} />
                <div style={{ marginLeft: 12 }}>
                  <p className="fs-14">{item.name}</p>
                  <p className="fs-12 color-grey-600">
                    {process.env.REACT_APP_BASE_URL}/u/{item.urlKey}
                  </p>
                </div>
              </li>
            )
          })}
        </ul>
        {/* {!loading && (
          <div className="search-result-footer">
            <p className="fs-13">
              찾으시는 사용자가 없으세요? <br />
              바인더스 링크를 전달해서 초대하세요!
            </p>
            <div className="copy-link-wrap">
              <p className="fs14">
                {process.env.REACT_APP_BASE_URL}/token?type=inviteStudio&token={token}
              </p>
              <button className="ml-10 btn btn-third btn-sm" onClick={copyLink}>
                복사
              </button>
            </div>
          </div>
        )} */}
      </div>
    )
  }

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef()

    useEffect(() => {
      const handleClick = (e: any) => {
        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => {
        document.removeEventListener('click', handleClick, true)
      }
    }, [ref])

    return ref
  }

  const ref = useOutsideClick(() => {
    setShowResult(false)
    if (selectedItem === null && keyword?.length > 0) {
      // setKeyword('')
      // onChange(null)
    }
  })

  return (
    <div className={`search-input ${isFocus ? '' : ''}`} ref={ref}>
      <div
        className="close-btn"
        onClick={() => {
          setShowResult(false)
          setIsFocus(false)
        }}
      ></div>
      <input
        type="text"
        className="input"
        placeholder={placeholder}
        value={keyword}
        onChange={changeSearchkeyword}
        onFocus={() => {
          setIsFocus(true)
        }}
        onBlur={() => {
          setIsFocus(false)
        }}
      />
      {renderResultBox()}
    </div>
  )
}
