type Props = {
  title?: string
  body?: string
  okText?: string
  cancelText?: string
  okClassName?: string
  cancelClassName?: string
  onOk?: () => void
  onCancel?: () => void
  showCloseBtn?: boolean
  isBlurBg?: boolean
  position?: 'center' | 'top' | 'bottom'
}
export default function Confirm({
  title = '',
  body = '',
  okText = '확인',
  cancelText = null,
  okClassName = null,
  cancelClassName = null,
  onOk,
  onCancel,
  showCloseBtn = false,
  isBlurBg = false,
  position = 'center',
}: Props) {
  return (
    <div className={`popup-wrap ${isBlurBg ? 'blur' : ''}`} style={{ zIndex: 300 }}>
      <div className={`popup text-center popup-${position}`}>
        {showCloseBtn && (
          <button
            className="close-btn popup-close"
            onClick={() => {
              onCancel?.()
            }}
          ></button>
        )}
        {title && <p className="popup-title bold" dangerouslySetInnerHTML={{ __html: title }}></p>}
        {body && <div className="popup-body" dangerouslySetInnerHTML={{ __html: body }}></div>}
        <div className="btn-wrap">
          {cancelText && (
            <button
              className={`${cancelClassName || 'btn'}`}
              onClick={() => {
                onCancel?.()
              }}
            >
              {cancelText}
            </button>
          )}

          <button
            className={`${okClassName || 'btn btn-primary'}`}
            onClick={() => {
              onOk?.()
            }}
          >
            {okText}
          </button>
        </div>
      </div>
    </div>
  )
}
