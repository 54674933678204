import { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'
import { notificationState } from 'src/recoil/atom/notification'
import popupState, { MainPopupType } from 'src/recoil/atom/popup'
import CompanyProfileImg from '../Common/CompanyProfileImg'

type Props = {
  item: any
  goNext?: () => void
  goPrev?: () => void
  onHide: () => void
  needToLogin?: () => void
  moveToCompany: () => void
  sendMessage: () => void
}

export default function ReferenceDetailWidget({ item, goNext, goPrev, moveToCompany, sendMessage, onHide }: Props) {
  const [{ account }] = useRecoilState(authState)
  const setPopupState = useSetRecoilState(popupState)

  const setNotificationState = useSetRecoilState(notificationState)
  const [isInterest, setIsInterest] = useState(item.isInterest)
  const [saveCount, setSaveCount] = useState(item.saveCount)
  const [shareCount, setShareCount] = useState(item.shareCount)

  const link = process.env.REACT_APP_BASE_URL + '/r/' + item.id

  const toggleInterest = (e) => {
    e.stopPropagation()

    if (!account) {
      setPopupState({ popupType: MainPopupType.NeedToLogin })
      return
    }

    if (isInterest) {
      api.userInterest
        .deletePortflio(item.id)
        .then(() => {
          setIsInterest(false)
          setSaveCount(saveCount - 1)
          dispatchEvent('onUserInterestUpdated', { referenceId: item.id, isInterest: false })
        })
        .catch((e) => {
          // do nothing
        })
    } else {
      api.userInterest
        .addPortfolio(item.id)
        .then(() => {
          setNotificationState({
            type: 'like_contents_on',
            message: '내 라이브러리에 저장헀어요',
          })
          setIsInterest(true)
          setSaveCount(saveCount + 1)
          dispatchEvent('onUserInterestUpdated', { referenceId: item.id, isInterest: true })
        })
        .catch((e) => {
          // do nothing
        })
    }
  }

  const dispatchEvent = (name: string, data: any) => {
    const event = new CustomEvent(name, { detail: data });
    document.dispatchEvent(event);
  }

  const copyLink = (e) => {
    e.stopPropagation()

    api.portfolio
      .getShare(item.id)
      .then(() => {
        setShareCount(shareCount + 1)
      })
      .catch((e) => {
        // do nothing
      })
    const t = document.createElement('textarea')
    document.body.appendChild(t)
    t.value = link
    t.select()
    document.execCommand('copy')
    document.body.removeChild(t)

    setNotificationState({
      type: 'link-copy-bl',
      message: '링크를 복사했어요!',
    })
  }

  return (
    <div className="reference-detail-widget">
      <div
        className="close-btn"
        onClick={(e) => {
          e.stopPropagation()
          onHide()
        }}
      ></div>
      <ul className="action-items">
        <li>
          <CompanyProfileImg company={item.company} size={36} onClick={moveToCompany} />
        </li>
        <li>
          <button
            className="message-btn"
            onClick={(e) => {
              e.stopPropagation()
              sendMessage()
            }}
          ></button>
        </li>
        <li>
          <button className={`like-btn ${isInterest ? 'active' : ''}`} onClick={toggleInterest}></button>
          <p className="fs-12">{saveCount}</p>
        </li>
        <li onClick={copyLink}>
          <button className="share-btn"> </button>
          <p className="fs-12">{shareCount}</p>
        </li>
        {goNext && (
          <li className="pc-only">
            <button
              className="next-btn"
              onClick={(e) => {
                e.stopPropagation()
                goNext()
              }}
            >
              {' '}
            </button>
          </li>
        )}
        {goPrev && (
          <li className="pc-only">
            <button
              className="prev-btn"
              onClick={(e) => {
                e.stopPropagation()
                goPrev()
              }}
            >
              {' '}
            </button>
          </li>
        )}
      </ul>
      <div
        className="float-top"
        onClick={(e) => {
          e.stopPropagation()
          document.querySelector('.reference-page').scrollTo({ top: 0, behavior: 'smooth' })
        }}
      ></div>
    </div>
  )
}
