import { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useRecoilState } from 'recoil'
import api from 'src/api'
import { IPushMessageState, pushMessageState } from 'src/recoil/atom/pushMessage'

export function usePushMessage() {
  const [pushMessage, setPushMessage] = useRecoilState<IPushMessageState>(pushMessageState)

  const showPushMessage = (props: any) => {
    setPushMessage({
      ...pushMessage,
      ...props,
    })
  }

  return { pushMessage, setPushMessage, showPushMessage }
}

export default function PushMessage() {
  const { pushMessage, setPushMessage } = usePushMessage()
  const [message, setMessage] = useState(null)
  const timer = useRef(null)
  const [show, setShow] = useState(false)
  const [cookie, setCookie] = useCookies(['showPushMessageInfo'])

  useEffect(() => {
    if (pushMessage) {
      showPushMessage()
    }
  }, [pushMessage])

  const showFadeInOut = () => {
    clearInterval(timer.current)
    let count = 0
    timer.current = setInterval(() => {
      count++
      if (count === 1) {
        setShow(true)
      } else if (count === 4) {
        setShow(false)
      } else if (count === 5) {
        setPushMessage(null)
        setMessage(null)
      } else if (count > 5) {
        clearInterval(timer.current)
      }
    }, 1000)
  }

  const checkUsePushMessage = (id: string) => {
    let data = cookie.showPushMessageInfo || {}
    if (data[id]) {
      const currentTime = new Date().getTime()
      const diffSecs = (currentTime - data[id]) / 1000
      if (diffSecs < 60 * 60) {
        return false
      }
    }

    data[id] = new Date().getTime()
    setCookie('showPushMessageInfo', data)
    return true
  }

  const showPushMessage = async () => {
    try {
      const res = await api.pushMessage.getList()
      for (const item of res.data) {
        if (item.id === pushMessage?.id && item.visible) {
          if (!checkUsePushMessage(String(item.id))) {
            return
          }

          setMessage(item.content)
          showFadeInOut()
        }
      }
    } catch {
      setPushMessage(null)
    }
  }

  if (!pushMessage || !message) {
    return <></>
  }

  return (
    <div className={`fixed-push-message ${show ? 'show' : ''}`}>
      <p className="fs-16 bold">{message}</p>
    </div>
  )
}
