import { Category } from "src/api/types/reference"

export const getMainCategoryId = (category: Category) => {
    if (!category) {
        return null
    }

    if (category.parent === null) {
        return category.id
    }
    else {
        return getMainCategoryId(category.parent)
    }
}

export const getMainPath = (category: Category) => {
    const categoryId = getMainCategoryId(category)
    if (!categoryId) {
        return null
    }

    if (categoryId === 10) {
        return '/main/creative'
    }

    if (categoryId === 11) {
        return '/main/tech'
    }

    return null
}