import { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import './assets/css/common.scss'
import AppRoutes from './components/AppRoutes'

function App() {
  SwiperCore.use([Autoplay, Pagination, Navigation])

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-T836XGM',
    })
  }, [])

  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    return (
      <BrowserRouter>
        <CookiesProvider>
          <RecoilRoot>
            <AppRoutes />
          </RecoilRoot>
        </CookiesProvider>
      </BrowserRouter>
    )
  } else {
    return (
      <CookiesProvider>
        <RecoilRoot>
          <AppRoutes />
        </RecoilRoot>
      </CookiesProvider>
    )
  }
}

export default App
