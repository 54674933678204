import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import CompanyProfileEditor from 'src/components/BlockEditor'
import { EditorBlocks } from 'src/components/BlockEditor/types'
import ManageLeftAside from 'src/components/Studio/LeftAside'
import api from 'src/api'
import authState from 'src/recoil/atom/auth'

export default function Index() {
  const navigate = useNavigate()
  const { account } = useRecoilValue(authState)
  const [editorValue, setEditorValue] = useState<EditorBlocks>([])
  const [loading, setLoading] = useState(true)

  const handleOnChange = useCallback((newValue: EditorBlocks) => {
    setEditorValue(newValue)
  }, [])
  //#endregion

  useEffect(() => {
    if (account.company?.introductionJson) {
      setEditorValue(JSON.parse(account.company.introductionJson))
    }
  }, [account])

  useEffect(() => {
    if (!account) {
      return
    }

    fetchData()
  }, [])

  const fetchData = () => {
    api.company
      .get(account.company.id)
      .then((res) => {
        const introductionJson = res.data.introductionJson
        if (!introductionJson) {
          setLoading(false)
          return
        }

        setEditorValue(JSON.parse(introductionJson))
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const save = () => {
    api.company.updateIntroductionJson(account.company.id, JSON.stringify(editorValue)).then(() => {
      navigate('/studio/profile')
    })
  }

  return (
    <div className="page studio-edit-page bg-color-grey-50">
      <div className="container box-container">
        <div>
          <CompanyProfileEditor blocks={editorValue} onBlocksChange={handleOnChange} />
        </div>
        <div className="mx-auto box-inner-container box-inner-container-sm btn-wrap" style={{ marginTop: 50 }}>
          <Link className="btn" to="/studio/profile">
            취소
          </Link>
          <button className="btn btn-primary" onClick={save}>
            저장
          </button>
        </div>
        {/* <section className="title-section">
            <div className="control-btn-wrap">
              <button className="delete-btn"></button>
              <button className="up-btn ml-10" disabled={true}></button>
              <button className="down-btn ml-10"></button>
            </div>
            <input
              className="fs-20"
              type="text"
              placeholder="타이틀을 입력해 주세요."
            />
          </section>
          <section className="vid-section">
            <div className="control-btn-wrap">
              <button className="delete-btn"></button>
              <button className="up-btn ml-10"></button>
              <button className="down-btn ml-10"></button>
            </div>
            <div className="container">
              <input
                type="text"
                className="input"
                placeholder="동영상 URL 혹은 임베드 코드를 입력해 주세요"
              />
              <button className="mt-20 upload-btn">
                <i className="ico-video"></i>동영상 임베드
              </button>
            </div>
          </section>
          <section className="vid-section">
            <div className="control-btn-wrap">
              <button className="delete-btn"></button>
              <button className="up-btn ml-10"></button>
              <button className="down-btn ml-10"></button>
            </div>
            <div className="vid-wrap">
              <iframe
                width="1014"
                height="570"
                src="https://www.youtube.com/embed/gQlMMD8auMs"
                title="BLACKPINK - ‘Pink Venom’ M/V"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </section>
          <section className="img-section">
            <div className="container">
              <p className="fs-14" style={{ marginTop: 16 }}>
                이미지를 화면 가로에 맞춰 채우려면 너비가 최소 1400픽셀 이상인
                <br className="desktop-only" />
                이미지를 업로드해 주세요.
              </p>
              <input className="hide" type="file" />
              <button className="mt-20 upload-btn">
                <i className="ico-image"></i>이미지 업로드
              </button>
            </div>
          </section>
          <section className="caption-img-section">
            <div className="container">
              <p className="fs-16 bold">이미지 업로드 후 캡션을 추가하세요!</p>
              <p className="fs-14" style={{ marginTop: 16 }}>
                이미지를 화면 가로에 맞춰 채우려면 너비가 최소 1400픽셀 이상인
                <br className="desktop-only" />
                이미지를 업로드해 주세요.
              </p>
              <input className="hide" type="file" />
              <button className="mt-20 upload-btn">
                <i className="ico-image"></i>이미지 업로드
              </button>
            </div>
          </section>
          <section className="text-section">
            <div className="control-btn-wrap">
              <button className="delete-btn"></button>
              <button className="up-btn ml-10"></button>
              <button className="down-btn ml-10"></button>
            </div>
            <textarea
              className="fs-14"
              placeholder="텍스트를 입력해 주세요."
            ></textarea>
          </section>
          <section className="caption-img-section">
            <div className="control-btn-wrap">
              <button className="delete-btn"></button>
              <button className="up-btn ml-10"></button>
              <button className="down-btn ml-10"></button>
            </div>
            <img src="/static/img/dummy/01.jpg" className="w-100" />
            <button className="caption-item-button"></button>
            <div className="caption-item-content top-start">
              <button className="close-btn"></button>
              <div className="content">
                <textarea className="fs-13" />
              </div>
            </div>
            <div className="caption-item-content top-center">
              <button className="close-btn"></button>
              <div className="content">
                <textarea className="fs-13" />
              </div>
            </div>
            <div className="caption-item-content top-end">
              <button className="close-btn"></button>
              <div className="content">
                <textarea className="fs-13" />
              </div>
            </div>
            <div className="caption-item-content bot-start">
              <button className="close-btn"></button>
              <div className="content">
                <textarea className="fs-13" />
              </div>
            </div>
            <div className="caption-item-content bot-center">
              <button className="close-btn"></button>
              <div className="content">
                <textarea className="fs-13" />
              </div>
            </div>
            <div className="caption-item-content bot-end">
              <button className="close-btn"></button>
              <div className="content">
                <textarea className="fs-13" />
              </div>
            </div>
          </section>
          <section className="add-widget-section">
            <p className="fs-16 bold">위젯추가</p>
            <ul>
              <li>
                <i className="ico-widget-title"></i>
                <p className="fs-13 bold">타이틀</p>
              </li>
              <li>
                <i className="ico-widget-video"></i>
                <p className="fs-13 bold">동영상 임베드</p>
              </li>
              <li>
                <i className="ico-widget-text"></i>
                <p className="fs-13 bold">텍스트</p>
              </li>
              <li>
                <i className="ico-widget-pic"></i>
                <p className="fs-13 bold">이미지</p>
              </li>
              <li>
                <i className="ico-widget-caption"></i>
                <p className="fs-13 bold">캡션</p>
              </li>
            </ul>
          </section> */}
      </div>
    </div>
  )
}
